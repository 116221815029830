var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('main',{staticClass:"main-content mt-0"},[_c('div',{staticClass:"page-header align-items-start min-vh-100",staticStyle:{"background-image":"url('/img/ZENDS_LOGIN_SFONDO.png')","background-repeat":"no-repeat","background-size":"cover"}},[_c('span',{staticClass:"mask bg-gradient-dark opacity-6"}),_c('div',{staticClass:"container my-auto d-flex align-items-center justify-content-center",staticStyle:{"height":"98vh"}},[_c('div',{staticClass:"row w-100 login-transition"},[_c('div',{staticClass:"col-lg-4 col-md-8 col-12 mx-auto"},[_c('div',{staticClass:"card z-index-0 fadeIn3 fadeInBottom",staticStyle:{"border-radius":"10px"}},[_c('div',{staticClass:"card-header p-0 position-relative mt-n4 mx-3 z-index-2",staticStyle:{"border-radius":"15px"}},[_c('div',{staticClass:"bg-gradient-primary py-3 pe-1 login-header"},[_c('h3',{staticClass:"text-white font-weight-bolder text-center mt-2 mb-0",staticStyle:{"font-weight":"700 !important"}},[_vm._v(" "+_vm._s(_vm.$t("Sign in"))+" ")]),_vm._m(0)])]),_c('div',{staticClass:"card-body"},[_c('form',{staticClass:"text-start",attrs:{"role":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit()}}},[_c('div',{staticClass:"input-group input-group-outline my-3",staticStyle:{"display":"block"}},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('base-input',{class:[
                          { 'has-success': passed },
                          { 'has-danger': failed },
                        ],attrs:{"required":"","type":"email","placeholder":_vm.$t('Email'),"addon-left-icon":"tim-icons icon-email-85","error":errors[0]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.email}})]}}])})],1),_c('div',{staticClass:"input-group input-group-outline mb-3",staticStyle:{"display":"block"}},[_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('base-input',{class:[
                          { 'has-success': passed },
                          { 'has-danger': failed },
                        ],attrs:{"required":"","placeholder":_vm.$t('Password'),"addon-left-icon":"tim-icons icon-lock-circle","type":"password","error":errors[0]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.password}})]}}])})],1),_c('div',{staticClass:"text-center"},[_c('base-button',{staticClass:"mb-3 btn bg-gradient-primary w-100 my-4 mb-2",attrs:{"native-type":"submit","type":"primary","size":"lg","block":""}},[_vm._v(" "+_vm._s(_vm.$t("Get Started"))+" ")])],1),_c('div',{staticClass:"pull-right text-sm text-center"},[_c('h6',[_c('a',{staticClass:"link footer-link text-primary text-gradient font-weight-bold",attrs:{"href":"/password/reset"}},[_vm._v(_vm._s(_vm.$t("Forgot Password?")))])])])])])])])])]),_vm._m(1),_c('footer',{staticClass:"footer position-absolute bottom-2 py-2 w-100"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center justify-content-lg-between"},[_c('div',{staticClass:"col-12 text-center col-md-6 my-auto mx-auto"},[_c('div',{staticClass:"copyright text-center text-sm text-white text-lg-start",staticStyle:{"width":"100%"}},[_c('i',{staticClass:"fa fa-copyright",staticStyle:{"font-weight":"200"}}),_vm._v(" "+_vm._s(_vm.$t("2013-2023 Zenyth Srl"))+" ")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-3 justify-content-center"},[_c('div',{staticClass:"text-center ms-auto"},[_c('h4',{staticClass:"px-3 text-white font-weight-bold"},[_vm._v("ZenDS")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"copyright"})])])
}]

export { render, staticRenderFns }