<template>
  <div class="content">
    <div class="row mt-3">
      <div class="col-12">
        <h4 slot="header" class="card-title">{{ $t("PLAYLIST LIST") }}</h4>
        <card card-body-classes="table-full-width">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('Search records')"
                  v-model="query"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th @click="sort('name')" class="table-header">
                    <span>
                      {{ $t("Name") }}
                      <SortIcon
                        :topColor="computedTopColor('name', 'top')"
                        :bottomColor="computedTopColor('name', 'bottom')"
                      />
                    </span>
                  </th>
                  <th>{{ $t("Container name") }}</th>
                  <th>{{ $t("action") }}</th>
                </tr>
              </thead>
              <tbody style="border-bottom: 0.0625rem solid #e3e3e3">
                <tr v-for="(t, key) in tableData" :key="key">
                  <td>{{ t.name }}</td>
                  <td>
                    <p
                      v-for="(single, k) in t.associate_playlist_container"
                      :key="k"
                    >
                      <a
                        @click="goToContainer(single.container_id)"
                        href="javascript:void(0)"
                        >{{ single.name }}</a
                      >
                    </p>
                  </td>
                  <td>
                    <!-- <i @click="edit(t.id)" class="tim-icons icon-pencil m-1" title=""
                                            style="color: var(--primary);"></i>
                                        <i v-if="t.is_deleted == false" @click="destroy(t.id)"
                                            class="tim-icons icon-simple-remove m-1" title=""
                                            style="color: var(--primary);"></i> -->
                    <button
                      @click="edit(t.id)"
                      title="Edit"
                      class="'edit btn-link"
                      type="success"
                      style="color: var(--primary)"
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </button>

                    <button
                      v-if="t.is_deleted == false && role_id !== '5'"
                      @click="destroy(t.id)"
                      title="Delete"
                      class="'delete btn-link"
                      type="success"
                      style="color: var(--primary)"
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </button>
                  </td>
                </tr>
                <place-holder-table
                  v-if="!loading"
                  :data="tableData"
                  :column="3"
                />
              </tbody>
            </table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
                {{ $t("of") }} {{ total }} {{ $t("entries") }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
import axios from "axios";
import swal from "sweetalert2";
import PlaceHolderTable from "../Tables/placeHolderTable.vue";
import SortIcon from "@/assets/customImg/SortIcon.vue";
import { getTopColor, getBottomColor } from "../../util/utils";
const url = process.env.VUE_APP_API_BASE_URL;

export default {
  components: {
    SortIcon,
    BasePagination,
    PlaceHolderTable,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  data: () => ({
    table: [],
    tableData: [],
    footerTable: ["Name", "Actions"],
    loading: true,

    query: null,

    // sort: "created_at",
    pagination: {
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
    },

    total: 0,
    role_id: 0,
    sortparam: {
      field: "name",
      sort: "asc",
    },
  }),
  async created() {
    this.$store.watch(
      () => this.$store.getters["profile/me"],
      (me) => {
        this.role_id = me.roles[0].id;
      }
    );
    await this.$store.dispatch("profile/me");
  },
  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    computedTopColor(table, arrowIcon) {
      if (arrowIcon === "top") {
        return getTopColor(table, this.sortparam);
      } else {
        return getBottomColor(table, this.sortparam);
      }
    },
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    sort: function (data) {
      this.sortparam.field = data;
      this.sortparam.sort = this.sortparam.sort == "asc" ? "desc" : "asc";
      this.getList();
    },

    async getList() {
      let params = {
        sort: {
          ...this.sortparam,
        },
        filter: {
          ...(this.query ? { name: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };

      try {
        await this.$store.dispatch("playlists/list", params);
        this.tableData = this.$store.getters["playlists/list"];
        this.total = this.$store.getters["playlists/listTotal"];
        this.loading = false;
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
      }
    },

    edit(id) {
      this.$router.push({ name: "Edit Playlist", params: { id } });
    },

    goToContainer(id) {
      this.$router.push({ name: "Playlist Associate", params: { id } });
    },

    async destroy(id) {
      var tmp = {};
      tmp["id"] = id;

      try {
        const confirmation = await swal.fire({
          title: this.$t("Are you sure?"),
          text: this.$t("You won't be able to revert this!"),
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: this.$t("Yes, delete it!"),
          buttonsStyling: false,
        });

        if (confirmation.value === true) {
          axios.post(`${url}/delete-playlist`, tmp).then((response) => {
            this.getList();
          });
          this.$notify({
            type: "success",
            message: this.$t("Playlist deleted successfully."),
            icon: "tim-icons icon-bell-55",
          });
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: error.response.data.errors[0].title,
            icon: "tim-icons icon-bell-55",
          });
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("Oops, something went wrong!"),
            icon: "tim-icons icon-bell-55",
          });
        }
      }
    },
  },
};
</script>

<style>
table tbody {
  font-size: 1rem !important;
}
.table > thead > tr > th {
  font-size: 1rem !important;
}
.table-header {
  cursor: pointer;
}
.table-header span {
  display: flex;
  align-items: center;
}
</style>
