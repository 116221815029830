<template>
  <div class="content">
    <div class="row mt-3">
      <div class="col-12">
        <h4 slot="header" class="card-title">
          {{ $t("ASSOCIATE DISPLAY WITH LICENSE") }}
        </h4>
        <card card-body-classes="table-full-width">
          <div id="app">
            <table class="table">
              <tr
                v-for="(table, key) in group"
                :key="table.id"
                v-if="key !== 'title'"
              >
                <td>
                  {{ $t("Selected Group") }} :<span
                    v-for="t in table"
                    :key="t.id"
                    v-if="key != ''"
                  >
                    | {{ t.storeparamvalue }} |
                  </span>
                </td>
              </tr>
            </table>
          </div>

          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('Search records')"
                  v-model="query"
                >
                </el-input>
              </base-input>
            </div>

            <table class="w-100" v-if="tableData.length !== 0">
              <thead class="border-bottom">
                <tr>
                  <th
                    @click="sort(table, key)"
                    v-for="(table, key) in tableData.title"
                    :key="key"
                    class="table-header"
                  >
                    <span>
                      {{ table }}
                      <SortIcon
                        :topColor="computedTopColor(table, 'top')"
                        :bottomColor="computedTopColor(table, 'bottom')"
                      />
                    </span>
                  </th>
                  <th class="text-center">{{ $t("license") }}</th>
                  <th class="text-center">{{ $t("expires after") }}</th>
                  <th class="text-center">{{ $t("Associate") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(table, key) in filteredAndSortedBlogs"
                  :key="table.id"
                  v-if="key !== ''"
                >
                  <td v-for="t in table" :key="t.id">
                    {{ t.displayparamvalue }}
                  </td>
                  <td
                    class="text-center"
                    v-if="table[0].selected_license_code === null"
                  >
                    <template v-if="licenses">
                      <el-select
                        v-model="selectedLicenses[key]"
                        placeholder="Select license"
                        @change="getLicenseData(key, $event)"
                      >
                        <el-option
                          v-for="item in licenses"
                          :key="item.license_code"
                          :label="item.license_code"
                          :value="item.license_code"
                        />
                      </el-select>
                    </template>
                  </td>
                  <td class="text-center" v-else>
                    {{ table[0].selected_license_code }}
                  </td>
                  <td class="text-center" v-if="table[0].expirydays !== '-'">
                    {{ table[0].expirydays }} {{ $t("days") }}
                  </td>
                  <td class="text-center" v-else>-</td>
                  <td v-if="table[0].gid != id" class="text-center">
                    <base-button
                      @click="associate(table[0].display_id)"
                      class="btn-sm bg-gradient-info-blue"
                      >{{ $t("Associate") }}</base-button
                    >
                  </td>
                  <td v-else class="text-center">
                    <base-button
                      @click="destroy(table[0].display_id)"
                      class="btn-sm bg-gradient-info-blue"
                      >{{ $t("Delete Associate") }}</base-button
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
                {{ $t("of") }} {{ total }} {{ $t("entries") }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="currentPage"
              :per-page="postsPerPage"
              :total="this.container.length"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";

import _ from "lodash";
import { BasePagination } from "src/components";
// import users from "./users";
import Fuse from "fuse.js";
import swal from "sweetalert2";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "src/components/ValidationError.vue";
import SortIcon from "@/assets/customImg/SortIcon.vue";
import { getTopColor, getBottomColor } from "../../util/utils";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  mixins: [formMixin],

  components: {
    SortIcon,
    ValidationError,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */

    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.tableData.total < highBound) {
        highBound = this.total;
      }

      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.total;
    },

    filteredAndSortedBlogs() {
      const data = [...this.tableData.data].sort((a, b) => {
        let modifier = 1;

        if (this.currentSortDir === "desc") modifier = -1;
        if (
          a[this.currentSort]["displayparamvalue"] <
          b[this.currentSort]["displayparamvalue"]
        ) {
          return -1 * modifier;
        }
        if (
          a[this.currentSort]["displayparamvalue"] >
          b[this.currentSort]["displayparamvalue"]
        ) {
          return 1 * modifier;
        }
        return 0;
      });

      return data;
    },
  },

  data() {
    const id = this.$route.params.id;

    return {
      currentSort: 0,
      currentSortDir: "asc",
      id: this.$route.params.id,
      currentPage: 1,
      postsPerPage: 10,

      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
      },
      tableData: [],

      associatedisplay: {
        group_id: "",
        display_id: "",
        license_code: "",
      },

      query: "",
      propsToSearch: ["firstname"],
      container: [],
      group: [],
      licenses: [],
      searchedData: [],
      fuseSearch: null,
      selectedLicenses: {},
      sortparam: {
        field: "name",
        sort: "asc",
      },
    };
  },
  methods: {
    computedTopColor(table, arrowIcon) {
      if (arrowIcon === "top") {
        return getTopColor(table, this.sortparam);
      } else {
        return getBottomColor(table, this.sortparam);
      }
    },
    getListDebounced: _.debounce(function () {
      this.getdisplay();
    }, 300),

    sort: function (title, i) {
      this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      this.currentSort = i;
      this.sortparam.field = title;
      this.sortparam.sort = this.sortparam.sort === "asc" ? "desc" : "asc";
    },
    getLicenseData(key, value) {
      this.selectedLicenses = {
        ...this.selectedLicenses,
        [key]: value,
      };
    },
    setCurrentPage(direction) {
      if (direction === -1 && this.currentPage > 1) {
        this.currentPage -= 1;
      } else if (
        direction === 1 &&
        this.currentPage < this.container.total_length / this.postsPerPage
      ) {
        this.currentPage += 1;
      }
    },

    getgroup() {
      const id = this.$route.params.id;
      axios.get(`${url}/groupgetiddata/${id}`).then((response) => {
        this.group = response.data;
      });
    },
    async associate(id) {
      this.associatedisplay.display_id = id;
      const gid = this.$route.params.id;
      this.associatedisplay.group_id = gid;
      const values = Object.values(this.selectedLicenses);
      this.associatedisplay.license_code = values[0];
      if (this.associatedisplay.license_code) {
        await axios
          .post(`${url}/associatedisplay`, this.associatedisplay)
          .then((res) => {
            this.$notify({
              type: "success",
              message: this.$t("Successfully."),
              icon: "tim-icons icon-bell-55",
            });
            this.getdisplay();
            this.getlicense();
            this.selectedLicenses = {};
          })
          .catch((e) => {
            if (e.response.status === 400) {
              this.$notify({
                type: "danger",
                message: e.response.data,
                icon: "tim-icons icon-bell-55",
              });
              this.selectedLicenses = {};
            }
          });
      } else {
        this.$notify({
          type: "danger",
          message: this.$t("Select a license code"),
          icon: "tim-icons icon-bell-55",
        });
      }
    },

    async destroy(id) {
      var group_id = this.$route.params.id;
      await axios
        .delete(`${url}/displaydeleteassociate/${id}/${group_id}`)
        .then((response) => {
          this.$notify({
            type: "success",
            message: this.$t("Successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.getdisplay();
          this.getlicense();
        })
        .catch((error) => {
          if (error.response.data.errors[0]) {
            this.$notify({
              type: "danger",
              message: this.$t("This Group still has associated Users."),
              icon: "tim-icons icon-bell-55",
            });
          } else {
            this.$notify({
              type: "danger",
              message: this.$t("Oops, something went wrong!"),
              icon: "tim-icons icon-bell-55",
            });
          }
        });
    },

    goToEdit(id) {
      this.$router.push({ name: "Edit Display", params: { id } });
    },

    async getdisplay() {
      let params = {
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.query ? { searchValue: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };
      await this.$store.dispatch("displays/get", params);
      const disData = await this.$store.getters["displays/display_list"];
      Promise.all(
        disData.data.map(async (newdata) => {
          if (newdata[0].selected_license_code) {
            const data = await this.getlicenceexpiryday(
              newdata[0].selected_license_code
            );
            newdata[0].expirydays = data;
          } else {
            newdata[0].expirydays = "-";
          }
        })
      ).then(() => {
        this.tableData = disData;
      });
    },
    getlicense() {
      const id = this.$route.params.id;
      axios.get(`${url}/getlicensedata/${id}`).then((response) => {
        this.licenses = response.data;
      });
    },
    getlicenceexpiryday(id) {
      const data = axios
        .get(`${url}/getlicenceexpiryday/${id}`)
        .then((response) => {
          return response.data;
        });
      return data;
    },
  },

  mounted() {
    // Fuse search initialization.
    (this.fuseSearch = new Fuse(this.tableData, {
      keys: ["firstname"],
      threshold: 0.3,
    })),
      this.getdisplay();
    this.getgroup();
    this.getlicense();
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    query: {
      handler: "getListDebounced",
      immediate: true,
    },

    pagination: {
      handler: "getdisplay",
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped>
table thead,
table tbody {
  font-size: 1rem;
}
table tbody tr {
  border-bottom: 1px solid #e9ecef;
}
table thead tr th {
  text-transform: uppercase;
}
table thead tr th,
table tbody tr td {
  padding: 12px 7px;
}
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

.bg-gradient-info-blue {
  background-image: linear-gradient(195deg, #49a3f1, #1a73e8) !important;
}
.table-header {
  cursor: pointer;
}
.table-header span {
  display: flex;
  align-items: center;
}
</style>
