<template>
<div >
<h4 class="card-title">Map</h4>
    <card>
        <template slot="header">
            <div class="card-category">
                <div class="col-sm-10 checkbox-radios">
                    <base-radio v-model="radios.display" name="1" inline>Display</base-radio>
                    <base-radio v-model="radios.display" name="2" inline>Store</base-radio>
                </div>
            </div>
        </template>
        <base-input>
            <!-- <el-select class="select-primary" size="large" placeholder="World"  v-model="maps.id" @change="ChangeMap"> -->
            <el-select class="select-primary" size="large" v-model="selects.usercountry" placeholder="World" @change="ChangeMap">
                <el-option v-for="option in selects" class="select-primary" :value="option.maps_name"
                    :label="option.maps_name" :key="option.id">
                </el-option>
            </el-select>
        </base-input>
        <div class="row">
             <div class="col-md-10 ml-5 table-responsive">
                <world-map style="height: 300px" :cid="selects.usercountry" :maptype="radios.display"></world-map>
            </div>
        </div>
    </card>
    </div>
</template>

<script>
import Loading from "src/components/LoadingPanel.vue";
import formMixin from "@/mixins/form-mixin";
import {
    Select,
    Option
} from "element-ui";
import {
    BaseRadio
} from "src/components/index";

const WorldMap = () => ({
    component: import("src/components/WorldMap/AsyncWorldMap.vue"),

    loading: Loading,
    delay: 200,
});

export default {
    name: "country-map-card",
    components: {
        WorldMap,
        [Option.name]: Option,
        [Select.name]: Select,
        BaseRadio,
    },
    mixins: [formMixin],
    data() {
        return {
            radios: {
                display: "1",
                store: "2",
            },

            selects: [],

            maps: {
                id: null,
            },
            selectedValue: null,
            matchingDatSet: [],
        };
    },
    methods: {
        async getMapCountry() {
            try {
                await this.$store.dispatch("countrymaps/getmapcountry");
                this.selects = await this.$store.getters["countrymaps/countrymapsdata"];
            } catch (e) {
                this.$notify({
                    type: "danger",
                    message: "Oops, something went wrong!",
                    icon: "tim-icons icon-bell-55",
                });
            }
        },
        async ChangeMap() {
            this.matchingDatSet = this.selects.allcountry.find(ele => ele.maps_name == this.selects.usercountry);
            if (this.matchingDatSet !== undefined) {
                this.selectedValue = this.matchingDatSet.id;
            }

            try {
                await this.$store.dispatch("countrymaps/changemap", this.selectedValue);
            } catch (e) {
                // console.log(e);
                this.$notify({
                    type: "danger",
                    message: "Oops, something went wrong!",
                    icon: "tim-icons icon-bell-55",
                });
            }
        },
        setProjection(type) {
            const createProjection = {
                korea: function (d3, element) {
                    const projection = d3.geoMercator().center([0, 0])
                        .rotate([-128, -36])
                        .scale(element.offsetWidth * 6)
                        .translate([element.offsetWidth / 2, element.offsetHeight / 2])
                    const path = d3.geoPath().projection(projection)
                    return {projection, path}
                },
                zoom: function (d3, element) {
                    var projection = d3.geoEquirectangular()
                        .center([23, -3])
                        .rotate([4.4, 0])
                        .scale(element.offsetHeight * 0.6)
                        .translate([element.offsetWidth / 2, element.offsetHeight / 2])
                    var path = d3.geoPath()
                        .projection(projection)
                    return {path: path, projection: projection}
                }
            }
            return createProjection[type]
        },
    },
    mounted() {
        this.getMapCountry();
    },
};
</script>

<style>
</style>
