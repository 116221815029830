<template>
  <div class="mt-3">
    <h4 slot="header" class="card-title">{{ $t("PACKAGE UPDATE") }}</h4>
    <card class="stacked-form">
      <form @submit.prevent>
        <div>
          <base-input
            label="Indicate the version of the new software (current: 3.0.0.3):"
            v-model="updatepack.name"
          />
          <span v-if="errors">
            <small class="error" v-text="errors" />
          </span>
          <div
            class="flex items-center justify-center w-full h-screen text-center"
            id="app"
          >
            <div
              class="p-12 bg-gray-100 border border-gray-300"
              @dragover="dragover"
              @dragleave="dragleave"
              @drop="drop"
            >
              <input
                type="file"
                multiple
                name="fields[assetsFieldHandle][]"
                id="assetsFieldHandle"
                class="w-px h-px opacity-0 overflow-hidden absolute"
                @change="onChange"
                ref="file"
                accept=".zip"
              />

              <label for="assetsFieldHandle" class="block cursor-pointer">
                <div>{{ $t("Drag & Drop file here") }}...</div>
              </label>

              <ul class="mt-4" v-if="this.filelist.length" v-cloak>
                <div id="preview">
                  <img src="" alt="ZIP FILE" height="100px" width="100px" />
                </div>
                <li
                  class="text-sm p-1"
                  v-for="(file, index) in filelist"
                  :key="index"
                  v-show="!type_length"
                >
                  {{ file.name }}
                </li>
                <base-alert type="danger" dismissible v-show="show">
                  <span v-if="type_length"
                    >{{ $t("Number of files selected for upload") }}
                    {{ filelist.length }}
                    {{ $t("exceeds maximum allowed limit of 1") }}.
                    {{ $t("Please retry your upload!") }}</span
                  >
                  <span v-else
                    >{{ $t("Invalid extension for file") }}
                    {{ filelist[0].name }}
                    {{ $t("Only exe files are supported") }}</span
                  >
                </base-alert>
              </ul>
            </div>
            <div style="border: 1px solid black">
              <base-button
                @click="chooseFiles()"
                class="mr-3 mt-3 float-right"
                native-type="submit"
                >{{ $t("Browse") }}..</base-button
              >
              <base-button
                @click="submitFile()"
                class="mt-3 float-right"
                native-type="submit"
                type="primary"
                v-show="showupload"
                >{{ $t("Submit") }}</base-button
              >
              <base-button
                @click="remove(filelist.indexOf(file))"
                class="mt-3 float-right"
                native-type="button"
                v-show="showupload"
                type="warning"
                title="Remove file"
                >{{ $t("Remove") }}</base-button
              >
            </div>
          </div>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
import { BaseAlert, BaseProgress } from "src/components";
import ValidationError from "src/components/ValidationError.vue";
import swal from "sweetalert2";

export default {
  components: {
    BaseProgress,
    BaseAlert,
    ValidationError,
  },
  data: () => ({
    filelist: [], // Store our uploaded files
    show: false,
    type_length: false,
    showconfirm: false,
    showupload: false,
    updatepack: {
      type: "updatepackage",
      name: null,
    },
    errors: null,
    responsesubmit: null,
    responsefile: null,
  }),
  methods: {
    onChange() {
      this.filelist = [...this.$refs.file.files];
      this.show = false;
      this.type_length = false;
      this.showconfirm = false;
      this.showupload = true;

      if (this.filelist.length != 1) {
        this.show = true;
        this.type_length = true;
        this.showupload = false;
      } else if (this.filelist[0].type != "application/zip") {
        this.show = true;
        this.showupload = false;
      }
    },
    chooseFiles() {
      document.getElementById("assetsFieldHandle").click();
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.showconfirm = false;
      this.showupload = false;
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },

    async submitFile() {
      try {
        await this.$store.dispatch("updatepackage/upload", this.filelist[0]);
        this.responsefile = await this.$store.getters["updatepackage/message"];
        if (this.responsefile == "Success") {
          this.$notify({
            type: "success",
            message: this.$t("File upload successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.showconfirm = true;
        } else {
          this.showconfirm = false;
          this.$notify({
            type: "danger",
            message: this.responsefile,
            icon: "tim-icons icon-bell-55",
          });
        }
        setInterval(() => {
          window.location.reload();
        }, 1150);
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
      }
    },
    async confirm() {
      this.errors = "";
      if (this.updatepack.name == null) {
        this.errors = "This field is required.";
      } else {
        try {
          const confirmation = await swal.fire({
            title: this.$t("Confirm update?"),
            showCancelButton: true,
            customClass: {
              confirmButton: "btn btn-success btn-fill",
              cancelButton: "btn btn-danger btn-fill",
            },
            confirmButtonText: "OK",
            buttonsStyling: false,
          });
          if (confirmation.value === true) {
            await this.$store.dispatch("updatepackage/add", this.updatepack);
            this.responsesubmit = await this.$store.getters[
              "updatepackage/updatepack"
            ];

            if (this.responsesubmit) {
              if (this.responsesubmit.message) {
                this.$notify({
                  type: "danger",
                  message: this.responsesubmit.message,
                  icon: "tim-icons icon-bell-55",
                });
              } else {
                this.$notify({
                  type: "success",
                  message: this.$t("Update inserted."),
                  icon: "tim-icons icon-bell-55",
                });
              }
            } else {
              this.$notify({
                type: "danger",
                message: this.$t("Something went wrong."),
                icon: "tim-icons icon-bell-55",
              });
            }
            window.location.reload();
          }
        } catch (error) {
          this.$notify({
            type: "danger",
            message: this.$t("Request insertion error"),
            icon: "tim-icons icon-bell-55",
          });
        }
      }
    },
  },
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}

.w-px {
  width: 1px;
}

.absolute {
  position: absolute;
}

.opacity-0 {
  opacity: 0;
}

.p-12 {
  padding: 3rem;
}

.border {
  border-width: 1px;
}

.border-gray-300 {
  border-color: #e2e8f0;
}

.bg-gray-100 {
  background-color: #f7fafc;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
