<template>
  <div>
    <main class="main-content mt-0">
      <div
        class="page-header align-items-start min-vh-100"
        style="
          background-image: url('/img/ZENDS_LOGIN_SFONDO.png');
          background-repeat: no-repeat;
          background-size: cover;
        "
      >
        <span class="mask bg-gradient-dark opacity-6"></span>
        <div
          class="container my-auto d-flex align-items-center justify-content-center"
          style="height: 98vh"
        >
          <div class="row w-100">
            <div class="col-lg-4 col-md-8 col-12 mx-auto">
              <div class="card z-index-0 fadeIn3 fadeInBottom">
                <div
                  class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                >
                  <div
                    class="bg-gradient-primary py-3 pe-1 reset-header"
                    style="border-radius: 10px"
                  >
                    <h3
                      class="text-white font-weight-bolder text-center mt-2 mb-0"
                      style="font-weight: 700 !important"
                    >
                      Reset Password
                    </h3>
                    <div class="row mt-3 justify-content-center">
                      <div class="text-center ms-auto">
                        <h4 class="px-3 text-white font-weight-bold">ZenDS</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <form
                    @submit.prevent="handleSubmit()"
                    role="form"
                    class="text-start"
                  >
                    <card class="card-login card-white">
                      <base-input
                        required
                        v-model="form.data.attributes.email"
                        type="email"
                        placeholder="Email"
                        addon-left-icon="tim-icons icon-email-85"
                      />
                      <validation-error :errors="apiValidationErrors.email" />

                      <div class="text-center">
                        <base-button
                          type="primary"
                          native-type="submit"
                          class="my-4"
                          >Send Password Reset Link</base-button
                        >
                      </div>
                    </card>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer class="footer">
          <div class="container-fluid">
            <div class="copyright"></div>
          </div>
        </footer>
        <footer class="footer position-absolute bottom-2 py-2 w-100">
          <div class="container">
            <div class="row align-items-center justify-content-lg-between">
              <div class="col-12 text-center col-md-6 my-auto mx-auto">
                <div
                  class="copyright text-center text-sm text-white text-lg-start"
                  style="width: 100%"
                >
                  <i class="fa fa-copyright" style="font-weight: 200"></i>
                  2013-2023 Zenyth Srl
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </main>
    <!-- <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <form ref="reset_password_form" @submit.prevent="handleSubmit()">
        <card class="card-login card-white">
          <template slot="header">
            <img src="/img/card-primary.png" alt="" />
            <h1 class="card-title">Reset Password</h1>
          </template>

           <base-input
              required
               v-model="form.data.attributes.email"
              type="email"
              placeholder="Email"
              addon-left-icon="tim-icons icon-email-85"
           />
          <validation-error :errors="apiValidationErrors.email" />

        <div class="text-center">
          <base-button type="primary" native-type="submit" class="my-4"
            >Send Password Reset Link</base-button
          >
        </div>
        </card>
      </form>
    </div> -->
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
export default {
  layout: "AuthLayout",
  mixins: [formMixin],
  components: { ValidationError },
  data() {
    return {
      form: {
        data: {
          type: "password-forgot",
          attributes: {
            email: "",
            redirect_url: process.env.VUE_APP_BASE_URL + "/password/email",
          },
        },
      },
    };
  },
  methods: {
    async handleSubmit() {
      if (this.$isDemo == 1) {
        this.$notify({
          type: "danger",
          message: "Password reset is disabled in the demo.",
        });
        return;
      }
      try {
        await this.$store.dispatch(
          "reset/forgotPassword",
          this.form && this.form.data
        );
        this.$notify({
          type: "success",
          message: "An email with reset password link was sent.",
        });
        this.goBack();
        this.$nextTick(() => {
          this.$refs["reset_password_form"].reset();
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "We can't find a user with that e-mail address.",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    goBack() {
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped>
.card-body {
  padding-bottom: 0px;
}

.card {
  margin-bottom: 0px;
}
.reset-page .card-login {
  padding-bottom: 0px;
}

.reset-header {
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 14%) 0px 4px 20px 0px,
    rgb(30 100 233 / 43%) 0px 7px 10px -5px !important;
}
</style>
