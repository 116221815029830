<template>
  <div class="mt-3">
    <h4 slot="header" class="card-title">{{ $t("ADD USER") }}</h4>
    <card class="stacked-form">
      <!-- <div class="col-12 text-right"><base-button @click="goBack" type="button" class="btn btn-sm btn-primary">Back to list</base-button></div> -->
      <form @submit.prevent>
        <div>
          <validation-error :errors="apiValidationErrors.attachment" />
          <base-input v-model="user.name" :label="$t('Name')" :placeholder="$t('Name')" />
          <validation-error :errors="apiValidationErrors.name" />
          <base-input
            v-model="user.email"
            :label="$t('Email')"
            type="email"
            :placeholder="$t('Enter email')"
          />
          <validation-error :errors="apiValidationErrors.email" />
          <base-input :label="$t('User Type')" v-model="user.role">
            <el-select
              class="select-primary"
              name="role"
              v-model="user.roles[0].id"
            >
              <el-option
                v-for="role in available_roles"
                :key="role.id"
                :value="role.id"
                :label="role.name"
              >
              </el-option>
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.roles" />
          <!-- <base-input
          v-model="user.password"
          label="Password"
          type="password"
          placeholder="Password"
        /></div>nfirmation"
          label="Password Confirmation"
          type="password"
          placeholder="Password Confirmation"
        />
        <validation-error :errors="apiValidationErrors.password_confirmation" /> -->
        </div>
        <div
          class="list-item"
          v-for="item in myList"
          :id="item.id"
          :order="item.attributes.user_order"
          :key="item.id"
          v-if="
            item.attributes.user_order != 0 &&
            item.attributes.user_order != null
          "
        >
          <base-input
            v-model="group[item.attributes.name + '_' + item.id]"
            :label="capital_letter(item.attributes.name)"
            :placeholder="capital_letter(item.attributes.name)"
          />
        </div>
        <base-button
          @click="add()"
          class="mt-3"
          native-type="submit"
          type="primary"
          >{{ $t("Submit") }}</base-button
        >
      </form>
    </card>
  </div>
</template>

<script>
import { ImageUpload } from "src/components/index";
import { Select, Option } from "element-ui";
import ValidationError from "src/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import axios from "axios";
import qs from "qs";
const url = process.env.VUE_APP_API_BASE_URL;
function sort(valuePath, array) {
  let path = valuePath.split(".");
  return array.sort((a, b) => {
    return getValue(a, path) - getValue(b, path);
  });

  function getValue(obj, path) {
    path.forEach((path) => (obj = obj[path]));
    return obj;
  }
}
export default {
  components: {
    ValidationError,
    ImageUpload,
    [Option.name]: Option,
    [Select.name]: Select,
  },

  mixins: [formMixin],

  data: () => ({
    user: {
      type: "users",
      name: null,
      email: null,
      password: null,
      password_confirmation: null,
      role: null,
      roles: [
        {
          id: null,
          type: "roles",
        },
      ],
      relationshipNames: ["roles"],
    },
    myList: [],
    group: [],

    available_roles: [],
  }),

  created() {
    this.getRoles();
  },

  methods: {
    capital_letter(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
    onAvatarChange(file) {
      this.images.avatar = file;
    },
    async add() {
      try {
        this.resetApiValidation();
        delete this.user.id;
        await this.$store.dispatch("users/add", this.user);
        var email = {
          email: this.user.email,
        };
        this.group.email = this.user.email;
        this.group.type = "user";
        this.user = await this.$store.getters["users/user"];
        this.sendMail(email);
        this.addGroupValue(this.group);
        this.$notify({
          type: "success",
          message: this.$t("User added successfully."),
          icon: "tim-icons icon-bell-55",
        });
        this.goBack();
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
        this.setApiValidation(e.response.data.errors);
        if (this.user.id) {
          await this.$store.dispatch("users/destroy", this.user.id);
        }
      }
    },
    sendMail(email) {
      axios.post(`${url}/send-mail`, email).then((response) => {});
    },
    addGroupValue(group_data) {
      axios
        .post(`${url}/groupadd`, qs.stringify(group_data, { encode: true }))
        .then((response) => {});
    },

    getRoles() {
      this.$store.dispatch("roles/list").then(() => {
        this.available_roles = this.$store.getters["roles/dropdown"];
        // console.log(this.available_roles);
      });
    },
    getgroup() {
      axios.get(`${url}/categories`).then((response) => {
        this.myList = sort("attributes.user_order", response.data.data);
      });
    },

    goBack() {
      this.$router.push({ name: "List Users Manager" });
    },
  },
  mounted() {
    this.getgroup();
  },
};
</script>

<style>
.list-item {
  padding: 0px !important;
  border: none !important;
}
</style>
