import { render, staticRenderFns } from "./GroupListManager.vue?vue&type=template&id=a613d990&scoped=true"
import script from "./GroupListManager.vue?vue&type=script&lang=js"
export * from "./GroupListManager.vue?vue&type=script&lang=js"
import style0 from "./GroupListManager.vue?vue&type=style&index=0&id=a613d990&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a613d990",
  null
  
)

export default component.exports