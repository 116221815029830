<template>
  <div class="modal-overlay">
    <div class="modal-container">
      <div class="model-header">
        <button class="close-button" @click="$emit('closePreviewModal')">
          <i class="tim-icons icon-simple-remove"></i>
        </button>
      </div>
      <div class="canvas-preview-detail" v-if="privewData">
        <div
          class="canvas"
          :style="{
            width: privewData.width + 'px',
            height: privewData.height + 'px',
            position: 'relative',
            overflow: 'hidden',
          }"
        >
          <div
            v-for="(container, containerIndex) in previewDetails"
            :key="containerIndex"
            :class="container.containername.split(' ').join('')"
            :style="{
              width: container.containerDesign.w + 'px',
              marginLeft: container.containerDesign.x + 'px',
              height: container.containerDesign.h + 'px',
              marginTop: container.containerDesign.y + 'px',
              position: 'absolute',
            }"
          >
            <template
              v-for="(playlistData, playlistIndex) in container.containerData
                .content_sorting"
            >
              <div
                v-for="(item, i) in availImages"
                :key="'zends' + playlistIndex + i"
              >
                <img
                  v-if="playlistData.type === 'image'"
                  :src="playlistData.img"
                  class="preview1"
                  :style="{
                    width: container.containerDesign.w + 'px',
                    height: container.containerDesign.h + 'px',
                    position: 'absolute',
                    background: '#fff',
                  }"
                  :key="playlistIndex"
                  v-show="item.index === playlistIndex"
                />
                <img
                  v-else-if="playlistData.type === 'Url'"
                  :src="`https://api.apiflash.com/v1/urltoimage?access_key=8657da721f7a4588ae338c2494c02386&wait_until=page_loaded&url=${playlistData.url}`"
                  class="preview1"
                  :style="{
                    width: container.containerDesign.w + 'px',
                    height: container.containerDesign.h + 'px',
                    position: 'absolute',
                  }"
                  :key="playlistIndex"
                  v-show="item.index === playlistIndex"
                />
                <iframe
                  v-else-if="playlistData.type === 'Html'"
                  :src="playlistData.url"
                  class="preview1"
                  :style="{
                    width: container.containerDesign.w + 'px',
                    height: container.containerDesign.h + 'px',
                    position: 'absolute',
                  }"
                  v-show="item.index === playlistIndex"
                />
                <iframe
                  v-else-if="playlistData.type === 'application'"
                  :src="playlistData.img"
                  class="preview"
                  :style="{
                    width: container.containerDesign.w + 'px',
                    height: container.containerDesign.h + 'px',
                    position: 'absolute',
                  }"
                  v-show="item.index === playlistIndex"
                />
                <video
                  v-else-if="playlistData.type === 'video'"
                  id="myvideo"
                  :style="{
                    width: container.containerDesign.w + 'px',
                    height: container.containerDesign.h + 'px',
                    position: 'absolute',
                    background: '#fff',
                  }"
                  controls
                  autoplay
                  loop
                  v-show="item.index === playlistIndex"
                >
                  <source
                    class="active"
                    :src="playlistData.img"
                    type="video/mp4"
                    :key="playlistIndex"
                  />
                </video>
                <embed
                  v-else
                  :src="playlistData.img"
                  :style="{
                    width: container.containerDesign.w + 'px',
                    height: container.containerDesign.h + 'px',
                    position: 'absolute',
                  }"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      image: "",
      design: {},
      dropdownresol: this.previewDetails[0].dropdownresol,
      availImages: [],
      currentIndex: 0,
      stopInterval: null,
    };
  },
  props: {
    privewData: {
      type: ImageData,
      required: true,
    },
    previewDetails: {
      type: Array,
    },
    design_id: {},
  },

  mounted() {
    let length;
    let name;
    this.previewDetails.map((newData) => {
      name = newData.containername;
      this.availImages.push({
        name: newData.containername,
        length: newData.containerData.content_sorting.length,
        index: 0,
      });
    });

    this.stopInterval = setInterval(() => {
      this.previewDetails.map((newData, containerIndex) => {
        this.availImages.map((data, dataIndex) => {
          if (data.name === newData.containername) {
            this.availImages[dataIndex].index = (data.index + 1) % data.length;
          }
        });
      });
    }, 10000);
  },

  beforeDestroy() {
    clearInterval(this.stopInterval);
  },

  methods: {
    closeModal() {
      this.$emit("closePreviewModal");
    },
  },
};
</script>
<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.modal-container {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  position: relative;
}

.model-header {
  width: 100%;
  height: 2.5rem;
  position: relative;
  display: flex;
  justify-content: end;
}

.close-button {
  margin: 10px 15px;
  background: none;
  border: none;
  cursor: pointer;
}

.canvas-preview-detail {
  padding: 20px;
  border-top: 1px solid #e9e9e9;
  width: 100%;
}

.canvas {
  border: 1px solid #b5b2b2;
}
</style>
