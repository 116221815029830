<template>
  <div class="content">
    <div class="row mt-3">
      <div class="col-12">
        <h4 slot="header" class="card-title">{{ $t("USER LIST") }}</h4>
        <card card-body-classes="table-full-width">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="postsPerPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('Search records')"
                  v-model="search"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>

            <table class="table">
              <thead>
                <tr>
                  <th @click="sort('username')" class="table-header">
                    <span>
                      {{ $t("NAME") }}
                      <SortIcon
                        :topColor="computedTopColor('username', 'top')"
                        :bottomColor="computedTopColor('username', 'bottom')"
                      />
                    </span>
                  </th>
                  <th @click="sort('useremail')" class="table-header">
                    <span>
                      {{ $t("EMAIL") }}
                      <SortIcon
                        :topColor="computedTopColor('useremail', 'top')"
                        :bottomColor="computedTopColor('useremail', 'bottom')"
                      />
                    </span>
                  </th>
                  <th @click="sort('role')" class="table-header">
                    <span>
                      {{ $t("ROLE") }}
                      <SortIcon
                        :topColor="computedTopColor('role', 'top')"
                        :bottomColor="computedTopColor('role', 'bottom')"
                      />
                    </span>
                  </th>
                  <th @click="sort('created_at')" class="table-header">
                    <span>
                      {{ $t("CREATED AT") }}
                      <SortIcon
                        :topColor="computedTopColor('created_at', 'top')"
                        :bottomColor="computedTopColor('created_at', 'bottom')"
                      />
                    </span>
                  </th>
                  <th>{{ $t("MODERATE CONTENT") }}</th>
                  <th>{{ $t("action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="table in filteredAndSortedBlogs" :key="table.id">
                  <td scope="row">{{ table.username }}</td>
                  <td>{{ table.useremail }}</td>
                  <td>{{ table.role }}</td>
                  <td>{{ table.created_at }}</td>
                  <td v-if="matched(table.userid) == false">
                    <input
                      type="checkbox"
                      name="mod"
                      id="mod"
                      :value="table.userid"
                      @click="check($event)"
                    />
                  </td>
                  <td v-else-if="matched(table.userid) == true">
                    <input
                      type="checkbox"
                      name="mode"
                      id="mode"
                      :value="table.userid"
                      @click="uncheck($event)"
                      checked
                    />
                  </td>
                  <td v-else>
                    <input
                      type="checkbox"
                      name="mode"
                      id="mode"
                      :value="table.userid"
                      @click="check($event)"
                    />
                  </td>
                  <td>
                    <button
                      @click="goToEdit(table.userid)"
                      title="Detail"
                      class="'details btn-link"
                      type="success"
                      style="color: var(--primary)"
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </button>
                    <button
                      @click="destroy(table.userid)"
                      title="Detail"
                      class="'details btn-link"
                      type="success"
                      style="color: var(--primary)"
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
                {{ $t("of") }} {{ total }} {{ $t("entries") }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="currentPage"
              :per-page="postsPerPage"
              :total="this.tableData.length"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
import swal from "sweetalert2";
import axios from "axios";
import SortIcon from "@/assets/customImg/SortIcon.vue";
import { getTopColor, getBottomColor } from "../../util/utils";
const url = process.env.VUE_APP_API_BASE_URL;
var obj = {};
var data = {};
export default {
  components: {
    SortIcon,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  data: () => ({
    table: [],
    footerTable: ["Name", "Created At", "Actions"],
    loading: true,

    query: null,
    currentSort: "username",
    currentSortDir: "asc",

    currentPage: 1,
    postsPerPage: 10,
    perPageOptions: [10, 25, 50, 100],
    // sort: "created_at",

    tableData: [],
    search: "",
    searchQuery: "",
    propsToSearch: ["attributes.firstname"],
    mode: "",
  }),

  computed: {
    filteredAndSortedBlogs() {
      const filteredContainers = this.tableData.filter((c) =>
        c.username.toLowerCase().match(this.search.toLowerCase())
      );

      const sortedContainers = filteredContainers
        .sort((a, b) => {
          let modifier = this.currentSortDir === "desc" ? -1 : 1;
          const aValue = a[this.currentSort].toLowerCase();
          const bValue = b[this.currentSort].toLowerCase();
          if (aValue < bValue) return -1 * modifier;
          if (aValue > bValue) return 1 * modifier;
          return 0;
        })
        .slice(
          (this.currentPage - 1) * this.postsPerPage,
          this.currentPage * this.postsPerPage
        );

      return sortedContainers;
    },

    from() {
      return this.postsPerPage * (this.currentPage - 1);
    },
    to() {
      let highBound = this.from + this.postsPerPage;

      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    total() {
      return this.tableData.length;
    },
  },

  // created(){

  // },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    computedTopColor(table, arrowIcon) {
      const sortparam = {
        field: this.currentSort,
        sort: this.currentSortDir,
      };
      if (arrowIcon === "top") {
        return getTopColor(table, sortparam);
      } else {
        return getBottomColor(table, sortparam);
      }
    },
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    check: function (e) {
      if (e.target.checked) {
        this.mod = e.target.value;

        axios.post(`${url}/moderateuser`, this.mod).then((response) => {
          this.$notify({
            type: "success",
            message: this.$t("User Moderate successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.getList();
        });
      }
    },

    uncheck: function (e) {
      if (e.target.value) {
        this.m = e.target.value;

        axios.post(`${url}/deletemoderateuser`, this.m).then((response) => {
          this.$notify({
            type: "success",
            message: this.$t("User Moderate Remove."),
            icon: "tim-icons icon-bell-55",
          });
          this.getList();
        });
      }
    },
    getmoderate() {
      axios.get(`${url}/getmoderateuser`).then((response) => {
        this.mode = response.data;
      });
    },

    matched(id) {
      if (Object.keys(this.mode).length > 0) {
        return this.mode.some(function (el) {
          return el.moderated_user_id == id;
        });
      }
    },

    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    setCurrentPage(direction) {
      if (direction === -1 && this.currentPage > 1) {
        this.currentPage -= 1;
      } else if (
        direction === 1 &&
        this.currentPage < this.tableData.length / this.postsPerPage
      ) {
        this.currentPage += 1;
      }
    },

    getList() {
      axios.get(`${url}/getusermanagerlist`).then((response) => {
        this.tableData = response.data;
      });
    },

    async destroy(id) {
      if (this.$isDemo == 1 && ["1", "2", "3"].includes(id)) {
        this.$notify({
          type: "danger",
          message: this.$t(
            "You are not allowed to change data of default users."
          ),
          icon: "tim-icons icon-bell-55",
        });
        return;
      }
      try {
        const confirmation = await swal.fire({
          title: this.$t("Are you sure?"),
          text: this.$t("You won't be able to revert this!"),
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: this.$t("Yes, delete it!"),
          buttonsStyling: false,
        });

        if (confirmation.value === true) {
          await this.$store.dispatch("users/destroy", id);
          this.$notify({
            type: "success",
            message: this.$t("User deleted successfully."),
            icon: "tim-icons icon-bell-55",
          });
          await this.getList();
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: error.response.data.errors[0].title,
            icon: "tim-icons icon-bell-55",
          });
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("Oops, something went wrong!"),
            icon: "tim-icons icon-bell-55",
          });
        }
      }
    },

    async disable(id) {
      id = { id: id };
      axios.post(`${url}/disable`, id).then((response) => {});
      await this.getList();
    },
    async sendMailLink(email) {
      obj["type"] = "password-forgot";
      obj["attributes"] = {
        email: email,
        redirect_url: process.env.VUE_APP_BASE_URL + "/password/email",
      };
      data = { data: obj };
      axios.post(`${url}/password-forgot`, data).then((response) => {
        this.$notify({
          type: "success",
          message: this.$t(
            "check your email. we've sent forgot password link."
          ),
          icon: "tim-icons icon-bell-55",
        });
      });
      await this.getList();
    },

    async enable(id) {
      id = { id: id };
      axios.post(`${url}/enable`, id).then((response) => {});
      await this.getList();
    },
    async loggedin(id) {
      id = { id: id };
      axios.post(`${url}/login`, id).then((response) => {});
    },

    goToAdd() {
      this.$router.push({ name: "Add User Manager" });
    },

    goToEdit(id) {
      this.$router.push({ name: "Edit User Manager", params: { id } });
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },

  mounted() {
    this.getList();
    this.matched();
    this.getmoderate();
  },

  watch: {
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.search = result;
    },
  },
};
</script>

<style>
.cell,
table > tbody > tr > td {
  font-size: 1rem !important;
}

.white-content .el-table table > tbody > tr > td {
  border-bottom: 0.0625rem solid #e3e3e3 !important;
  border-color: #fff;
}
</style>