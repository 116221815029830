<template>
  <div class="mt-3">
    <h4 slot="header" class="card-title">{{ $t("INSERT NEW PARAMETER") }}</h4>
    <card class="stacked-form">
      <form @submit.prevent>
        <div>
          <base-input
            v-model="category.name"
            :label="$t('Parameter Name')"
            :placeholder="$t('Parameter Name')"
          />
          <validation-error :errors="apiValidationErrors.name" />
          <base-button
            @click="add()"
            class="mt-3"
            native-type="submit"
            type="primary"
            >{{ $t("Submit") }}</base-button
          >
        </div>
      </form>
    </card>
  </div>
</template>

<script>
import ValidationError from "src/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";

export default {
  components: { ValidationError },

  mixins: [formMixin],

  data: () => ({
    category: {
      type: "categories",
      name: null,
    },
  }),

  methods: {
    async add() {
      try {
        await this.$store.dispatch("categories/add", this.category);
        this.$notify({
          type: "success",
          message: this.$t("Parameter added successfully."),
          icon: "tim-icons icon-bell-55",
        });
        this.category = await this.$store.getters["categories/category"];
        this.goBack();
      } catch (e) {
        console.log(e);
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
        this.setApiValidation(e.response.data.errors);
      }
    },

    goBack() {
      this.$router.push({ name: "List Parameter" });
    },
  },
};
</script>
y
