<template>
  <div class="mt-3">
    <h4>{{ $t("Edit Group") }}</h4>
    <card class="stacked-form">
      <div class="col-12 text-right">
        <base-button
          @click="goBack"
          type="button"
          class="btn btn-sm btn-primary"
          >{{ $t("Back to list") }}</base-button
        >
      </div>
      <span v-if="errors && errors">
        <small class="error" v-text="errors" />
      </span>
      <form @submit.prevent>
        <div
          class="list-item"
          v-for="item in myList"
          :id="item.id"
          :order="item.attributes.store_order"
          :key="item.id"
          v-if="
            item.attributes.store_order != 0 &&
            item.attributes.store_order != null
          "
        >
          <base-input
            type="text"
            v-model="my_data[item.attributes.name + '_' + item.id]"
            :label="capital_letter(item.attributes.name)"
            :placeholder="capital_letter(item.attributes.name)"
            required
          />
        </div>
        <base-button
          @click="update()"
          class="mt-3"
          native-type="submit"
          type="primary"
          >{{ $t("Submit") }}</base-button
        >
      </form>
    </card>
  </div>
</template>

<script>
import ValidationError from "src/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import axios from "axios";
import { Vue } from "vue";
const url = process.env.VUE_APP_API_BASE_URL;

function sort(valuePath, array) {
  let path = valuePath.split(".");
  return array.sort((a, b) => {
    return getValue(a, path) - getValue(b, path);
  });

  function getValue(obj, path) {
    path.forEach((path) => (obj = obj[path]));
    return obj;
  }
}

export default {
  components: { ValidationError },

  mixins: [formMixin],

  data: () => ({
    my_data: {},
    group: {},
    myList: [],
    errors: null,
  }),

  created() {
    this.getedit();
  },

  methods: {
    capital_letter(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },

    getgroup() {
      axios.get(`${url}/categories`).then((response) => {
        this.myList = sort("attributes.store_order", response.data.data);
      });
    },

    getedit(id) {
      id = this.$route.params.id;
      axios.get(`${url}/groupedit/${id}`).then((response) => {
        response.data.forEach((value, index) => {
          this.$set(this.my_data, value.name, value.storeparamvalue);
        });
      });
    },

    async update(id) {
      id = this.$route.params.id;
      this.errors = "";
      // var next = false;
      // var i = 0;
      // Object.keys(this.myList).forEach(key => {
      //   if(this.myList[key].attributes.store_order != 0){
      //     i++;
      //   }
      // });
      // Object.keys(this.my_data).forEach(key => {
      //   if (Object.keys(this.my_data).length != i) {
      //     this.errors = "* Field is required";
      //     next = true;
      //   }else if(this.my_data[key] == "" || this.my_data[key] == null){
      //         this.errors = "* Field is required";
      //         next = true;
      //       }
      // });
      // if (Object.keys(this.my_data).length != i) {
      //   this.errors = "* Field is required";
      //   next = true;
      // }
      // if (next == true) {
      //   return false;
      // }
      axios.patch(`${url}/groupupdate/${id}`, this.my_data).then((response) => {
        if (response.data == "error") {
          this.$notify({
            type: "danger",
            message: this.$t("Please Fill the correct address."),
            icon: "tim-icons icon-bell-55",
          });
        } else {
          this.$notify({
            type: "success",
            message: this.$t("Group Updated Successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.goBack();
        }
      });
    },

    goBack() {
      this.$router.push({ name: "Group List" });
    },
  },
  mounted() {
    this.getgroup();
  },
};
</script>
