<template>
  <div class="content">
    <div class="row mt-3">
      <div class="col-12">
        <h4 slot="header" class="card-title">{{ $t("CONTAINER LIST") }}</h4>
        <card card-body-classes="table-full-width">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="postsPerPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="Search records"
                  v-model="search"
                >
                </el-input>
              </base-input>
            </div>

            <table class="table">
              <thead>
                <tr>
                  <th @click="sort('name')" class="table-header">
                    <span>
                      {{ $t("Container name")
                      }}<SortIcon
                        :topColor="computedTopColor('name', 'top')"
                        :bottomColor="computedTopColor('name', 'bottom')"
                      />
                    </span>
                  </th>
                  <th>{{ $t("Created By") }}</th>
                  <th>{{ $t("Associated Template") }}</th>
                  <th>{{ $t("action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="c in filteredAndSortedBlogs" :key="c.id">
                  <!-- <td scope="row" :v-model="contid">{{ c.id }}</td> -->
                  <td>{{ c.name }}</td>
                  <td scope="row">{{ c.uname }}</td>
                  <td scope="row">{{ c.templateparamvalue }}</td>

                  <!-- <td v-isf="c.id == c.category_id"> {{ c.templateparamvalue }}  | <span  v-if="matched(c.idtemplate) == true">Now Playing</span> <span v-else>Not in Use</span></td> -->
                  <!-- <td v-else></td> -->

                  <td v-if="test(c.id) == true">
                    <span v-for="t in testing" :key="t.id">
                      <p v-if="c.id == t.category_id">
                        {{ t.templateparamvalue }} |
                        <span v-if="matched(t.idtemplate) == true">
                          {{ $t("Now Plaing") }}
                        </span>
                        <span v-else>{{ $t("Not in Use") }}</span>
                      </p></span
                    >
                  </td>

                  <!-- <td v-else></td> -->

                  <td v-if="matchedcont(c.id) == true">
                    <button
                      @click="goToEdit(c.id)"
                      title="Edit"
                      class="'edit btn-link"
                      type="success"
                      style="color: var(--primary)"
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </button>
                    <a
                      href="javascript:void(0)"
                      id="setscheduler"
                      @click="modal(c.id)"
                      >{{ $t("Set Scheduler") }}</a
                    >
                  </td>
                  <td v-else>
                    <button
                      @click="goToEdit(c.id)"
                      title="Edit"
                      class="'edit btn-link"
                      type="success"
                      style="color: var(--primary)"
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </button>
                    <button
                      @click="destroy(c.id)"
                      title="Delete"
                      class="'delete btn-link"
                      type="success"
                      style="color: var(--primary)"
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </button>

                    <a
                      href="javascript:void(0)"
                      id="setscheduler"
                      @click="modal(c.id)"
                      >{{ $t("Set Scheduler") }}</a
                    >
                  </td>
                </tr>
                <place-holder-table
                  v-if="!loading"
                  :data="filteredAndSortedBlogs"
                  :column="4"
                />
              </tbody>
            </table>
          </div>

          <modal
            :show.sync="openmodal"
            class="modal-search"
            id="setscheduler"
            :centered="false"
            :show-close="true"
            :modalContentClasses="widthcont"
          >
            <div class="row table-full-width">
              <h3>{{ $t("Scheduling settings for the current Container") }}</h3>

              <div class="col-md-12">
                <el-table :data="secondTableData">
                  <el-table-column width="400" label="Typology" align="center">
                    <div
                      class=""
                      style="text-align: justify"
                      slot-scope="{ row }"
                    >
                      <div>
                        <!-- <base-radio  v-model="radios.display" name="1" inline>Display</base-radio> -->
                        <input
                          value="1"
                          type="radio"
                          id="sf1"
                          name="scheduler_form"
                          v-model="schdular.Typologychecked"
                          @change="checkboxhide()"
                        />
                        <label for="sf1">{{ $t("Always run") }}</label>
                      </div>
                      <div>
                        <input
                          value="2"
                          id="sf2"
                          type="radio"
                          name="scheduler_form"
                          v-model="schdular.Typologychecked"
                          @change="checkboxselect()"
                        />
                        <label for="sf2">{{ $t("Run if checked") }}</label
                        ><br />
                      </div>
                      <div>
                        <input
                          value="3"
                          id="sf3"
                          type="radio"
                          name="scheduler_form"
                          v-model="schdular.Typologychecked"
                          @change="checkboxselect()"
                        />
                        <label for="sf3">{{
                          $t("Do not execute if selected")
                        }}</label
                        ><br />
                      </div>
                      <div class="row">
                        <div class="col-md-7">
                          <el-date-picker
                            type="date"
                            placeholder="Date Picker"
                            v-model="schdular.datePickerStart"
                            :disabled="row.ckdisabled"
                          >
                          </el-date-picker>
                        </div>
                        <div class="col-md-5">
                          <el-time-select
                            :disabled="row.ckdisabled"
                            v-model="schdular.timePickerStart"
                            :picker-options="{
                              start: '00:00',
                              step: '00:15',
                              end: '23:59',
                            }"
                            :placeholder="$t('Select time')"
                          >
                          </el-time-select>
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-md-7">
                          <el-date-picker
                            type="date"
                            :disabled="row.ckdisabled"
                            :placeholder="$t('Date Picker')"
                            v-model="schdular.datePickerEnd"
                          >
                          </el-date-picker>
                        </div>
                        <div class="col-md-5">
                          <el-time-select
                            :disabled="row.ckdisabled"
                            v-model="schdular.timePickerEnd"
                            :picker-options="{
                              start: '00:00',
                              step: '00:15',
                              end: '23:59',
                            }"
                            :placeholder="$t('Select time')"
                          >
                          </el-time-select>
                        </div>
                      </div>
                    </div>
                  </el-table-column>

                  <el-table-column width="100" label="Lun" align="center">
                    <div slot-scope="{ row }" class="checkbox-cell">
                      <base-checkbox
                        v-model="schdular.Lunchecked"
                        :disabled="row.ckdisabled"
                      ></base-checkbox>
                    </div>
                  </el-table-column>

                  <el-table-column width="100" label="Mar" align="center">
                    <div slot-scope="{ row }" class="checkbox-cell">
                      <base-checkbox
                        v-model="schdular.Marchecked"
                        :disabled="row.ckdisabled"
                      ></base-checkbox>
                    </div>
                  </el-table-column>

                  <el-table-column width="100" label="Mer" align="center">
                    <div slot-scope="{ row }" class="checkbox-cell">
                      <base-checkbox
                        v-model="schdular.Merchecked"
                        :disabled="row.ckdisabled"
                      ></base-checkbox>
                    </div>
                  </el-table-column>

                  <el-table-column width="100" label="Gio" align="center">
                    <div slot-scope="{ row }" class="checkbox-cell">
                      <base-checkbox
                        v-model="schdular.Giochecked"
                        :disabled="row.ckdisabled"
                      ></base-checkbox>
                    </div>
                  </el-table-column>

                  <el-table-column width="100" label="Ven" align="center">
                    <div slot-scope="{ row }" class="checkbox-cell">
                      <base-checkbox
                        v-model="schdular.Venchecked"
                        :disabled="row.ckdisabled"
                      ></base-checkbox>
                    </div>
                  </el-table-column>
                  <el-table-column width="100" label="Sab" align="center">
                    <div slot-scope="{ row }" class="checkbox-cell">
                      <base-checkbox
                        v-model="schdular.Sabchecked"
                        :disabled="row.ckdisabled"
                      ></base-checkbox>
                    </div>
                  </el-table-column>
                  <el-table-column width="100" label="Dom" align="center">
                    <div slot-scope="{ row }" class="checkbox-cell">
                      <base-checkbox
                        v-model="schdular.Domchecked"
                        :disabled="row.ckdisabled"
                      ></base-checkbox>
                    </div>
                  </el-table-column>
                </el-table>
              </div>

              <div class="text-right col-md-12">
                <base-button @click="closem()" class="" type="primary"
                  >{{ $t("Close") }}
                </base-button>
                <base-button
                  class=""
                  native-type="submit"
                  type="primary"
                  @click="addschdular(), openmodal"
                >
                  {{ $t("Confirmation") }}</base-button
                >
              </div>
            </div>
          </modal>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
                {{ $t("of") }} {{ total }} {{ $t("entries") }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="currentPage"
              :per-page="postsPerPage"
              :total="this.container.length"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  TimeSelect,
} from "element-ui";

import { BasePagination } from "src/components";
// import users from "./users";
import Fuse from "fuse.js";
import swal from "sweetalert2";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "src/components/ValidationError.vue";
import { Modal } from "@/components";
import { required } from "vee-validate/dist/rules";
import PlaceHolderTable from "../Tables/placeHolderTable.vue";
import SortIcon from "@/assets/customImg/SortIcon.vue";
import { getTopColor, getBottomColor } from "../../util/utils";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  mixins: [formMixin],

  components: {
    SortIcon,
    ValidationError,
    BasePagination,
    Modal,
    required,
    PlaceHolderTable,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [TimeSelect.name]: TimeSelect,
    [DatePicker.name]: DatePicker,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    filteredAndSortedBlogs() {
      const filteredContainers = this.container.filter((c) =>
        c.name.toLowerCase().includes(this.search.toLowerCase())
      );

      const sortedContainers = filteredContainers
        .sort((a, b) => {
          let modifier = this.currentSortDir === "desc" ? -1 : 1;
          const aValue = a[this.currentSort].toLowerCase();
          const bValue = b[this.currentSort].toLowerCase();
          if (aValue < bValue) return -1 * modifier;
          if (aValue > bValue) return 1 * modifier;
          return 0;
        })
        .slice(
          (this.currentPage - 1) * this.postsPerPage,
          this.currentPage * this.postsPerPage
        );

      return sortedContainers;
    },
    queriedData() {
      let result = this.container;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.postsPerPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.postsPerPage * (this.currentPage - 1);
    },
    total() {
      return this.container.length;
    },
  },

  data() {
    return {
      currentSort: "name",
      currentSortDir: "asc",

      currentPage: 1,
      postsPerPage: 10,
      perPageOptions: [10, 25, 50, 100],
      search: "",

      id: "",

      query: "",
      propsToSearch: ["firstname"],
      container: [],
      widthcont: "widthcont-100",
      group: [],
      licenses: [],
      searchedData: [],
      fuseSearch: null,
      openmodal: false,
      secondTableData: [
        {
          Typologychecked: "",
          Lunchecked: false,
          Marchecked: false,
          Merchecked: false,
          Giochecked: false,
          Venchecked: false,
          Sabchecked: false,
          Domchecked: false,
          ckdisabled: true,
        },
      ],

      schdular: {
        Typologychecked: 1,
        datePickerEnd: "",
        datePickerStart: "",
        timePickerStart: "",
        timePickerEnd: "",
        Lunchecked: 0,
        Marchecked: 0,
        Merchecked: 0,
        Giochecked: 0,
        Venchecked: 0,
        Sabchecked: 0,
        Domchecked: 0,
        contid: "",
      },
      searchModalVisible: true,
      usetemp: "",
      usecontainer: "",
      testing: [],
      loading: true,
    };
  },
  methods: {
    computedTopColor(table, arrowIcon) {
      const sortparam = {
        field: this.currentSort,
        sort: this.currentSortDir,
      };
      if (arrowIcon === "top") {
        return getTopColor(table, sortparam);
      } else {
        return getBottomColor(table, sortparam);
      }
    },
    matched(id) {
      if (Object.keys(this.usetemp).length > 0) {
        return this.usetemp.some(function (el) {
          return el == id;
        });
      }
    },

    matchedcont(id) {
      if (Object.keys(this.usecontainer).length > 0) {
        return this.usecontainer.some(function (el) {
          return el == id;
        });
      }
    },

    test(id) {
      if (Object.keys(this.testing).length > 0) {
        return this.testing.some(function (el) {
          return el.category_id == id;
        });
      }
    },

    modal(id) {
      this.openmodal = true;
      this.schdular.contid = id;
    },
    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    setCurrentPage(direction) {
      if (direction === -1 && this.currentPage > 1) {
        this.currentPage -= 1;
      } else if (
        direction === 1 &&
        this.currentPage < this.container.length / this.postsPerPage
      ) {
        this.currentPage += 1;
      }
    },

    checkboxselect() {
      this.secondTableData[0].ckdisabled = false;
    },
    checkboxhide() {
      this.secondTableData[0].ckdisabled = true;
      this.secondTableData[0].Lunchecked = false;
      this.secondTableData[0].Marchecked = false;
      this.secondTableData[0].Merchecked = false;
      this.secondTableData[0].Giochecked = false;
      this.secondTableData[0].Venchecked = false;
      this.secondTableData[0].Sabchecked = false;
      this.secondTableData[0].Domchecked = false;
    },

    getcontainer() {
      axios.get(`${url}/associatetemplatecontainer`).then((response) => {
        this.loading = false;
        this.container = response.data.data;
        this.temp = response.data.temp;
      });
    },

    gettemplate() {
      axios.get(`${url}/getassociatetemplatecontainer`).then((response) => {
        this.usetemp = response.data;
      });
    },

    getcont() {
      axios.get(`${url}/getnotdeletecontainer`).then((response) => {
        this.usecontainer = response.data;
      });
    },

    gettest() {
      axios.get(`${url}/asstemp`).then((response) => {
        this.testing = response.data;
      });
    },

    async addschdular() {
      try {
        axios
          .post(`${url}/addschdularcontainer`, this.schdular)
          .then((response) => {});
        this.$notify({
          type: "success",
          message: this.$t("Schdular added successfully."),
          icon: "tim-icons icon-bell-55",
        });
        // add schedule model is close
        this.openmodal = false;
        this.schdular = [];
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
        this.setApiValidation(e.response.data.errors);
      }
    },

    async destroy(id) {
      try {
        const confirmation = await swal.fire({
          title: this.$t("Are you sure?"),
          text: this.$t("You won't be able to revert this!"),
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: this.$t("Yes, delete it!"),
          buttonsStyling: false,
        });

        if (confirmation.value === true) {
          axios.delete(`${url}/deletecontainer/${id}`);
          this.$notify({
            type: "success",
            message: this.$t("Container deleted successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.getcontainer();
          this.gettemplate();
          this.gettest();
          this.getcont();
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: this.$t("This Container still has associated Users."),
            icon: "tim-icons icon-bell-55",
          });
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("Oops, something went wrong!"),
            icon: "tim-icons icon-bell-55",
          });
        }
      }
    },

    goToEdit(id) {
      this.$router.push({ name: "Edit Conatiner", params: { id } });
    },
    closem() {
      this.openmodal = false;
      this.schdular = [];
      this.schdular.Typologychecked = 2;
    },
  },

  mounted() {
    // Fuse search initialization.
    (this.fuseSearch = new Fuse(this.tableData, {
      keys: ["firstname"],
      threshold: 0.3,
    })),
      this.getcontainer();
    this.gettemplate();
    this.gettest();
    this.getcont();
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    query: {
      handler: "getListDebounced",
      immediate: true,
    },

    query(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.query);
      }
      this.searchedData = result;
    },
  },
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

.flip-transition-move {
  transition: all 0.7s;
}

.content-img {
  width: 38%;
  margin-top: -12px;
  display: flex;
}

.list-group-item {
  max-height: 50px;
}

.list-group {
  cursor: move;
}

.text-title {
  margin-left: -62px;
  margin-top: -11px;
}

.card {
  border: 0.0625rem solid #e3e3e3;
}

.card-header {
  background-color: #e3e3e3 !important;
}

table {
  width: 100% !important;
}

.modal-search .modal-dialog {
  margin: 23px auto;
  max-width: 65% !important;
  display: flex;
  justify-content: center;
  height: 50vh;
  top: 0;
  align-items: center;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 2px 0px var(--primary) inset;
  margin-right: 3px;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 35%;
  height: 31%;
  margin: 31% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: var(--primary);
}

.pickup > .mb-0 > input {
  border-radius: 25px;
  border: 1px solid #dddddd;
  background-color: #f9f9f9;
}
.table-header {
  cursor: pointer;
}
.table-header span {
  display: flex;
  align-items: center;
}
</style>

<style>
.widthcont-100 {
  height: auto;
  width: 100%;
}
</style>
