<template>
  <div class="content">
    <div class="row mt-3">
      <div class="col-12">
        <h4 slot="header" class="card-title">{{ $t("GROUP LICENSE LIST") }}</h4>
        <card card-body-classes="table-full-width">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('Search records')"
                  v-model="query"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th @click="sort('license_code')" class="table-header">
                    <span>
                      {{ $t("Code") }}
                      <SortIcon
                        :topColor="computedTopColor('license_code', 'top')"
                        :bottomColor="
                          computedTopColor('license_code', 'bottom')
                        "
                      />
                    </span>
                  </th>
                  <th @click="sort('expiry_date')" class="table-header">
                    <span>
                      {{ $t("Deadline")
                      }}<SortIcon
                        :topColor="computedTopColor('expiry_date', 'top')"
                        :bottomColor="computedTopColor('expiry_date', 'bottom')"
                      />
                    </span>
                  </th>
                  <th @click="sort('gdata')" class="table-header">
                    <span>
                      {{ $t("Store") }}
                      <SortIcon
                        :topColor="computedTopColor('gdata', 'top')"
                        :bottomColor="computedTopColor('gdata', 'bottom')"
                      />
                    </span>
                  </th>
                  <th @click="sort('display_number')" class="table-header">
                    <span>
                      {{ $t("Usable Displays") }}
                      <SortIcon
                        :topColor="computedTopColor('display_number', 'top')"
                        :bottomColor="
                          computedTopColor('display_number', 'bottom')
                        "
                      />
                    </span>
                  </th>
                  <th @click="sort('display_use')" class="table-header">
                    <span>
                      {{ $t("Display in Use") }}
                      <SortIcon
                        :topColor="computedTopColor('display_use', 'top')"
                        :bottomColor="computedTopColor('display_use', 'bottom')"
                      />
                    </span>
                  </th>
                  <th>{{ $t("action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(tab, key) in tableData.data" :key="key">
                  <td scope="row">{{ tab[0].license_code }}</td>
                  <td scope="row">{{ tab[0].expiry_date }}</td>
                  <td scope="row">
                    <span v-for="(t, k) in tab" :key="k">{{ t.gdata }} | </span>
                  </td>
                  <td scope="row">{{ tab[0].display_number }}</td>
                  <td scope="row">{{ tab[0].display_use }}</td>
                  <td scope="row">
                    <button
                      @click="destroy(tab[0].license_code)"
                      title="Delete"
                      class="'delete btn-link"
                      type="success"
                      style="color: var(--primary)"
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </button>
                    <button
                      @click="goToEdit(tab[0].license_code)"
                      title="Edit"
                      class="'edit btn-link"
                      type="success"
                      style="color: var(--primary)"
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </button>
                    <button
                      @click="getpdf(tab[0].id)"
                      title="PDF"
                      class="'delete btn-link"
                      type="success"
                      style="color: var(--primary)"
                    >
                      <i class="tim-icons icon-paper"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
                {{ $t("of") }} {{ total }} {{ $t("entries") }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationError from "src/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import swal from "sweetalert2";
import { Table, TableColumn, DatePicker, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
import SortIcon from "@/assets/customImg/SortIcon.vue";
import { getTopColor, getBottomColor } from "../../util/utils";
import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  components: {
    SortIcon,
    ValidationError,
    BasePagination,
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  mixins: [formMixin],

  data() {
    const id = this.$route.params.id;

    return {
      sortparam: {
        field: "license_code",
        sort: "asc",
      },
      table: [],
      tableData: [],
      currentPage: 1,
      query: "",
      selects: {
        simple: "",
        gbs: "",
        licenses: [
          { value: "Quarrel", label: "Quarrel" },
          { value: "Gold", label: "Gold" },
        ],
        gb: [
          { value: "1", label: "1" },
          { value: "2", label: "2" },
          { value: "3", label: "3" },
          { value: "4", label: "4" },
          { value: "5", label: "5" },
          { value: "6", label: "6" },
          { value: "7", label: "7" },
          { value: "8", label: "8" },
          { value: "9", label: "9" },
          { value: "10", label: "10" },
          { value: "11", label: "11" },
          { value: "12", label: "12" },
          { value: "13", label: "13" },
          { value: "14", label: "14" },
          { value: "15", label: "15" },
          { value: "16", label: "16" },
          { value: "17", label: "17" },
          { value: "18", label: "18" },
          { value: "19", label: "19" },
          { value: "Unlimited", label: "Unlimited" },
        ],
      },

      license: {
        type: "licenses",
        display_number: null,
        display_use: null,
        group_id: id,
        expiry_date: "",
        gb: "",
        view_statistics: false,
        webcam_statistics: false,
        wifi_statistics: false,
        licensedetails: [],
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
      },
      currentSort: "group_name",
      currentSortDir: "asc",
    };
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.tableData.total < highBound) {
        highBound = this.total;
      }

      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.total;
    },
  },
  methods: {
    computedTopColor(table, arrowIcon) {
      if (arrowIcon === "top") {
        return getTopColor(table, this.sortparam);
      } else {
        return getBottomColor(table, this.sortparam);
      }
    },
    setCurrentPage(direction) {
      if (direction === -1 && this.currentPage > 1) {
        this.currentPage -= 1;
      } else if (
        direction === 1 &&
        this.currentPage < this.tableData.length / this.tableData.perPage
      ) {
        this.currentPage += 1;
      }
    },
    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
      this.sortparam.field = s;
      this.sortparam.sort = this.sortparam.sort == "asc" ? "desc" : "asc";
      this.getgroup();
    },
    async getgroup() {
      const id = this.$route.params.id;
      let params = {
        sort: {
          ...this.sortparam,
        },
        // ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.query ? { searchValue: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };
      await axios
        .get(`${url}/getallgroupdata/${id}/${JSON.stringify(params)}`)
        .then((response) => {
          this.tableData = response.data;
        });
    },

    goToEdit(id) {
      this.$router.push({ name: "Edit License", params: { id } });
    },
    async destroy(code) {
      try {
        const confirmation = await swal.fire({
          title: this.$t("Are you sure?"),
          text: this.$t("You want to delete the license!"),
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: this.$t("Yes, delete it!"),
          buttonsStyling: false,
        });

        if (confirmation.value === true) {
          await axios.post(`${url}/removelicense/${code}`);
          this.$notify({
            type: "success",
            message: this.$t("License deleted successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.getlicenses();
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
      }
    },

    goBack() {
      this.$router.push({ name: "Group List" });
    },
  },

  mounted() {
    this.getgroup();
  },
};
</script>
