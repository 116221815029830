<template>
  <div class="mt-3">
    <h4>{{ $t("EDIT TEMPLATE") }}</h4>
    <card class="stacked-form">
      <div class="col-12 text-right">
        <base-button
          @click="goBack"
          type="button"
          class="btn btn-sm btn-primary"
          >{{ $t("Back to list") }}</base-button
        >
      </div>
      <span v-if="errors && errors">
        <small class="error" v-text="errors" />
      </span>
      <form @submit.prevent>
        <div
          class="list-item ml-2"
          v-for="item in myList"
          :id="item.id"
          :order="item.attributes.template_order"
          :key="item.id"
          v-if="
            item.attributes.template_order != 0 &&
            item.attributes.template_order != null
          "
        >
          <base-input
            type="text"
            v-model="my_data[item.attributes.name + '_' + item.id]"
            :label="capital_letter(item.attributes.name)"
            :placeholder="capital_letter(item.attributes.name)"
            required
          />
        </div>
        <base-button
          @click="update()"
          class="mt-3"
          native-type="submit"
          type="primary"
          >{{ $t("Submit") }}</base-button
        >
      </form>
    </card>
    <hr />
    <h4>{{ $t("ASSOCIATED DESIGN") }}</h4>
    <card class="stacked-form">
      <span v-if="errors && errors">
        <small class="error" v-text="errors" />
      </span>
      <form @submit.prevent>
        <div class="list-item ml-2">
          <img :src="design" alt="img" height="auto" width="160px" />
        </div>
        <base-button
          @click="editdesign()"
          class="mt-3"
          native-type="submit"
          type="primary"
          >{{ $t("Edit Design") }}</base-button
        >
      </form>
    </card>
    <hr />
    <h4>{{ $t("TEMPLATE PARAMETERS") }}</h4>
    <card class="stacked-form">
      <span v-if="errors && errors">
        <small class="error" v-text="errors" />
      </span>
      <form @submit.prevent>
        <div class="col-md-4">
          {{ my_data.second }}
          <base-input
            :label="$t('Non-activity timer (seconds):')"
            v-model="timertemp.timer"
          />
          <base-button
            @click="timer()"
            class="mt-3"
            native-type="submit"
            type="primary"
            >{{ $t("Update") }}</base-button
          >
        </div>
      </form>
    </card>
  </div>
</template>

<script>
import ValidationError from "src/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import axios from "axios";
import swal from "sweetalert2";
import { Vue } from "vue";
const url = process.env.VUE_APP_API_BASE_URL;

function sort(valuePath, array) {
  let path = valuePath.split(".");
  return array.sort((a, b) => {
    return getValue(a, path) - getValue(b, path);
  });

  function getValue(obj, path) {
    path.forEach((path) => (obj = obj[path]));
    return obj;
  }
}

export default {
  components: { ValidationError },

  mixins: [formMixin],

  data: () => ({
    my_data: {},
    group: {},
    myList: [],
    errors: null,
    design: "",
    designid: "",
    timertemp: {
      timer: "",
    },
  }),

  created() {
    this.getedit();
  },

  methods: {
    capital_letter(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },

    getgroup() {
      axios.get(`${url}/categories`).then((response) => {
        this.myList = sort("attributes.template_order", response.data.data);
      });
    },

    getedit(id) {
      id = this.$route.params.id;
      axios.get(`${url}/edittemplate/${id}`).then((response) => {
        response.data.forEach((value, index) => {
          this.$set(this.my_data, value.name, value.templateparamvalue);
          // console.log(value);
          this.timertemp.timer = value.second;
          this.designid = value.designid;
          this.design = value.filename;
        });
        // this.timertemp = response.data[0].second
      });
    },

    async update(id) {
      id = this.$route.params.id;
      this.errors = "";
      var next = false;
      var i = 0;
      Object.keys(this.myList).forEach((key) => {
        if (this.myList[key].attributes.template_order != 0) {
          i++;
        }
      });
      Object.keys(this.my_data).forEach((key) => {
        if (Object.keys(this.my_data).length != i) {
          this.errors = "* Field is required";
          next = true;
        } else if (this.my_data[key] == "" || this.my_data[key] == null) {
          this.errors = "* Field is required";
          next = true;
        }
      });
      if (Object.keys(this.my_data).length != i) {
        this.errors = "* Field is required";
        next = true;
      }
      if (next == true) {
        return false;
      }

      axios
        .patch(`${url}/templateupdate/${id}`, this.my_data)
        .then((response) => {
          this.goBack();
        });
    },

    goBack() {
      this.$router.push({ name: "List Template" });
    },

    editdesign() {
      var id = this.designid;
      this.$router.push({ name: "Edit Design", params: { id } });
    },

    timer(id) {
      // console.log(this.timertemp);
      id = this.$route.params.id;
      axios.patch(`${url}/updatetimer/${id}`, this.timertemp).then();
      const test = swal.fire({
        title: "Success",
        text: this.$t("Activity timer updated."),
        timer: 2000,
        showConfirmButton: false,
      });
    },
  },

  mounted() {
    this.getgroup();
  },
};
</script>

<style>
.swal2-show {
  background-color: white;
}
</style>
