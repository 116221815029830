<template>
  <div class="mt-3">
    <h4>{{ $t("TEMPLATE INSERTION") }}</h4>
    <form @submit.prevent>
      <card class="stacked-form">
        <div class="row">
          <h4 class="ml-4">
            {{ $t("Selected display") }} :
            <span v-for="t in container" :key="t.id"
              >{{ t.displayparamvalue }} |
            </span>
          </h4>
        </div>

        <base-button
          @click="save()"
          class="btn-sm"
          native-type="submit"
          type="primary"
          >{{ $t("Submit") }}</base-button
        >
      </card>
      <div class="row mt-5">
        <div class="col-6">
          <card class="stacked-form" title="TEMPLATE LISTING">
            <div>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <el-select
                  class="select-primary mb-3 pagination-select"
                  v-model="pagination.perPage"
                  :placeholder="$t('Per page')"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>

                <base-input>
                  <el-input
                    type="search"
                    class="mb-3 search-input"
                    clearable
                    prefix-icon="el-icon-search"
                    :placeholder="$t('Search records')"
                    v-model="query"
                    aria-controls="datatables"
                  >
                  </el-input>
                </base-input>
              </div>
              <div id="app">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        @click="sortChange(table)"
                        v-for="(table, key) in tableData.title"
                        :key="key"
                        class="table-header"
                      >
                        <span>
                          {{ $t(table) }}
                          <SortIcon
                            :topColor="computedTopColor(table, 'top')"
                            :bottomColor="computedTopColor(table, 'bottom')"
                          />
                        </span>
                      </th>
                      <th>{{ $t("action") }}</th>
                      <th>{{ $t("Software Version") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(table, key) in tableData.data" :key="key">
                      <td v-for="t in table" :key="t.id">
                        {{ t.templateparamvalue }}
                      </td>
                      <td>
                        <img
                          :src="table[0].filename"
                          alt="preview"
                          width="160px"
                          height="auto"
                          style="border: 1px solid"
                        />
                      </td>
                      <td>
                        <base-button
                          title="Edit"
                          class="'details btn-link"
                          type="success"
                          style="color: var(--primary)"
                        >
                          <i
                            @click="
                              add(
                                table[0].idtemplate,
                                table[0].templateparamvalue
                              )
                            "
                            class="tim-icons icon-simple-add m-3"
                            title=""
                            style="color: var(--primary)"
                          ></i
                        ></base-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                slot="footer"
                class="col-12 d-flex justify-content-cent</div>er justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
                    {{ $t("of") }} {{ total }} {{ $t("entries") }}
                  </p>
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.postsPerPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>
          </card>
        </div>
        <div class="col-6">
          <card
            class="stacked-form"
            title="SORTING PLAYLISTS OF TEMPLATES FOR THE DISPLAY"
          >
            <div
              class="col-12 justify-content-center justify-content-sm-between flex-wrap"
            >
              <draggable v-model="getplaylist.content_sorting">
                <!-- {{getplaylist}} -->
                <transition-group name="flip-transition">
                  <ul
                    :key="key"
                    class="list-group"
                    v-for="(data, key) in getplaylist.content_sorting"
                  >
                    <li class="list-group-item">
                      <div>
                        <span class="text-title font-weight-bold ml-2"
                          >{{ data.templateparamvalue }} |
                        </span>
                      </div>
                    </li>
                    <span>
                      <a
                        href="javascript:void(0)"
                        @click="remove(key)"
                        class="m-1"
                        >{{ $t("Remove") }}</a
                      >
                      |<a
                        href="javascript:void(0)"
                        id="setscheduler"
                        @click="modal(data.idtemplate)"
                        class="m-1"
                        >{{ $t("Set scheduler") }}</a
                      >
                    </span>
                  </ul>
                </transition-group>
              </draggable>
            </div>
          </card>
        </div>
      </div>
      <modal
        :show.sync="openmodal"
        class="modal-search"
        id="setscheduler"
        :centered="false"
        :show-close="true"
        :modalContentClasses="widthtemp"
      >
        <div class="row table-full-width">
          <h3>{{ $t("Scheduling settings") }}</h3>

          <div class="col-sm-12">
            <el-table :data="secondTableData">
              <el-table-column width="400" label="Typology" align="center">
                <div class="" style="text-align: justify" slot-scope="{ row }">
                  <div>
                    <!-- <base-radio  v-model="radios.display" name="1" inline>Display</base-radio> -->
                    <input
                      value="1"
                      type="radio"
                      id="sf1"
                      name="scheduler_form"
                      v-model="schdular.Typologychecked"
                      @change="checkboxhide()"
                    />
                    <label for="sf1">{{ $t("Always run") }}</label>
                  </div>
                  <div>
                    <input
                      value="2"
                      id="sf2"
                      type="radio"
                      name="scheduler_form"
                      v-model="schdular.Typologychecked"
                      @change="checkboxselect()"
                    />
                    <label for="sf2">{{ $t("Run if checked") }}</label
                    ><br />
                  </div>
                  <div>
                    <input
                      value="3"
                      id="sf3"
                      type="radio"
                      name="scheduler_form"
                      v-model="schdular.Typologychecked"
                      @change="checkboxselect()"
                    />
                    <label for="sf3">{{
                      $t("Do not execute if selected")
                    }}</label
                    ><br />
                  </div>
                  <div class="row">
                    <div class="col-md-7">
                      <el-date-picker
                        type="date"
                        :placeholder="$t('Date Picker')"
                        v-model="schdular.datePickerStart"
                        :disabled="row.ckdisabled"
                      >
                      </el-date-picker>
                    </div>
                    <div class="col-md-5">
                      <el-time-select
                        :disabled="row.ckdisabled"
                        v-model="schdular.timePickerStart"
                        :picker-options="{
                          start: '00:00',
                          step: '00:15',
                          end: '23:59',
                        }"
                        :placeholder="$t('Select time')"
                      >
                      </el-time-select>
                    </div>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col-md-7">
                      <el-date-picker
                        type="date"
                        :disabled="row.ckdisabled"
                        :placeholder="$t('Date Picker')"
                        v-model="schdular.datePickerEnd"
                      >
                      </el-date-picker>
                    </div>
                    <div class="col-md-5">
                      <el-time-select
                        :disabled="row.ckdisabled"
                        v-model="schdular.timePickerEnd"
                        :picker-options="{
                          start: '00:00',
                          step: '00:15',
                          end: '23:59',
                        }"
                        :placeholder="$t('Select time')"
                      >
                      </el-time-select>
                    </div>
                  </div>
                </div>
              </el-table-column>

              <el-table-column width="100" label="Lun" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Lunchecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>

              <el-table-column width="100" label="Mar" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Marchecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>

              <el-table-column width="100" label="Mer" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Merchecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>

              <el-table-column width="100" label="Gio" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Giochecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>

              <el-table-column width="100" label="Ven" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Venchecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>
              <el-table-column width="100" label="Sab" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Sabchecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>
              <el-table-column width="100" label="Dom" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Domchecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>
            </el-table>
          </div>

          <div class="text-right col-md-12">
            <base-button @click="closem()" class="" type="primary">{{
              $t("Close")
            }}</base-button>
            <base-button
              class=""
              native-type="submit"
              type="primary"
              @click="addschdular(), openmodal"
            >
              {{ $t("Confirmation") }}
            </base-button>
          </div>
        </div>
      </modal>
    </form>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  Select,
  TimeSelect,
  Option,
  DatePicker,
} from "element-ui";
import { BasePagination } from "src/components";
import draggable from "vuedraggable";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "src/components/ValidationError.vue";
import { Modal } from "@/components";
import { required } from "vee-validate/dist/rules";
import SortIcon from "@/assets/customImg/SortIcon.vue";
import { getTopColor, getBottomColor } from "../../util/utils";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  mixins: [formMixin],

  components: {
    SortIcon,
    [DatePicker.name]: DatePicker,
    Modal,
    ValidationError,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    draggable,
    [TimeSelect.name]: TimeSelect,
    required,
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.tableData.total < highBound) {
        highBound = this.total;
      }

      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.total;
    },
  },

  data() {
    return {
      currentSort: "name",
      currentSortDir: "asc",
      widthtemp: "widthtemp-100",
      getplaylist: {
        content_sorting: [],
      },
      container: "",
      fuseSearch: null,

      searchedData: [],

      query: "",

      openmodal: false,
      isValid: true,
      tableData: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
      },
      secondTableData: [
        {
          Typologychecked: "",
          Lunchecked: false,
          Marchecked: false,
          Merchecked: false,
          Giochecked: false,
          Venchecked: false,
          Sabchecked: false,
          Domchecked: false,
          ckdisabled: true,
        },
      ],

      sortparam: {
        field: "name",
        sort: "asc",
      },

      schdular: {
        Typologychecked: 1,
        datePickerEnd: "",
        datePickerStart: "",
        timePickerStart: "",
        timePickerEnd: "",
        Lunchecked: 0,
        Marchecked: 0,
        Merchecked: 0,
        Giochecked: 0,
        Venchecked: 0,
        Sabchecked: 0,
        Domchecked: 0,
        tempid: "",
      },
      searchModalVisible: true,
    };
  },
  methods: {
    computedTopColor(table, arrowIcon) {
      if (arrowIcon === "top") {
        return getTopColor(table, this.sortparam);
      } else {
        return getBottomColor(table, this.sortparam);
      }
    },
    modal(id) {
      this.openmodal = true;
      this.schdular.tempid = id;
    },
    getListDebounced: _.debounce(function () {
      this.getgroup();
    }, 300),

    // sort: function (s) {
    //     if (s === this.currentSort) {
    //         this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
    //     }
    //     this.currentSort = s;
    // },

    sortChange(data) {
      this.sortparam.field = data;
      this.sortparam.sort = this.sortparam.sort == "asc" ? "desc" : "asc";
      this.getgroup();
    },

    setCurrentPage(direction) {
      if (direction === -1 && this.pagination.currentPage > 1) {
        this.pagination.currentPage -= 1;
      } else if (
        direction === 1 &&
        this.pagination.currentPage <
          this.tableData.data.length / this.pagination.perPage
      ) {
        this.pagination.currentPage += 1;
      }
    },

    remove(index) {
      // console.log(index);
      this.getplaylist.content_sorting.splice(index, 1);
    },

    add(id, templateparamvalue, displayid) {
      displayid = this.$route.params.id;
      this.getplaylist.content_sorting.push({
        idtemplate: id,
        templateparamvalue: templateparamvalue,
        id_display: displayid,
      });
      // this.playlist.content.push({'playlist_id': id,  'name': name ,'container_id':containerid})
    },

    checkboxselect() {
      this.secondTableData[0].ckdisabled = false;
    },
    checkboxhide() {
      this.secondTableData[0].ckdisabled = true;
      this.secondTableData[0].Lunchecked = false;
      this.secondTableData[0].Marchecked = false;
      this.secondTableData[0].Merchecked = false;
      this.secondTableData[0].Giochecked = false;
      this.secondTableData[0].Venchecked = false;
      this.secondTableData[0].Sabchecked = false;
      this.secondTableData[0].Domchecked = false;
    },

    async getdisplay(id) {
      id = this.$route.params.id;
      axios.get(`${url}/displayedit/${id}`).then((response) => {
        // console.log(response.data) + "12";
        this.container = response.data;
      });
    },

    async getgroup() {
      let params = {
        sort: {
          ...this.sortparam,
        },
        filter: {
          ...(this.query ? { searchValue: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };
      await this.$store.dispatch("designs/designgetdata", params);
      this.tableData = await this.$store.getters["designs/group_list"];
    },

    async getPlaylist() {
      const id = this.$route.params.id;
      await axios.get(`${url}/getdisplayplaylist/${id}`).then((response) => {
        // console.log(response.data);
        this.getplaylist = response.data;
      });
    },

    save() {
      if (this.getplaylist.content_sorting == "") {
        this.$notify({
          type: "danger",
          message: this.$t("Please Select Template."),
          icon: "tim-icons icon-bell-55",
        });
        return false;
      } else {
        axios
          .post(`${url}/adddesignplaylist`, this.getplaylist)
          .then((response) => {
            this.tableData = response.data;
            this.goBack();
          });
      }
    },

    goBack() {
      this.$router.push({ name: "Display List Manager" });
    },

    async addschdular() {
      try {
        axios
          .post(`${url}/addschdulartemplate`, this.schdular)
          .then((response) => {});
        this.$notify({
          type: "success",
          message: this.$t("Schedular added successfully."),
          icon: "tim-icons icon-bell-55",
        });
        this.openmodel = false;
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
        this.setApiValidation(e.response.data.errors);
      }
    },

    closem() {
      this.openmodal = false;
      this.schdular = [];
      this.schdular.Typologychecked = 2;
    },
  },

  mounted() {
    this.getgroup();
    this.getdisplay();
    this.getPlaylist();
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
  },
};
</script>
<style>
.table > tbody > tr > td {
  padding: 5px;
}
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

.flip-transition-move {
  transition: all 0.7s;
}

.content-img {
  width: 38%;
  margin-top: -12px;
  display: flex;
}

.list-group-item {
  max-height: 50px;
}

.list-group {
  cursor: move;
}

.text-title {
  margin-left: -62px;
  margin-top: -11px;
}

.card {
  border: 0.0625rem solid #e3e3e3;
}

.card-header {
  background-color: #e3e3e3 !important;
}

table {
  width: 100% !important;
}

.modal-search .modal-dialog {
  margin: 23px auto;
  max-width: 65% !important;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 2px 0px var(--primary) inset;
  margin-right: 3px;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 35%;
  height: 31%;
  margin: 31% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: var(--primary);
}

.pickup > .mb-0 > input {
  border-radius: 25px;
  border: 1px solid #dddddd;
  background-color: #f9f9f9;
}
.table-header {
  cursor: pointer;
}
.table-header span {
  display: flex;
  align-items: center;
}
</style>
<style>
.widthtemp-100 {
  height: auto;
  width: 100%;
}
</style>
