<template>
  <div class="mt-3">
    <h4>{{ $t("DISPLAY DETAIL - SOFTWARE VERSION: 3.0.0.0") }}</h4>
    <card>
      <card>
        <div class="row">
          <div class="col-6">
            <div class="card" style="min-height: 94%">
              <ul class="list-group">
                <li
                  class="list-group-item m-2"
                  style="background-color: var(--primary); color: #ffffff"
                >
                  {{ $t("LAST SCREENSHOT") }}<i class="handle"></i>
                </li>
              </ul>

              <div class="card-body text-center py-5">
                <code
                  ><img
                    :src="
                      urlEnv + 'upload/screen/' + id + '/' + screenname + '.jpg'
                    "
                    alt="Image of Last Screenshot"
                    style="height: 450px;"
                /></code>
              </div>

              <div class="card-body text-center ml-4" v-if="lastseen != ''">
                {{ $t("Last Connection") }} : {{ lastseen }}
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card">
              <ul class="list-group">
                <li
                  class="list-group-item m-2"
                  style="background-color: var(--primary); color: #ffffff"
                >
                  {{ $t("PARAMETERS") }}
                </li>
              </ul>

              <div>
                <div id="app">
                  <table class="table">
                    <thead>
                      <tr>
                        <th v-for="(table, key) in tableData.title" :key="key">
                          {{ table }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(table, key) in tableData"
                        :key="table.id"
                        v-if="key !== 'title'"
                      >
                        <td v-for="t in table" :key="t.id" v-if="key != ''">
                          {{ t.displayparamvalue }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <br />
                <form @submit.prevent>
                  <div
                    class="d-flex align-items-center mx-3 justify-content-between"
                  >
                    <label class="w-25 pr-1">
                      {{ $t("Interval to request playlist updates (Seconds)") }}
                      :</label
                    >
                    <div class="d-flex w-75 justify-content-end">
                      <div class="my-auto w-100">
                        <base-input
                          class="my-auto w-100"
                          placeholder="60"
                          v-model="playlist.seconds"
                        ></base-input>
                      </div>
                      <div class="ml-3">
                        <base-button
                          @click="addrefresh()"
                          native-type="submit"
                          type="primary"
                          >{{ $t("Refresh") }}</base-button
                        >
                      </div>
                    </div>
                  </div>
                </form>
                <br />
                <form @submit.prevent>
                  <div
                    class="d-flex align-items-center mx-3 justify-content-between"
                  >
                    <label class="w-25">{{ $t("Shutdown time") }} :</label>
                    <div class="d-flex w-75 justify-content-end">
                      <div class="my-auto w-100">
                        <base-input v-model="time" class="mb-0">
                          <select
                            class="select-time-picker-shutdown"
                            v-model="time.ora"
                          >
                            <option
                              v-for="time in timeOptions"
                              :key="time"
                              :value="time"
                            >
                              {{ time }}
                            </option>
                          </select>
                        </base-input>
                      </div>
                      <div class="ml-3">
                        <base-button
                          @click="addshutdown()"
                          native-type="submit"
                          type="primary"
                          >{{ $t("Refresh") }}</base-button
                        >
                      </div>
                    </div>
                  </div>
                </form>
                <br />
                <form @submit.prevent>
                  <div
                    class="d-flex align-items-center mx-3 justify-content-between"
                  >
                    <label class="w-25">{{ $t("Restart time") }} :</label>
                    <div class="d-flex w-75 justify-content-end">
                      <div class="m-auto w-100">
                        <base-input
                          placeholder="60"
                          v-model="timereboot"
                          style="margin-bottom: 0px"
                        >
                          <select
                            class="select-time-picker-shutdown"
                            v-model="timereboot.ora"
                          >
                            <option
                              v-for="time in timeOptions"
                              :key="time"
                              :value="time"
                            >
                              {{ time }}
                            </option>
                          </select>
                        </base-input>
                      </div>
                      <div class="ml-3">
                        <base-button
                          @click="addreboot()"
                          native-type="submit"
                          type="primary"
                          >{{ $t("Refresh") }}</base-button
                        >
                      </div>
                    </div>
                  </div>
                </form>
                <br />
              </div>
              <div>
                <el-checkbox
                  class="mx-3"
                  label="Receive Offline Email"
                  v-model="emailOPTChecked"
                  @change="getOPTEmail"
                />
              </div>
            </div>
          </div>
        </div>
      </card>

      <card>
        <collapse :multiple-active="false" :active-index="1">
          <collapse-item
            title="TEMPLATE SELECTION"
            :custom-toggle="templateSection"
          >
            <div class="card-body text-center py-5">
              <card>
                <table class="table" v-if="template != ''">
                  <tbody>
                    <tr>
                      <td>
                        <div class="row">
                          <div
                            class="col-6"
                            v-for="t in template"
                            :key="t.id"
                            style="display: inline"
                          >
                            <card class="font-icon-detail">
                              <a
                                href="javascript:void(0)"
                                @click="updatetemplate(t.idtemplate)"
                              >
                                {{ t.templateparamvalue }}
                                <br />
                                <br />
                                <img
                                  v-if="t.orientation == 0"
                                  style="border: 1px solid black"
                                  class="ml-4"
                                  :src="t.filename"
                                  alt=""
                                  width="300px"
                                />
                                <img
                                  v-else-if="t.orientation == 1"
                                  style="border: 1px solid black"
                                  class="ml-4"
                                  :src="t.filename"
                                  alt=""
                                  width="150px"
                                />
                              </a>
                            </card>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </card>
            </div>
          </collapse-item>
        </collapse>
      </card>

      <card v-if="screenWidth <= 1848">
        <collapse :multiple-active="false" :active-index="0">
          <collapse-item title="SITUATION REPORT">
            <card>
              <table class="table">
                <tbody>
                  <tr>
                    <td>{{ $t("Assigned templates and designs") }} :</td>
                    <td>
                      <tr
                        v-for="temp in associatedtemplate"
                        :key="temp.id"
                        v-if="associatedtemplate != ''"
                      >
                        <td style="text-align: center">
                          <p>{{ temp.templateparamvalue }}</p>
                          <img
                            v-if="temp.orientation == 1"
                            style="border: 1px solid black"
                            :src="temp.filename"
                            alt="pre"
                            width="150px"
                          />
                          <img
                            v-else-if="temp.orientation == 0"
                            style="border: 1px solid black"
                            :src="temp.filename"
                            alt="pre"
                            width="300px"
                          />
                          <br />
                          <a
                            href="javascript:void(0)"
                            @click="edittemplate(temp.idtemplate)"
                            >{{ $t("Edit the Template") }}</a
                          >
                        </td>
                      </tr>
                    </td>
                    <td v-if="associatedtemplate == ''">
                      <i
                        class="tim-icons icon-alert-circle-exc m-3"
                        style="color: red"
                      ></i>
                      <base-button
                        type="primary"
                        size="sm"
                        @click="addtemplate()"
                        >{{
                          $t("Create a new Template and a new design")
                        }}</base-button
                      >
                    </td>
                  </tr>
                </tbody>
              </table>

              <hr />
              <table class="table-responsive">
                <tbody>
                  <tr>
                    <td>{{ $t("Details") }} :</td>
                    <td>
                      <table
                        class="table-responsive"
                        align="center"
                        border="1"
                        v-if="this.all != ''"
                      >
                        <tbody>
                          <tr>
                            <th style="text-align: center" width="100">
                              {{ $t("Box") }}
                            </th>
                            <th style="text-align: center" width="250">
                              {{ $t("Contenitore") }}
                            </th>
                            <th style="text-align: center" width="1200">
                              {{ $t("Playlist") }}
                            </th>
                          </tr>
                          <tr v-for="(content, k) in all.container[0]" :key="k">
                            <td scope="row" align="center">
                              <h3>{{ content.boxId + 1 }}</h3>
                            </td>

                            <td scope="row" align="center">
                              <h3>
                                <a href="" @click="containerside(content.id)">{{
                                  content.name
                                }}</a>
                              </h3>
                            </td>
                            <td scope="row" align="center">
                              <tr
                                align="center"
                                v-for="(playlists, j) in all.playlist[
                                  content.id
                                ]"
                                :key="j"
                              >
                                <td width="2500" style="border: 0">
                                  <h3>
                                    <a
                                      href=""
                                      @click="playlistside(playlists.id)"
                                    >
                                      {{ playlists.playlistname }}
                                    </a>
                                  </h3>
                                  <table align="center" border="1" width="1200">
                                    <tbody>
                                      <tr>
                                        <th style="text-align: center">
                                          {{ $t("Contenuto") }}
                                        </th>
                                        <th style="text-align: center">
                                          {{ $t("Tipo") }}
                                        </th>
                                      </tr>
                                      <tr
                                        v-for="(content, l) in all.content[
                                          playlists.id
                                        ]"
                                        :key="l"
                                      >
                                        <td scope="row" align="center">
                                          <p>{{ content.contentparamvalue }}</p>
                                        </td>
                                        <td
                                          scope="row"
                                          align="center"
                                          v-if="content.mime == 'Url'"
                                        >
                                          <i
                                            class="tim-icons icon-credit-card"
                                          ></i>
                                        </td>
                                        <td
                                          scope="row"
                                          align="center"
                                          v-else-if="content.mime == 'Html'"
                                        >
                                          <i
                                            class="tim-icons icon-credit-card"
                                          ></i>
                                        </td>
                                        <td
                                          scope="row"
                                          align="center"
                                          v-else-if="
                                            content.mime == 'application'
                                          "
                                        >
                                          <i
                                            class="tim-icons icon-credit-card"
                                          ></i>
                                        </td>
                                        <td
                                          scope="row"
                                          align="center"
                                          v-else-if="content.mime == 'image'"
                                        >
                                          <i
                                            class="tim-icons icon-image-02"
                                          ></i>
                                        </td>
                                        <td
                                          scope="row"
                                          align="center"
                                          v-else-if="content.mime == 'video'"
                                        >
                                          <i
                                            class="tim-icons icon-video-66"
                                          ></i>
                                        </td>
                                        <td
                                          scope="row"
                                          align="center"
                                          v-else
                                        ></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </card>
          </collapse-item>
        </collapse>
      </card>
      <card v-else style="border: none">
        <collapse :multiple-active="false" :active-index="0">
          <collapse-item title="SITUATION REPORT">
            <card style="border: none">
              <!-- <table class="table">
                <tbody>
                  <tr>
                    <td>{{ $t("Assigned group") }} :</td>
                    <td v-if="associatedstore.display_id == id">
                      <i
                        class="tim-icons icon-check-2 m-3"
                        style="color: green"
                      ></i>
                      <base-button
                        type="primary"
                        size="sm"
                        @click="groupdetail(associatedstore.group_id)"
                        >{{ $t("Group details") }}</base-button
                      >
                    </td>
                    <td scope="row" v-else>
                      <i
                        class="tim-icons icon-alert-circle-exc m-3"
                        style="color: red"
                      ></i>
                      <base-button type="primary" size="sm" @click="addgroup()">
                        {{ $t("Create Group") }}</base-button
                      >
                    </td>
                  </tr>
                </tbody>
              </table> -->
              <div class="large-container">
                <div class="table" style="width: 40%">
                  <div class="content-header">
                    {{ $t("Assigned templates and designs") }} :
                  </div>
                  <span class="template-container">
                    <div
                      v-for="temp in associatedtemplate"
                      :key="temp.id"
                      v-if="associatedtemplate != ''"
                    >
                      <span>
                        <p class="text-center">{{ temp.templateparamvalue }}</p>
                        <img
                          v-if="temp.orientation == 1"
                          style="border: 1px solid black"
                          :src="temp.filename"
                          alt="pre"
                          width="150px"
                        />
                        <img
                          v-else-if="temp.orientation == 0"
                          style="border: 1px solid black"
                          :src="temp.filename"
                          alt="pre"
                          width="300px"
                        />
                        <br />
                        <a
                          class="edit-large-btn"
                          href="javascript:void(0)"
                          @click="edittemplate(temp.idtemplate)"
                          >{{ $t("Edit the Template") }}</a
                        >
                      </span>
                    </div>
                  </span>
                  <span v-if="associatedtemplate == ''">
                    <i
                      class="tim-icons icon-alert-circle-exc m-3"
                      style="color: red"
                    ></i>
                    <base-button
                      type="primary"
                      size="sm"
                      @click="addtemplate()"
                      >{{
                        $t("Create a new Template and a new design")
                      }}</base-button
                    >
                  </span>
                </div>

                <table class="table-responsive">
                  <div class="content-header">{{ $t("Details") }} :</div>
                  <tbody>
                    <tr>
                      <td>
                        <table
                          class="table-responsive pb-0"
                          align="center"
                          border="1"
                          v-if="this.all != ''"
                        >
                          <tbody>
                            <tr>
                              <th style="text-align: center" width="100">
                                {{ $t("Box") }}
                              </th>
                              <th style="text-align: center" width="250">
                                {{ $t("Contenitore") }}
                              </th>
                              <th style="text-align: center" width="1200">
                                {{ $t("Playlist") }}
                              </th>
                            </tr>
                            <tr
                              v-for="(content, k) in all.container[0]"
                              :key="k"
                            >
                              <td scope="row" align="center">
                                <h3>{{ content.boxId + 1 }}</h3>
                              </td>

                              <td scope="row" align="center">
                                <h3>
                                  <a
                                    href=""
                                    @click="containerside(content.id)"
                                    >{{ content.name }}</a
                                  >
                                </h3>
                              </td>
                              <td scope="row" align="center">
                                <tr
                                  align="center"
                                  v-for="(playlists, j) in all.playlist[
                                    content.id
                                  ]"
                                  :key="j"
                                >
                                  <td width="2500" style="border: 0">
                                    <h3>
                                      <a
                                        href=""
                                        @click="playlistside(playlists.id)"
                                      >
                                        {{ playlists.playlistname }}
                                      </a>
                                    </h3>
                                    <table
                                      align="center"
                                      border="1"
                                      width="1200"
                                    >
                                      <tbody>
                                        <tr>
                                          <th style="text-align: center">
                                            {{ $t("Contenuto") }}
                                          </th>
                                          <th style="text-align: center">
                                            {{ $t("Tipo") }}
                                          </th>
                                        </tr>
                                        <tr
                                          v-for="(content, l) in all.content[
                                            playlists.id
                                          ]"
                                          :key="l"
                                        >
                                          <td scope="row" align="center">
                                            <p>
                                              {{ content.contentparamvalue }}
                                            </p>
                                          </td>
                                          <td
                                            scope="row"
                                            align="center"
                                            v-if="content.mime == 'Url'"
                                          >
                                            <i
                                              class="tim-icons icon-credit-card"
                                            ></i>
                                          </td>
                                          <td
                                            scope="row"
                                            align="center"
                                            v-else-if="content.mime == 'Html'"
                                          >
                                            <i
                                              class="tim-icons icon-credit-card"
                                            ></i>
                                          </td>
                                          <td
                                            scope="row"
                                            align="center"
                                            v-else-if="
                                              content.mime == 'application'
                                            "
                                          >
                                            <i
                                              class="tim-icons icon-credit-card"
                                            ></i>
                                          </td>
                                          <td
                                            scope="row"
                                            align="center"
                                            v-else-if="content.mime == 'image'"
                                          >
                                            <i
                                              class="tim-icons icon-image-02"
                                            ></i>
                                          </td>
                                          <td
                                            scope="row"
                                            align="center"
                                            v-else-if="content.mime == 'video'"
                                          >
                                            <i
                                              class="tim-icons icon-video-66"
                                            ></i>
                                          </td>
                                          <td
                                            scope="row"
                                            align="center"
                                            v-else
                                          ></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </card>
          </collapse-item>
        </collapse>
      </card>

      <card>
        <collapse :multiple-active="false" :active-index="1">
          <collapse-item title="CONTROL PANEL">
            <div class="row">
              <div class="card-body text-center py-5" v-if="this.control == ''">
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ $t("Command") }}</th>
                      <th>{{ $t("action") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row">{{ $t("Reboot") }}</td>
                      <td>
                        <base-button
                          class=""
                          @click="reboot()"
                          v-model="reqreboot"
                          type="primary"
                          >{{ $t("Request") }}</base-button
                        >
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">{{ $t("Shutdown") }}</td>
                      <td>
                        <base-button
                          class=""
                          @click="shutdown()"
                          v-model="reqshutdown"
                          type="primary"
                          >{{ $t("Request") }}
                        </base-button>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">{{ $t("Disassociation") }}</td>
                      <td>
                        <base-button
                          class=""
                          @click="disassociation()"
                          v-model="reqdisassociation"
                          type="primary"
                        >
                          {{ $t("Request") }}</base-button
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card-body text-center py-5" v-else>
                <h2 style="text-align: center">
                  {{ $t("There is a pending command") }}
                </h2>
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ $t("Command") }}</th>
                      <th>{{ $t("Date") }}</th>
                      <th>{{ $t("Applicant") }}</th>
                      <th>{{ $t("action") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="cn in control" :key="cn.id">
                      <td scope="row" v-if="cn.idComando == 1">
                        {{ $t("Remote Terminal") }} <br />
                        {{ cn.host }} <br />
                        {{ cn.port }}
                      </td>
                      <td scope="row" v-else-if="cn.idComando == 2">
                        {{ $t("Reboot") }}
                      </td>
                      <td scope="row" v-else-if="cn.idComando == 3">
                        {{ $t("Shutdown") }}
                      </td>
                      <td scope="row" v-else-if="cn.idComando == 4">
                        {{ $t("Player Update") }}
                      </td>
                      <td scope="row" v-else-if="cn.idComando == 5">
                        {{ $t("Disassociation") }}
                      </td>
                      <td>{{ cn.requestDate }}</td>
                      <td>{{ cn.user }}</td>
                      <td>
                        <base-button class="" @click="del()" type="primary">{{
                          $t("Cancel")
                        }}</base-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </collapse-item>
        </collapse>
      </card>

      <card>
        <collapse :multiple-active="false" :active-index="1">
          <collapse-item title="STATE">
            <div
              style="text-align: center; color: black"
              v-for="s in status"
              :key="s.id"
            >
              {{ s }}
            </div>
          </collapse-item>
        </collapse>
      </card>
    </card>

    <modal
      :show.sync="openmodal"
      class="modal-search"
      id="setscheduler"
      :centered="false"
      :show-close="true"
      :modalContentClasses="widthtemp"
    >
      <div class="row table-full-width">
        <h3>{{ $t("Scheduling Settings") }}</h3>

        <div class="col-md-12">
          <el-table :data="secondTableData">
            <el-table-column width="400" label="Typology" align="center">
              <div class="" style="text-align: justify" slot-scope="{ row }">
                <div>
                  <!-- <base-radio  v-model="radios.display" name="1" inline>Display</base-radio> -->
                  <input
                    value="1"
                    type="radio"
                    id="sf1"
                    name="scheduler_form"
                    v-model="schdular.Typologychecked"
                    @change="checkboxhide()"
                  />
                  <label for="sf1">{{ $t("Always run") }}</label>
                </div>
                <div>
                  <input
                    value="2"
                    id="sf2"
                    type="radio"
                    name="scheduler_form"
                    v-model="schdular.Typologychecked"
                    @change="checkboxselect()"
                  />
                  <label for="sf2">{{ $t("Run if checked") }}</label
                  ><br />
                </div>
                <div>
                  <input
                    value="3"
                    id="sf3"
                    type="radio"
                    name="scheduler_form"
                    v-model="schdular.Typologychecked"
                    @change="checkboxselect()"
                  />
                  <label for="sf3">{{ $t("Do not execute if selected") }}</label
                  ><br />
                </div>
                <div class="row">
                  <div class="col-md-7">
                    <el-date-picker
                      type="date"
                      placeholder="Date Picker"
                      v-model="schdular.datePickerStart"
                      :disabled="row.ckdisabled"
                    >
                    </el-date-picker>
                  </div>
                  <div class="col-md-5">
                    <el-time-select
                      :disabled="row.ckdisabled"
                      v-model="schdular.timePickerStart"
                      :picker-options="{
                        start: '00:00',
                        step: '00:15',
                        end: '23:59',
                      }"
                      :placeholder="$t('Select time')"
                    >
                    </el-time-select>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-7">
                    <el-date-picker
                      type="date"
                      :disabled="row.ckdisabled"
                      :placeholder="$t('Date Picker')"
                      v-model="schdular.datePickerEnd"
                    >
                    </el-date-picker>
                  </div>
                  <div class="col-md-5">
                    <el-time-select
                      :disabled="row.ckdisabled"
                      v-model="schdular.timePickerEnd"
                      :picker-options="{
                        start: '00:00',
                        step: '00:15',
                        end: '23:59',
                      }"
                      :placeholder="$t('Select time')"
                    >
                    </el-time-select>
                  </div>
                </div>
              </div>
            </el-table-column>

            <el-table-column width="100" label="Lun" align="center">
              <div slot-scope="{ row }" class="checkbox-cell">
                <base-checkbox
                  v-model="schdular.Lunchecked"
                  :disabled="row.ckdisabled"
                ></base-checkbox>
              </div>
            </el-table-column>

            <el-table-column width="100" label="Mar" align="center">
              <div slot-scope="{ row }" class="checkbox-cell">
                <base-checkbox
                  v-model="schdular.Marchecked"
                  :disabled="row.ckdisabled"
                ></base-checkbox>
              </div>
            </el-table-column>

            <el-table-column width="100" label="Mer" align="center">
              <div slot-scope="{ row }" class="checkbox-cell">
                <base-checkbox
                  v-model="schdular.Merchecked"
                  :disabled="row.ckdisabled"
                ></base-checkbox>
              </div>
            </el-table-column>

            <el-table-column width="100" label="Gio" align="center">
              <div slot-scope="{ row }" class="checkbox-cell">
                <base-checkbox
                  v-model="schdular.Giochecked"
                  :disabled="row.ckdisabled"
                ></base-checkbox>
              </div>
            </el-table-column>

            <el-table-column width="100" label="Ven" align="center">
              <div slot-scope="{ row }" class="checkbox-cell">
                <base-checkbox
                  v-model="schdular.Venchecked"
                  :disabled="row.ckdisabled"
                ></base-checkbox>
              </div>
            </el-table-column>
            <el-table-column width="100" label="Sab" align="center">
              <div slot-scope="{ row }" class="checkbox-cell">
                <base-checkbox
                  v-model="schdular.Sabchecked"
                  :disabled="row.ckdisabled"
                ></base-checkbox>
              </div>
            </el-table-column>
            <el-table-column width="100" label="Dom" align="center">
              <div slot-scope="{ row }" class="checkbox-cell">
                <base-checkbox
                  v-model="schdular.Domchecked"
                  :disabled="row.ckdisabled"
                ></base-checkbox>
              </div>
            </el-table-column>
          </el-table>
        </div>

        <div class="text-right col-md-12">
          <base-button @click="closem()" class="" type="primary">{{
            $t("Close")
          }}</base-button>
          <base-button
            class=""
            native-type="submit"
            type="primary"
            @click="addschdular(), openmodal"
          >
            {{ $t("Confirmation") }}</base-button
          >
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  TimeSelect,
  Table,
  TableColumn,
  Option,
  Select,
  DatePicker,
  Checkbox,
} from "element-ui";
import { ref } from "vue";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";
import { relativeTimeRounding } from "moment";
import { Modal } from "@/components";
import Fuse from "fuse.js";
import { TabPane, Tabs, Collapse, CollapseItem } from "src/components";
import swal from "sweetalert2";

const url = process.env.VUE_APP_API_BASE_URL;
const imageUrl = process.env.VUE_APP_CLEAN_URL;

export default {
  mixins: [formMixin],

  components: {
    [Table.name]: Table,
    Modal,
    [TableColumn.name]: TableColumn,
    [TimeSelect.name]: TimeSelect,
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
    [Checkbox.name]: Checkbox,
    TabPane,
    Tabs,
    Collapse,
    CollapseItem,
  },

  created() {
    this.getplaylist();
    this.getshutdown();
    this.getreboot();
    this.gettemplate();
  },

  data() {
    return {
      urlEnv: imageUrl,
      selects: {
        simple: "1",
        emailcheck: [
          {
            value: "1",
            label: "also check",
          },
          {
            value: "2",
            label: "offline only",
          },
        ],
        email: "",
        displayid: this.$route.params.id,
      },
      emailOPTChecked: false,
      lastseen: "",
      screenname: "",
      status: [],
      widthtemp: "widthtemp-100",
      openmodal: false,
      id: this.$route.params.id,
      tableData: [],
      associatedstore: [],
      associatedtemplate: [],
      playlist: {
        seconds: null,
      },
      control: "",
      time: {
        ora: null,
      },
      template: {},
      temp: {
        displayid: this.$route.params.id,
      },
      all: "",
      test: "",

      timereboot: {
        ora: null,
      },
      secondTableData: [
        {
          Typologychecked: "",
          Lunchecked: false,
          Marchecked: false,
          Merchecked: false,
          Giochecked: false,
          Venchecked: false,
          Sabchecked: false,
          Domchecked: false,
          ckdisabled: true,
        },
      ],

      schdular: {
        Typologychecked: 1,
        datePickerEnd: "",
        datePickerStart: "",
        timePickerStart: "",
        timePickerEnd: "",
        Lunchecked: 0,
        Marchecked: 0,
        Merchecked: 0,
        Giochecked: 0,
        Venchecked: 0,
        Sabchecked: 0,
        Domchecked: 0,
        contid: "",
      },
      reqreboot: "",
      reqshutdown: "",
      reqdisassociation: "",
      sec: "",
      timeOptions: [],
      templateSection: true,
      screenWidth: window.innerWidth,
    };
  },
  methods: {
    checkboxselect() {
      this.secondTableData[0].ckdisabled = false;
    },
    checkboxhide() {
      this.secondTableData[0].ckdisabled = true;
      this.secondTableData[0].Lunchecked = false;
      this.secondTableData[0].Marchecked = false;
      this.secondTableData[0].Merchecked = false;
      this.secondTableData[0].Giochecked = false;
      this.secondTableData[0].Venchecked = false;
      this.secondTableData[0].Sabchecked = false;
      this.secondTableData[0].Domchecked = false;
    },

    modal() {
      this.openmodal = true;
    },

    getdisplay() {
      const id = this.$route.params.id;
      axios.get(`${url}/displaygetiddata/${id}`).then((response) => {
        this.tableData = response.data;
      });
    },

    getlastseen() {
      const id = this.$route.params.id;
      axios.get(`${url}/getlastseen/${id}`).then((response) => {
        this.lastseen = response.data;
      });
    },

    getscreenshot() {
      const id = this.$route.params.id;
      axios.get(`${url}/getscreenshoot/${id}`).then((response) => {
        this.screenname = response.data;
      });
    },

    getstatus() {
      const id = this.$route.params.id;
      axios.get(`${url}/getstatus/${id}`).then((response) => {
        this.status = response.data;
      });
    },

    async getcontrolpanel(id) {
      id = this.$route.params.id;
      axios.get(`${url}/getcontrolpanel/${id}`).then((response) => {
        this.control = response.data;
      });
    },

    async offline(id) {
      id = this.$route.params.id;
      axios.patch(`${url}/offline/${id}`, this.selects).then((response) => {
        this.$notify({
          type: "success",
          message: this.$t("Successfully."),
          icon: "tim-icons icon-bell-55",
        });
      });
    },

    async getoffline(id) {
      id = this.$route.params.id;
      axios.get(`${url}/getoffline/${id}`).then((response) => {
        this.selects.email = response.data[0].email;
      });
    },

    async getOPTEmail() {
      const detailId = this.$route.params.id;
      const newEmailOptStatus = this.emailOPTChecked ? 1 : 0;
      const obj = {
        opt_email: newEmailOptStatus,
      };
      try {
        await axios
          .post(`${url}/update-display-email/${detailId}`, obj)
          .then((response) => {
            this.$notify({
              type: "success",
              message: response.data,
              icon: "tim-icons icon-bell-55",
            });
          });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Something went to wrong",
          icon: "tim-icons icon-bell-55",
        });
      }
    },

    async getEmailNotificationStatus() {
      const detailId = this.$route.params.id;
      await axios.post(`${url}/getemailstatus/${detailId}`).then((res) => {
        this.emailOPTChecked = res.data.opt_email === 1 ? true : false;
      });
    },

    async del(id) {
      id = this.$route.params.id;
      try {
        const confirmation = await swal.fire({
          title: this.$t("Are you sure?"),

          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: this.$t("Yes, Cancel it!"),
          buttonsStyling: false,
        });

        if (confirmation.value === true) {
          await this.$store.dispatch("displays/del", id);
          this.$notify({
            type: "success",
            message: this.$t("Request Cancelled successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.getcontrolpanel();
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: this.$t("This Request still has associated Users."),
            icon: "tim-icons icon-bell-55",
          });
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("Oops, something went wrong!"),
            icon: "tim-icons icon-bell-55",
          });
        }
      }
    },

    // gettemplateall() {
    //      axios
    //     .get(`${url}/getalltemplatedata`)
    //     .then((response) => {
    //       console.log(response.data);
    //     this.all = response.data;
    // })
    // },

    getalltemplatedatadisplay() {
      const id = this.$route.params.id;
      axios.get(`${url}/getalltemplatedatadisplay/${id}`).then((response) => {
        this.all = response.data;
      });
    },

    getplaylist() {
      const id = this.$route.params.id;
      axios.get(`${url}/getplaylist/${id}`).then((response) => {
        this.playlist = response.data;
        this.sec = this.playlist.seconds * 1000;
      });
    },

    getshutdown() {
      const id = this.$route.params.id;
      axios.get(`${url}/getshutdown/${id}`).then((response) => {
        const timeValue = response.data[0].ora;
        this.time.ora = timeValue === "00:00:00" ? "" : timeValue;
      });
    },
    getreboot() {
      const id = this.$route.params.id;
      axios.get(`${url}/getrestart/${id}`).then((response) => {
        this.timereboot = response.data[0];
      });
    },
    getassociated() {
      const id = this.$route.params.id;
      axios.get(`${url}/getstoreassociateddisplay/${id}`).then((response) => {
        this.associatedstore = response.data;
      });
    },

    getassociatedtemplate() {
      const id = this.$route.params.id;
      axios.get(`${url}/getassociatetemplate/${id}`).then((response) => {
        this.associatedtemplate = response.data;
      });
    },

    gettemplate() {
      axios.get(`${url}/gettemplatedata`).then((response) => {
        this.template = response.data;
      });
    },

    async generateTimeOptions() {
      await axios
        .get(`${url}/get-time-dropdown?interval=30minutes`)
        .then((response) => {
          this.timeOptions = response.data;
        });
    },

    async updatetemplate(tid) {
      const id = this.$route.params.id;
      this.temp["idtemplate"] = tid;

      await axios
        .patch(`${url}/updatetemplatedisplay/${id}`, this.temp)
        .then((response) => {
          this.$notify({
            type: "success",
            message: this.$t("Successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.templateSection = false;
          setTimeout(() => {
            this.templateSection = true;
          }, 1500);
          this.getassociatedtemplate();
          this.getalltemplatedatadisplay();
        });
    },

    reboot(id) {
      id = this.$route.params.id;
      axios
        .post(`${url}/managercontrollreboot/${id}`, this.reqreboot)
        .then((response) => {
          this.$notify({
            type: "success",
            message: this.$t("Successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.getcontrolpanel();
        });
    },

    shutdown(id) {
      id = this.$route.params.id;
      axios
        .post(`${url}/managercontrollshutdown/${id}`, this.reqshutdown)
        .then((response) => {
          this.$notify({
            type: "success",
            message: this.$t("Successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.getcontrolpanel();
        });
    },

    disassociation(id) {
      id = this.$route.params.id;
      axios
        .post(
          `${url}/managercontrolldisassociation/${id}`,
          this.reqdisassociation
        )
        .then((response) => {
          this.$notify({
            type: "success",
            message: this.$t("Successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.getcontrolpanel();
        });
    },

    async addrefresh(id) {
      id = this.$route.params.id;

      axios
        .patch(`${url}/playlistupdate/${id}`, this.playlist)
        .then((response) => {
          this.$notify({
            type: "success",
            message: this.$t("Successfully."),
            icon: "tim-icons icon-bell-55",
          });
        });
    },
    async addshutdown(id) {
      id = this.$route.params.id;
      axios.patch(`${url}/addshutdown/${id}`, this.time).then((response) => {
        this.$notify({
          type: "success",
          message: this.$t("Successfully."),
          icon: "tim-icons icon-bell-55",
        });
      });
    },
    async addreboot(id) {
      id = this.$route.params.id;

      axios
        .patch(`${url}/addrestart/${id}`, this.timereboot)
        .then((response) => {
          this.$notify({
            type: "success",
            message: this.$t("Successfully."),
            icon: "tim-icons icon-bell-55",
          });
        });
    },

    groupdetail(id) {
      this.$router.push({
        name: "Group Detail",
        params: {
          id,
        },
      });
    },

    edittemplate(id) {
      this.$router.push({
        name: "Edit Template",
        params: {
          id,
        },
      });
    },
    addgroup() {
      this.$router.push({
        name: "New Group",
      });
    },

    containerside(id) {
      this.$router.push({
        name: "List Container",
        params: {
          id,
        },
      });
    },

    playlistside(id) {
      this.$router.push({
        name: "Edit Playlist",
        params: {
          id,
        },
      });
    },

    addtemplate() {
      this.$router.push({
        name: "New Template",
      });
    },

    closem() {
      this.openmodal = false;
      this.schdular = [];
      this.schdular.Typologychecked = 2;
    },

    intervaltime() {
      const self = this;
      setInterval(
        function () {
          self.getscreenshot();
        }.bind(this),
        45000
      );

      setInterval(
        function () {
          self.getlastseen();
        }.bind(this),
        45000
      );

      setInterval(
        function () {
          self.getstatus();
        }.bind(this),
        45000
      );
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
  },

  mounted() {
    this.getdisplay();
    this.getassociated();
    this.getassociatedtemplate();
    this.getoffline();
    this.getalltemplatedatadisplay();
    this.getcontrolpanel();
    this.getlastseen();
    this.getscreenshot();
    this.intervaltime();
    this.getstatus();
    this.generateTimeOptions();
    this.getEmailNotificationStatus();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style>
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

#headingOne {
  background-color: var(--primary) !important;
}

#headingOne a,
#headingOne a i {
  color: #ffffff !important;
}

.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

.flip-transition-move {
  transition: all 0.7s;
}

.content-img {
  width: 38%;
  margin-top: -12px;
  display: flex;
}

.list-group-item {
  max-height: 50px;
}

.list-group {
  cursor: move;
}

.text-title {
  margin-left: -62px;
  margin-top: -11px;
}

.card {
  border: 0.0625rem solid #e3e3e3;
}

.card-header {
  background-color: #e3e3e3 !important;
}

table {
  width: 100% !important;
}

.modal-search .modal-dialog {
  margin: 23px auto;
  max-width: 65% !important;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 2px 0px var(--primary) inset;
  margin-right: 3px;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 35%;
  height: 31%;
  margin: 31% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: var(--primary);
}

.pickup > .mb-0 > input {
  border-radius: 25px;
  border: 1px solid #dddddd;
  background-color: #f9f9f9;
}

.select-time-picker-shutdown {
  color: #222a42;
  border-color: rgba(29, 37, 59, 0.5);
  border-radius: 0.4285rem;
  width: 100%;
  padding: 10px 18px 9px 18px;
  background: #fff;
}
.select-time-picker-shutdown:focus {
  border-color: #49a3f1;
}

.large-container {
  display: flex;
}

.template-container {
  display: flex;
  justify-content: center;
}

.edit-large-btn {
  display: flex;
  justify-content: center;
  margin: 0.5rem;
}

.content-header {
  margin-bottom: 0.7rem;
}
</style>
<style>
.widthtemp-100 {
  height: auto;
  width: 100%;
}
</style>
