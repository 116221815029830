<template>
  <div class="content">
    <div class="row mt-3">
      <div class="col-12">
        <h4 slot="header" class="card-title">{{ $t("PENDING ACTIONS") }}</h4>
        <card card-body-classes="table-full-width">
          <div>
            <form>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <el-select
                  class="select-primary mb-3 pagination-select"
                  v-model="pagination.perPage"
                  :placeholder="$t('Per page')"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>

                <base-input>
                  <el-input
                    type="search"
                    class="mb-3 search-input"
                    clearable
                    prefix-icon="el-icon-search"
                    :placeholder="$t('Search records')"
                    v-model="searchQuery"
                    aria-controls="datatables"
                  >
                  </el-input>
                </base-input>
              </div>
              <el-table
                :data="queriedData"
                v-for="column in tableColumns"
                :key="column.label"
              >
                <template>
                  <el-table-column
                    :key="column.label1"
                    :prop="column"
                    :label="$t(column.label1)"
                  >
                  </el-table-column>
                </template>

                <template>
                  <el-table-column
                    :key="column.label2"
                    :label="$t(column.label2)"
                  >
                  </el-table-column>
                </template>

                <template>
                  <el-table-column
                    :key="column.label3"
                    :label="$t(column.label3)"
                  >
                  </el-table-column>
                </template>

                <template>
                  <el-table-column
                    :key="column.label4"
                    :label="$t(column.label4)"
                  >
                  </el-table-column>
                </template>

                <template>
                  <el-table-column
                    :key="column.label5"
                    :label="$t(column.label5)"
                  >
                  </el-table-column>
                </template>

                <template>
                  <el-table-column
                    :key="column.label6"
                    :label="$t(column.label6)"
                  >
                  </el-table-column>
                </template>
              </el-table>
            </form>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
                {{ $t("of") }} {{ total }} {{ $t("entries") }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
// import users from "./users";
import Fuse from "fuse.js";
import swal from "sweetalert2";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "src/components/ValidationError.vue";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  mixins: [formMixin],

  components: {
    ValidationError,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },

  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["attributes.name"],
      tableColumns: [
        {
          label1: "Store Data",
          label2: "Applicant",
          label3: "Supervisor",
          label4: "User Involved",
          label5: "Action Type",
          label6: "Requested Data",
          label7: "Action",
          minWidth: 100,
        },
      ],
      tableData: [],
      table: [],
      searchedData: [],
      fuseSearch: null,
    };
  },
  methods: {
    handleLike(index, row) {
      swal.fire({
        title: `You liked ${row.name}`,
        buttonsStyling: false,
        icon: "success",
        customClass: {
          confirmButton: "btn btn-success btn-fill",
        },
      });
    },
    handleEdit(index, row) {
      swal.fire({
        title: `${this.$t("You want to edit")} ${row.name}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-info btn-fill",
        },
      });
    },

    async destroy(id) {
      if (this.$isDemo == 1 && ["1", "2", "3"].includes(role)) {
        this.$notify({
          type: "danger",
          message: this.$t(
            "You are not allowed to change data of default roles."
          ),
          icon: "tim-icons icon-bell-55",
        });
        return;
      }
      try {
        const confirmation = await swal.fire({
          title: this.$t("Are you sure?"),
          text: this.$t("You won't be able to revert this!"),
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: this.$t("Yes, delete it!"),
          buttonsStyling: false,
        });

        if (confirmation.value === true) {
          await this.$store.dispatch("groups/destroy", id);
          this.$notify({
            type: "success",
            message: this.$t("Group deleted successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.getgroup();
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: this.$t("This Group still has associated Users."),
            icon: "tim-icons icon-bell-55",
          });
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("Oops, something went wrong!"),
            icon: "tim-icons icon-bell-55",
          });
        }
      }
    },

    goToEdit(id) {
      this.$router.push({ name: "Edit Group", params: { id } });
    },

    goToDetails(id) {
      this.$router.push({ name: "Group Detail", params: { id } });
    },

    addLicense(id) {
      this.$router.push({ name: "Add License", params: { id } });
    },
  },

  mounted() {
    // Fuse search initialization.
    (this.fuseSearch = new Fuse(this.tableData, {
      keys: ["attributes.name"],
      threshold: 0.3,
    })),
      this.getgroup(),
      this.getdisplay();
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content {
  font-size: inherit !important;
}
</style>
