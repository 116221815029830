<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-12">
        <h4 slot="header" class="card-title">{{ $t("DISPLAY LIST") }}</h4>
        <card card-body-classes="table-full-width">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('Search records')"
                  v-model="query"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <div id="app">
              <table class="table" v-if="tableData.title != ''">
                <thead>
                  <tr>
                    <th
                      @click="sort('firstname')"
                      v-for="(table, key) in tableData.title"
                      :key="key"
                    >
                      {{ $t(table) }}
                    </th>
                    <th>{{ $t("action") }}</th>
                    <th>{{ $t("Software Version") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(table, key) in filteredAndSortedBlogs"
                    :key="table.id"
                    v-if="key !== 'title' && key != 'total_length'"
                  >
                    <td v-for="t in table" :key="t.id">
                      {{ t.displayparamvalue }}
                    </td>

                    <td>
                      <button
                        @click="goToDetails(table[0].display_id)"
                        title="Detail"
                        class="'details btn-link"
                        type="success"
                        style="color: var(--primary)"
                      >
                        <i class="tim-icons icon-bag-16"></i>
                      </button>
                    </td>

                    <td>3.0.0.0</td>
                  </tr>
                </tbody>
              </table>
              <card v-else>
                <h3
                  style="
                    text-align: center;
                    border: 1px solid black;
                    color: red;
                  "
                >
                  {{$t("Oops No data Found")}}...
                </h3>
              </card>
            </div>
          </div>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
               <p class="card-category">
                {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
                {{ $t("of") }} {{ total }} {{ $t("entries") }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";

import _ from "lodash";
import { BasePagination } from "src/components";
// import users from "./users";
import Fuse from "fuse.js";
import swal from "sweetalert2";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "src/components/ValidationError.vue";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  mixins: [formMixin],

  components: {
    ValidationError,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */

    filteredAndSortedBlogs() {
      return this.tableData.data.sort((a, b) => {
        let modifier = 1;

        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return;
        -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]);
        return 1 * modifier;
        return 0;
      });
    },

    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.tableData.total < highBound) {
        highBound = this.total;
      }

      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.total;
    },
  },

  data() {
    return {
      currentSort: "firstname",
      currentSortDir: "asc",

      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
      },

      query: "",
      propsToSearch: ["firstname"],
      tableData: [],
      group: [],
      licenses: [],
      searchedData: [],
      fuseSearch: null,
    };
  },
  methods: {
    getListDebounced: _.debounce(function () {
      this.getdisplay();
    }, 300),

    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    setCurrentPage(direction) {
      if (direction === -1 && this.pagination.currentPage > 1) {
        this.pagination.currentPage -= 1;
      } else if (
        direction === 1 &&
        this.pagination.currentPage <
          this.tableData.data.length / this.pagination.perPage
      ) {
        this.pagination.currentPage += 1;
      }
    },

    async getdisplay() {
      let params = {
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.query ? { searchValue: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };
      await this.$store.dispatch("displays/displaygetdata", params);
      this.tableData = await this.$store.getters["displays/display_list"];
    },

    goToDetails(id) {
      this.$router.push({ name: "Detail Display Watcher", params: { id } });
      // setInterval(() => {
      //   window.location.reload();
      // }, 1000);
    },
  },

  mounted() {
    // Fuse search initialization.
    (this.fuseSearch = new Fuse(this.tableData, {
      keys: ["firstname"],
      threshold: 0.3,
    })),
      this.getdisplay();
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    query: {
      handler: "getListDebounced",
      immediate: true,
    },

    pagination: {
      handler: "getdisplay",
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

.table > tbody > tr > td {
  padding: 5px;
}
</style>
