<template>
  <div class="mt-3">
    <h4>{{ $t("EDIT CONTENT") }}</h4>
    <card class="stacked-form">
      <div class="col-12 text-right">
        <base-button
          @click="goBack"
          type="button"
          class="btn btn-sm btn-primary"
          >{{ $t("Back to list") }}</base-button
        >
      </div>
      <span v-if="errors && errors">
        <small class="error" v-text="errors" />
      </span>
      <form @submit.prevent>
        <div
          class="list-item"
          v-for="item in myList"
          :id="item.id"
          :order="item.attributes.content_order"
          :key="item.id"
          v-if="
            item.attributes.content_order != 0 &&
            item.attributes.content_order != null
          "
        >
          <base-input
            type="text"
            v-model="my_data[item.attributes.name + '_' + item.id]"
            :label="capital_letter(item.attributes.name)"
            :placeholder="capital_letter(item.attributes.name)"
            required
          />
        </div>
        <card v-if="content.mime == 'Html' && content.htmldetails === null">
          <h4>{{ $t("Change allowed domains") }}</h4>
          <base-input
            :label="$t('Enter domain allowed')"
            v-model="domain"
          ></base-input>

          <base-button class="btn-sm btn-danger ml-3" @click="adddomain()">{{
            $t("Add")
          }}</base-button>
          <!-- <div
            v-for="(data, key) in stringExplode(content.domain)"
            :key="key"
            role="alert"
            class="alert alert-info col-sm-3"
            :v-bind="group.listdomain"
          >
            <button type="button" aria-label="Close" class="close">
              <i class="tim-icons icon-simple-remove" @click="removed()"> </i>
            </button>
            <span data-notify="message"> {{ data }} </span>
          </div> -->

          <div
            role="alert"
            class="alert alert-info col-sm-3"
            v-for="(list, key) in group.listdomain"
            :key="key"
          >
            <button type="button" aria-label="Close" class="close">
              <i class="tim-icons icon-simple-remove" @click="removed(key)">
              </i>
            </button>
            <span data-notify="message"> {{ list }} </span>
          </div>
        </card>
        <card v-if="content.mime == 'Html' && content.htmldetails !== null">
          <div class="form-group">
            <label class="col-sm-3 control-label">{{ $t("HEADER") }}</label>
            <div class="col-sm-6">
              <textarea
                style="width: 800px; height: 110px"
                v-model="html[1].htmldetails"
              >

                &lt;!DOCTYPE HTML PUBLIC &quot;-//W3C//DTD HTML 4.01//EN&quot; &quot;http://www.w3.org/TR/html4/strict.dtd&quot;&gt;&lt;HTML&gt;&lt;HEAD&gt;&lt;/HEAD&gt;
  </textarea
              >
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-3 control-label">{{ $t("CSS") }}</label>
            <div class="col-sm-6">
              <textarea
                v-model="html.css"
                name="css"
                height="300"
                style="width: 800px"
              ></textarea>
            </div>
          </div>

          <br />

          <br />
          <base-input label="DESCRIPTION">
            <editor
              v-model="html[2].htmldetails"
              api-key="f2l19ekob9uuxnqmmi9mqg3shlqend3d4as3639l2fm9fdkn"
              :init="{
                height: 500,
                menubar: true,
                plugins: [
                  'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                  'searchreplace wordcount visualblocks visualchars code fullscreen',
                  'insertdatetime media nonbreaking save table contextmenu directionality',
                  'emoticons template paste textcolor colorpicker textpattern imagetools',
                ],
                toolbar1:
                  'undo redo | formatselect | bold italic backcolor | \
                       alignleft aligncenter alignright alignjustify | \
                       bullist numlist outdent indent | removeformat | help',
                toolbar2: 'preview media | forecolor backcolor emoticons',
              }"
            />
          </base-input>
        </card>
        <base-button
          @click="update()"
          class="mt-3"
          native-type="submit"
          type="primary"
          >{{ $t("Submit") }}</base-button
        >
      </form>
    </card>
  </div>
</template>

<script>
import ValidationError from "src/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import axios from "axios";
import { Vue } from "vue";
import Editor from "@tinymce/tinymce-vue";
import qs from "qs";
const url = process.env.VUE_APP_API_BASE_URL;

function sort(valuePath, array) {
  let path = valuePath.split(".");
  return array.sort((a, b) => {
    return getValue(a, path) - getValue(b, path);
  });

  function getValue(obj, path) {
    path.forEach((path) => (obj = obj[path]));
    return obj;
  }
}

export default {
  components: { ValidationError, editor: Editor },

  BaseInput,
  mixins: [formMixin],

  data: () => ({
    my_data: {},
    html: {},
    content: {},
    group: {
      listdomain: [],
    },
    myList: [],
    errors: null,
    domain: "",
  }),

  created() {
    this.getedit();
    this.getedithtml();
  },

  methods: {
    capital_letter(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },

    getgroup() {
      axios.get(`${url}/categories`).then((response) => {
        this.myList = sort("attributes.content_order", response.data.data);
      });
    },

    getedit(id) {
      id = this.$route.params.id;
      axios.get(`${url}/editcontent/${id}`).then((response) => {
        response.data.forEach((value, index) => {
          this.$set(this.my_data, value.name, value.contentparamvalue);
        });
        this.content = response.data[0];
        var dom = response.data[0].domain.split(",");

        dom.forEach((value, index) => {
          this.group.listdomain.push(value);
        });
      });
    },

    getedithtml(id) {
      id = this.$route.params.id;
      axios.get(`${url}/edithtmlcontent/${id}`).then((response) => {
        this.html = response.data;
      });
    },

    async adddomain() {
      if (this.domain == "" || this.domain == null) {
        this.$notify({
          type: "danger",
          message: this.$t("Invalid URL"),
          icon: "tim-icons icon-bell-55",
        });
      } else {
        this.group.listdomain.push(this.domain);
        return (this.domain = "");
      }
    },

    async update(id) {
      id = this.$route.params.id;
      this.errors = "";
      var next = false;
      var i = 0;

      if (next == true) {
        return false;
      }
      this.content.domain = this.group.listdomain.join();
      var tmp = {};

      tmp["data"] = this.content;
      tmp["value"] = this.my_data;
      tmp["html"] = this.html;
      tmp["css"] = this.html.css;

      if (
        tmp["value"].Description_1 == undefined ||
        tmp["value"].Description_1 == "" ||
        tmp["value"].name_3 == undefined ||
        tmp["value"].name_3 == ""
      ) {
        this.$notify({
          type: "danger",
          message: this.$t("Please Filled All The Required Field."),
          icon: "tim-icons icon-bell-55",
        });
      } else {
        axios.patch(`${url}/updateimagecontent/${id}`, tmp).then((response) => {
          this.goBack();
        });
      }
    },

    removed(key) {
      this.group.listdomain.splice(key, 1);
    },

    goBack() {
      this.$router.push({ name: "List Content" });
    },
    stringExplode(string) {
      return string.split(",");
    },
  },
  mounted() {
    this.getgroup();
  },
};
</script>
