<template>
  <div>
    <main class="main-content mt-0">
      <div
        class="page-header align-items-start min-vh-100"
        style="
          background-image: url('/img/ZENDS_LOGIN_SFONDO.png');
          background-repeat: no-repeat;
          background-size: cover;
        "
      >
        <span class="mask bg-gradient-dark opacity-6"></span>
        <div
          class="container my-auto d-flex align-items-center justify-content-center"
          style="height: 98vh"
        >
          <div class="row w-100 login-transition">
            <div class="col-lg-4 col-md-8 col-12 mx-auto">
              <div
                class="card z-index-0 fadeIn3 fadeInBottom"
                style="border-radius: 10px"
              >
                <div
                  class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                  style="border-radius: 15px"
                >
                  <div class="bg-gradient-primary py-3 pe-1 login-header">
                    <h3
                      class="text-white font-weight-bolder text-center mt-2 mb-0"
                      style="font-weight: 700 !important"
                    >
                      {{ $t("Sign in") }}
                    </h3>
                    <div class="row mt-3 justify-content-center">
                      <div class="text-center ms-auto">
                        <h4 class="px-3 text-white font-weight-bold">ZenDS</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <form
                    @submit.prevent="handleSubmit()"
                    role="form"
                    class="text-start"
                  >
                    <div
                      class="input-group input-group-outline my-3"
                      style="display: block"
                    >
                      <ValidationProvider
                        name="email"
                        rules="required|email"
                        v-slot="{ passed, failed, errors }"
                      >
                        <base-input
                          required
                          v-model="email"
                          type="email"
                          :placeholder="$t('Email')"
                          addon-left-icon="tim-icons icon-email-85"
                          :error="errors[0]"
                          :class="[
                            { 'has-success': passed },
                            { 'has-danger': failed },
                          ]"
                        >
                        </base-input>
                        <validation-error :errors="apiValidationErrors.email" />
                      </ValidationProvider>
                    </div>
                    <div
                      class="input-group input-group-outline mb-3"
                      style="display: block"
                    >
                      <ValidationProvider
                        name="password"
                        rules="required|min:4"
                        v-slot="{ passed, failed, errors }"
                      >
                        <base-input
                          required
                          v-model="password"
                          :placeholder="$t('Password')"
                          addon-left-icon="tim-icons icon-lock-circle"
                          type="password"
                          :error="errors[0]"
                          :class="[
                            { 'has-success': passed },
                            { 'has-danger': failed },
                          ]"
                        >
                        </base-input>
                        <validation-error
                          :errors="apiValidationErrors.password"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="text-center">
                      <base-button
                        native-type="submit"
                        type="primary"
                        class="mb-3 btn bg-gradient-primary w-100 my-4 mb-2"
                        size="lg"
                        block
                      >
                        {{ $t("Get Started") }}
                      </base-button>
                    </div>
                    <div class="pull-right text-sm text-center">
                      <h6>
                        <a
                          href="/password/reset"
                          class="link footer-link text-primary text-gradient font-weight-bold"
                          >{{ $t("Forgot Password?") }}</a
                        >
                      </h6>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer class="footer">
          <div class="container-fluid">
            <div class="copyright"></div>
          </div>
        </footer>
        <footer class="footer position-absolute bottom-2 py-2 w-100">
          <div class="container">
            <div class="row align-items-center justify-content-lg-between">
              <div class="col-12 text-center col-md-6 my-auto mx-auto">
                <div
                  class="copyright text-center text-sm text-white text-lg-start"
                  style="width: 100%"
                >
                  <i class="fa fa-copyright" style="font-weight: 200"></i>
                  {{ $t("2013-2023 Zenyth Srl") }}
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </main>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";

extend("email", email);
extend("min", min);

extend("required", required);

export default {
  mixins: [formMixin],
  components: {
    ValidationError,
  },
  data() {
    return {
      email: "",
      password: "",
      subscribe: true,
    };
  },
  methods: {
    async handleSubmit() {
      const user = {
        data: {
          type: "token",
          attributes: {
            email: this.email,
            password: this.password,
          },
        },
      };
      // console.log(user);
      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };

      try {
        await this.$store.dispatch("login", { user, requestOptions });
      } catch (e) {
        var str = e.message;
        var msg = "";
        if (str.indexOf("422") == -1) {
          msg = "Invalid credentials!";
        } else {
          msg = "User Not verified!";
        }
        this.$notify({
          message: msg,
          icon: "tim-icons icon-bell-55",
          type: "danger",
        });
        this.setApiValidation(e.response.data.errors);
      }
    },
  },
};
</script>
<style>
.login-transition {
  animation: LoginY 1.5s ease;
}

@keyframes LoginY {
  0% {
    opacity: 0.3;
    margin-top: 50rem;
  }
  100% {
    opacity: 1;
    margin-top: 0rem;
  }
}
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.login-header {
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 14%) 0px 4px 20px 0px,
    rgb(30 100 233 / 43%) 0px 7px 10px -5px !important;
}
</style>
