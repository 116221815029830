<template>
  <div class="mt-3">
    <h4 slot="header" class="card-title">{{ $t("NEW DESIGN CREATION") }}</h4>
    <card class="stacked-form">
      <div class="col-md-12">
        <base-input
          :label="$t('Enter the design name')"
          class="col-md-4 offset-md-4"
          v-model="designname"
        />
        <div class="col-md-4 offset-md-4">
          <span v-if="errors">
            <small class="error" v-text="errors" />
          </span>
        </div>
      </div>
      <div
        class="col-md-12 d-inline-flex bg-light-gray border-solid border-bottom"
      >
        <div class="col-md-4 text-center border-right-ccc">
          <input
            type="radio"
            class="mt-2"
            value="1"
            v-model="radio"
            :disabled="disableRadio"
            @change="ChangeOrientation"
          />
          {{ $t("Vertical") }}
        </div>
        <div class="col-md-4 text-center border-right-ccc">
          <input
            type="radio"
            value="2"
            class="mt-2"
            v-model="radio"
            :disabled="disableRadio"
            @change="ChangeOrientation"
          />
          {{ $t("Horizontal") }}
        </div>
        <div class="col-md-4 text-center">
          <el-select
            class="select-primary bg-white"
            name="role"
            placeholder="FullHD 1920x1080 16:9"
            v-model="dropdownresol"
            @change="CheckSize"
            style="color: black"
          >
            <el-option
              v-for="resole in resolution"
              :key="resole.id"
              :value="resole.attributes.description"
              :label="resole.attributes.description"
              :name="resole.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8 text-center">
          <canvas
            id="canvas2"
            style="
              border: 1px solid #000000;
              background: white;
              box-shadow: 1px 5px 29px rgba(50, 50, 50, 0.5);
            "
            v-bind:width="width"
            v-bind:height="height"
            ref="canvas2"
            @dblclick="myDblClick($event)"
            @mousemove="myMove($event)"
            @mouseup="myUp"
            @mousedown="myDown"
          ></canvas>
        </div>
        <div id="dati_container" class="col-md-4 pl-0 pr-0">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h3 class="panel-title mb-0">{{ $t("Sections Details") }}</h3>
            </div>
            <div class="panel-body bg-fafafa">
              <div class="tab-wrapper tab-right" id="tabdiv">
                <!-- <ul class="nav nav-tabs" id="uldiv" v-html="uldivcontent"></ul> -->
                <ul class="nav nav-tabs d-block p-0" id="uldiv">
                  <div v-for="i in index" :key="i" class="border-solid p-2">
                    <!-- <li class="elemento" :class="{active :i=='selezionato'}"> -->
                    <a
                      @click="SelezionaBox(i, $event)"
                      :id="`home-href-${i}`"
                      href="javascript:void(0)"
                      data-toggle="tab"
                      >{{ $t("Section") }}. {{ i }}</a
                    >
                    <!-- </li> -->
                  </div>
                </ul>

                <div id="contentdiv" class="mar-right-10-per">
                  <div
                    v-for="i in index"
                    :key="i"
                    :id="`home-${i}`"
                    class="d-none hide-show pad1015 d-grid border-solid"
                  >
                    <!-- <div  > -->
                    <p>
                      {{ $t("Asse X") }}
                      <input
                        class="dimensioni_input_sezioni"
                        @change="AggiornaX(i - 1)"
                        maxlength="4"
                        type="text"
                        :id="`x${i - 1}`"
                        :value="CalcolaXReale(i - 1)"
                      />
                    </p>
                    <p>
                      {{ $t("Asse Y") }}
                      <input
                        class="dimensioni_input_sezioni"
                        @change="AggiornaY(i - 1)"
                        maxlength="4"
                        type="text"
                        :id="`y${i - 1}`"
                        :value="CalcolaYReale(i - 1)"
                      />
                    </p>
                    <p>
                      {{ $t("Length") }}
                      <input
                        class="dimensioni_input_sezioni"
                        @change="AggiornaW(i - 1)"
                        maxlength="4"
                        type="text"
                        :id="`w${i - 1}`"
                        :value="CalcolaWidthReale(i - 1)"
                      />
                    </p>
                    <p>
                      {{ $t("Height") }}
                      <input
                        class="dimensioni_input_sezioni"
                        @change="AggiornaH(i - 1)"
                        maxlength="4"
                        type="text"
                        :id="`h${i - 1}`"
                        :value="CalcolaHeightReale(i - 1)"
                      />
                    </p>
                    <p>{{ $t("Proportions") }} :</p>
                    <div class="row">
                      <span class="col-md-3"
                        ><input
                          class="icheck"
                          type="radio"
                          name="myradio"
                          id="radio-43-"
                          v-on:click="Resize(i - 1, 4, 3)"
                          :checked="firstradio[i - 1] == true"
                        />4:3</span
                      >
                      <span class="col-md-3"
                        ><input
                          class="icheck"
                          type="radio"
                          name="myradio"
                          id="radio-169-"
                          v-on:click="Resize(i - 1, 16, 9)"
                          :checked="secondradio[i - 1] == true"
                        />
                        16:9</span
                      >
                      <span class="col-md-4"
                        ><input
                          class="icheck"
                          type="radio"
                          name="myradio"
                          :checked="freeradio[i - 1] == true"
                        />
                        {{ $t("Free") }}</span
                      >
                      <span style="float: left; margin: 8px"
                        ><a
                          href="javascript:void(0)"
                          id="full-screen-"
                          class="btn btn-primary"
                          v-on:click="FullScreen(i - 1)"
                          >{{ $t("Full screen") }}</a
                        ></span
                      >
                    </div>

                    <p v-if="i < index">
                      {{ $t("Carry on") }} &nbsp; &nbsp; &nbsp;<a
                        href=" javascript:void(0)"
                        v-on:click="MoveUp(i - 1)"
                        ><i class="fa fa-arrow-up"></i
                      ></a>
                    </p>
                    <p v-if="i > 1">
                      {{ $t("Bring it back") }} &nbsp; &nbsp;<a
                        href="javascript:void(0)"
                        v-on:click="MoveDown(i - 1)"
                        ><i class="fa fa-arrow-down"></i
                      ></a>
                    </p>
                    {{ $t("Container") }}
                    <!-- <span v-if="cates != ''">
                                    <el-select class="select-primary bg-white  col-md-12" :placeholder="cates[0].split('-')[0]"
                                        name="select_categoria" v-model="dpcat[i - 1]"
                                        @change="AggiornaBoxCategoriaSelezionata(i - 1)" style="color: black;"
                                        id="select_categoria-">
                                        <el-option v-for="com in cates" :key="com" :value="com" :label="com.split('-')[0]"
                                            :name="com">
                                        </el-option>
                                    </el-select> -->

                    <el-select
                      class="select-primary bg-white col-md-12"
                      :placeholder="
                        cates != '' ? cates[0].split('-')[0] : 'No Data'
                      "
                      name="select_categoria"
                      v-model="dpcat[i - 1]"
                      @change="AggiornaBoxCategoriaSelezionata(i - 1)"
                      style="color: black"
                      id="select_categoria-"
                    >
                      <el-option
                        v-for="com in cates"
                        :key="com"
                        :value="com"
                        :label="com.split('-')[0]"
                        :name="com"
                      >
                      </el-option>
                    </el-select>
                    <!-- </span> -->
                    <br />
                    <br />{{ $t("Command") }}
                    <el-select
                      class="select-primary bg-white"
                      :placeholder="command_wrapper[0]"
                      name="select_command"
                      v-model="dpcom[i - 1]"
                      @change="AggiornaBoxCommand(i - 1)"
                      style="color: black"
                      id="select_command-"
                    >
                      <el-option
                        v-for="com in command_wrapper"
                        :key="com"
                        :value="com"
                        :label="com.split('-')[0]"
                        :name="com"
                      >
                      </el-option>
                    </el-select>

                    <br />

                    <span :id="`params-${i - 1}`">
                      <p
                        align="left"
                        :class="[
                          { 'd-block': singlesliderindex[i - 1] },
                          { 'd-none': !singlesliderindex[i - 1] },
                        ]"
                      >
                        Zoom :
                        <input
                          type="hidden"
                          class="single-slider"
                          v-model="zoom_value[i]"
                          id="zoom"
                          name="zoom-"
                        />
                        <br />
                        <br />
                        <br />
                        <slider v-model="zoom_value[i - 1]" type="primary">
                        </slider>
                      </p>
                      <br />
                      <p
                        :class="[
                          { 'd-block': templateselectclass[i - 1] },
                          { 'd-none': !templateselectclass[i - 1] },
                        ]"
                      >
                        {{ $t("Template") }}
                        <el-select
                          class="select-primary bg-white"
                          :placeholder="TemplateSelezionato[0]"
                          v-model="templatedata[i - 1]"
                          name="select_template"
                          @change="AggiornaBoxTemplateSelezionato(i - 1)"
                          style="color: black"
                          id="select_command-"
                        >
                          <el-option
                            v-for="temp in templates"
                            :key="temp.idtemplate"
                            :value="`${temp.templateparamvalue}###${temp.idtemplate}`"
                            :label="temp.templateparamvalue"
                            :name="temp.templateparamvalue"
                          >
                          </el-option>
                        </el-select>
                      </p>

                      <p
                        :class="[
                          { 'd-block': templatetargetclass[i - 1] },
                          { 'd-none': !templatetargetclass[i - 1] },
                        ]"
                      >
                        {{ $t("Section target") }}
                        <el-select
                          class="select-primary bg-white col-md-12"
                          :placeholder="TemplateTarget[0]"
                          v-model="sectiondata[i - 1]"
                          name="select_section_target"
                          @change="AggiornaBoxTargetSection(i - 1)"
                          style="color: black"
                          id="select_section-target"
                        >
                          <el-option
                            v-for="temp in TemplateTarget"
                            :key="temp.id"
                            :value="temp"
                            :label="temp"
                            :name="temp"
                          >
                          </el-option>
                        </el-select>
                      </p>

                      <p
                        :class="[
                          { 'd-block': templatetargetclass[i - 1] },
                          { 'd-none': !templatetargetclass[i - 1] },
                        ]"
                      >
                        {{ $t("Target Container") }}
                        <el-select
                          class="select-primary bg-white col-md-12"
                          :placeholder="TargetContainer[0]"
                          v-model="targetdata[i - 1]"
                          name="select_section_target"
                          @change="AggiornaBoxTargetCategory(i - 1)"
                          style="color: black"
                          id="select-target-container"
                        >
                          <el-option
                            v-for="cat in cates"
                            :key="cat"
                            :value="cat"
                            :label="cat.split('-')[0]"
                            :name="cat"
                          >
                          </el-option>
                        </el-select>
                      </p>
                      <p>
                        {{ $t("Always intercept the click") }}
                        <input
                          style="float: left; margin-right: 8px"
                          type="checkbox"
                          @change="AggiornaClick(i - 1)"
                          name="select_click-"
                          v-model="clickintercept[i - 1]"
                          id="select_click-"
                        />
                      </p>
                    </span>

                    <br /><a
                      href="javascript:void(0)"
                      v-on:click="EliminaBox(i - 1)"
                      ><button
                        style="margin-top: 8px"
                        class="btn btn-danger"
                        type="button"
                      >
                        {{ $t("Remove") }}
                      </button></a
                    >
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <modal
        :show.sync="openmodal"
        class="modal-search"
        id="setscheduler"
        :centered="false"
        :show-close="true"
      >
        <template slot="header" class="headerClasses">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("Duplicate the design") }}
          </h5>
        </template>
        <div class="row table-full-width">
          <div class="col-sm-12 text-center">
            {{ $t("Enter the design name") }} :
            <input
              type="text"
              v-model="seconddesignname"
              style="border: 1px solid #ccc"
            />
            <span v-if="seconderrors">
              <small class="error" v-text="seconderrors" />
            </span>
            <!-- <base-input label="Enter the design name" class="form-control" v-model="seconddesignname" /> -->
          </div>

          <div class="text-right col-md-12">
            <!-- <base-button @click="openmodal = false" class="" type="primary">Close</base-button> -->
            <base-button
              class=""
              native-type="submit"
              type="primary"
              @click="checksubmit()"
              >{{ $t("Confirmation") }}
            </base-button>
          </div>
        </div>
      </modal>
      <div class="row">
        <div class="col-md-7 text-right">
          <a
            style="margin: 8px"
            href="javascript:void(0)"
            id="parametro"
            class="btn btn-primary"
            @click="InsertDesignedTemplate()"
            :disabled="buttonDisable"
            >{{ $t("Save") }}</a
          >
          <a
            style="margin: 8px"
            href="javascript:void(0)"
            id="parametro2"
            class="btn btn-primary"
            @click="openmodal = true"
            :disabled="buttonDisable"
            >{{ $t("duplicate") }}</a
          >
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { BaseRadio, Slider } from "src/components/index";
import { Select, Option } from "element-ui";
import axios from "axios";
import Vue from "vue";
import ValidationError from "src/components/ValidationError.vue";
import { Modal } from "@/components";

const url = process.env.VUE_APP_API_BASE_URL;
var Division = 2.5;
var INTERVAL = 20; // how often, in milliseconds, we check to see if a redraw is needed
var canvasValid = false;
var ctx, stylePaddingLeft, stylePaddingTop, styleBorderLeft, styleBorderTop;
var HEIGHT, offsetx, offsety;
var WIDTH;
var boxes2 = [];
var zoom_value = [];
var checkedTrasparentClick = []; // 0 = click can go through, 1 = the canvas can intercept the click.
var ghostcanvas = "";
var gctx = "";
var mySel = null;
var mySelColor = "green";
var mySelWidth = 2;
var mySelBoxColor = "darkred"; // New for selection boxes
var mySelBoxSize = 6;
var selezionato; // id of the selected box
var x_reale_height;
var x_reale_width;
var orientamento = 2;
var lista_categorie = [];
var command_list = [];
var categories = "";
var how_many_playlists = [];
var checkedCategory = [];
var checkedCommand = [];
var checkedTemplate = [];
var c = [];
var targetSection = [];
var targetCategory = [];
var nonRefreshare = false;
var templates = [];
var isDrag = false;
var isResizeDrag = false;
var selectionHandles = [];
var mx, my; // mouse coordinates
var expectResize = -1; // New, will save the # of the selection handle if the mouse is over one.
var orientation = 0;
var cat = [];
var catcat = [];

export default {
  components: {
    BaseRadio,
    Slider,
    Modal,
    [Option.name]: Option,
    [Select.name]: Select,
  },

  data: () => ({
    radio: "2",
    available_roles: [],
    resolution: [],
    dropdownresol: "FullHD 1920x1080 16:9",
    disableRadio: false,
    window: {
      width: 0,
      height: 0,
    },
    height: 432,
    width: 768,
    canvas: null,
    mx: null,
    my: null,
    ischecked: false,
    ischecked43: false,
    ischeckedlibera: false,
    uldivcontent: "",
    index: 0,
    categorywrapper: [],
    command_wrapper: [],
    template_wrapper: [],
    how_many_playlists: [],
    dpcat: [],
    selecttemplate: null,
    dpcom: [],
    singlesliderindex: [],
    TemplateSelezionato: [],
    templateselectclass: [],
    templatetargetclass: [],
    TemplateTarget: [],
    TargetContainer: [],
    designname: null,
    seconddesignname: null,
    errors: null,
    sliders: {
      simple: 30,
      rangeSlider: [20, 60],
    },
    zoom_value: [],
    templatedata: [],
    sectiondata: [],
    targetdata: [],
    dataBoxArray: {
      type: "dataBoxArray",
    },
    designedtemplate: null,
    freeradio: [],
    firstradio: [],
    secondradio: [],
    clickintercept: [],
    openmodal: false,
    seconderrors: null,
    cates: [],
    selezionato1: 0,
    buttonDisable: true,
    templates: [],
  }),
  created() {
    this.getResolution();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  mounted() {
    this.init2();
    this.returncategorywrapper();
    this.returnsectionscommandwrapper();
    this.returntemplatewrapper();
    this.CheckSize();
    this.getcont();
    this.gettemplate();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    getResolution() {
      axios.get(`${url}/resolutions`).then((response) => {
        this.resolution = response.data.data;
      });
    },
    handleResize() {
      this.window.width = window.visualViewport.width;
      this.window.height = window.visualViewport.height;
    },
    CheckSize() {
      var dropdownratio = this.dropdownresol.split(" ")[2];
      let ratio_numerator = dropdownratio.split(":")[0];
      let ratio_denumerator = dropdownratio.split(":")[1];
      var canvaswidth = Math.round(Math.abs(this.window.width / Division));
      var canvasheight = Math.round(
        (canvaswidth / ratio_numerator) * ratio_denumerator
      );

      if (this.dropdownresol.indexOf("Quadrato") > -1) {
        this.disableRadio = true;
        this.height = canvasheight;
        this.width = canvaswidth;
      } else {
        this.disableRadio = false;

        if (this.radio == 1) {
          this.height = canvaswidth;
          this.width = canvasheight;
        } else {
          this.height = canvasheight;
          this.width = canvaswidth;
        }
      }
      this.init2();
    },
    ChangeOrientation() {
      let dropdownval = this.dropdownresol;

      if (dropdownval.length == 0) {
        dropdownval = "16:9";
      } else {
        dropdownval = this.dropdownresol.split(" ")[2];
      }
      let ratio_numerator = dropdownval.split(":")[0];
      let ratio_denumerator = dropdownval.split(":")[1];
      let canvaswidth = Math.round(Math.abs(this.window.width / Division));
      let canvasheight = Math.round(
        (canvaswidth / ratio_numerator) * ratio_denumerator
      );

      if (this.radio == 1) {
        orientamento = 1;
        this.height = canvaswidth;
        this.width = canvasheight;
      } else {
        this.height = canvasheight;
        this.width = canvaswidth;
        orientamento = 2;
      }
      dropdownval = [];
      this.init2();
      orientation = this.radio;
    },

    init2() {
      this.index = 0;
      this.canvas = this.$refs.canvas2;
      var canvas = this.canvas;
      HEIGHT = this.height;
      WIDTH = this.width;

      ctx = this.canvas.getContext("2d");
      ghostcanvas = document.createElement("canvas");
      ghostcanvas.height = HEIGHT;
      ghostcanvas.width = WIDTH;
      gctx = ghostcanvas.getContext("2d");

      boxes2 = [];
      cat = [];
      catcat = [];
      //fixes a problem where double clicking causes text to get selected on the canvas
      this.canvas.onselectstart = function () {
        return false;
      };

      // fixes mouse co-ordinate problems when there's a border or padding
      // see getMouse for more detail
      if (document.defaultView && document.defaultView.getComputedStyle) {
        stylePaddingLeft =
          parseInt(
            document.defaultView.getComputedStyle(this.canvas, null)[
              "paddingLeft"
            ],
            10
          ) || 0;
        stylePaddingTop =
          parseInt(
            document.defaultView.getComputedStyle(this.canvas, null)[
              "paddingTop"
            ],
            10
          ) || 0;
        styleBorderLeft =
          parseInt(
            document.defaultView.getComputedStyle(this.canvas, null)[
              "borderLeftWidth"
            ],
            10
          ) || 0;
        styleBorderTop =
          parseInt(
            document.defaultView.getComputedStyle(this.canvas, null)[
              "borderTopWidth"
            ],
            10
          ) || 0;
      }

      for (var i = 0; i < 16; i++) {
        var rect = new this.Box2();
        selectionHandles.push(rect);
      }
    },
    myDblClick(e) {
      this.getMouse(e);
      // for this method width and height determine the starting X and Y, too.
      // so I left them as vars in case someone wanted to make them args for something and copy this code
      let width = 40;
      let height = 40;

      var x = this.mx - width / 2;
      var y = this.my - height / 2;
      var color =
        "rgba(" +
        this.getRandomInt(0, 255) +
        "," +
        this.getRandomInt(0, 255) +
        "," +
        this.getRandomInt(0, 255) +
        ",0.7)";
      this.addRect(
        this.mx - width / 2,
        this.my - height / 2,
        width,
        height,
        color
      );
      this.mainDraw();
    },
    addRect(x, y, w, h, fill) {
      var rect = new this.Box2();
      rect.x = x;
      rect.y = y;
      rect.w = w;
      rect.h = h;
      rect.fill = fill;
      boxes2.push(rect);
      zoom_value.push(80);
      checkedTrasparentClick.push(0);
      //checkedCommand.push("Nessuna azione");
      this.invalidate();
    },
    invalidate() {
      canvasValid = false;
    },
    getMouse(e) {
      var element = this.canvas,
        offsetX = 0,
        offsetY = 0;

      if (element.offsetParent) {
        do {
          offsetX += element.offsetLeft;
          offsetY += element.offsetTop;
        } while ((element = element.offsetParent));
      }
      // Add padding and border style widths to offset
      offsetX += stylePaddingLeft;
      offsetY += stylePaddingTop;

      offsetX += styleBorderLeft;
      offsetY += styleBorderTop;

      // by zeel

      // this.mx = e.pageX - offsetX;
      // this.my = e.pageY - offsetY

      var rect = e.target.getBoundingClientRect();

      this.mx = e.clientX - rect.left;
      this.my = e.clientY - rect.top;
    },
    mainDraw() {
      if (boxes2.length === 0) {
        this.buttonDisable = true;
      } else {
        this.buttonDisable = false;
      }
      if (canvasValid == false) {
        this.clear(ctx);
        this.index = 0;
        var l = boxes2.length;
        this.index = boxes2.length;
        this.dpcat[boxes2.length] =
          this.categorywrapper[
            Object.keys(this.categorywrapper).slice(0, 1)[0]
          ];
        this.dpcom[boxes2.length] = this.command_wrapper[0];
        var selectedbox = this.selezionato1;

        for (var i = 0; i < l; i++) {
          if (i == selectedbox) {
            var element = document.getElementsByClassName("hide-show");
            for (var j = 0; j < element.length; j++) {
              element[j].classList.add("d-none");
            }
            var ind = i + 1;
            var element = document.getElementById("home-" + ind);
            if (element != null) {
              element.classList.remove("d-none");
            }
          }

          var checkBox169 = document.getElementById("radio-169" + i);
          if (checkBox169 != null && checkBox169.checked == true) {
            ratio = 9 / 16;
            boxes2[i].h = boxes2[i].w * ratio;
          } else {
            var checkBox43 = document.getElementById("radio-43" + i);
            if (checkBox43 != null && checkBox43.checked == true) {
              ratio = 3 / 4;
              boxes2[i].h = boxes2[i].w * ratio;
            }
          }
          this.draw(boxes2[i], ctx, 0, i + 1);

          let tab_pane_elements = document.getElementsByClassName("tab-pane");

          while (tab_pane_elements.length > 0) tab_pane_elements[0].remove();

          let elemento = document.getElementsByClassName("elemento");

          while (elemento.length > 0) elemento[0].remove();

          // if ((boxes2[i].w / boxes2[i].h) == (16 / 9)) {
          //     this.firstradio[i] = true;
          //     this.secondradio[i] = false;
          this.freeradio[i] = true;
          // } else if ((boxes2[i].w / boxes2[i].h) == (4 / 3)) {
          //     this.firstradio[i] = false;
          //     this.secondradio[i] = true;
          //     this.freeradio[i] = false;
          // } else {
          //     this.firstradio[i] = false;
          //     this.secondradio[i] = false;
          //     this.freeradio[i] = true;
          // }
        }
        canvasValid = true;
      }
    },
    clear(c) {
      c.clearRect(0, 0, WIDTH, HEIGHT);
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    Box2() {
      this.x = 0;
      this.y = 0;
      this.w = 1; // default width and height?
      this.h = 1;
      this.fill = "#A1A1A1";
    },
    draw($event, context, optionalColor, textToWrite) {
      if (context === gctx) {
        context.fillStyle = "black"; // always want black for the ghost canvas
      } else {
        context.fillStyle = $event.fill;
      }

      // We can skip the drawing of elements that have moved off the screen:
      if ($event.x > WIDTH || $event.y > HEIGHT) return;
      if ($event.x + $event.w < 0 || $event.y + $event.h < 0) return;

      context.fillRect($event.x, $event.y, $event.w, $event.h);

      var fontsize = 0;

      if ($event.w > $event.h) fontsize = Math.abs($event.w) / 2;
      if ($event.h >= $event.w) fontsize = Math.abs($event.h) / 2;
      if (fontsize > Math.abs($event.w)) fontsize = Math.abs($event.w);
      if (fontsize > Math.abs($event.h)) fontsize = Math.abs($event.h);

      var newx = $event.x + $event.w / 2 - fontsize * 0.4;
      var newy = $event.y + $event.h / 2 + fontsize * 0.5;

      // check if 16:9 or 4:3 is selected

      context.font = fontsize + "pt sans-serif";
      context.strokeText(textToWrite, newx, newy);
      //context.strokeText("Canvas Rocks!", 5, 130);

      // draw selection
      // this is a stroke along the box and also 8 new selection handles
      if (mySel === $event) {
        context.strokeStyle = mySelColor;
        context.lineWidth = mySelWidth;
        context.strokeRect($event.x, $event.y, $event.w, $event.h);

        // draw the boxes

        var half = mySelBoxSize / 2;
        // 0  1  2
        // 3     4
        // 5  6  7

        // top left, middle, right
        selectionHandles[0].x = $event.x - half;
        selectionHandles[0].y = $event.y - half;

        selectionHandles[1].x = $event.x + $event.w / 2 - half;
        selectionHandles[1].y = $event.y - half;

        selectionHandles[2].x = $event.x + $event.w - half;
        selectionHandles[2].y = $event.y - half;

        //middle left
        selectionHandles[3].x = $event.x - half;
        selectionHandles[3].y = $event.y + $event.h / 2 - half;

        //middle right
        selectionHandles[4].x = $event.x + $event.w - half;
        selectionHandles[4].y = $event.y + $event.h / 2 - half;

        //bottom left, middle, right
        selectionHandles[6].x = $event.x + $event.w / 2 - half;
        selectionHandles[6].y = $event.y + $event.h - half;

        selectionHandles[5].x = $event.x - half;
        selectionHandles[5].y = $event.y + $event.h - half;

        selectionHandles[7].x = $event.x + $event.w - half;
        selectionHandles[7].y = $event.y + $event.h - half;

        context.fillStyle = mySelBoxColor;

        for (var i = 0; i < 8; i++) {
          var cur = selectionHandles[i];
          context.fillRect(cur.x, cur.y, mySelBoxSize, mySelBoxSize);
        }
      }
    }, // end draw

    CalcolaXReale(boxid) {
      if (orientamento == 2) {
        var x_reale = this.dropdownlength().x_reale_width;
      } else {
        var x_reale = this.dropdownlength().x_reale_height;
      }

      var x_fasulla = boxes2[boxid].x;
      var lunghezza_fasulla = this.width;
      return Math.round((x_fasulla * x_reale) / lunghezza_fasulla);
    },
    CalcolaYReale(boxid) {
      if (orientamento == 2) {
        var y_reale = this.dropdownlength().x_reale_height;
      } else {
        var y_reale = this.dropdownlength().x_reale_width;
      }

      var y_fasulla = boxes2[boxid].y;
      var altezza_fasulla = this.height;
      return Math.round((y_fasulla * y_reale) / altezza_fasulla);
    },

    CalcolaWidthReale(boxid) {
      if (orientamento == 2) {
        var width_reale = this.dropdownlength().x_reale_width;
      } else {
        var width_reale = this.dropdownlength().x_reale_height;
      }
      var width_fasulla = boxes2[boxid].w;
      var lunghezza_fasulla = this.width;
      return Math.round((width_fasulla * width_reale) / lunghezza_fasulla);
    },
    CalcolaHeightReale(boxid) {
      if (orientamento == 2) {
        var height_reale = this.dropdownlength().x_reale_height;
      } else {
        var height_reale = this.dropdownlength().x_reale_width;
      }

      var y_fasulla = boxes2[boxid].h;
      var altezza_fasulla = this.height;
      return Math.round((y_fasulla * height_reale) / altezza_fasulla);
    },
    SelezionaBox(boxId, event) {
      if (event != null) {
        for (
          var i = 0;
          i < document.getElementById("uldiv").children.length;
          i++
        ) {
          document.getElementById("uldiv").children[i].style.borderRight =
            "1px solid #ccc";
        }
        // event.path[1].style.borderRight = "2px solid #006699";
        var element = document.getElementsByClassName("hide-show");
        for (var i = 0; i < element.length; i++) {
          element[i].classList.add("d-none");
        }

        var element = document.getElementById("home-" + boxId);
        element.classList.remove("d-none");
      }

      mySel = boxes2[boxId - 1];
      this.selezionato1 = boxId;
      offsetx = mySel.x;
      offsety = mySel.y;
      mySel.x = offsetx;
      mySel.y = offsety;
      isDrag = true;
      canvasValid = false;
      gctx.clearRect(0, 0, WIDTH, HEIGHT);
      isDrag = false;
      isResizeDrag = false;
      expectResize = -1;
      this.mainDraw(boxes2[boxId - 1], ctx, 0, boxId);
      //$("select_comando-"+boxId).val(checkedCommand[boxId]);
      //AggiornaBoxCommand(boxId);
    },
    async returncategorywrapper() {
      await this.$store.dispatch("templatesdetail/categorywrapper");
      this.categorywrapper = await this.$store.getters[
        "templatesdetail/cat_wrapper"
      ];
    },

    async returnsectionscommandwrapper() {
      await this.$store.dispatch(
        "templatesdetail/returnsectionscommandwrapper"
      );
      this.command_wrapper = await this.$store.getters[
        "templatesdetail/command_wrapper"
      ];
    },
    async returntemplatewrapper() {
      await this.$store.dispatch("templatesdetail/returntemplatewrapper");
      this.template_wrapper = await this.$store.getters[
        "templatesdetail/template_wrapper"
      ];
    },

    AggiornaBoxCategoriaSelezionata(boxid) {
      checkedCategory[boxid] = this.dpcat[boxid];
    },
    AggiornaBoxCommand(boxid) {
      //if (zoom_value[boxid]=='undefined') zoom_value[boxid]=80;
      checkedCommand[boxid] = this.dpcom[boxid];

      var comando = checkedCommand[boxid].split("-")[0];

      switch (comando) {
        case "Nessuna azione":
          this.singlesliderindex[boxid] = false;
          this.templateselectclass[boxid] = false;
          this.templatetargetclass[boxid] = false;
          break;
        case "Full screen":
          this.singlesliderindex[boxid] = true;
          this.templateselectclass[boxid] = false;
          this.templatetargetclass[boxid] = false;
          break;
        case "Carica template":
          var templates = this.template_wrapper;
          var TemplateSelezionato = [];
          templates.forEach(function (value, index) {
            TemplateSelezionato.push(value.split("###")[0]);
          });
          this.singlesliderindex[boxid] = false;
          this.templateselectclass[boxid] = true;
          this.templatetargetclass[boxid] = false;

          this.TemplateSelezionato = TemplateSelezionato;

          break;

        case "Carica contenitore":
          this.singlesliderindex[boxid] = false;
          this.templateselectclass[boxid] = false;
          this.templatetargetclass[boxid] = true;

          var my_box_index = "1";
          var Templatetarget = [];
          var TargetContainer = [];

          boxes2.forEach(function (value, index) {
            Templatetarget.push(my_box_index);
            my_box_index++;
          });
          this.TemplateTarget = Templatetarget;

          this.categorywrapper.forEach(function (value, index2) {
            // var value2 = value.split("-");
            // value2 = value2.slice(0, value2.length - 1).join('-');
            TargetContainer.push(value);
          });
          this.TargetContainer = TargetContainer;
          break;
      }
    },
    AggiornaX(boxid) {
      var x_inserita_reale = document.getElementById("x" + boxid).value;
      var lunghezza_fasulla = this.width;
      var width_reale = this.dropdownlength().x_reale_width;
      if (orientamento == 2) {
        boxes2[boxid].x = (x_inserita_reale * lunghezza_fasulla) / width_reale;
        canvasValid = false;
      } else {
        boxes2[boxid].x = (x_inserita_reale * lunghezza_fasulla) / width_reale;
        canvasValid = false;
      }
      this.mainDraw();
    },
    AggiornaY(boxid) {
      var y_inserita_reale = document.getElementById("y" + boxid).value;
      var altezza_fasulla = this.height;
      var altezza_reale = this.dropdownlength().x_reale_height;
      if (orientamento == 2) {
        boxes2[boxid].y = (y_inserita_reale * altezza_fasulla) / altezza_reale;
        canvasValid = false;
      } else {
        boxes2[boxid].y = (y_inserita_reale * altezza_fasulla) / altezza_reale;
        canvasValid = false;
      }
      this.mainDraw();
    },
    AggiornaW(boxid) {
      var w_inserita_reale = document.getElementById("w" + boxid).value;
      var larghezza_fasulla = this.width;
      var larghezza_reale = this.dropdownlength().x_reale_width;
      if (orientamento == 2) {
        boxes2[boxid].w =
          (w_inserita_reale * larghezza_fasulla) / larghezza_reale;
        canvasValid = false;
      } else {
        boxes2[boxid].w =
          (w_inserita_reale * larghezza_fasulla) / larghezza_reale;
        canvasValid = false;
      }

      this.mainDraw();
    },
    AggiornaH(boxid) {
      var h_inserita_reale = document.getElementById("h" + boxid).value;
      var altezza_fasulla = this.height;
      var altezza_reale = this.dropdownlength().x_reale_height;
      if (orientamento == 2) {
        boxes2[boxid].h = (h_inserita_reale * altezza_fasulla) / altezza_reale;
        canvasValid = false;
      } else {
        boxes2[boxid].h = (h_inserita_reale * altezza_fasulla) / altezza_reale;
        canvasValid = false;
      }
      this.mainDraw();
    },
    dropdownlength() {
      if (this.radio == 2) {
        x_reale_height = this.dropdownresol.split(" ")[1].split("x")[1];
        x_reale_width = this.dropdownresol.split(" ")[1].split("x")[0];
      } else {
        x_reale_height = this.dropdownresol.split(" ")[1].split("x")[1];
        x_reale_width = this.dropdownresol.split(" ")[1].split("x")[0];
      }

      return {
        x_reale_height,
        x_reale_width,
      };
    },
    Resize(boxid, w1, h1) {
      var ratio = h1 / w1;
      boxes2[boxid].h = boxes2[boxid].w * ratio;
      canvasValid = false;
      this.mainDraw();
    },
    FullScreen(boxid) {
      // console.log(boxid);
      this.freeradio[boxid] = true;
      var newX = 0;
      var newY = 0;
      var newW = WIDTH;
      var newH = HEIGHT;

      boxes2[boxid].x = newX;
      boxes2[boxid].y = newY;
      boxes2[boxid].w = newW;
      boxes2[boxid].h = newH;
      canvasValid = false;
      this.mainDraw();
    },
    EliminaBox(boxid) {
      var temp = [];
      for (var index = 0; index < boxes2.length; ++index) {
        if (index != boxid) temp.push(boxes2[index]);
      }
      boxes2 = temp;
      checkedTrasparentClick.splice(boxid, 1);
      canvasValid = false;
      this.mainDraw();
    },
    MoveUp(boxid) {
      var temp = [];
      var temp2 = []; // transparenza		|| checkedTrasparentClick
      var temp3 = []; // contenitore 		|| checkedCategory
      var temp4 = []; // comando     		|| checkedCommand
      var temp5 = []; // zoom        		|| zoom_value
      var temp6 = []; // target section         || targetSection
      var temp7 = []; // target category        || targetCategory
      var temp8 = []; // template da caricare   || checkedTemplate

      for (var index = 0; index < boxes2.length; index++) {
        if (index != boxid) {
          temp2.push(checkedTrasparentClick[index]);
          temp3.push(checkedCategory[index]);
          temp4.push(checkedCommand[index]);
          temp5.push(zoom_value[index]);
          temp6.push(targetSection[index]);
          temp7.push(targetCategory[index]);
          temp8.push(checkedTemplate[index]);

          temp.push(boxes2[index]);
        } else {
          temp2.push(checkedTrasparentClick[index + 1]);
          temp2.push(checkedTrasparentClick[index]);

          temp3.push(checkedCategory[index + 1]);
          temp3.push(checkedCategory[index]);

          temp4.push(checkedCommand[index + 1]);
          temp4.push(checkedCommand[index]);

          temp5.push(zoom_value[index + 1]);
          temp5.push(zoom_value[index]);

          temp6.push(targetSection[index + 1]);
          temp6.push(targetSection[index]);

          temp7.push(targetCategory[index + 1]);
          temp7.push(targetCategory[index]);

          temp8.push(checkedTemplate[index + 1]);
          temp8.push(checkedTemplate[index]);

          temp.push(boxes2[index + 1]);
          temp.push(boxes2[index]);
          index++;
        }
      }

      boxes2 = temp;
      checkedTrasparentClick = temp2;
      checkedCategory = temp3;
      checkedCommand = temp4;

      zoom_value = temp5;
      this.zoom_value.push(zoom_value);
      targetSection = temp6;
      targetCategory = temp7;
      checkedTemplate = temp8;
      this.SelezionaBox(boxid + 1);
      this.mainDraw();
    },
    MoveDown(boxid) {
      var temp = [];
      var temp2 = []; // transparenza		|| checkedTrasparentClick
      var temp3 = []; // contenitore 		|| checkedCategory
      var temp4 = []; // comando     		|| checkedCommand
      var temp5 = []; // zoom        		|| zoom_value
      var temp6 = []; // target section         || targetSection
      var temp7 = []; // target category        || targetCategory
      var temp8 = []; // template da caricare   || checkedTemplate

      for (var index = 0; index < boxes2.length; index++) {
        if (index + 1 != boxid) {
          temp2.push(checkedTrasparentClick[index]);
          temp3.push(checkedCategory[index]);
          temp4.push(checkedCommand[index]);
          temp5.push(zoom_value[index]);
          temp6.push(targetSection[index]);
          temp7.push(targetCategory[index]);
          temp8.push(checkedTemplate[index]);
          temp.push(boxes2[index]);
        } else {
          temp2.push(checkedTrasparentClick[index + 1]);
          temp2.push(checkedTrasparentClick[index]);

          temp3.push(checkedCategory[index + 1]);
          temp3.push(checkedCategory[index]);

          temp4.push(checkedCommand[index + 1]);
          temp4.push(checkedCommand[index]);

          temp5.push(zoom_value[index + 1]);
          temp5.push(zoom_value[index]);

          temp6.push(targetSection[index + 1]);
          temp6.push(targetSection[index]);

          temp7.push(targetCategory[index + 1]);
          temp7.push(targetCategory[index]);

          temp8.push(checkedTemplate[index + 1]);
          temp8.push(checkedTemplate[index]);

          temp.push(boxes2[index + 1]);
          temp.push(boxes2[index]);
          index++;
        }
      }
      boxes2 = temp;
      checkedTrasparentClick = temp2;
      checkedCategory = temp3;
      checkedCommand = temp4;

      zoom_value = temp5;
      this.zoom_value.push(zoom_value);
      targetSection = temp6;
      targetCategory = temp7;
      checkedTemplate = temp8;
      this.SelezionaBox(boxid);
      this.mainDraw();
    },
    myMove(e) {
      if (isDrag) {
        this.getMouse(e);

        mySel.x = this.mx - offsetx;
        mySel.y = this.my - offsety;

        // something is changing position so we better invalidate the canvas!
        this.invalidate();
      } else if (isResizeDrag) {
        // time ro resize!
        var oldx = mySel.x;
        var oldy = mySel.y;

        // 0  1  2
        // 3     4
        // 5  6  7
        switch (expectResize) {
          case 0:
            mySel.x = this.mx;
            mySel.y = this.my;
            mySel.w += oldx - this.mx;
            mySel.h += oldy - this.my;
            break;
          case 1:
            mySel.y = this.my;
            mySel.h += oldy - this.my;
            break;
          case 2:
            mySel.y = this.my;
            mySel.w = this.mx - oldx;
            mySel.h += oldy - this.my;
            break;
          case 3:
            mySel.x = this.mx;
            mySel.w += oldx - this.mx;
            break;
          case 4:
            mySel.w = this.mx - oldx;
            break;
          case 5:
            mySel.x = this.mx;
            mySel.w += oldx - this.mx;
            mySel.h = this.my - oldy;
            break;
          case 6:
            mySel.h = this.my - oldy;
            break;
          case 7:
            mySel.w = this.mx - oldx;
            mySel.h = this.my - oldy;
            break;
        }

        this.invalidate();
      }

      this.getMouse(e);
      // if there's a selection see if we grabbed one of the selection handles
      if (mySel !== null && !isResizeDrag) {
        for (var i = 0; i < 8; i++) {
          // 0  1  2
          // 3     4
          // 5  6  7

          var cur = selectionHandles[i];
          //selezionato=cur;
          // we dont need to use the ghost context because
          // selection handles will always be rectangles
          if (
            this.mx >= cur.x &&
            this.mx <= cur.x + mySelBoxSize &&
            this.my >= cur.y &&
            this.my <= cur.y + mySelBoxSize
          ) {
            // we found one!
            expectResize = i;
            this.invalidate();

            switch (i) {
              case 0:
                this.canvas.style.cursor = "nw-resize";
                break;
              case 1:
                this.canvas.style.cursor = "n-resize";
                break;
              case 2:
                this.canvas.style.cursor = "ne-resize";
                break;
              case 3:
                this.canvas.style.cursor = "w-resize";
                break;
              case 4:
                this.canvas.style.cursor = "e-resize";
                break;
              case 5:
                this.canvas.style.cursor = "sw-resize";
                break;
              case 6:
                this.canvas.style.cursor = "s-resize";
                break;
              case 7:
                this.canvas.style.cursor = "se-resize";
                break;
            }
            return;
          }
        }
        // not over a selection box, return to normal
        isResizeDrag = false;
        expectResize = -1;
        this.canvas.style.cursor = "auto";
      }
      this.mainDraw();
    },
    myUp() {
      isDrag = false;
      isResizeDrag = false;
      expectResize = -1;
      this.mainDraw();
    },
    myDown(e) {
      this.getMouse(e);

      //we are over a selection box
      if (expectResize !== -1) {
        isResizeDrag = true;
        return;
      }

      this.clear(gctx);
      var l = boxes2.length;
      for (var i = l - 1; i >= 0; i--) {
        // draw shape onto ghost context
        this.draw(boxes2[i], gctx, "black");

        // get image data at the mouse x,y pixel

        var imageData = gctx.getImageData(this.mx, this.my, 1, 1);
        var index = (this.mx + this.my * imageData.width) * 4;

        // if the mouse pixel exists, select and break
        if (imageData.data[3] > 0) {
          mySel = boxes2[i];
          this.selezionato1 = i;
          offsetx = this.mx - mySel.x;
          offsety = this.my - mySel.y;
          mySel.x = this.mx - offsetx;
          mySel.y = this.my - offsety;
          isDrag = true;

          this.invalidate();
          this.clear(gctx);
          this.CalcolaXReale(i);
          return;
        }
      }
      // havent returned means we have selected nothing
      mySel = null;
      // clear the ghost canvas for next time
      this.clear(gctx);
      // invalidate because we might need the selection border to disappear
      this.invalidate();
      this.mainDraw();
    },
    AggiornaBoxTargetCategory(boxId) {
      targetCategory[boxId] = this.targetdata[boxId];
    },
    AggiornaBoxTargetSection(boxId) {
      targetSection[boxId] = this.sectiondata[boxId];
    },
    AggiornaBoxTemplateSelezionato(boxid) {
      checkedTemplate[boxid] = this.templatedata[boxid];
    },
    AggiornaClick(boxid) {
      if (this.clickintercept[boxid]) {
        checkedTrasparentClick[boxid] = 1;
      } else {
        checkedTrasparentClick[boxid] = 0;
      }
    },
    async InsertDesignedTemplate() {
      var dataBoxArray = [];
      // return;
      this.errors = "";
      if (this.designname == null) {
        this.errors = "Please enter the design name";
        return;
      } else {
        try {
          mySel = null;

          if (this.dropdownresol) {
            var caratteristicheDesignedTemplate = this.dropdownresol;
          } else {
            var caratteristicheDesignedTemplate = "FullHD 1920x1080 16:9";
          }
          var image;
          canvasValid = false;

          dataBoxArray = [];

          // $("#parametro").attr({
          //     "data-toggle": "modal",
          //     "data-target": "#basicModal"
          // });

          // setTimeout(function (){

          var l = boxes2.length - 1;
          image = this.canvas.toDataURL();
          var valueToPush = {};

          valueToPush["image"] = image;
          dataBoxArray.push(valueToPush);

          if (orientation == 1) valueToPush["orientation"] = "vertical";
          else valueToPush["orientation"] = "horizontal";
          dataBoxArray.push(valueToPush);

          valueToPush["caratteristicheDesignedTemplate"] =
            caratteristicheDesignedTemplate;
          dataBoxArray.push(valueToPush);
          valueToPush["title"] = this.designname;

          dataBoxArray.push(valueToPush);
          for (var i = 0; i <= l; i++) {
            var valueToPush = {};
            valueToPush["boxId"] = i;
            valueToPush["x"] = this.CalcolaXReale(i);
            valueToPush["y"] = this.CalcolaYReale(i);
            valueToPush["w"] = this.CalcolaWidthReale(i);
            valueToPush["h"] = this.CalcolaHeightReale(i);
            var dpcat;
            if (this.dpcat[i] == undefined) {
              dpcat = this.cates[0];
            } else {
              dpcat = this.dpcat[i];
            }
            var select_command;

            if (this.dpcom[i] == undefined) {
              select_command = this.command_wrapper[0];
            } else {
              select_command = this.dpcom[i];
            }

            var linktemplate;
            if (this.templatedata.length == 0) {
              linktemplate = this.TemplateSelezionato[0];
            } else {
              linktemplate = this.templatedata[i];
            }
            if (linktemplate === undefined) {
              linktemplate = "running###running";
            }
            var target_category;
            if (this.targetdata.length == 0) {
              target_category = this.TargetContainer[0];
            } else {
              target_category = this.targetdata[i];
            }
            var target_section;
            if (this.sectiondata.length == 0) {
              target_section = this.TemplateTarget[0];
            } else {
              target_section = this.sectiondata[i];
            }

            var zoom_value_slider;
            if (
              this.singlesliderindex[i] == undefined ||
              this.singlesliderindex[i] == false
            ) {
              zoom_value_slider = undefined;
            } else {
              zoom_value_slider = this.zoom_value[i];
            }
            valueToPush["category"] = dpcat;
            valueToPush["command"] = select_command;
            valueToPush["zoom"] = zoom_value_slider;
            valueToPush["linktemplate"] = linktemplate;

            valueToPush["click"] = checkedTrasparentClick[i];

            if (select_command.indexOf("Carica contenitore") > -1) {
              if (typeof targetSection[i] !== "undefined") {
                if (valueToPush["target_section"] != "") {
                  // no edit has been done
                  valueToPush["target_section"] = target_section;
                } else {
                  valueToPush["target_section"] = targetSection[i];
                }

                if (targetCategory[i].indexOf("-") == -1) {
                  // no edit has been done
                  valueToPush["target_category"] = target_category;
                } else {
                  valueToPush["target_category"] = targetCategory[i];
                }
              } else {
                valueToPush["target_section"] = target_section;
                valueToPush["target_category"] = target_category;
              }
            }

            dataBoxArray.push(valueToPush);
          }
          this.dataBoxArray = dataBoxArray;
          await this.$store.dispatch(
            "templatesdetail/insertdesignedtemplatewrapper",
            this.dataBoxArray
          );
          this.designedtemplate = await this.$store.getters[
            "templatesdetail/cat_wrapper"
          ];
          if (this.designedtemplate) {
            this.$notify({
              type: "success",
              message: this.$t("Design Inserted."),
              icon: "tim-icons icon-bell-55",
            });
          } else {
            this.$notify({
              type: "danger",
              message: this.$t("Something went wrong!"),
              icon: "tim-icons icon-bell-55",
            });
          }
          this.$router.push("/template/list-design");
        } catch (e) {
          this.$notify({
            type: "danger",
            message: this.$t("Connection error"),
            icon: "tim-icons icon-bell-55",
          });
        }
      }
    },
    checksubmit() {
      this.seconderrors = "";
      var title = this.designname;
      if (this.seconddesignname != null) {
        // data-dismiss="modal"
        this.openmodal = false;
        // $("#title2").val("");
        this.designname = this.seconddesignname;
        this.InsertDesignedTemplate();
        this.designname = title;
      } else {
        this.seconderrors = "Please enter a name";
      }
    },
    getcont() {
      axios.get(`${url}/getcontainerdesign`).then((response) => {
        this.cates = response.data;
      });
    },
    async gettemplate() {
      await axios.get(`${url}/gettemplatedata`).then((response) => {
        this.templates = response.data;
      });
    },
  },
};
</script>

<style>
.mar-right-10-per {
  margin-right: 54px;
}

.bg-light-gray {
  background-color: #eee;
}

.form-check {
  margin-bottom: 0.5rem;
}

.d-inline-flex {
  display: inline-flex;
}

.border-solid {
  border: 1px solid #ccc;
}

.border-right-ccc {
  border-right: 1px solid #ccc;
}

.border-bottom {
  margin-bottom: 15px;
}

.panel-default > .panel-heading {
  border-color: #eff2f7;
  background: #fafafa;
  color: #767676;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 15px;
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  box-shadow: 0 1px 1pxrgba (0, 0, 0, 0.05);
  border: none;
  box-shadow: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}

.panel .actions {
  position: absolute;
  right: 30px;
  top: 18px;
}

.panel-body {
  padding: 15px;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  margin-right: 3px;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 35%;
  height: 31%;
  margin: 31% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: var(--primary);
}

.tab-wrapper {
  margin-bottom: 20px;
}

.tab-wrapper.tab-right > .nav-tabs {
  float: right;
  margin-left: -1px;
}

.elemento {
  float: none !important;
  min-width: 75px !important;
}

.tab-wrapper.tab-right .tab-content {
  overflow: auto;
  border-top: 1px solid #ddd;
}

.tab-wrapper .tab-content {
  border: 1px solid #ddd;
  border-top: 0;
  padding: 10px 15px;
}

.tab-content > .tab-pane {
  display: none;
}

.d-block {
  display: block;
}

.pad1015 {
  padding: 10px 15px;
}

.bg-fafafa {
  background: #fafafa;
}

.dimensioni_input_sezioni {
  min-width: 40px;
  max-width: 60px;
  margin-left: 20px;
  text-align: center;
  text-align: -webkit-center;
  padding: 2px 5px;
  float: right;
  margin-right: 25%;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.d-grid {
  display: grid;
}
</style>
