export function getTopColor(columnName, sortparam) {
  if (sortparam && sortparam.field.toLowerCase() === columnName.toLowerCase()) {
    return sortparam.sort.toLowerCase() === "asc" ? "#49a3f1" : "gray";
  }
  return "gray";
}

export function getBottomColor(columnName, sortparam) {
  if (sortparam && sortparam.field.toLowerCase() === columnName.toLowerCase()) {
    return sortparam.sort.toLowerCase() !== "asc" ? "#49a3f1" : "gray";
  }
  return "gray";
}
