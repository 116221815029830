var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-overlay"},[_c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"model-header"},[_c('button',{staticClass:"close-button",on:{"click":function($event){return _vm.$emit('closePreviewModal')}}},[_c('i',{staticClass:"tim-icons icon-simple-remove"})])]),(_vm.privewData)?_c('div',{staticClass:"canvas-preview-detail"},[_c('div',{staticClass:"canvas",style:({
          width: _vm.privewData.width + 'px',
          height: _vm.privewData.height + 'px',
          position: 'relative',
          overflow: 'hidden',
        })},_vm._l((_vm.previewDetails),function(container,containerIndex){return _c('div',{key:containerIndex,class:container.containername.split(' ').join(''),style:({
            width: container.containerDesign.w + 'px',
            marginLeft: container.containerDesign.x + 'px',
            height: container.containerDesign.h + 'px',
            marginTop: container.containerDesign.y + 'px',
            position: 'absolute',
          })},[_vm._l((container.containerData
              .content_sorting),function(playlistData,playlistIndex){return _vm._l((_vm.availImages),function(item,i){return _c('div',{key:'zends' + playlistIndex + i},[(playlistData.type === 'image')?_c('img',{directives:[{name:"show",rawName:"v-show",value:(item.index === playlistIndex),expression:"item.index === playlistIndex"}],key:playlistIndex,staticClass:"preview1",style:({
                  width: container.containerDesign.w + 'px',
                  height: container.containerDesign.h + 'px',
                  position: 'absolute',
                  background: '#fff',
                }),attrs:{"src":playlistData.img}}):(playlistData.type === 'Url')?_c('img',{directives:[{name:"show",rawName:"v-show",value:(item.index === playlistIndex),expression:"item.index === playlistIndex"}],key:playlistIndex,staticClass:"preview1",style:({
                  width: container.containerDesign.w + 'px',
                  height: container.containerDesign.h + 'px',
                  position: 'absolute',
                }),attrs:{"src":`https://api.apiflash.com/v1/urltoimage?access_key=8657da721f7a4588ae338c2494c02386&wait_until=page_loaded&url=${playlistData.url}`}}):(playlistData.type === 'Html')?_c('iframe',{directives:[{name:"show",rawName:"v-show",value:(item.index === playlistIndex),expression:"item.index === playlistIndex"}],staticClass:"preview1",style:({
                  width: container.containerDesign.w + 'px',
                  height: container.containerDesign.h + 'px',
                  position: 'absolute',
                }),attrs:{"src":playlistData.url}}):(playlistData.type === 'application')?_c('iframe',{directives:[{name:"show",rawName:"v-show",value:(item.index === playlistIndex),expression:"item.index === playlistIndex"}],staticClass:"preview",style:({
                  width: container.containerDesign.w + 'px',
                  height: container.containerDesign.h + 'px',
                  position: 'absolute',
                }),attrs:{"src":playlistData.img}}):(playlistData.type === 'video')?_c('video',{directives:[{name:"show",rawName:"v-show",value:(item.index === playlistIndex),expression:"item.index === playlistIndex"}],style:({
                  width: container.containerDesign.w + 'px',
                  height: container.containerDesign.h + 'px',
                  position: 'absolute',
                  background: '#fff',
                }),attrs:{"id":"myvideo","controls":"","autoplay":"","loop":""}},[_c('source',{key:playlistIndex,staticClass:"active",attrs:{"src":playlistData.img,"type":"video/mp4"}})]):_c('embed',{style:({
                  width: container.containerDesign.w + 'px',
                  height: container.containerDesign.h + 'px',
                  position: 'absolute',
                }),attrs:{"src":playlistData.img}})])})})],2)}),0)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }