<template>
  <form @submit.prevent>
    <h4 slot="header" class="card-title mt-3">{{ $t("NEW PLAYLIST") }}</h4>
    <card class="stacked-form">
      <div class="row">
        <base-input
          label="Enter the playlist name"
          class="col-md-12"
          v-model="playlist.pname"
        />
      </div>
      <base-button
        @click="save()"
        native-type="submit"
        type="primary"
        :disabled="playlist.content.length === 0"
        >{{ $t("Submit") }}</base-button
      >
    </card>
    <div class="row mt-5">
      <div class="col-7">
        <card class="stacked-form table-responsive" title="CONTENT LISTING">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('Search records')"
                  v-model="query"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <modal
              :show.sync="openimagemodal"
              class="modal-search"
              id="setscheduler"
              :centered="false"
              :show-close="true"
            >
              <div
                v-if="selectedImage"
                max-width="85vw"
                style="text-align: center"
              >
                <img :src="selectedImage" alt="" width="100%" height="auto" />

                <br />
                <br />
                <hr />
                <base-button
                  @click="openimagemodal = false"
                  class=""
                  type="primary"
                  >{{ $t("Close") }}</base-button
                >
              </div>
            </modal>

            <modal
              :show.sync="openvideomodal"
              class="modal-search"
              id="setscheduler"
              :centered="false"
              :show-close="true"
            >
              <div
                v-if="selectedVideo"
                max-width="85vw"
                style="text-align: center"
              >
                <iframe
                  :src="selectedVideo"
                  height="auto"
                  width="35%"
                  title="Iframe Example"
                ></iframe>

                <br />
                <br />
                <hr />
                <base-button @click="closemodal()" class="" type="primary">{{
                  $t("Close")
                }}</base-button>
              </div>
            </modal>

            <modal
              :show.sync="openappmodal"
              class="modal-search"
              id="setscheduler"
              :centered="false"
              :show-close="true"
            >
              <div
                v-if="selectedapp"
                max-width="85vw"
                style="text-align: center"
              >
                <iframe
                  :src="selectedapp"
                  height="390px"
                  width="400px"
                  title="Iframe Example"
                ></iframe>

                <br />
                <br />
                <hr />
                <base-button
                  @click="openappmodal = false"
                  class=""
                  type="primary"
                  >{{ $t("Close") }}</base-button
                >
              </div>
            </modal>
            <div id="app">
              <table class="table">
                <thead>
                  <tr>
                    <th
                      @click="sortChange(table)"
                      v-for="(table, key) in tableData.title"
                      :key="key"
                      class="table-header"
                    >
                      <span>
                        {{ table }}
                        <SortIcon
                          :topColor="computedTopColor(table, 'top')"
                          :bottomColor="computedTopColor(table, 'bottom')"
                        />
                      </span>
                    </th>
                    <th @click="sortChange('type')" class="table-header">
                      <span>
                        {{ $t("Type") }}
                        <SortIcon
                          :topColor="computedTopColor('type', 'top')"
                          :bottomColor="computedTopColor('type', 'bottom')"
                        />
                      </span>
                    </th>
                    <th @click="sortChange('preview')" class="table-header">
                      <span>
                        {{ $t("Preview") }}
                        <SortIcon
                          :topColor="computedTopColor('preview', 'top')"
                          :bottomColor="computedTopColor('preview', 'bottom')"
                        />
                      </span>
                    </th>
                    <th class="text-center">{{ $t("Schedule") }}</th>
                    <th class="text-center">{{ $t("action") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(table, key) in tableData.data"
                    :key="table.id"
                    v-if="key != 'title'"
                  >
                    <template v-for="t in table">
                      <td v-if="t.name === 'name'" :key="t.id">
                        {{ t.contentparamvalue }}
                      </td>
                      <td
                        v-else
                        :key="t.id + 'zends'"
                        style="max-width: 325px; padding: 0 1.2rem"
                      >
                        {{ t.contentparamvalue }}
                      </td>
                    </template>
                    <td
                      v-if="table[0].mime == 'Html' || table[0].mime == 'Url'"
                    >
                      <i
                        style="color: var(--primary)"
                        class="tim-icons icon-credit-card"
                      ></i>
                    </td>
                    <td v-else-if="table[0].mime == 'image'">
                      <i
                        style="color: var(--primary)"
                        class="tim-icons icon-image-02"
                      ></i>
                    </td>
                    <td v-else-if="table[0].mime == 'video'">
                      <i
                        style="color: var(--primary)"
                        class="tim-icons icon-video-66"
                      ></i>
                    </td>
                    <td v-else-if="table[0].mime == 'application'">
                      <i
                        style="color: var(--primary)"
                        class="tim-icons icon-notes"
                      ></i>
                    </td>
                    <td v-else></td>

                    <td v-if="table[0].mime == 'video'">
                      <a
                        href="javascript:void()"
                        @click="videopreview(table[0].content_file)"
                        ><video
                          :src="table[0].content_file"
                          height="50px"
                          width="50px"
                      /></a>
                    </td>
                    <td v-else-if="table[0].mime == 'application'">
                      <a
                        href="javascript::void)"
                        @click="apppreview(table[0].content_file)"
                        ><img
                          @click="apppreview(table[0].content_file)"
                          src="/img/pdf-icon.png"
                          width="40px"
                          height="45px"
                      /></a>
                    </td>

                    <td v-else-if="table[0].mime == 'Url'">
                      <a :href="table[0].url" target="_blank">
                        {{ $t("link") }}</a
                      >
                    </td>
                    <td v-else-if="table[0].mime == 'Html'">
                      <a target="_blank" :href="table[0].url">{{
                        $t("link")
                      }}</a>
                    </td>
                    <td v-else-if="table[0].mime == 'image'">
                      <img
                        style="
                          display: inline;
                          object-fit: contain;
                          border-radius: 0;
                        "
                        :src="table[0].content_file"
                        alt="img"
                        height="50px"
                        width="50px"
                        @click="zoom(table[0].content_file)"
                      />
                    </td>
                    <td v-else-if="table[0].mime == 'application'">
                      <img
                        @click="apppreview(table[0].content_file)"
                        style="display: inline"
                        src="https://api.zends.eu/storage/app/public/app.png"
                        height="50px"
                        width="50px"
                      />
                    </td>
                    <td v-else-if="table[0].mime == ''">
                      <a
                        href="http://zends.eu/content/list-content"
                        target="_blank"
                        >link</a
                      >
                    </td>

                    <td class="text-center">
                      <i
                        style="color: black"
                        class="tim-icons icon-simple-remove"
                      ></i>
                    </td>

                    <td class="text-center">
                      <base-button
                        title="Edit"
                        class="'details btn-link"
                        type="success"
                        style="color: var(--primary)"
                      >
                        <i
                          @click="
                            add(
                              table[0].content_id,
                              table[0].contentparamvalue,
                              table[0].content_file,
                              table[0].mime
                            )
                          "
                          class="tim-icons icon-simple-add m-3"
                          title=""
                          style="color: var(--primary)"
                        ></i
                      ></base-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
                  {{ $t("of") }} {{ total }} {{ $t("entries") }}
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
          </div>
        </card>
      </div>

      <div class="col-5 content-sorting">
        <card class="stacked-form" title="CONTENT SORTING">
          <!-- <h4 class="total-duration" v-if="totalDuration !== 0">
            Total:- {{ totalDuration }} seconds
          </h4> -->
          <div
            class="col-12 justify-content-center justify-content-sm-between flex-wrap"
          >
            <draggable v-model="playlist.content">
              <transition-group name="flip-transition">
                <ul
                  :key="key"
                  class="list-group"
                  v-for="(data, key) in playlist.content"
                >
                  <li class="list-group-item">
                    <div class="row row-content">
                      <div class="col-md-2 content-section">
                        <div class="content-img" style="margin-top: -1px">
                          <img
                            style="
                              display: inline;
                              object-fit: contain;
                              border-radius: 0;
                            "
                            v-if="data.type == 'image'"
                            :src="data.img"
                            type="image"
                            height="50px"
                            width="50px"
                          />
                          <img
                            v-else-if="data.type == 'application'"
                            src="/img/pdf-icon.png"
                            width="40px"
                            height="45px"
                          />
                          <video
                            style="
                              display: inline;
                              object-fit: contain;
                              border-radius: 0;
                            "
                            v-else-if="data.type == 'video'"
                            :src="data.img"
                            class="video-container"
                            height="50px"
                            width="50px"
                          />
                          <img
                            style="
                              display: inline;
                              object-fit: contain;
                              border-radius: 0;
                            "
                            v-else-if="data.type == 'application'"
                            src="https://api.zends.eu/storage/app/public/app.png"
                            height="50px"
                            width="50px"
                          />
                          <a v-else href="javascript::void()">link</a>
                        </div>
                      </div>
                      <span
                        class="text-title font-weight-bold text-responstive"
                        style="align-self: center"
                        :class="data.type == 'video' && 'vedio-text'"
                        >{{ data.name }} - {{ data.type }}</span
                      >
                    </div>
                  </li>
                  <span v-if="data.type == 'video'">
                    <a href="javascript:void(0)" @click="remove(key)">{{
                      $t("Remove")
                    }}</a>
                    <!-- |
                    <a
                      href="javascript:void(0)"
                      id="setscheduler"
                      @click="modal(data.content_id)"
                      >{{ $t("Set Scheduler") }}</a
                    > -->
                  </span>
                  <span v-else>
                    <a href="javascript:void(0)" @click="remove(key)">{{
                      $t("Remove")
                    }}</a>
                    <!-- |
                    <a
                      href="javascript:void(0)"
                      id="setscheduler"
                      @click="modal(data.content_id)"
                      >{{ $t("Set Scheduler") }}</a
                    >
                    |
                    <a
                      href="javascript:void(0)"
                      id="setduration"
                      @click="modalduration(data.content_id, data.name)"
                      >{{ $t("Duration") }}</a
                    > -->
                  </span>
                </ul>
              </transition-group>
            </draggable>
          </div>
        </card>
      </div>
    </div>
    <modal
      :show.sync="openmodal"
      class="modal-search"
      id="setscheduler"
      :centered="false"
      :show-close="true"
      :modalContentClasses="widthplay100"
    >
      <div class="row table-full-width">
        <h3>{{ $t("Scheduling Settings") }}</h3>

        <div class="col-sm-12">
          <el-table :data="secondTableData">
            <el-table-column width="400" label="Typology" align="center">
              <div class="" style="text-align: justify" slot-scope="{ row }">
                <div>
                  <!-- <base-radio  v-model="radios.display" name="1" inline>Display</base-radio> -->
                  <input
                    value="1"
                    type="radio"
                    id="sf1"
                    name="scheduler_form"
                    v-model="schdular.Typologychecked"
                    @change="checkboxhide()"
                  />
                  <label for="sf1">{{ $t("Always run") }}</label>
                </div>
                <div>
                  <input
                    value="2"
                    id="sf2"
                    type="radio"
                    name="scheduler_form"
                    v-model="schdular.Typologychecked"
                    @change="checkboxselect()"
                  />
                  <label for="sf2">{{ $t("Run if checked") }}</label
                  ><br />
                </div>
                <div>
                  <input
                    value="3"
                    id="sf3"
                    type="radio"
                    name="scheduler_form"
                    v-model="schdular.Typologychecked"
                    @change="checkboxselect()"
                  />
                  <label for="sf3">{{ $t("Do not execute if selected") }}</label
                  ><br />
                </div>
                <div class="row">
                  <div class="col-md-7">
                    <el-date-picker
                      type="date"
                      :placeholder="$t('Date Picker')"
                      v-model="schdular.datePickerStart"
                      :disabled="row.ckdisabled"
                    >
                    </el-date-picker>
                  </div>
                  <div class="col-md-5">
                    <el-time-select
                      :disabled="row.ckdisabled"
                      v-model="schdular.timePickerStart"
                      :picker-options="{
                        start: '00:00',
                        step: '00:15',
                        end: '23:59',
                      }"
                      :placeholder="$t('Select time')"
                    >
                    </el-time-select>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-7">
                    <el-date-picker
                      type="date"
                      :disabled="row.ckdisabled"
                      :placeholder="$t('Date Picker')"
                      v-model="schdular.datePickerEnd"
                    >
                    </el-date-picker>
                  </div>
                  <div class="col-md-5">
                    <el-time-select
                      :disabled="row.ckdisabled"
                      v-model="schdular.timePickerEnd"
                      :picker-options="{
                        start: '00:00',
                        step: '00:15',
                        end: '23:59',
                      }"
                      :placeholder="$t('Select time')"
                    >
                    </el-time-select>
                  </div>
                </div>
              </div>
            </el-table-column>

            <el-table-column width="100" label="Lun" align="center">
              <div slot-scope="{ row }" class="checkbox-cell">
                <base-checkbox
                  v-model="schdular.Lunchecked"
                  :disabled="row.ckdisabled"
                ></base-checkbox>
              </div>
            </el-table-column>

            <el-table-column width="100" label="Mar" align="center">
              <div slot-scope="{ row }" class="checkbox-cell">
                <base-checkbox
                  v-model="schdular.Marchecked"
                  :disabled="row.ckdisabled"
                ></base-checkbox>
              </div>
            </el-table-column>

            <el-table-column width="100" label="Mer" align="center">
              <div slot-scope="{ row }" class="checkbox-cell">
                <base-checkbox
                  v-model="schdular.Merchecked"
                  :disabled="row.ckdisabled"
                ></base-checkbox>
              </div>
            </el-table-column>

            <el-table-column width="100" label="Gio" align="center">
              <div slot-scope="{ row }" class="checkbox-cell">
                <base-checkbox
                  v-model="schdular.Giochecked"
                  :disabled="row.ckdisabled"
                ></base-checkbox>
              </div>
            </el-table-column>

            <el-table-column width="100" label="Ven" align="center">
              <div slot-scope="{ row }" class="checkbox-cell">
                <base-checkbox
                  v-model="schdular.Venchecked"
                  :disabled="row.ckdisabled"
                ></base-checkbox>
              </div>
            </el-table-column>
            <el-table-column width="100" label="Sab" align="center">
              <div slot-scope="{ row }" class="checkbox-cell">
                <base-checkbox
                  v-model="schdular.Sabchecked"
                  :disabled="row.ckdisabled"
                ></base-checkbox>
              </div>
            </el-table-column>
            <el-table-column width="100" label="Dom" align="center">
              <div slot-scope="{ row }" class="checkbox-cell">
                <base-checkbox
                  v-model="schdular.Domchecked"
                  :disabled="row.ckdisabled"
                ></base-checkbox>
              </div>
            </el-table-column>
          </el-table>
        </div>

        <div class="text-right col-md-12">
          <base-button @click="closem()" class="" type="primary">{{
            $t("Close")
          }}</base-button>
          <base-button
            class=""
            native-type="submit"
            type="primary"
            @click="addschdular(), (openmodal = false)"
          >
            {{ $t("Confirmation") }}</base-button
          >
        </div>
      </div>
    </modal>

    <modal
      :show.sync="openmodalduration"
      class="modal-search"
      id="setduration"
      :centered="false"
      :show-close="true"
    >
      <div>
        <h3>{{ $t("ENTER THE DURATION") }}</h3>
        <hr />
        <hr />
        <div>
          <h4 class="ml-3">{{ name }}</h4>
          <vue-timepicker
            class="timepicker-without-scrollbar"
            format="HH:mm:ss"
            v-model="duration.timePicker"
          ></vue-timepicker>
        </div>
        <br />
        <hr />
        <div class="text-right col-md-12 duration-btn">
          <base-button @click="closeModalDuearion()" class="" type="primary">{{
            $t("Close")
          }}</base-button>
          <base-button
            class=""
            native-type="submit"
            type="primary"
            @click="
              addduration(duration.contid, duration.timePicker),
                openmodalduration
            "
          >
            {{ $t("Confirmation") }}</base-button
          >
        </div>
      </div>
    </modal>
  </form>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  Select,
  TimeSelect,
  Option,
  DatePicker,
} from "element-ui";
import { BasePagination } from "src/components";
import draggable from "vuedraggable";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "src/components/ValidationError.vue";
import { Modal } from "@/components";
import { required } from "vee-validate/dist/rules";
import VueTimepicker from "vue2-timepicker/dist/VueTimepicker.common.js";
import "vue2-timepicker/dist/VueTimepicker.css";
import SortIcon from "@/assets/customImg/SortIcon.vue";
import { getTopColor, getBottomColor } from "../../util/utils";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  mixins: [formMixin],

  components: {
    SortIcon,
    [DatePicker.name]: DatePicker,
    Modal,
    ValidationError,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    draggable,
    [TimeSelect.name]: TimeSelect,
    required,
    VueTimepicker,
  },
  computed: {
    // totalDuration() {
    //   return this.playlist.content
    //     .map((data) => data.duration || 0)
    //     .reduce((total, duration) => total + duration, 0);
    // },
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.tableData.total < highBound) {
        highBound = this.total;
      }

      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.total;
    },
  },

  data() {
    return {
      fuseSearch: null,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
      },

      sortparam: {
        field: "name",
        sort: "asc",
      },

      searchedData: [],
      search: "",
      query: "",
      playlist: {
        pname: "",
        content: [],
      },

      name: "",
      openmodal: false,
      openmodalduration: false,
      isValid: true,
      tableData: "",
      secondTableData: [
        {
          Typologychecked: "",
          Lunchecked: false,
          Marchecked: false,
          Merchecked: false,
          Giochecked: false,
          Venchecked: false,
          Sabchecked: false,
          Domchecked: false,
          ckdisabled: true,
        },
      ],
      widthplay100: "widthplay-100",
      schdular: {
        Typologychecked: 1,
        datePickerEnd: "",
        datePickerStart: "",
        timePickerStart: "",
        timePickerEnd: "",
        Lunchecked: 0,
        Marchecked: 0,
        Merchecked: 0,
        Giochecked: 0,
        Venchecked: 0,
        Sabchecked: 0,
        Domchecked: 0,
        contid: "",
      },
      duration: {
        timePicker: "00:00:10",
        contid: "",
      },
      searchModalVisible: true,
      selectedapp: "",
      selectedvideo: "",
      selectedimage: "",
      openappmodal: false,
      openvideomodal: false,
      openimagemodal: false,
    };
  },
  methods: {
    computedTopColor(table, arrowIcon) {
      if (arrowIcon === "top") {
        return getTopColor(table, this.sortparam);
      } else {
        return getBottomColor(table, this.sortparam);
      }
    },
    getApiBaseUrl() {
      return url;
    },
    modal(id) {
      this.openmodal = true;
      this.schdular.contid = id;
    },
    modalduration(id, name) {
      this.openmodalduration = true;
      this.duration.contid = id;
      this.name = name;

      axios.get(`${url}/get-playlist-duration/${id}`).then((response) => {
        this.duration.timePicker = response.data;
      });
    },
    closeModalDuearion() {
      this.openmodalduration = false;
      this.duration.timePicker = "00:00:10";
    },
    getListDebounced: _.debounce(function () {
      this.getcontent();
    }, 300),

    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    closemodal() {
      this.openvideomodal = false;
      this.selectedVideo = "";
    },

    setCurrentPage(direction) {
      if (direction === -1 && this.pagination.currentPage > 1) {
        this.pagination.currentPage -= 1;
      } else if (
        direction === 1 &&
        this.pagination.currentPage <
          this.tableData.data.length / this.pagination.perPage
      ) {
        this.pagination.currentPage += 1;
      }
    },

    sortChange(data) {
      this.sortparam.field = data;
      this.sortparam.sort = this.sortparam.sort == "asc" ? "desc" : "asc";
      this.getcontent();
    },

    remove(index) {
      this.playlist.content.splice(index, 1);
    },

    async add(content_id, title, url, type) {
      const urls = process.env.VUE_APP_API_BASE_URL;
      // const id = this.$route.params.id;
      let duration;
      if (type === "video") {
        try {
          // const durationResponse = await axios.get(
          //   `${urls}/get-playlist-duration/${content_id}`
          // );
          duration =
            // durationResponse.data !== ""
            //   ? this.timeStringToSeconds(durationResponse.data)
            //   :
            await this.getVedioLength(url);
        } catch (error) {
          console.error("Error fetching video duration: ", error);
        }
      } else {
        // const durationResponse = await axios.get(
        //   `${urls}/get-playlist-duration/${content_id}`
        // );
        duration = 10;
        // durationResponse.data !== ""
        //   ? this.timeStringToSeconds(durationResponse.data)
        //   : 10;
      }
      this.playlist.content.push({
        content_id: content_id,
        img: url,
        name: title,
        type: type,
        duration: duration,
      });
    },

    async getVedioLength(url) {
      return new Promise((resolve, reject) => {
        const videoUrl = url;
        const videoElement = document.createElement("video");
        videoElement.src = videoUrl;

        videoElement.addEventListener("loadedmetadata", () => {
          const videoDuration = videoElement.duration;
          const length = Number(videoDuration.toFixed(2));
          resolve(length);
        });

        videoElement.addEventListener("error", (error) => {
          reject(error);
        });
      });
    },

    timeStringToSeconds(timeString) {
      const timeParts = timeString.split(":");

      if (timeParts.length === 3) {
        const hours = parseInt(timeParts[0], 10);
        const minutes = parseInt(timeParts[1], 10);
        const seconds = parseInt(timeParts[2], 10);

        return hours * 3600 + minutes * 60 + seconds;
      } else if (timeParts.length === 2) {
        const minutes = parseInt(timeParts[0], 10);
        const seconds = parseInt(timeParts[1], 10);

        return minutes * 60 + seconds;
      } else {
        return parseInt(timeParts[0], 10);
      }
    },

    checkboxselect() {
      this.secondTableData[0].ckdisabled = false;
    },
    videopreview(videourl) {
      this.selectedVideo = videourl;
      this.openvideomodal = true;
    },
    apppreview(appurl) {
      this.selectedapp = appurl;
      this.openappmodal = true;
    },
    zoom(url) {
      this.selectedImage = url;
      this.openimagemodal = true;
    },
    closem() {
      this.openmodal = false;
      this.schdular = [];
      this.schdular.Typologychecked = 2;
    },
    checkboxhide() {
      this.secondTableData[0].ckdisabled = true;
      this.secondTableData[0].Lunchecked = false;
      this.secondTableData[0].Marchecked = false;
      this.secondTableData[0].Merchecked = false;
      this.secondTableData[0].Giochecked = false;
      this.secondTableData[0].Venchecked = false;
      this.secondTableData[0].Sabchecked = false;
      this.secondTableData[0].Domchecked = false;
      this.schdular.Lunchecked = false;
      this.schdular.Marchecked = false;
      this.schdular.Merchecked = false;
      this.schdular.Giochecked = false;
      this.schdular.Venchecked = false;
      this.schdular.Sabchecked = false;
      this.schdular.Domchecked = false;
      this.schdular.datePickerEnd = "";
      this.schdular.datePickerStart = "";
      this.schdular.timePickerStart = "";
      this.schdular.timePickerEnd = "";
    },
    async getcontent() {
      let params = {
        sort: {
          ...this.sortparam,
        },
        filter: {
          ...(this.query ? { searchValue: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };
      await this.$store.dispatch("contentfile/contentgetdata", params);
      this.tableData = await this.$store.getters["contentfile/content_list"];
    },
    save() {
      axios.post(`${url}/add-playlist`, this.playlist).then((response) => {
        this.tableData = response.data;
        this.goBack();
      });
    },

    goBack() {
      this.$router.push({ name: "Play List" });
    },

    async addschdular() {
      // Convert boolean properties to numbers in the schdular object
      this.schdular.Lunchecked = this.schdular.Lunchecked ? 1 : 0;
      this.schdular.Marchecked = this.schdular.Marchecked ? 1 : 0;
      this.schdular.Merchecked = this.schdular.Merchecked ? 1 : 0;
      this.schdular.Giochecked = this.schdular.Giochecked ? 1 : 0;
      this.schdular.Venchecked = this.schdular.Venchecked ? 1 : 0;
      this.schdular.Sabchecked = this.schdular.Sabchecked ? 1 : 0;
      this.schdular.Domchecked = this.schdular.Domchecked ? 1 : 0;
      try {
        axios
          .post(`${url}/add-playlist-schedular`, this.schdular)
          .then((response) => {});
        this.$notify({
          type: "success",
          message: this.$t("Schedular added successfully."),
          icon: "tim-icons icon-bell-55",
        });
        this.openmodel = false;
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
        this.setApiValidation(e.response.data.errors);
      }
    },

    async addduration(id, time) {
      const playListId = this.$route.params.id;
      this.duration.playlist_id = Number(playListId);
      try {
        axios
          .post(`${url}/add-playlist-duration`, this.duration)
          .then((response) => {});
        this.$notify({
          type: "success",
          message: this.$t("Duration added successfully."),
          icon: "tim-icons icon-bell-55",
        });
        const itemToUpdate = this.playlist.content.find(
          (item) => item.content_id === id
        );
        itemToUpdate.duration = this.timeStringToSeconds(time);
        this.openmodalduration = false;
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
        this.setApiValidation(e.response.data.errors);
      }
    },
  },

  mounted() {
    this.getcontent();
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getcontent",
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

.flip-transition-move {
  transition: all 0.7s;
}

.content-img {
  width: 38%;
  margin-top: -12px;
  display: flex;
}

.list-group-item {
  max-height: 50px;
}

.list-group {
  cursor: move;
}

.text-title {
  margin-left: -62px;
  margin-top: -11px;
}

.card {
  border: 0.0625rem solid #e3e3e3;
}

.card-header {
  background-color: #e3e3e3 !important;
}

table {
  width: 100% !important;
}

.modal-search .modal-dialog {
  display: flex;
  justify-content: center;
  margin: 23px auto;
  max-width: 65% !important;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 2px 0px var(--primary) inset;
  margin-right: 3px;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 35%;
  height: 31%;
  margin: 31% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: var(--primary);
}

.pickup > .mb-0 > input {
  border-radius: 25px;
  border: 1px solid #dddddd;
  background-color: #f9f9f9;
}
.modal-content {
  width: 700px;
  height: auto;
}

.el-date-editor {
  border: 1px solid rgba(34, 42, 66, 0.2) !important;
}

.vue__time-picker input.display-time {
  border: 1px solid #d2d2d2;
  width: 100%;
  height: 40px;
  padding: 0.3em 0.5em;
  font-size: 17px;
}

.clear-btn {
  position: absolute;
  top: 26px;
}

.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: #2e92ea;
  color: #fff;
}

.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #2e92ea;
  color: #fff;
}

.vue__time-picker-dropdown .hint,
.vue__time-picker .dropdown .hint,
.vue__time-picker .dropdown ul li {
  font-size: 17px;
}
.timepicker-without-scrollbar ::-webkit-scrollbar {
  /* Hide the scrollbar for the time picker */
  display: none;
}

.duration-btn {
  padding-right: 0px !important;
}
</style>
<style>
.widthplay-100 {
  height: auto;
  width: 100%;
}
</style>

<style scoped>
.list-group-item {
  max-height: 100%;
  padding: 0;
}

.content-section {
  padding: 12px 7px;
  vertical-align: middle;
  display: flex;
  justify-content: center;
}

.content-img {
  display: flex;
  justify-content: center;
  height: 50px;
  width: 50px;
}
.text-title {
  margin-left: -5px;
}

.content-sorting {
  position: relative;
}

.total-duration {
  text-align: end;
  position: absolute;
  margin-top: -3.3rem;
  width: 90%;
}

.white-content .table > tbody > tr > td {
  padding: 0;
}
.table-header {
  cursor: pointer;
}
.table-header span {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .row-content {
    display: flex;
    justify-content: center;
    position: relative;
  }
  .text-responstive {
    align-self: center;
    width: 90%;
    text-align: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .row-content {
    margin-left: 0.5rem;
  }
}
</style>
