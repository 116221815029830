<template>
  <div class="content">
    <!-- <h2> DASHBOARD </h2> -->
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6 pl-0 card-container">
        <card
          type="testimonial"
          header-classes="card-header-avatar"
          style="
            position: relative;
            border-radius: 0.75rem;
            border: 0.0625rem solid #e3e3e3 !important;
          "
        >
          <template>
            <div class="pull-left" v-if="role_id == 1">
              <a href="javascript::void()">
                <h2 class="icon-box bg-gradient-info-blue">
                  <i class="fas fa-desktop icon-color"></i>
                </h2>
              </a>
            </div>
            <div
              class="pull-left"
              v-else-if="role_id == 2"
              style="display: flex"
            >
              <a href="javascript::void()">
                <h2 class="icon-box bg-gradient-info-blue">
                  <i class="fas fa-desktop icon-color"></i>
                </h2>
              </a>
              <!-- <h4 class="card-title">+55</h4> -->
            </div>
            <div class="pull-left" v-else>
              <a href="javascript::void()">
                <h2 class="icon-box bg-gradient-info-blue">
                  <i class="fas fa-desktop icon-color"></i>
                </h2>
              </a>
            </div>
            <div class="pull-right" v-if="role_id == 1">
              <router-link to="/display/list-display">
                <h4 class="card-title">{{ $t("Manage Display") }}</h4>
                <h2>{{ displayadmin || 0 }}</h2>
              </router-link>
            </div>
            <div class="pull-right" v-else-if="role_id == 2">
              <router-link to="/display/list-display-manager">
                <h4 class="card-title">{{ $t("Manage Display") }}</h4>
                <h2>{{ displaymanager || 0 }}</h2>
              </router-link>
            </div>
            <div class="pull-right" v-else>
              <a href="javascript::void()">
                <h1>
                  <h4 class="card-title">{{ $t("Manage Display") }}</h4>
                  <!-- <h2>{{ group }}</h2> -->
                  <h2>0</h2>
                </h1>
              </a>
            </div>
          </template>
          <div class="card-footer pl-1 footer-section">
            <p class="mb-1 d-flex p-1">
              <span class="d-flex"
                ><span class="status green mr-1"></span>{{ $t("Online") }}:
                {{ onlineDisplayCount }}</span
              >
            </p>
            <p class="mb-1 p-1">
              <span class="d-flex">
                <span class="status red mr-1"></span>{{ $t("Offline") }}:
                {{ offlineDisplayCount }}</span
              >
            </p>
          </div>
        </card>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 pl-0 card-container">
        <card
          type="testimonial"
          header-classes="card-header-avatar"
          style="
            border-radius: 0.75rem;
            border: 0.0625rem solid #e3e3e3 !important;
          "
        >
          <template>
            <div class="pull-left" v-if="role_id == 1">
              <a href="javascript::void()">
                <h2 class="icon-box bg-gradient-dark-black">
                  <i class="fas fa-cogs icon-color"></i>
                </h2>
                <!-- <h4 class="card-title">+55</h4> -->
              </a>
            </div>
            <div
              class="pull-left"
              v-else-if="role_id == 2"
              style="display: flex"
            >
              <a href="javascript::void()">
                <h2 class="icon-box bg-gradient-dark-black">
                  <i class="fas fa-cogs icon-color"></i>
                </h2>
                <!-- <h4 class="card-title">+55</h4> -->
              </a>
            </div>
            <div class="pull-left" v-else>
              <a href="javascript::void()">
                <h2 class="icon-box bg-gradient-dark-black">
                  <i class="fa fa-sitemap icon-color"></i>
                </h2>
                <!-- <h4 class="card-title">+55</h4> -->
              </a>
            </div>
            <div class="pull-right" v-if="role_id == 1">
              <router-link to="/license/list-license">
                <h4 class="card-title">{{ $t("Manage Licenses") }}</h4>
                <h2>{{ license }}</h2>
              </router-link>
            </div>
            <div class="pull-right" v-else-if="role_id == 2">
              <a href="/group/list-group-manager">
                <h4 class="card-title">{{ $t("Manage Licenses") }}</h4>
                <h2>{{ managerLicenseCount }}</h2>
              </a>
            </div>
            <div class="pull-right" v-else>
              <a href="javascript::void()">
                <h1>
                  <h4 class="card-title">{{ $t("Manage Licenses") }}</h4>
                  <h2>0</h2>
                </h1>
              </a>
            </div>
          </template>
          <template>
            <div class="card-footer pl-1 footer-section">
              <p class="mb-1 p-1" v-if="subLicenseCount !== 0">
                <span class="d-flex">
                  <span class="status info-warning mr-1"></span
                  >{{ $t("Expiring") }}: {{ subLicenseCount }}</span
                >
              </p>
              <p class="mb-1 d-flex p-1" v-if="expierLicenseCount !== 0">
                <span class="d-flex"
                  ><span class="status danger-warning mr-1"></span
                  >{{ $t("Expired") }}: {{ expierLicenseCount }}</span
                >
              </p>
            </div>
          </template>
        </card>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 pl-0 card-container">
        <card
          type="testimonial"
          header-classes="card-header-avatar"
          style="
            border-radius: 0.75rem;
            border: 0.0625rem solid #e3e3e3 !important;
          "
        >
          <template>
            <div class="pull-left" v-if="role_id == 1">
              <a href="javascript::void()">
                <h2 class="icon-box bg-gradient-success-green">
                  <i class="fa fa-sitemap icon-color"></i>
                </h2>
                <!-- <h4 class="card-title">+55</h4> -->
              </a>
            </div>
            <div class="pull-left" v-else-if="role_id == 2">
              <a href="javascript::void()">
                <h2 class="icon-box bg-gradient-success-green">
                  <i class="fa fa-sitemap icon-color"></i>
                </h2>
                <!-- <h4 class="card-title">+55</h4> -->
              </a>
            </div>
            <div class="pull-left" v-else>
              <a href="javascript::void()">
                <h2 class="icon-box bg-gradient-success-green">
                  <i class="fa fa-sitemap icon-color"></i>
                </h2>
                <!-- <h4 class="card-title">+55</h4> -->
              </a>
            </div>
            <div class="pull-right" v-if="role_id == 1">
              <router-link to="/group/list-group">
                <h4 class="card-title">{{ $t("Manage Groups") }}</h4>
                <h2>{{ groupadmin }}</h2>
              </router-link>
            </div>
            <div class="pull-right" v-else-if="role_id == 2">
              <router-link to="/group/list-group-manager">
                <h4 class="card-title">{{ $t("Manage Groups") }}</h4>
                <h2>{{ group }}</h2>
              </router-link>
            </div>
            <div class="pull-right" v-else>
              <a href="javascript::void()">
                <h4 class="card-title">{{ $t("Manage Groups") }}</h4>
                <h2>0</h2>
              </a>
            </div>
          </template>
          <template>
            <div class="card-footer p-3 footer-section"></div>
          </template>
        </card>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 pl-0 card-container">
        <card
          type="testimonial"
          header-classes="card-header-avatar"
          style="
            border-radius: 0.75rem;
            border: 0.0625rem solid #e3e3e3 !important;
          "
        >
          <template>
            <div class="pull-left" v-if="role_id == 1">
              <a href="javascript::void()">
                <h2 class="icon-box bg-gradient-chart-pink">
                  <i class="fas fa-user icon-color"></i>
                </h2>
              </a>
            </div>
            <div class="pull-left" v-else-if="role_id == 2">
              <a href="javascript::void()">
                <h2 class="icon-box bg-gradient-chart-pink">
                  <i class="fas fa-user icon-color"></i>
                </h2>
                <!-- <h4 class="card-title">+55</h4> -->
              </a>
            </div>
            <div class="pull-left" v-else>
              <a href="javascript::void()">
                <h2 class="icon-box bg-gradient-chart-pink">
                  <i class="fas fa-user icon-color"></i>
                </h2>
                <!-- <h4 class="card-title">+55</h4> -->
              </a>
            </div>
            <div class="pull-right" v-if="role_id == 1">
              <router-link to="/user-management/list-users">
                <h4 class="card-title">{{ $t("Manage Users") }}</h4>
                <h2>{{ admin_user_list }}</h2>
              </router-link>
            </div>
            <div class="pull-right" v-else-if="role_id == 2">
              <router-link to="/user-management/list-users-manager">
                <h4 class="card-title">{{ $t("Manage Users") }}</h4>
                <h2>{{ manager_user_list }}</h2>
              </router-link>
            </div>
            <div class="pull-right" v-else>
              <a href="javascript::void()">
                <h4 class="card-title">{{ $t("Manage Users") }}</h4>
                <h2>0</h2>
              </a>
            </div>
          </template>
          <template>
            <div class="card-footer p-3 footer-section"></div>
          </template>
        </card>
      </div>
      <div
        v-if="role_id == 1 || role_id == 2"
        class="col-lg-12"
        style="margin-top: -2.5rem"
      >
        <!-- <task-list /> -->
        <sales-table />

        <!-- <country-map-card></country-map-card> -->
        <chart-table />
      </div>
      <div v-else class="col-lg-12">
        <!-- <task-list /> -->
        <!-- <sales-table /> -->
        <!-- <country-map-card></country-map-card> -->
        <!-- <chart-table /> -->
      </div>
    </div>
  </div>
</template>
<script>
import CountryMapCard from "./CountryMapCard";
import ChartTable from "./ChartTable";
import SalesTable from "./SalesTable";

import axios from "axios";
import TaskList from "./TaskList.vue";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  components: {
    CountryMapCard,
    TaskList,
    ChartTable,
    SalesTable,
  },
  data() {
    return {
      group: [],
      display: [],
      adminDisplayList: [],
      license: 0,
      admin_user_list: 0,
      manager_user_list: 0,
      role_id: 0,
      sortparam: {
        field: "name",
        sort: "asc",
      },
      pagination: {
        perPage: 100,
        currentPage: 1,
      },
      onlineDisplayCount: 0,
      offlineDisplayCount: 0,
      subLicenseCount: 0,
      expierLicenseCount: 0,
      managerLicenseCount: 0,
    };
  },
  methods: {
    async created() {
      let watcherInitialized = false;

      this.$store.watch(
        () => this.$store.getters["profile/me"],
        async (me) => {
          if (!watcherInitialized) {
            watcherInitialized = true;
            this.role_id = me.roles[0].id;
            this.getNullData();
            if (Number(this.role_id) === 1) {
              this.getdisplayAdminList();
            } else if (Number(this.role_id) === 2) {
              const id = localStorage.getItem("user_id");
              this.getManagerLicenseList(id);
              this.getdisplayAdminList();
            }
          }
        }
      );
      try {
        await this.$store.dispatch("profile/me");
      } catch (error) {
        if (error.response.status === 401) {
          this.$router.push("/login");
          localStorage.removeItem("vue-authenticate.vueauth_access_token");
          this.$store.commit("isAuthenticated", { isAuthenticated: false });
        }
      }
    },
    async getgroupadmin() {
      this.groupadmin = 0;

      axios.get(`${url}/groupgetdataadminmap`).then((response) => {
        this.groupadmin = response.data.length;
      });
    },
    async getgroupmanager() {
      this.group = 0;

      let params = {
        sort: {
          ...this.sortparam,
        },
        filter: {
          ...(this.query ? { searchValue: this.query } : {}),
        },
        page: {
          number: "",
          size: "",
        },
      };
      await this.$store.dispatch("groups/associate", params);
      this.group = Object.keys(
        await this.$store.getters["groups/group_list"].data
      ).length;
    },
    async getdisplayAdminList() {
      let params = {
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.query ? { searchValue: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };
      if (Number(this.role_id) === 1) {
        this.getlicenses();
        await this.$store.dispatch("displays/add", params);
      } else if (Number(this.role_id) === 2) {
        await this.$store.dispatch("displays/get", params);
      }
      this.adminDisplayList = await this.$store.getters[
        "displays/display_list"
      ];
      this.displaymanager = this.adminDisplayList.total;

      const numericKeyProperties = Object.keys(this.adminDisplayList)
        .filter((key) => !isNaN(key))
        .reduce((obj, key) => {
          obj[key] = this.adminDisplayList[key];
          return obj;
        }, {});

      // Extract the first object from each array
      const firstObjects = Object.values(numericKeyProperties)
        .map((array) =>
          Array.isArray(array) && array.length > 0 ? array[0] : null
        )
        .filter((object) => object !== null);

      const datas = firstObjects;

      this.onlineDisplayCount = 0;
      this.offlineDisplayCount = 0;

      datas &&
        datas.map((a) => {
          // Compare the "status" property of objects a and b
          if (a.status === "online") {
            this.onlineDisplayCount++;
          } else if (a.status === "offline") {
            this.offlineDisplayCount++;
          } else {
            return 0;
          }
        });
    },
    async getdisplayadmin() {
      this.displayadmin = 0;

      axios.get(`${url}/displaygetdataadminmap`).then((response) => {
        this.displayadmin = response.data.length;
      });
    },
    async getlicenses() {
      let params = {
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.query ? { searchValue: this.query } : {}),
        },
        page: {
          number: 1,
          size: 100,
        },
      };
      await this.$store.dispatch("licenses/list", params);
      const tableData = await this.$store.getters["licenses/license_list"];
      this.license = tableData.total;
      const datas = tableData && tableData.data.map((dat) => dat[0]);

      Promise.all(
        datas.map(async (newdata) => {
          const data = await this.getlicenceexpiryday(newdata.license_code);
          newdata.expirydays = data;
        })
      ).then(() => {
        // Initialize counters
        let subLicenseCount = 0;
        let expierLicenseCount = 0;

        datas &&
          datas.map((a) => {
            if (a.expirydays < 30 && a.expirydays > 0) {
              subLicenseCount++;
            } else if (a.expirydays <= 0) {
              expierLicenseCount++;
            }
          });
        this.expierLicenseCount = expierLicenseCount;
        this.subLicenseCount = subLicenseCount;
      });
    },
    async getManagerLicenseList(id) {
      await axios.post(`${url}/get-user-license/${id}`).then((response) => {
        this.managerLicenseCount = response.data.length;
        const datas = response.data;
        Promise.all(
          datas.map(async (newdata) => {
            const data = await this.getlicenceexpiryday(newdata.license_code);
            newdata.expirydays = data;
          })
        ).then(() => {
          let subLicenseCount = 0;
          let expierLicenseCount = 0;

          datas &&
            datas.map((a) => {
              if (a.expirydays < 30 && a.expirydays > 0) {
                subLicenseCount++;
              } else if (a.expirydays < 1) {
                expierLicenseCount++;
              }
            });
          this.expierLicenseCount = expierLicenseCount;
          this.subLicenseCount = subLicenseCount;
        });
      });
    },
    getlicenceexpiryday(id) {
      const data = axios
        .get(`${url}/getlicenceexpiryday/${id}`)
        .then((response) => {
          return response.data;
        });
      return data;
    },
    async adminUsergetList() {
      let params = {
        include: "roles",
        ...(this.sort
          ? {
              sort: this.sort,
            }
          : {}),
        filter: {
          ...(this.query
            ? {
                name: this.query,
              }
            : {}),
          ...(this.query
            ? {
                email: this.query,
              }
            : {}),
          ...(this.query
            ? {
                roles: this.query,
              }
            : {}),
        },
        page: {
          number: "",
          size: "",
        },
      };

      await this.$store.dispatch("users/list", params);
      this.admin_user_list = this.$store.getters["users/listTotal"];
    },
    async getManagerUserList() {
      await axios.get(`${url}/getusermanagerlist`).then((response) => {
        this.manager_user_list = response.data.length;
      });
    },
    getNullData() {
      this.offlineDisplayCount = 0;
      this.onlineDisplayCount = 0;
      this.mainLicenseCount = 0;
      this.expierLicenseCount = 0;
      this.subLicenseCount = 0;
    },
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    bigLineChartCategories() {
      return [
        { name: "Accounts", icon: "tim-icons icon-single-02" },
        { name: "Purchases", icon: "tim-icons icon-gift-2" },
        { name: "Sessions", icon: "tim-icons icon-tap-02" },
      ];
    },
  },
  mounted() {
    this.getdisplayadmin(),
      this.getgroupadmin(),
      this.getgroupmanager(),
      this.adminUsergetList();
    this.getManagerUserList();
    this.created();
    this.getdisplayAdminList();
  },
  watch: {
    role_id(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.onlineDisplayCount = 0;
        this.offlineDisplayCount = 0;
      }
    },
  },
};
</script>
<style scoped>
.card-testimonial {
  margin-top: 20px;
  text-align: center;
}

.footer-section {
  position: absolute;
  text-align: left;
  bottom: -2.6rem;
  padding: 15px !important;
}
.icon-box {
  width: 64px;
  height: 64px;
  margin-bottom: 0;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: -2.8rem !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(64, 64, 64, 0.4) !important;
}
.icon-color {
  color: #fff;
}

.bg-gradient-chart-pink {
  background-image: linear-gradient(195deg, #ec407a, #d81b60) !important;
}

.bg-gradient-success-green {
  background-image: linear-gradient(195deg, #66bb6a, #43a047) !important;
}

.bg-gradient-dark-black {
  background-image: linear-gradient(195deg, #42424a, #191919) !important;
}

.bg-gradient-info-blue {
  background-image: linear-gradient(195deg, #49a3f1, #1a73e8) !important;
}

.status {
  cursor: default;
  display: list-item;
  list-style: none;
  margin: auto;
  position: relative;
  /* border: 1px solid black; */
  border-radius: 30px;
  width: 20px;
  height: 20px;
  color: #f7f7f7;
  max-width: 200px;
}

.red {
  background-color: red;
}

.danger-warning {
  background-color: red;
}

.info-warning {
  background-color: orange;
}

.green {
  background-color: green;
}
@media (min-width: 992px) and (max-width: 1360px) {
  .card-container {
    padding-left: 5px;
    padding-right: 5px;
  }
  h4 {
    font-size: 16px;
  }
}
</style>
