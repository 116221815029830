<template>
  <div class="mt-3">
    <h4>{{ $t("DISPLAY DETAIL - SOFTWARE VERSION: 3.0.0.0") }}</h4>
    <card>
      <card>
        <div class="row">
          <div class="col-6">
            <div class="card" style="min-height: 94%">
              <ul class="list-group">
                <li
                  class="list-group-item m-2"
                  style="background-color: var(--primary); color: #ffffff"
                >
                  {{ $t("PARAMETERS") }}
                </li>
              </ul>

              <div>
                <div id="app">
                  <table class="table">
                    <thead>
                      <tr>
                        <th v-for="(table, key) in tableData.title" :key="key">
                          {{ table }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(table, key) in tableData"
                        :key="table.id"
                        v-if="key !== 'title'"
                      >
                        <td v-for="t in table" :key="t.id" v-if="key != ''">
                          {{ t.displayparamvalue }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <br />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card">
              <ul class="list-group">
                <li
                  class="list-group-item m-2"
                  style="background-color: var(--primary); color: #ffffff"
                >
                  {{ $t("LAST SCREENSHOT") }}<i class="handle"></i>
                </li>
              </ul>

              <div class="card-body text-center py-5">
                <code><img src="" alt="Image of Last Screenshot" /></code>
              </div>
            </div>
          </div>
        </div>
      </card>

      <card>
        <collapse :multiple-active="false" :active-index="0">
          <collapse-item title="SITUATION REPORT">
            <div class="row">
              <div class="col-12">
                <div class="card" style="border: none">
                  <card>
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>{{ $t("Assigned group") }} :</td>
                          <td v-if="associatedstore.display_id == id">
                            <i
                              class="tim-icons icon-check-2 m-3"
                              style="color: green"
                            ></i>
                            <base-button type="primary" size="sm">
                              {{ $t("Group details") }}</base-button
                            >
                          </td>
                          <td scope="row" v-else>
                            <i
                              class="tim-icons icon-alert-circle-exc m-3"
                              style="color: red"
                            ></i>
                            <base-button type="primary" size="sm">
                              {{ $t("Create Group") }}</base-button
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="table">
                      <tbody>
                        <tr>
                          <td>{{ $t("Assigned templates and designs") }}: :</td>
                          <td>
                            <tr
                              v-for="temp in associatedtemplate"
                              :key="temp.id"
                              v-if="associatedtemplate != ''"
                            >
                              <td>
                                <p style="text-align: center">
                                  {{ temp.templateparamvalue }}
                                </p>
                                <img
                                  v-if="temp.orientation == 1"
                                  style="border: 1px solid black"
                                  :src="temp.filename"
                                  alt="pre"
                                  width="150px"
                                />
                                <img
                                  v-else-if="temp.orientation == 0"
                                  style="border: 1px solid black"
                                  :src="temp.filename"
                                  alt="pre"
                                  width="300px"
                                />
                                <br />
                              </td>
                            </tr>
                          </td>
                          <td v-if="associatedtemplate == ''">
                            <i
                              class="tim-icons icon-alert-circle-exc m-3"
                              style="color: red"
                            ></i
                            ><base-button
                              type="primary"
                              size="sm"
                              @click="addtemplate()"
                            >
                              {{
                                $t("Create a new Template and a new design")
                              }}</base-button
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <hr />
                    <br />

                    <table class="table-responsive">
                      <tbody>
                        <tr>
                          <td>{{ $t("Details") }} :</td>
                          <td>
                            <table
                              class="table-responsive pb-0"
                              align="center"
                              border="1"
                              v-if="this.all != ''"
                            >
                              <tbody>
                                <tr>
                                  <th style="text-align: center" width="100">
                                    {{ $t("Box") }}
                                  </th>
                                  <th style="text-align: center" width="250">
                                    {{ $t("Contenitore") }}
                                  </th>
                                  <th style="text-align: center" width="1200">
                                    {{ $t("Playlist") }}
                                  </th>
                                </tr>
                                <tr
                                  v-for="(content, k) in all.container[0]"
                                  :key="k"
                                >
                                  <td scope="row" align="center">
                                    <h3>{{ content.boxId + 1}}</h3>
                                  </td>

                                  <td scope="row" align="center">
                                    <h3>{{ content.name }}</h3>
                                  </td>
                                  <td scope="row" align="center">
                                    <tr
                                      align="center"
                                      v-for="(playlists, j) in all.playlist[
                                        content.id
                                      ]"
                                      :key="j"
                                    >
                                      <td width="2000" style="border: 0">
                                        <h3>{{ playlists.playlistname }}</h3>
                                        <table
                                          align="center"
                                          border="1"
                                          width="1200"
                                        >
                                          <tbody>
                                            <tr>
                                              <th style="text-align: center">
                                                {{ $t("Contenuto") }}
                                              </th>
                                              <th style="text-align: center">
                                                {{ $t("Tipo") }}
                                              </th>
                                            </tr>
                                            <tr
                                              v-for="(content, l) in all
                                                .content[playlists.id]"
                                              :key="l"
                                            >
                                              <td scope="row" align="center">
                                                <p>
                                                  {{
                                                    content.contentparamvalue
                                                  }}
                                                </p>
                                              </td>
                                              <td
                                                scope="row"
                                                align="center"
                                                v-if="content.mime == 'Url'"
                                              >
                                                <i
                                                  class="tim-icons icon-credit-card"
                                                ></i>
                                              </td>
                                              <td
                                                scope="row"
                                                align="center"
                                                v-else-if="
                                                  content.mime == 'Html'
                                                "
                                              >
                                                <i
                                                  class="tim-icons icon-credit-card"
                                                ></i>
                                              </td>
                                              <td
                                                scope="row"
                                                align="center"
                                                v-else-if="
                                                  content.mime == 'image'
                                                "
                                              >
                                                <i
                                                  class="tim-icons icon-image-02"
                                                ></i>
                                              </td>
                                              <td
                                                scope="row"
                                                align="center"
                                                v-else-if="
                                                  content.mime == 'video'
                                                "
                                              >
                                                <i
                                                  class="tim-icons icon-video-66"
                                                ></i>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </card>
                </div>
              </div>
            </div>
          </collapse-item>
        </collapse>
      </card>
    </card>
  </div>
</template>

<script>
import { TimeSelect, Table, TableColumn, Option, Select } from "element-ui";
import { ref } from "vue";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";
import { relativeTimeRounding } from "moment";
import { TabPane, Tabs, Collapse, CollapseItem } from "src/components";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  mixins: [formMixin],

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    TabPane,
    Tabs,
    Collapse,
    CollapseItem,
  },

  created() {
    this.getplaylist();
    this.getshutdown();
    this.getreboot();
    this.gettemplate();
  },

  data() {
    return {
      selects: {
        simple: "1",
        emailcheck: [
          { value: "1", label: "also check" },
          { value: "2", label: "offline only" },
        ],
        email: "",
        displayid: this.$route.params.id,
      },

      id: this.$route.params.id,
      tableData: [],
      associatedstore: [],
      associatedtemplate: [],
      playlist: {
        seconds: null,
      },
      time: {
        ora: null,
      },
      template: {},
      temp: {
        displayid: this.$route.params.id,
      },
      all: "",
      test: "",

      timereboot: {
        ora: null,
      },
      templateSection : true
    };
  },
  methods: {
    getdisplay() {
      const id = this.$route.params.id;
      axios.get(`${url}/displaygetiddata/${id}`).then((response) => {
        this.tableData = response.data;
      });
    },

    async offline(id) {
      id = this.$route.params.id;
      axios.patch(`${url}/offline/${id}`, this.selects).then((response) => {
        this.$notify({
          type: "success",
          message: this.$t("Successfully."),
          icon: "tim-icons icon-bell-55",
        });
      });
    },

    async getoffline(id) {
      id = this.$route.params.id;
      axios.get(`${url}/getoffline/${id}`).then((response) => {
        this.selects.email = response.data[0].email;
      });
    },

    // gettemplateall() {

    //      axios
    //     .get(`${url}/getalltemplatedata`)
    //     .then((response) => {

    //       console.log(response.data);
    //     this.all = response.data;
    // })
    // },

    getalltemplatedatadisplay() {
      const id = this.$route.params.id;
      axios.get(`${url}/getalltemplatedatadisplay/${id}`).then((response) => {
        this.all = response.data;
      });
    },

    getplaylist() {
      const id = this.$route.params.id;
      axios.get(`${url}/getplaylist/${id}`).then((response) => {
        this.playlist = response.data;
      });
    },

    getshutdown() {
      const id = this.$route.params.id;
      axios.get(`${url}/getshutdown/${id}`).then((response) => {
        const timeValue = response.data[0].ora;
        this.time.ora = timeValue === "00:00:00" ? "" : timeValue;
      });
    },
    getreboot() {
      const id = this.$route.params.id;
      axios.get(`${url}/getrestart/${id}`).then((response) => {
        this.timereboot = response.data[0];
      });
    },
    getassociated() {
      const id = this.$route.params.id;
      axios.get(`${url}/getstoreassociateddisplay/${id}`).then((response) => {
        this.associatedstore = response.data;
      });
    },

    getassociatedtemplate() {
      const id = this.$route.params.id;
      axios.get(`${url}/getassociatetemplate/${id}`).then((response) => {
        // console.log(response.data);
        this.associatedtemplate = response.data;
      });
    },

    gettemplate() {
      axios.get(`${url}/gettemplatedata`).then((response) => {
        this.template = response.data;
      });
    },

    updatetemplate(tid) {
      const id = this.$route.params.id;
      this.temp["idtemplate"] = tid;

      axios
        .patch(`${url}/updatetemplatedisplay/${id}`, this.temp)
        .then((response) => {
          this.$notify({
            type: "success",
            message: this.$t("Successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.templateSection = false;
           setTimeout(() => {
            this.templateSection = true;
          },1500)
          this.gettemplate();
          this.getassociatedtemplate();
          this.getalltemplatedatadisplay();
        });
    },

    async addrefresh(id) {
      id = this.$route.params.id;

      axios
        .patch(`${url}/playlistupdate/${id}`, this.playlist)
        .then((response) => {
          this.$notify({
            type: "success",
            message: this.$t("Successfully."),
            icon: "tim-icons icon-bell-55",
          });
        });
    },
    async addshutdown(id) {
      id = this.$route.params.id;

      axios.patch(`${url}/addshutdown/${id}`, this.time).then((response) => {
        this.$notify({
          type: "success",
          message: this.$t("Successfully."),
          icon: "tim-icons icon-bell-55",
        });
      });
    },
    async addreboot(id) {
      id = this.$route.params.id;

      axios
        .patch(`${url}/addrestart/${id}`, this.timereboot)
        .then((response) => {
          this.$notify({
            type: "success",
            message: this.$t("Successfully."),
            icon: "tim-icons icon-bell-55",
          });
        });
    },

    groupdetail(id) {
      this.$router.push({ name: "Group Detail", params: { id } });
    },

    edittemplate(id) {
      this.$router.push({ name: "Edit Template", params: { id } });
    },
    addgroup() {
      this.$router.push({ name: "New Group" });
    },

    containerside(id) {
      this.$router.push({ name: "List Container", params: { id } });
    },

    playlistside(id) {
      this.$router.push({ name: "Edit Playlist", params: { id } });
    },

    addtemplate() {
      this.$router.push({ name: "New Template" });
    },
  },

  mounted() {
    this.getdisplay();
    this.getassociated();
    this.getassociatedtemplate();
    this.getoffline();
    this.getalltemplatedatadisplay();
  },
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content {
  font-size: inherit !important;
}
#headingOne {
  background-color: var(--primary) !important;
}

#headingOne a,
#headingOne a i {
  color: #ffffff !important;
}
</style>
