<template>
  <div class="mt-3">
    <h4>
      {{ $t("NEW CONTENT CREATION") }} ({{ $t("mb used") }}: {{ this.mbused }},
      {{ $t("mb free") }}: {{ this.mb - this.mbused }})
    </h4>
    <card class="stacked-form">
      <span v-if="errors && errors">
        <small class="error" v-text="errors" />
      </span>

      <form @submit.prevent>
        <div
          class="list-item"
          v-for="item in myList"
          :id="item.id"
          :order="item.attributes.content_order"
          :key="item.id"
          v-if="
            item.attributes.content_order != 0 &&
            item.attributes.content_order != null
          "
        >
          <base-input
            type="text"
            v-model="group[item.attributes.name + '_' + item.id]"
            :label="capital_letter(item.attributes.name)"
            :placeholder="capital_letter($t(item.attributes.name))"
            required
          />
        </div>
        <!-- <div class="col-sm-10 checkbox-radios">
                <base-radio v-model="radios.radio1" name="1"  value="A" inline>Upload Content</base-radio>
                <base-radio v-model="radios.radio1" name="2" value="B" inline>URL</base-radio>
                <base-radio v-model="radios.radio1" name="3" inline>HTML 5</base-radio>
                <base-radio v-model="radios.radio1" name="4" inline>Weather Forecast</base-radio>

        </div> -->

        <div class="mt-4">
          <input type="radio" class="mr-1" v-model="content" value="upload" />
          {{ $t("Upload Content") }}
          <input type="radio" class="ml-4" v-model="content" value="url" />
          {{ $t("URL") }}
          <input type="radio" class="ml-4" v-model="content" value="html" />
          {{ $t("HTML 5") }}
          <input type="radio" class="ml-4" v-model="content" value="weather" />
          {{ $t("Weather Forecast") }}
          <!-- <a href="javascript:void(0)" id="setscheduler" @click="openmodal = true" class="ml-4">Set Scheduler</a> -->
        </div>

        <modal
          :show.sync="openmodal"
          class="modal-search"
          id="setscheduler"
          :centered="false"
          :show-close="true"
          :modalContentClasses="width100"
        >
          <div class="row table-full-width">
            <h3>{{ $t("Scheduling settings for the current content") }}</h3>

            <div class="col-md-12">
              <el-table :data="secondTableData">
                <el-table-column width="400" label="Typology" align="center">
                  <div
                    class=""
                    style="text-align: justify"
                    slot-scope="{ row }"
                  >
                    <div>
                      <!-- <base-radio  v-model="radios.display" name="1" inline>Display</base-radio> -->
                      <input
                        value="1"
                        type="radio"
                        id="sf1"
                        name="scheduler_form"
                        v-model="schdular.Typologychecked"
                        @change="checkboxhide()"
                      />
                      <label for="sf1">{{ $t("Always run") }}</label>
                    </div>
                    <div>
                      <input
                        value="2"
                        id="sf2"
                        type="radio"
                        name="scheduler_form"
                        v-model="schdular.Typologychecked"
                        @change="checkboxselect()"
                      />
                      <label for="sf2">{{ $t("Run if checked") }}</label
                      ><br />
                    </div>
                    <div>
                      <input
                        value="3"
                        id="sf3"
                        type="radio"
                        name="scheduler_form"
                        v-model="schdular.Typologychecked"
                        @change="checkboxselect()"
                      />
                      <label for="sf3">{{
                        $t("Do not execute if selected")
                      }}</label
                      ><br />
                    </div>
                    <div class="row">
                      <div class="col-md-7">
                        <el-date-picker
                          type="date"
                          :placeholder="$t('Date Picker')"
                          v-model="schdular.datePickerStart"
                          :disabled="row.ckdisabled"
                        >
                        </el-date-picker>
                      </div>
                      <div class="col-md-5">
                        <el-time-select
                          :disabled="row.ckdisabled"
                          v-model="schdular.timePickerStart"
                          :picker-options="{
                            start: '00:00',
                            step: '00:15',
                            end: '23:59',
                          }"
                          :placeholder="$t('Select time')"
                        >
                        </el-time-select>
                      </div>
                    </div>
                    <br />
                    <div class="row">
                      <div class="col-md-7">
                        <el-date-picker
                          type="date"
                          :disabled="row.ckdisabled"
                          :placeholder="$t('Date Picker')"
                          v-model="schdular.datePickerEnd"
                        >
                        </el-date-picker>
                      </div>
                      <div class="col-md-5">
                        <el-time-select
                          :disabled="row.ckdisabled"
                          v-model="schdular.timePickerEnd"
                          :picker-options="{
                            start: '00:00',
                            step: '00:15',
                            end: '23:59',
                          }"
                          :placeholder="$t('Select time')"
                        >
                        </el-time-select>
                      </div>
                    </div>
                  </div>
                </el-table-column>

                <el-table-column width="100" label="Lun" align="center">
                  <div slot-scope="{ row }" class="checkbox-cell">
                    <base-checkbox
                      v-model="schdular.Lunchecked"
                      :disabled="row.ckdisabled"
                    ></base-checkbox>
                  </div>
                </el-table-column>

                <el-table-column width="100" label="Mar" align="center">
                  <div slot-scope="{ row }" class="checkbox-cell">
                    <base-checkbox
                      v-model="schdular.Marchecked"
                      :disabled="row.ckdisabled"
                    ></base-checkbox>
                  </div>
                </el-table-column>

                <el-table-column width="100" label="Mer" align="center">
                  <div slot-scope="{ row }" class="checkbox-cell">
                    <base-checkbox
                      v-model="schdular.Merchecked"
                      :disabled="row.ckdisabled"
                    ></base-checkbox>
                  </div>
                </el-table-column>

                <el-table-column width="100" label="Gio" align="center">
                  <div slot-scope="{ row }" class="checkbox-cell">
                    <base-checkbox
                      v-model="schdular.Giochecked"
                      :disabled="row.ckdisabled"
                    ></base-checkbox>
                  </div>
                </el-table-column>

                <el-table-column width="100" label="Ven" align="center">
                  <div slot-scope="{ row }" class="checkbox-cell">
                    <base-checkbox
                      v-model="schdular.Venchecked"
                      :disabled="row.ckdisabled"
                    ></base-checkbox>
                  </div>
                </el-table-column>
                <el-table-column width="100" label="Sab" align="center">
                  <div slot-scope="{ row }" class="checkbox-cell">
                    <base-checkbox
                      v-model="schdular.Sabchecked"
                      :disabled="row.ckdisabled"
                    ></base-checkbox>
                  </div>
                </el-table-column>
                <el-table-column width="100" label="Dom" align="center">
                  <div slot-scope="{ row }" class="checkbox-cell">
                    <base-checkbox
                      v-model="schdular.Domchecked"
                      :disabled="row.ckdisabled"
                    ></base-checkbox>
                  </div>
                </el-table-column>
              </el-table>
            </div>

            <div class="text-right col-md-12">
              <base-button @click="closem()" class="" type="primary">{{
                $t("Close")
              }}</base-button>
              <base-button
                class=""
                native-type="submit"
                type="primary"
                @click="addschdular(), openmodal"
                >{{ $t("Confirmation") }}</base-button
              >
            </div>
          </div>
        </modal>

        <br />
        <card v-show="content === 'upload'">
          <div
            class="flex items-center justify-center w-full h-screen text-center"
            id="app"
          >
            <div
              class="bg-gray-100 border border-gray-300"
              @dragover="dragover"
              @dragleave="dragleave"
              @drop="drop"
            >
              <input
                type="file"
                multiple
                name="fields[assetsFieldHandle][]"
                id="assetsFieldHandle"
                class="w-px h-px opacity-0 overflow-hidden absolute"
                @change="onChange"
                ref="file"
                accept=".jpeg,.pdf,.gif,.bmp,.tif,.tiff,.jpg,.png,.avi,.mp4,.mov,.mkv,.mpg,.mpeg,.wmv,.mp3,.wav,.aiff,.wma,.webm'"
              />

              <label
                for="assetsFieldHandle"
                class="p-12 w-100 block cursor-pointer"
              >
                <div>{{ $t("Drag & Drop file here") }}...</div>
              </label>

              <ul class="p-12 mt-4" v-if="this.filelist.length" v-cloak>
                <div id="preview">
                  <img
                    v-if="url && filetype[0] == 'image'"
                    :src="url"
                    style="width: auto; height: 160px"
                  />

                  <video
                    v-else-if="url && filetype[0] == 'video'"
                    preload="metadata"
                    controls
                    style="width: auto; height: 160px"
                  >
                    <source :src="url" type="video/mp4" />
                  </video>
                  <embed v-else :src="url" style="width: auto; height: 160px" />
                </div>
                <br />
                <!-- <base-progress value-position="right" v-model="progress" :value="0" /> -->
                <base-progress
                  max="100"
                  value-position="right"
                  :value="uploadPercentage"
                ></base-progress>
                <li
                  class="text-sm p-1"
                  v-for="(file, index) in filelist"
                  :key="index"
                  v-show="!type_length"
                >
                  {{ file.name }}
                </li>
                <base-alert type="danger" dismissible v-show="show">
                  <span v-if="type_length"
                    >{{ $t("Number of files selected for upload") }}
                    {{ filelist.length }}
                    {{ $t("exceeds maximum allowed limit of 1") }}.
                    {{ $t("Please retry your upload!") }}</span
                  >
                  <span v-else
                    >{{ $t("Invalid extension for file") }}
                    {{ filelist[0].name }}
                    {{ $t("Only zip files are supported") }}</span
                  >
                </base-alert>
              </ul>
            </div>
            <div
              class="mb-3"
              v-if="imageerrr"
              style="background-color: #ec4545"
            >
              <base-alert type="danger">
                <span>
                  {{ $t("Width of image file") }} {{ filelist[0].name }}
                  {{ $t("cannot exceed 5760 px") }}.</span
                >
                <br />
                <span>
                  {{ $t("Height of image file") }} {{ filelist[0].name }}
                  {{ $t("cannot exceed 4096 px") }}.
                </span>
              </base-alert>
              <p v-show="(showupload = false)"></p>
              <!-- <p style="color:black"> Width of image file {{filelist[0].name}} cannot exceed 5760 px.</p> -->
              <!-- <p style="color:black"> Height of image file {{filelist[0].name}} cannot exceed 4096 px.</p>  -->
            </div>
            <div
              class="mb-3"
              v-if="imageerrrwidth"
              style="background-color: #ec4545"
            >
              <base-alert type="danger">
                <span>
                  {{ $t("Width of image file") }} {{ filelist[0].name }}
                  {{ $t("cannot exceed 5760 px") }}.</span
                >
              </base-alert>
              <p v-show="(showupload = false)"></p>
            </div>
            <div
              class="mb-3"
              v-if="imageerrrheight"
              style="background-color: #ec4545"
            >
              <base-alert type="danger">
                <span>
                  {{ $t("Height of image file") }} {{ filelist[0].name }}
                  {{ $t("cannot exceed 4096 px") }}.
                </span>
              </base-alert>
              <p v-show="(showupload = false)"></p>
            </div>
            <div
              class="mb-3"
              v-if="isNotValidFileSize"
              style="background-color: #ec4545"
            >
              <base-alert type="danger">
                <span>
                  {{ $t(" The selected file,") }}
                  {{ filelist[0].name }}
                  {{
                    $t(
                      "exceeds the maximum allowed size of 250MB. Kindly choose a file that is within this limit to proceed."
                    )
                  }}
                </span>
              </base-alert>
              <p v-show="(showupload = false)"></p>
            </div>
            <div style="border: 1px solid black">
              <base-button
                @click="chooseFiles()"
                class="mr-3 mt-3 float-right"
                native-type="submit"
                type="primary"
                >{{ $t("Browse") }}..</base-button
              >
              <base-button
                @click="addonlyfile()"
                class="mt-3 float-right"
                native-type="submit"
                type="primary"
                v-show="showupload"
                >{{ $t("Submit") }}</base-button
              >
              <base-button
                @click="remove(filelist.indexOf(file))"
                class="mt-3 float-right danger"
                native-type="button"
                v-show="showremove"
                title="Remove file"
                >{{ $t("Remove") }}</base-button
              >
            </div>
          </div>
        </card>
        <base-button
          @click="addfile()"
          class="ml-4"
          native-type="submit"
          type="primary"
          v-show="showsave"
          >{{ $t("Save") }}</base-button
        >

        <card v-show="content === 'url'">
          <h4>{{ $t("URL ENTRY") }}</h4>
          <hr />
          <label class="col-sm-2">{{ $t("URL") }}</label>
          <div class="col-sm-7">
            <ValidationProvider
              :rules="{
                required: true,
                regex:
                  /(https?:\/\/)?([a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9](?::\d+)?\/?([a-z_\/0-9\-#.]*)\??([a-z_\/0-9\-#=&]*)/,
              }"
              v-slot="{ passed, failed }"
            >
              <base-input
                v-model="group.url"
                type="text"
                @change="addDomainFromURL()"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                :disabled="disabled == 1"
              >
              </base-input>
              <base-button class="btn-sm" @click.native="adddomainpopup">{{
                $t("Check URL")
              }}</base-button>
              <base-button
                class="btn-sm btn-danger ml-3"
                @click="removeurl()"
                >{{ $t("Delete URL") }}</base-button
              >
              <img
                v-if="isUrl === true"
                class="website-preview"
                :src="imageSrc"
                frameborder="2"
                ref="image"
                @load="handleImageLoad"
              />
              <div class="website-preview-loader" v-if="isLoading">
                <img src="/img/loading.gif" style="height: 50px; width: 50px" />
              </div>
              <base-input
                label="Add Domain"
                v-model="domain"
                v-if="isUrl == true"
              ></base-input>

              <base-button
                class="btn-sm btn-danger ml-3"
                @click="adddomain()"
                v-if="isUrl == true"
                >{{ $t("Add") }}</base-button
              >
              <div
                role="alert"
                class="alert alert-info col-sm-3"
                v-for="(list, key) in group.listDomain"
                :key="key"
              >
                <button type="button" aria-label="Close" class="close">
                  <i class="tim-icons icon-simple-remove" @click="removed(key)">
                  </i>
                </button>
                <span data-notify="message"> {{ list }} </span>
              </div>

              <br />
              <br />
              <base-button
                v-if="isUrl == true"
                class="btn-sm"
                @click="add()"
                native-type="submit"
                type="primary"
                >{{ $t("Save") }}</base-button
              >
            </ValidationProvider>
          </div>
        </card>

        <card v-show="content === 'html'">
          <h4>{{ $t("INSERT HTML5") }}</h4>
          <hr />
          <div class="form-group">
            <label
              class="col-sm-3 control-label"
              style="display: block; margin-right: auto; margin-left: 420px"
              >{{ $t("HEADER") }}</label
            >
            <div
              class="col-sm-6"
              style="display: block; margin-right: auto; margin-left: auto"
            >
              <textarea
                style="width: 800px; height: 110px"
                v-model="group.header"
              >
            &lt;!DOCTYPE HTML PUBLIC &quot;-//W3C//DTD HTML 4.01//EN&quot; &quot;http://www.w3.org/TR/html4/strict.dtd&quot;&gt;&lt;HTML&gt;&lt;HEAD&gt;&lt;/HEAD&gt;
  </textarea
              >
            </div>
          </div>

          <div class="form-group">
            <label
              class="col-sm-3 control-label"
              style="display: block; margin-right: auto; margin-left: 420px"
              >{{ $t("CSS") }}</label
            >
            <div
              class="col-sm-6"
              style="display: block; margin-right: auto; margin-left: auto"
            >
              <textarea
                name="css"
                v-model="group.css"
                height="300"
                style="width: 800px"
              ></textarea>
            </div>
          </div>

          <base-button
            @click="addhtml()"
            class="mt-3"
            native-type="submit"
            type="primary"
            style="display: block; margin-right: auto; margin-left: auto"
            >{{ $t("Update") }}</base-button
          >
          <br />
          <br />
          <base-input label="DESCRIPTION">
            <editor
              v-model="group.html"
              api-key="f2l19ekob9uuxnqmmi9mqg3shlqend3d4as3639l2fm9fdkn"
              :init="{
                height: 500,
                menubar: true,
                plugins: [
                  'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                  'searchreplace wordcount visualblocks visualchars code fullscreen',
                  'insertdatetime media nonbreaking save table contextmenu directionality',
                  'emoticons template paste textcolor colorpicker textpattern imagetools',
                ],
                toolbar1:
                  'undo redo | formatselect | bold italic backcolor | \
                       alignleft aligncenter alignright alignjustify | \
                       bullist numlist outdent indent | removeformat | help',
                toolbar2: 'preview media | forecolor backcolor emoticons',
              }"
            />
          </base-input>

          <base-button
            @click="addhtml()"
            class="mt-3"
            native-type="submit"
            type="primary"
            style="display: block; margin-right: auto; margin-left: auto"
            >{{ $t("Insert") }}</base-button
          >
          <br />

          <br />
        </card>

        <card v-show="content === 'weather'">
          <div>
            <h4>{{ $t("URL ENTRY") }}</h4>
            <hr />
            <label class="col-sm-2">{{ $t("URL") }}</label>
            <div class="col-sm-7">
              <ValidationProvider
                :rules="{
                  required: true,
                  regex:
                    /(https?:\/\/)?([a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9](?::\d+)?\/?([a-z_\/0-9\-#.]*)\??([a-z_\/0-9\-#=&]*)/,
                }"
                v-slot="{ passed, failed }"
              >
                <base-input
                  v-model="group.url"
                  type="text"
                  @change="addDomainFromURL()"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  :disabled="disabled == 1"
                >
                </base-input>
                <base-button class="btn-sm" @click.native="adddomainpopup">{{
                  $t("Check URL")
                }}</base-button>
                <base-button
                  class="btn-sm btn-danger ml-3"
                  @click="removeurl()"
                  >{{ $t("Delete URL") }}</base-button
                >
                <img
                  v-if="isUrl === true"
                  class="website-preview"
                  :src="imageSrc"
                  frameborder="2"
                  ref="image"
                  @load="handleImageLoad"
                />
                <div class="website-preview-loader" v-if="isLoading">
                  <img
                    src="/img/loading.gif"
                    style="height: 50px; width: 50px"
                  />
                </div>
                <base-input
                  :label="$t('Add Domain')"
                  v-model="domain"
                  v-if="isUrl == true"
                ></base-input>

                <base-button
                  class="btn-sm btn-danger ml-3"
                  @click="adddomain()"
                  v-if="isUrl == true"
                  >{{ $t("Add") }}</base-button
                >
                <div
                  role="alert"
                  class="alert alert-info col-sm-3"
                  v-for="(list, key) in group.listDomain"
                  :key="key"
                >
                  <button type="button" aria-label="Close" class="close">
                    <i
                      class="tim-icons icon-simple-remove"
                      @click="removed(key)"
                    >
                    </i>
                  </button>
                  <span data-notify="message"> {{ list }} </span>
                </div>

                <br />
                <br />
                <base-button
                  v-if="isUrl == true"
                  class="btn-sm"
                  @click="add()"
                  native-type="submit"
                  type="primary"
                  >{{ $t("Save") }}</base-button
                >
              </ValidationProvider>
            </div>
          </div>
        </card>
        <div v-show="content === 'weather'" class="pl-3">
          <pre>{{ descriptionText }}</pre>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
import { BaseAlert, BaseProgress } from "src/components";
import axios from "axios";
import ValidationError from "src/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import { BaseCheckbox, BaseRadio } from "src/components/index";
import { required } from "vee-validate/dist/rules";
import Editor from "@tinymce/tinymce-vue";
import { Table, TableColumn, TimeSelect, DatePicker, Image } from "element-ui";
import { extend } from "vee-validate";
import { Modal } from "@/components";
import swal from "sweetalert2";

var _URL = window.URL || window.webkitURL;

extend("required", required);
const url = process.env.VUE_APP_API_BASE_URL;

function sort(valuePath, array) {
  let path = valuePath.split(".");
  return array.sort((a, b) => {
    return getValue(a, path) - getValue(b, path);
  });

  function getValue(obj, path) {
    path.forEach((path) => (obj = obj[path]));
    return obj;
  }
}

export default {
  components: {
    Modal,
    ValidationError,
    BaseInput,
    BaseCheckbox,
    BaseRadio,
    BaseProgress,
    editor: Editor,
    BaseAlert,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [TimeSelect.name]: TimeSelect,
    [DatePicker.name]: DatePicker,
    required,
  },

  mixins: [formMixin],

  data: () => ({
    content: "upload",
    idTempScheduler: null,
    schedulerform: "1",

    filelist: [], // Store our uploaded files
    show: false,
    type_length: false,
    showconfirm: false,
    showupload: false,
    showremove: false,
    updatepack: {
      type: "updatepackage",
      name: null,
    },
    domain: null,
    // listDomain : [],
    errors: null,
    responsesubmit: null,
    responsefile: null,

    weather_url: "http://192.168.1.65/widgets/generalWeather/?",
    url: "",
    disabled: 0,

    radios: {
      radio1: null,
    },
    myList: [],
    errors: "",
    previewUrl:
      "https://api.apiflash.com/v1/urltoimage?access_key=8657da721f7a4588ae338c2494c02386&wait_until=page_loaded&url=",
    group: {
      type: "contents",
      listDomain: [],
      url: "",
      header: "",
    },

    url: "",
    isUrl: false,
    showsave: false,
    isValid: true,
    searchModalVisible: true,
    openmodal: false,
    secondTableData: [
      {
        Typologychecked: "",
        Lunchecked: false,
        Marchecked: false,
        Merchecked: false,
        Giochecked: false,
        Venchecked: false,
        Sabchecked: false,
        Domchecked: false,
        ckdisabled: true,
      },
    ],

    schdular: {
      Typologychecked: 1,
      datePickerEnd: "",
      datePickerStart: "",
      timePickerStart: "",
      timePickerEnd: "",
      Lunchecked: 0,
      Marchecked: 0,
      Merchecked: 0,
      Giochecked: 0,
      Venchecked: 0,
      Sabchecked: 0,
      Domchecked: 0,
    },
    filetype: "",
    type: "",
    mb: 0,
    mbused: 0,
    isLoading: false,
    imageSrc: "",
    isImageLoaded: false,
    uploadPercentage: 0,
    progress: 0,
    imageerrr: false,
    imageerrrwidth: false,
    imageerrrheight: false,
    isNotValidFileSize: false,
    width100: "width-100",
    descriptionText: `IMPORTANTE:
Il Widget Meteo nel formato standard prevede l'utilizzo in una sezione delle dimensioni di 320x900px (Larghezza x Altezza).
Di seguito all’url possono essere aggiunte delle variabili per modificarne in parte colori e dimensioni.
Se si utilizza la variabile “Zoom” è necessario ricalcolare con la stessa percentuale la misura della sezione in cui verrà posizionato.

Per aggiungere una Variabile è necessario inserire il '?' seguito dalla Variabile che si intende utilizzare.

Esempio (Modifica colore del campo data e ora):
OROLOGIO E METEO (link must be visible)

È possibile utilizzare più variabili in coda all’url originale separandole con '&'.

Esempio (modifica colore del campo data e ora e delle etichette meteo):
OROLOGIO E METEO (link must be visible)

Per i colori è possibile inserire il nome del colore in inglese 'Red', 'White', 'Black', ecc..).

Esempio (modifica colore del campo data e ora e delle etichette meteo utilizzando il nome colore):
OROLOGIO E METEO (link must be visible)



Di seguito alcuni esempi di variabili che è possibile utilizzare:

city=Milano
country=IT
zone=Europe/Rome
zoom=100
background=rgb(255,255,255) |or| background=white
watch_color=rgb(31,35,97) |or| background=blue
IT_color=rgb(255,255,255) |or| background=white
IT_size=18
IT_weight=bold
EN_color=rgb(96,146,205) |or| background=red
EN_size=16
EN_weight=bold
header_color=rgb(31,35,97) |or| background=blue
temperatura_color=rgb(31,35,97) |or| background=blue
temperatura_size=75`,
  }),
  watch: {
    content: {
      handler(newValue, oldValue) {
        if (this.content == "html") {
          this.group.css = "/*CSS */";
          this.group.header = `<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01//EN" "http://www.w3.org/TR/html4/strict.dtd">

<HTML>

<HEAD>

</HEAD>`;
        }
      },
      deep: true,
    },
    isImageLoaded() {
      if (this.isImageLoaded) {
        this.imageSrc = `https://api.apiflash.com/v1/urltoimage?access_key=8657da721f7a4588ae338c2494c02386&wait_until=page_loaded&url=${this.group.url}`;
      }
    },
  },
  methods: {
    loadImage() {
      this.isLoading = true;
      const imageUrl = `https://api.apiflash.com/v1/urltoimage?access_key=8657da721f7a4588ae338c2494c02386&wait_until=page_loaded&url=${this.group.url}`;
      const img = new Image();
      img.onload = () => {
        this.isImageLoaded = true;
      };
      img.src = imageUrl;
    },
    handleImageLoad() {
      this.isLoading = false;
    },
    checkboxselect() {
      this.secondTableData[0].ckdisabled = false;
    },
    checkboxhide() {
      this.secondTableData[0].ckdisabled = true;
      this.secondTableData[0].Lunchecked = false;
      this.secondTableData[0].Marchecked = false;
      this.secondTableData[0].Merchecked = false;
      this.secondTableData[0].Giochecked = false;
      this.secondTableData[0].Venchecked = false;
      this.secondTableData[0].Sabchecked = false;
      this.secondTableData[0].Domchecked = false;
    },

    showSwal(type) {
      type === "custom-html";
      {
        swal.fire({
          title: this.$t("Work Under Maintanance"),
          Text: this.$t("We will come back Shortly"),
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
          },
          timer: 2000,
          html: `<b>We will come back Shortly</b><br><b>THANK YOU</b>`,
        });
      }
    },

    getmaxgb() {
      axios.get(`${url}/mbuse`).then((response) => {
        this.mb = response.data;
      });
    },

    getgb() {
      axios.get(`${url}/mbf`).then((response) => {
        this.mbused = response.data;
      });
    },

    capital_letter(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },

    chooseFiles() {
      document.getElementById("assetsFieldHandle").click();
    },

    async adddomain() {
      if (this.domain == "" || this.domain == null) {
        this.$notify({
          type: "danger",
          message: this.$t("Invalid URL"),
          icon: "tim-icons icon-bell-55",
        });
      } else {
        this.group.listDomain.push(this.domain);

        return (this.domain = "");
      }
    },

    async addDomainFromURL() {
      let domain = new URL(this.group.url);
      domain = domain.hostname;
    },

    async removeurl() {
      this.isLoading = false;
      this.isUrl = false;
      this.group.url = "";
      this.group.listDomain = [];
      this.disabled = 0;
    },

    async add() {
      if (
        this.group.Description_1 == undefined ||
        this.group.Description_1 == "" ||
        this.group.name_3 == undefined ||
        this.group.name_3 == ""
      ) {
        this.$notify({
          type: "danger",
          message: this.$t("Please Fill All Required Parameter!"),
          icon: "tim-icons icon-bell-55",
        });
      } else {
        await axios
          .post(`${url}/groupadd`, this.group)
          .then((response) => {
            this.$notify({
              type: "success",
              message: this.$t("Content added successfully."),
              icon: "tim-icons icon-bell-55",
            });
            this.$router.push({ path: "/content/list-content" });
          })
          .catch((error) => {
            this.$notify({
              type: "danger",
              message: this.$t("Oops, something went wrong!"),
              icon: "tim-icons icon-bell-55",
            });
            this.setApiValidation(error.response.data.errors);
          });
      }
    },

    async addhtml() {
      if (
        this.group.Description_1 == undefined ||
        this.group.Description_1 == "" ||
        this.group.name_3 == undefined ||
        this.group.name_3 == ""
      ) {
        this.$notify({
          type: "danger",
          message: this.$t("Please Fill Firstname and Description."),
          icon: "tim-icons icon-bell-55",
        });
      } else {
        await axios
          .post(`${url}/groupadd`, this.group)
          .then((response) => {
            this.$notify({
              type: "success",
              message: this.$t("Content added successfully."),
              icon: "tim-icons icon-bell-55",
            });
            this.$router.push({ path: "/content/list-content" });
          })
          .catch((error) => {
            this.$notify({
              type: "danger",
              message: this.$t("Oops, something went wrong!"),
              icon: "tim-icons icon-bell-55",
            });
            this.setApiValidation(error.response.data.errors);
          });
      }
    },
    goBack() {
      this.$router.push({
        name: "List Content",
      });
    },
    getgroup() {
      axios.get(`${url}/categories`).then((response) => {
        this.myList = sort("attributes.content_order", response.data.data);
      });
    },

    async addschdular() {
      try {
        axios.post(`${url}/addschdular`, this.schdular).then((response) => {});
        this.$notify({
          type: "success",
          message: this.$t("Schdular added successfully."),
          icon: "tim-icons icon-bell-55",
        });
        this.goBack();
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
        this.setApiValidation(e.response.data.errors);
      }
    },

    onChange(e) {
      var hwmsg = false;
      this.imageerrr = false;
      this.imageerrrwidth = false;
      this.imageerrrheight = false;
      this.isNotValidFileSize = false;
      var file, img;
      this.filelist = [...this.$refs.file.files];
      this.type = this.filelist[0].type;
      this.filetype = this.type.split("/");
      this.err = hwmsg;

      this.url = URL.createObjectURL(this.filelist[0]);
      this.show = false;
      this.type_length = false;
      this.showconfirm = false;
      this.showupload = true;
      this.showremove = true;

      if (this.filelist.length != 1 || this.filelist.length > 1) {
        this.show = true;
        this.type_length = true;
        this.showupload = false;
        this.showremove = true;
        // } else if (this.filelist[0].type != "image/jpeg" || "application/pdf") {
        //     this.show = true;
        //      this.showupload = false;
      }
      const maxSize = 250 * 1024 * 1024;
      if ((file = this.filelist[0])) {
        if (file.size > maxSize) {
          this.isNotValidFileSize = true;
          hwmsg = true;
        }
        img = new Image();
        img.onload = function () {
          if (img.width > 5760 && img.height > 4096) {
            this.imageerrr = true;
            hwmsg = true;
          } else if (img.width > 5760) {
            this.imageerrrwidth = true;
            hwmsg = true;
          } else if (img.height > 4096) {
            this.imageerrrheight = true;
            hwmsg = true;
          }
        }.bind(this);
        img.src = _URL.createObjectURL(file);
      }
    },

    gettype() {},
    remove(i) {
      this.filelist.splice(i, 1);
      this.showconfirm = false;
      this.showupload = false;
      this.showremove = false;
      this.imageerrr = false;
      this.imageerrrwidth = false;
      this.imageerrrheight = false;
      this.isNotValidFileSize = false;
      this.url = "";
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },

    async addfile() {
      var tmp = [];

      tmp["filename"] = this.filelist[0].name;
      tmp["filesize"] = this.filelist[0].size;
      tmp["filetype"] = this.filelist[0].type;
      tmp["data"] = this.group;
      try {
        await this.$store.dispatch("contentfile/upload", tmp);
        this.responsefile = await this.$store.getters["contentfile/message"];
        if (this.responsefile == "Success") {
          this.$notify({
            type: "success",
            message: this.$t("Content upload successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.showupload = false;
          this.goBack();
        } else {
          this.$notify({
            type: "danger",
            message: this.responsefile,
            icon: "tim-icons icon-bell-55",
          });
        }
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
      }
    },

    async addonlyfile() {
      var tmp = [];
      tmp["file"] = this.filelist[0];
      tmp["data"] = this.group;

      if (
        tmp["data"].Description_1 == undefined ||
        tmp["data"].Description_1 == "" ||
        tmp["data"].name_3 == undefined ||
        tmp["data"].name_3 == ""
      ) {
        this.$notify({
          type: "danger",
          message: this.$t("Please Fill All Required Parameter!"),
          icon: "tim-icons icon-bell-55",
        });
      } else {
        const maxSize = 250 * 1024 * 1024;
        if (tmp["file"].size > maxSize) {
          const fileName = tmp["file"].name;
          this.$notify({
            type: "danger",
            message: this.$t(
              `${fileName} file size is should be Less than 250MB!`
            ),
            icon: "tim-icons icon-bell-55",
            timeout: 8000,
          });
        } else if (this.filelist[0].size / 1000000 > this.mb - this.mbused) {
          this.$notify({
            type: "danger",
            message: this.$t("MB Free is Less than File Size!"),
            icon: "tim-icons icon-bell-55",
            timeout: 8000,
          });
        } else {
          try {
            // await this.$store.dispatch("contentfile/uploadfile", tmp);
            const formData = new FormData();

            formData.append("file", tmp["file"]);

            const options = {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            };
            return axios
              .post(`${url}/adduploadfile`, formData, {
                options,
                onUploadProgress: function (progressEvent) {
                  this.uploadPercentage = parseInt(
                    Math.round(
                      (progressEvent.loaded / progressEvent.total) * 100
                    )
                  );
                }.bind(this),
              })
              .then((response) => {
                // this.progress = 100;
                this.showupload = false;
                this.showsave = true;
                return response.data.message;
              });
          } catch (e) {
            this.$notify({
              type: "danger",
              message: this.$t("Oops, something went wrong!"),
              icon: "tim-icons icon-bell-55",
            });
          }
        }
      }
    },

    removed(key) {
      this.group.listDomain.splice(key, 1);
    },

    //   goBack() {
    //   this.$router.push({ name: "List Content" });
    // },

    adddomainpopup() {
      let domain = new URL(this.group.url);
      domain = domain.hostname;
      this.group.listDomain.push(domain);
      this.loadImage();
      this.disabled = 1;
      this.isUrl = true;
    },
    closem() {
      this.openmodal = false;
      this.schdular = [];
      this.schdular.Typologychecked = 2;
    },
  },
  mounted() {
    this.getgroup();
    this.getmaxgb();
    this.getgb();
  },
};
</script>

<style scoped>
/* @import '../../assets/sass/dashboard/_variables.scss'; */
[v-cloak] {
  display: none;
}

.w-px {
  width: 1px;
}

.absolute {
  position: absolute;
}

.opacity-0 {
  opacity: 0;
}

.p-12 {
  padding: 3rem;
}

.border {
  border-width: 1px;
}

.border-gray-300 {
  border-color: #e2e8f0;
}

.bg-gray-100 {
  background-color: #f7fafc;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-item {
  border: none !important;
}

.card {
  border: 0.0625rem solid #e3e3e3;
}

.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

.flip-transition-move {
  transition: all 0.7s;
}

.content-img {
  width: 38%;
  margin-top: -12px;
  display: flex;
}

.list-group-item {
  max-height: 50px;
}

.list-group {
  cursor: move;
}

.text-title {
  margin-left: -62px;
  margin-top: -11px;
}

.card-header {
  background-color: #e3e3e3 !important;
}

table {
  width: 100% !important;
}

.modal-search .modal-dialog {
  margin: 23px auto;
  max-width: 65% !important;
  width: 180%;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 2px 0px var(--primary) inset;
  margin-right: 3px;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 35%;
  height: 31%;
  margin: 31% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: var(--primary);
}

.pickup > .mb-0 > input {
  border-radius: 25px;
  border: 1px solid #dddddd;
  background-color: #f9f9f9;
}

.wrapper {
  width: 420px;
  overflow-y: hidden;
  height: 400px;
}

.website-preview-loader {
  padding: 10px;
  height: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.website-preview {
  margin-block: 20px;
  border: 1px solid rgba(29, 37, 59, 0.3);
  box-shadow: 0 14px 32px 0 rgba(0, 0, 0, 0.15);
}
</style>
<style>
.width-100 {
  height: auto;
  width: 100%;
}
.danger {
  border-width: 2px;
  border: none;
  position: relative;
  overflow: hidden;
  margin: 4px 1px;
  border-radius: 0.4285rem;
  cursor: pointer;
  background: red !important;
  background-size: 210% 210%;
  background-position: top right;
  background-color: red !important;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #ffffff;
}
</style>
<style>
.danger:hover {
  background: red !important;
  background-color: red !important;
  background-image: red !important;
}
.danger:focus {
  background: red !important;
  background-color: red !important;
  background-image: red !important;
}
.danger:active {
  background: red !important;
  background-color: red !important;
  background-image: red !important;
}

.danger:not(:disabled):not(.disabled):active,
.danger:not(:disabled):not(.disabled).active,
.show > .danger.dropdown-toggle {
  color: #ffffff;
  background: red !important;
  background-color: red !important;
  background-image: red !important;
}
</style>
