<template>
  <div class="mt-3">
    <h4>{{ $t("NEW LICENSE CREATION") }}</h4>
    <card class="stacked-form">
      {{ $t("SELECTED GROUP") }} :
      <h4>
        <span
          v-for="table in tableData"
          :key="table.id"
          v-if="table.storeparamvalue == null"
        ></span>
        <span v-else>{{ table.storeparamvalue }} | </span>
      </h4>
      <div class="col-12 text-right">
        <base-button
          @click="goBack"
          type="button"
          class="btn btn-sm btn-primary"
          >{{ $t("Back to list") }}</base-button
        >
      </div>
      <hr />
      <form @submit.prevent>
        <div>
          <base-input-hidden v-model="license.license_code" />

          <base-input-hidden v-model="license.group_id" />

          <base-input
            v-model="license.display_number"
            :label="$t('Display Number')"
            :placeholder="$t('Display Number')"
          />
          <validation-error :errors="apiValidationErrors.display_number" />

          <base-input :label="$t('Expiry Date')" placeholder="Date">
            <el-date-picker
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
              :placeholder="$t('Expiry Date')"
              v-model="license.expiry_date"
            >
            </el-date-picker>
          </base-input>
          <validation-error :errors="apiValidationErrors.expiry_date" />

          <base-input :label="$t('License Type')">
            <el-select
              class="select-primary"
              size="large"
              :placeholder="$t('Lite')"
              v-model="license.license_type"
            >
              <el-option
                v-for="option in selects.licenses"
                class="select-primary"
                :value="option.value"
                :label="option.label"
                :key="option.label"
              >
              </el-option>
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.license_type" />

          <base-input label="Gb Associated" v-model="license.gb">
            <el-select
              v-model="license.gb"
              class="select-primary"
              size="large"
              placeholder="1"
            >
              <el-option
                v-for="option in selects.gb"
                class="select-primary"
                :value="option.value"
                :label="option.label"
                :key="option.label"
              >
              </el-option>
            </el-select>
          </base-input>
          <hr />

          <div class="row">
            <div class="col-sm-10 checkbox-radios">
              <base-checkbox v-model="license.view_statistics">{{
                $t("Enable view statistics")
              }}</base-checkbox>
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-sm-10 checkbox-radios">
              <base-checkbox v-model="license.webcam_statistics">{{
                $t("Enable Webcam Statistics")
              }}</base-checkbox>
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-sm-10 checkbox-radios">
              <base-checkbox v-model="license.wifi_statistics">{{
                $t("Enable Wi-Fi statistics")
              }}</base-checkbox>
            </div>
          </div>

          <hr />

          <base-button
            @click="add()"
            class="mt-3"
            native-type="submit"
            type="primary"
            >{{ $t("Submit") }}</base-button
          >
        </div>
      </form>
    </card>
  </div>
</template>

<script>
import ValidationError from "src/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import { DatePicker, Select, Option } from "element-ui";

import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  components: {
    ValidationError,
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
  },

  mixins: [formMixin],

  data() {
    const id = this.$route.params.id;
    return {
      tableData: [],
      selects: {
        simple: "",
        gbs: "",
        licenses: [
          { value: "Lite", label: "Lite" },
          { value: "Gold", label: "Gold" },
          { value: "Gold Touch", label: "Gold Touch" },
        ],
        gb: [
          { value: "1", label: "1" },
          { value: "2", label: "2" },
          { value: "3", label: "3" },
          { value: "4", label: "4" },
          { value: "5", label: "5" },
          { value: "6", label: "6" },
          { value: "7", label: "7" },
          { value: "8", label: "8" },
          { value: "9", label: "9" },
          { value: "10", label: "10" },
          { value: "11", label: "11" },
          { value: "12", label: "12" },
          { value: "13", label: "13" },
          { value: "14", label: "14" },
          { value: "15", label: "15" },
          { value: "16", label: "16" },
          { value: "17", label: "17" },
          { value: "18", label: "18" },
          { value: "19", label: "19" },
          { value: "Unlimited", label: "Unlimited" },
        ],
      },

      license: {
        type: "licenses",
        display_number: null,
        group_id: id,
        expiry_date: "",
        license_type: "Lite",
        gb: "1",
        view_statistics: false,
        webcam_statistics: false,
        wifi_statistics: false,
        license_code: Math.random().toString(36),
      },
    };
  },

  methods: {
    validate() {
      if (
        this.license.display_number === null &&
        this.license.expiry_date === ""
      ) {
        this.$notify({
          type: "danger",
          message: this.$t("All fields are required."),
          icon: "tim-icons icon-bell-55",
        });
        return false;
      } else if (this.license.display_number === null) {
        this.$notify({
          type: "danger",
          message: this.$t("Display number fields are required."),
          icon: "tim-icons icon-bell-55",
        });
        return false;
      } else if (this.license.expiry_date === "") {
        this.$notify({
          type: "danger",
          message: this.$t("Expiry date fields are required."),
          icon: "tim-icons icon-bell-55",
        });
        return false;
      }
      return true;
    },
    async add() {
      this.license.view_statistics = this.license.view_statistics ? 1 : 0;
      this.license.webcam_statistics = this.license.webcam_statistics ? 1 : 0;
      this.license.wifi_statistics = this.license.wifi_statistics ? 1 : 0;
      try {
        if (this.validate()) {
          await this.$store.dispatch("licenses/add", this.license);
          this.$notify({
            type: "success",
            message: this.$t("License added successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.license = await this.$store.getters["licenses/license"];
          this.goBack();
        }
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
        this.setApiValidation(e.response.data.errors);
      }
    },

    goBack() {
      this.$router.push({ name: "License List" });
    },

    getgroup() {
      const id = this.$route.params.id;
      axios.get(`${url}/getidvalue/${id}`).then((response) => {
        this.tableData = response.data;
      });
    },
  },

  mounted() {
    this.getgroup();
  },
};
</script>
