<template>
  <div>
    <div class="card mt-4" id="basic-info">
      <div class="card-header">
        <h1>{{ $t("Basic Info") }}</h1>
      </div>
      <div class="card-body pt-0">
        <div class="row">
          <div class="col-6">
            <el-input
              :placeholder="capital_letter($t('firstname'))"
              v-model="formData.name"
            >
            </el-input>
          </div>
          <div class="col-6">
            <el-input
              :placeholder="capital_letter($t('Phone Number'))"
              type="number"
              v-model="formData.phone"
            ></el-input>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <el-input
              :placeholder="capital_letter($t('email'))"
              type="email"
              v-model="formData.email"
            >
            </el-input>
          </div>
          <!-- <div class="col-6">
            <el-input
              name="confirmemail"
              :placeholder="capital_letter($t('confirmemail'))"
              type="email"
              v-model="formData.confirmemail"
            ></el-input>
          </div> -->
        </div>
        <div class="row mt-4">
          <div class="col-md-4 col-12 mb-4">
            <div class="el-input">
              <input
                ref="fileInput"
                type="file"
                autocomplete="off"
                :placeholder="capital_letter($t('profileimage'))"
                @change="handleFileChange"
                class="el-input__inner"
              />
            </div>
            <div id="target" class="image-preview">
              <div class="image-container">
                <img
                  class="profile-image"
                  :src="imagepreview"
                  alt="Image Preview"
                  v-if="imagepreview"
                />
                <span
                  v-if="imagepreview"
                  class="close-icon"
                  @click="clearImagePreview"
                >
                  &times;
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-8 col-12">
            <div class="row">
              <div class="col-4">
                <el-select
                  disabled
                  v-model="createddate.month"
                  :placeholder="capital_letter($t('select month'))"
                >
                  <el-option
                    v-for="month in months"
                    :key="month"
                    :label="month"
                    :value="month"
                  >
                    <span style="float: left">{{ month }}</span>
                  </el-option>
                </el-select>
              </div>
              <div class="col-4">
                <el-select
                  disabled
                  v-model="createddate.date"
                  :placeholder="capital_letter($t('select day'))"
                >
                  <el-option
                    v-for="sday in day"
                    :key="sday"
                    :label="sday"
                    :value="sday"
                  >
                    <span style="float: left">{{ sday }}</span>
                  </el-option>
                </el-select>
              </div>
              <div class="col-4">
                <el-select
                  disabled
                  v-model="createddate.year"
                  :placeholder="capital_letter($t('select year'))"
                >
                  <el-option
                    v-for="year in years"
                    :key="year"
                    :label="year"
                    :value="year"
                  >
                    <span style="float: left">{{ year }}</span>
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <button
            class="btn bg-gradient-info btn-sm sub-btn"
            @click="handleClick"
          >
            {{ $t("Submit") }}
          </button>
        </div>
      </div>
    </div>
    <div class="card mt-4" id="password">
      <div class="card-header">
        <h2>{{ $t("Change Password") }}</h2>
      </div>
      <div class="card-body pt-0">
        <el-input
          :placeholder="capital_letter($t('Current password'))"
          type="password"
          v-model="passDetail.current_password"
        >
        </el-input>

        <div class="input-group input-group-outline my-4">
          <el-input
            :placeholder="capital_letter($t('New password'))"
            type="password"
            v-model="passDetail.password"
          >
          </el-input>
        </div>
        <div class="input-group input-group-outline">
          <el-input
            :placeholder="capital_letter($t('Confirm New password'))"
            type="password"
            v-model="passDetail.password_confirmation"
          >
          </el-input>
        </div>
        <h5 class="mt-5 pass-heading">{{ $t("Password requirements") }}</h5>
        <p class="text-muted mb-2">
          {{ $t("Please follow this guide for a strong password:") }}
        </p>
        <ul class="text-muted ps-4 mb-0 float-left">
          <li>
            <span class="text-sm">{{ $t("One special characters") }}</span>
          </li>
          <li>
            <span class="text-sm">{{ $t("Min 6 characters") }}</span>
          </li>
          <li>
            <span class="text-sm">{{
              $t("One number (2 are recommended)")
            }}</span>
          </li>
          <li>
            <span class="text-sm">{{ $t("Change it often") }}</span>
          </li>
        </ul>
        <button
          @click="updatePassword"
          class="btn bg-gradient-info btn-sm float-right mt-6 mb-0 pass-btn"
        >
          {{ $t("Update password") }}
        </button>
      </div>
    </div>
    <div class="card mt-4" id="tech-user-detail">
      <div class="card-header">
        <h2>{{ $t("Update Technical User Detail") }}</h2>
      </div>
      <div class="card-body pt-0">
        <div class="row">
          <div class="col-6">
            <el-input
              :placeholder="capital_letter($t('email'))"
              v-model="tech_user_detail.secondary_email"
              :readonly="created_by !== 1"
            >
            </el-input>
          </div>
          <div class="col-6">
            <el-input
              :placeholder="capital_letter($t('Phone Number'))"
              type="number"
              v-model="tech_user_detail.secondary_phone"
              :readonly="created_by !== 1"
            ></el-input>
          </div>
        </div>
        <div class="row mt-4">
          <el-checkbox
            class="mx-3"
            label="Receive Email for All Displays"
            v-model="reciveEmialDisplay"
            @change="getReciveEmailForAllDisplay"
            :disabled="created_by !== 1"
          />
        </div>
        <div class="row mt-2">
          <button
            class="btn bg-gradient-info btn-sm sub-btn"
            @click="updateTechnicalDetail"
            :disabled="created_by !== 1"
          >
            {{ $t("Submit") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DatePicker, Select, Option, Checkbox } from "element-ui";
import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  name: "SettingsPage",
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      formData: {
        name: "",
        email: "",
        profile_image: null,
        phone: "",
      },
      created_by: 0,
      tech_user_detail: {
        secondary_email: "",
        secondary_phone: "",
      },
      imagepreview: null,
      reciveEmialDisplay: false,
      createddate: {
        date: "",
        month: "",
        year: "",
      },
      passDetail: {
        current_password: "",
        password: "",
        password_confirmation: "",
      },
      day: [],
      months: [],
      years: [],
      userId: 0,
    };
  },
  async created() {
    await this.getUserDetails();
  },
  mounted() {
    this.pickDay();
    this.pickMonths();
    this.pickYear();
    this.userId = localStorage.getItem("user_id");
  },
  methods: {
    formatDate(inputDate) {
      const parsedDate = new Date(inputDate);
      const year = parsedDate.getFullYear();
      const month = this.months[parsedDate.getMonth()];
      const day = parsedDate.getDate();

      this.createddate.date = day;
      this.createddate.month = month;
      this.createddate.year = year;
      const formattedDate = `${year}, ${month}, ${day}`;
      return formattedDate;
    },
    capital_letter(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
    handleFileChange(event) {
      // Access the selected file from the input
      const selectedFile = event.target.files[0];
      this.formData.profile_image = selectedFile;
      if (selectedFile) {
        // Read the selected image and set the image preview
        const reader = new FileReader();
        reader.onload = () => {
          this.imagepreview = reader.result;
        };
        reader.readAsDataURL(selectedFile);
      } else {
        this.imagepreview = null; // Clear image preview
      }
    },
    clearImagePreview() {
      this.imagepreview = null;
      this.$refs.fileInput.value = "";
    },
    pickDay() {
      var y;
      for (y = 1; y <= 31; y++) {
        this.day.push(y);
      }
    },
    pickMonths() {
      this.months[0] = "January";
      this.months[1] = "February";
      this.months[2] = "March";
      this.months[3] = "April";
      this.months[4] = "May";
      this.months[5] = "June";
      this.months[6] = "July";
      this.months[7] = "August";
      this.months[8] = "September";
      this.months[9] = "October";
      this.months[10] = "November";
      this.months[11] = "December";
    },
    pickYear() {
      var k;
      for (k = 1990; k <= 2030; k++) {
        this.years.push(k);
      }
    },
    validation() {
      if (
        this.formData.name === "" &&
        this.formData.email === "" &&
        this.formData.confirmemail === "" &&
        this.formData.phone === "" &&
        this.formData.email === "" &&
        this.createddate.date === "" &&
        this.createddate.month === "" &&
        this.createddate.year === ""
      ) {
        this.$notify({
          type: "danger",
          message: this.$t("All fields are required."),
          icon: "tim-icons icon-bell-55",
        });
        return false;
      } else if (this.formData.name === "") {
        this.$notify({
          type: "danger",
          message: this.$t("Name is required."),
          icon: "tim-icons icon-bell-55",
        });
        return false;
      } else if (this.formData.phone === "") {
        this.$notify({
          type: "danger",
          message: this.$t("Phone is required."),
          icon: "tim-icons icon-bell-55",
        });
        return false;
      } else if (this.formData.email === "") {
        this.$notify({
          type: "danger",
          message: this.$t("Email is required."),
          icon: "tim-icons icon-bell-55",
        });
        return false;
        // } else if (this.formData.email !== this.formData.confirmemail) {
        //   this.$notify({
        //     type: "danger",
        //     message: this.$t("Email does not match."),
        //     icon: "tim-icons icon-bell-55",
        //   });
        //   return false;
        // } else if (this.formData.profile_image === null) {
        //   this.$notify({
        //     type: "danger",
        //     message: this.$t("Profile Image is required."),
        //     icon: "tim-icons icon-bell-55",
        //   });
        //   return false;
      } else if (
        this.createddate.date === "" ||
        this.createddate.month === "" ||
        this.createddate.year === ""
      ) {
        this.$notify({
          type: "danger",
          message: this.$t("Created_at is required."),
          icon: "tim-icons icon-bell-55",
        });
        return false;
      }
      return true;
    },
    // validatePassword() {
    //   if(this.passDetail.password === "" &&  this.passDetail.password_confirmation === "") {
    //     return false;
    //   }
    // },
    async handleClick() {
      const userData = new FormData();
      userData.append("name", this.formData.name);
      userData.append("email", this.formData.email);
      userData.append("phone", this.formData.phone);

      if (this.formData.profile_image && this.formData.profile_image !== null) {
        userData.append("profile_image", this.formData.profile_image);
      }
      // update User
      if (this.validation()) {
        const headers = { "Content-Type": "multipart/form-data" };
        await axios
          .post(`${url}/update-profile/${this.userId}`, userData, { headers })
          .then(async (res) => {
            await this.getUserDetails();
            this.$notify({
              type: "success",
              message: res.data,
              icon: "tim-icons icon-bell-55",
            });
          });
      }
    },
    async updatePassword() {
      await axios
        .post(`${url}/update-password/${this.userId}`, this.passDetail)
        .then((res) => {
          this.$notify({
            type: "success",
            message: res.data,
            icon: "tim-icons icon-bell-55",
          });
        });
    },
    async updateTechnicalDetail() {
      const obj = {
        user_id: this.userId,
        secondary_email: this.tech_user_detail.secondary_email,
        secondary_phone: this.tech_user_detail.secondary_phone,
      };
      await axios
        .post(`${url}/update-user`, obj)
        .then((res) => {
          this.$notify({
            type: "success",
            message: res.data,
            icon: "tim-icons icon-bell-55",
          });
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error.response.data.message,
            icon: "tim-icons icon-bell-55",
          });
        });
    },
    async getReciveEmailForAllDisplay() {
      const newEmailOptStatus = this.reciveEmialDisplay ? 1 : 0;
      const obj = {
        opt_email: newEmailOptStatus,
      };
      try {
        await axios
          .post(`${url}/update-email-preference/${this.userId}`, obj)
          .then((response) => {
            this.$notify({
              type: "success",
              message: response.data,
              icon: "tim-icons icon-bell-55",
            });
          });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Something went to wrong",
          icon: "tim-icons icon-bell-55",
        });
      }
    },
    async getUserDetails() {
      this.$store.watch(
        () => this.$store.getters["profile/me"],
        (me) => {
          this.formData.name = me.name;
          this.formData.email = me.email;
          this.formData.phone = me.phone;
          this.created_by = me.created_by;
          this.tech_user_detail.secondary_email = me.secondary_email;
          this.tech_user_detail.secondary_phone = me.secondary_phone;
          this.userId = me.id;
          this.reciveEmialDisplay = me.opt_email_master === 1 ? true : false;
          this.formatDate(me.created_at.split(" ")[0]);
        }
      );
      await this.$store.dispatch("profile/me");
    },
  },
};
</script>

<style scoped>
/* .el-input is-disabled el-input--suffix {
  border-color: red;
  color: #c0c4cc;
  cursor: not-allowed;
} */
input[type="file"] {
  line-height: 25px !important;
  padding-top: 4px !important;
  padding-left: 6px !important;
}

.image-preview {
  position: relative;
}

.close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  font-size: 20px;
  color: red; /* Set the color to red */
}

.card .card-header {
  padding: 1.5rem;
  background-color: #fff !important;
}
.pass-heading {
  font-size: 1.25rem;
  line-height: 1.375;
  color: #344767;
}

.text-muted,
.text-sm {
  color: #7b809a !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}

.btn {
  text-transform: uppercase;
}
.mb-0 {
  margin-bottom: 0 !important;
}

.mt-6 {
  margin-top: 4rem !important;
}

.image-preview {
  position: relative;
}

.image-container {
  position: relative;
  display: inline-block;
}

.profile-image {
  margin-top: 1rem;
  height: 100px;
}

.close-icon {
  position: absolute;
  top: 8px;
  right: -7px;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  background-color: #1d8cf8;
  padding: 0px 6px;
  border-radius: 50%;
}

.el-select {
  width: 100%;
}

.sub-btn {
  margin-left: 15px;
}

.sub-btn:hover,
.pass-btn:hover {
  background: #1d8cf8 -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#1d7cda),
      to(#1d8cf8)
    ) repeat-x !important;
}
</style>
