<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-12">
        <h4 slot="header" class="card-title">{{ $t("DISPLAY LIST") }}</h4>
        <card card-body-classes="table-full-width">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('Search records')"
                  v-model="query"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <modal
              :show.sync="openmodal"
              class="modal-search"
              id="setscheduler"
              :centered="false"
              :show-close="true"
              :modalContentClasses="widthtemp"
            >
              <div class="row table-full-width">
                <h3></h3>

                <div class="col-md-12">
                  <el-table :data="secondTableData">
                    <el-table-column
                      width="400"
                      label="Typology"
                      align="center"
                    >
                      <div
                        class=""
                        style="text-align: justify"
                        slot-scope="{ row }"
                      >
                        <div>
                          <!-- <base-radio  v-model="radios.display" name="1" inline>Display</base-radio> -->
                          <input
                            value="1"
                            type="radio"
                            id="sf1"
                            name="scheduler_form"
                            v-model="schdular.Typologychecked"
                            @change="checkboxhide()"
                          />
                          <label for="sf1">{{ $t("Always run") }}</label>
                        </div>
                        <div>
                          <input
                            value="2"
                            id="sf2"
                            type="radio"
                            name="scheduler_form"
                            v-model="schdular.Typologychecked"
                            @change="checkboxselect()"
                          />
                          <label for="sf2">{{ $t("Run if checked") }}</label
                          ><br />
                        </div>
                        <div>
                          <input
                            value="3"
                            id="sf3"
                            type="radio"
                            name="scheduler_form"
                            v-model="schdular.Typologychecked"
                            @change="checkboxselect()"
                          />
                          <label for="sf3">{{
                            $t("Do not execute if selected")
                          }}</label
                          ><br />
                        </div>
                        <div class="row">
                          <div class="col-md-7">
                            <el-date-picker
                              type="date"
                              :placeholder="$t('Date Picker')"
                              v-model="schdular.datePickerStart"
                              :disabled="row.ckdisabled"
                            >
                            </el-date-picker>
                          </div>
                          <div class="col-md-5">
                            <el-time-select
                              :disabled="row.ckdisabled"
                              v-model="schdular.timePickerStart"
                              :picker-options="{
                                start: '00:00',
                                step: '00:15',
                                end: '23:59',
                              }"
                              :placeholder="$t('Select time')"
                            >
                            </el-time-select>
                          </div>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col-md-7">
                            <el-date-picker
                              type="date"
                              :disabled="row.ckdisabled"
                              :placeholder="$t('Date Picker')"
                              v-model="schdular.datePickerEnd"
                            >
                            </el-date-picker>
                          </div>
                          <div class="col-md-5">
                            <el-time-select
                              :disabled="row.ckdisabled"
                              v-model="schdular.timePickerEnd"
                              :picker-options="{
                                start: '00:00',
                                step: '00:15',
                                end: '23:59',
                              }"
                              :placeholder="$t('Select time')"
                            >
                            </el-time-select>
                          </div>
                        </div>
                      </div>
                    </el-table-column>

                    <el-table-column width="100" label="Lun" align="center">
                      <div slot-scope="{ row }" class="checkbox-cell">
                        <base-checkbox
                          v-model="schdular.Lunchecked"
                          :disabled="row.ckdisabled"
                        ></base-checkbox>
                      </div>
                    </el-table-column>

                    <el-table-column width="100" label="Mar" align="center">
                      <div slot-scope="{ row }" class="checkbox-cell">
                        <base-checkbox
                          v-model="schdular.Marchecked"
                          :disabled="row.ckdisabled"
                        ></base-checkbox>
                      </div>
                    </el-table-column>

                    <el-table-column width="100" label="Mer" align="center">
                      <div slot-scope="{ row }" class="checkbox-cell">
                        <base-checkbox
                          v-model="schdular.Merchecked"
                          :disabled="row.ckdisabled"
                        ></base-checkbox>
                      </div>
                    </el-table-column>

                    <el-table-column width="100" label="Gio" align="center">
                      <div slot-scope="{ row }" class="checkbox-cell">
                        <base-checkbox
                          v-model="schdular.Giochecked"
                          :disabled="row.ckdisabled"
                        ></base-checkbox>
                      </div>
                    </el-table-column>

                    <el-table-column width="100" label="Ven" align="center">
                      <div slot-scope="{ row }" class="checkbox-cell">
                        <base-checkbox
                          v-model="schdular.Venchecked"
                          :disabled="row.ckdisabled"
                        ></base-checkbox>
                      </div>
                    </el-table-column>
                    <el-table-column width="100" label="Sab" align="center">
                      <div slot-scope="{ row }" class="checkbox-cell">
                        <base-checkbox
                          v-model="schdular.Sabchecked"
                          :disabled="row.ckdisabled"
                        ></base-checkbox>
                      </div>
                    </el-table-column>
                    <el-table-column width="100" label="Dom" align="center">
                      <div slot-scope="{ row }" class="checkbox-cell">
                        <base-checkbox
                          v-model="schdular.Domchecked"
                          :disabled="row.ckdisabled"
                        ></base-checkbox>
                      </div>
                    </el-table-column>
                  </el-table>
                </div>

                <div class="text-right col-md-12">
                  <base-button @click="closem()" class="" type="primary">{{
                    $t("Close")
                  }}</base-button>
                  <base-button
                    class=""
                    native-type="submit"
                    type="primary"
                    @click="addschdular(), openmodal"
                  >
                    {{ $t("Confirmation") }}</base-button
                  >
                </div>
              </div>
            </modal>
            <div id="app">
              <table class="table" v-if="tableData.length !== 0">
                <thead>
                  <tr>
                    <th
                      @click="sort(table, key)"
                      v-for="(table, key) in tableData.title"
                      :key="key"
                      class="table-header"
                    >
                      <span>
                        {{ $t(table)
                        }}<SortIcon
                          :topColor="computedTopColor(table, 'top')"
                          :bottomColor="computedTopColor(table, 'bottom')"
                        />
                      </span>
                    </th>
                    <th
                      class="text-center table-header"
                      style="display: flex; justify-content: center"
                      @click="sort('status', 0)"
                    >
                      <span>
                        {{ $t("Status")
                        }}<SortIcon
                          :topColor="computedTopColor('status', 'top')"
                          :bottomColor="computedTopColor('status', 'bottom')"
                        />
                      </span>
                    </th>
                    <th>{{ $t("Software Version") }}</th>
                    <th class="text-center">{{ $t("action") }}</th>
                  </tr>
                </thead>
                <tbody v-if="tableData.data != ''">
                  <tr
                    v-for="(table, key) in filteredAndSortedBlogs"
                    :key="table.id"
                    v-if="key !== ''"
                  >
                    <td v-for="t in table" :key="t.id">
                      {{ t.displayparamvalue }}
                    </td>
                    <td class="status-td">
                      <span
                        class="status-span"
                        :data-title="table[0].time"
                        :class="table[0].status === 'offline' ? 'red' : 'green'"
                      >
                      </span>
                    </td>
                    <td>3.0.0.0</td>

                    <td class="text-center" scope="row" v-if="table[0].did">
                      <button
                        @click="modal(table[0].display_id)"
                        title="Schedular"
                        class="'edit btn-link"
                        type="success"
                        style="color: var(--primary)"
                      >
                        <i class="tim-icons icon-notes"></i>
                      </button>
                      <button
                        @click="addTemplate(table[0].display_id)"
                        title="Add Template"
                        class="'add btn-link"
                        type="success"
                        style="color: var(--primary)"
                      >
                        <i class="tim-icons icon-simple-add"></i>
                      </button>
                      <button
                        @click="goToEdit(table[0].display_id)"
                        title="Edit"
                        class="'edit btn-link"
                        type="success"
                        style="color: var(--primary)"
                      >
                        <i class="tim-icons icon-pencil"></i>
                      </button>
                      <button
                        @click="goToDetails(table[0].display_id)"
                        title="Detail"
                        class="'details btn-link"
                        type="success"
                        style="color: var(--primary)"
                      >
                        <i class="tim-icons icon-bag-16"></i>
                      </button>
                    </td>

                    <td class="text-center" scope="row" v-else>
                      <button
                        @click="modal(table[0].display_id)"
                        title="Schedular"
                        class="'edit btn-link"
                        type="success"
                        style="color: var(--primary)"
                      >
                        <i class="tim-icons icon-notes"></i>
                      </button>

                      <button
                        @click="addTemplate(table[0].display_id)"
                        title="Add Template"
                        class="'add btn-link"
                        type="success"
                        style="color: var(--primary)"
                      >
                        <i class="tim-icons icon-simple-add"></i>
                      </button>
                      <button
                        @click="goToEdit(table[0].display_id)"
                        title="Edit"
                        class="'edit btn-link"
                        type="success"
                        style="color: var(--primary)"
                      >
                        <i class="tim-icons icon-pencil"></i>
                      </button>
                      <button
                        @click="destroy(table[0].display_id)"
                        title="Delete"
                        class="'delete btn-link"
                        type="success"
                        style="color: var(--primary)"
                      >
                        <i class="tim-icons icon-simple-remove"></i>
                      </button>

                      <button
                        @click="goToDetails(table[0].display_id)"
                        title="Detail"
                        class="'details btn-link"
                        type="success"
                        style="color: var(--primary)"
                      >
                        <i class="tim-icons icon-bag-16"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div v-else >
               <table class="table">
                <thead>
                  <tr>
                    <th>FIRSTNAME</th>
                    <th>BUSINESS NAME</th>
                    <th
                      @click="sort('firstname')"
                      v-for="(table, key) in tableData.title"
                      :key="key"
                    >
                      {{ table }}
                    </th>
                    <th>Actions</th>
                    <th>Software Version</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>

            </div> -->
          </div>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
                {{ $t("of") }} {{ total }} {{ $t("entries") }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  TimeSelect,
} from "element-ui";
import _ from "lodash";

import { BasePagination } from "src/components";
// import users from "./users";
import Fuse from "fuse.js";
import swal from "sweetalert2";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "src/components/ValidationError.vue";
import { Modal } from "@/components";
import SortIcon from "@/assets/customImg/SortIcon.vue";
import { getTopColor, getBottomColor } from "../../util/utils";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  mixins: [formMixin],

  components: {
    SortIcon,
    ValidationError,
    BasePagination,
    Modal,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.tableData.total < highBound) {
        highBound = this.total;
      }

      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.total;
    },
    filteredAndSortedBlogs() {
      let keyword = "displayparamvalue";
      return [...this.tableData.data].sort((a, b) => {
        if (this.sortparam.field === "status") {
          keyword = "status";
        }
        let modifier = this.currentSortDir === "desc" ? -1 : 1;
        const aValue = a[this.currentSort][keyword].toLowerCase();
        const bValue = b[this.currentSort][keyword].toLowerCase();
        if (aValue < bValue) return -1 * modifier;
        if (aValue > bValue) return 1 * modifier;
        return 0;
      });
    },
  },

  data() {
    return {
      currentSort: 0,
      currentSortDir: "asc",

      widthtemp: "widthtemp-100",
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
      },

      query: "",
      propsToSearch: ["firstname"],
      tableData: [],
      group: [],
      licenses: [],
      openmodal: false,
      searchedData: [],
      fuseSearch: null,
      secondTableData: [
        {
          Typologychecked: "1",
          Lunchecked: false,
          Marchecked: false,
          Merchecked: false,
          Giochecked: false,
          Venchecked: false,
          Sabchecked: false,
          Domchecked: false,
          ckdisabled: true,
        },
      ],

      schdular: {
        Typologychecked: 1,
        datePickerEnd: "",
        datePickerStart: "",
        timePickerStart: "",
        timePickerEnd: "",
        Lunchecked: 0,
        Marchecked: 0,
        Merchecked: 0,
        Giochecked: 0,
        Venchecked: 0,
        Sabchecked: 0,
        Domchecked: 0,
        contid: "",
      },
      duration: {
        timePicker: "",
        contid: "",
      },
      searchModalVisible: true,
      selectedapp: "",
      selectedvideo: "",
      selectedimage: "",
      openappmodal: false,
      openvideomodal: false,
      openimagemodal: false,
      sortparam: {
        field: "name",
        sort: "asc",
      },
    };
  },
  methods: {
    computedTopColor(table, arrowIcon) {
      if (arrowIcon === "top") {
        return getTopColor(table, this.sortparam);
      } else {
        return getBottomColor(table, this.sortparam);
      }
    },
    closem() {
      this.openmodal = false;
      this.schdular = [];
      this.schdular.Typologychecked = 2;
    },

    getListDebounced: _.debounce(function () {
      this.getdisplay();
    }, 300),

    sort: function (data, s) {
      this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      this.currentSort = s;
      this.sortparam.field = data;
      this.sortparam.sort = this.sortparam.sort == "asc" ? "desc" : "asc";
    },

    setCurrentPage(direction) {
      if (direction === -1 && this.pagination.currentPage > 1) {
        this.pagination.currentPage -= 1;
      } else if (
        direction === 1 &&
        this.pagination.currentPage <
          this.tableData.data.length / this.pagination.perPage
      ) {
        this.pagination.currentPage += 1;
      }
    },

    async addschdular() {
      //  console.log(this.schdular);
      try {
        axios
          .post(`${url}/add-display-schedular`, this.schdular)
          .then((response) => {});
        this.$notify({
          type: "success",
          message: this.$t("Schedular added successfully."),
          icon: "tim-icons icon-bell-55",
        });
        this.openmodel = false;
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
        this.setApiValidation(e.response.data.errors);
      }
    },

    checkboxselect() {
      this.secondTableData[0].ckdisabled = false;
    },
    checkboxhide() {
      this.secondTableData[0].ckdisabled = true;
      this.secondTableData[0].Lunchecked = false;
      this.secondTableData[0].Marchecked = false;
      this.secondTableData[0].Merchecked = false;
      this.secondTableData[0].Giochecked = false;
      this.secondTableData[0].Venchecked = false;
      this.secondTableData[0].Sabchecked = false;
      this.secondTableData[0].Domchecked = false;
    },

    async getdisplay() {
      let params = {
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.query ? { searchValue: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };
      await this.$store.dispatch("displays/get", params);
      this.tableData = await this.$store.getters["displays/display_list"];
    },

    async destroy(id) {
      try {
        const confirmation = await swal.fire({
          title: this.$t("Are you sure?"),
          text: this.$t("You won't be able to revert this!"),
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: this.$t("Yes, delete it!"),
          buttonsStyling: false,
        });

        if (confirmation.value === true) {
          await this.$store.dispatch("displays/destroy", id);
          this.$notify({
            type: "success",
            message: this.$t("Display deleted successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.getdisplay();
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: this.$t("This Display still has associated Users."),
            icon: "tim-icons icon-bell-55",
          });
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("Oops, something went wrong!"),
            icon: "tim-icons icon-bell-55",
          });
        }
      }
    },

    goToEdit(id) {
      this.$router.push({ name: "Edit Display Manager", params: { id } });
    },

    goToDetails(id) {
      this.$router.push({ name: "Detail Display", params: { id } });
      //  setInterval(() => { window.location.reload(); }, 1000);
    },

    goToSchedular(id) {
      // this.$router.push({name: 'ense', params: {id}})
    },

    addTemplate(id) {
      // console.log(id);
      this.$router.push({ name: "Display Template", params: { id } });
    },

    modal(id) {
      this.openmodal = true;
      this.schdular.displayid = id;
    },
  },

  mounted() {
    // Fuse search initialization.
    (this.fuseSearch = new Fuse(this.tableData, {
      keys: ["firstname"],
      threshold: 0.3,
    })),
      this.getdisplay();
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    query: {
      handler: "getListDebounced",
      immediate: true,
    },

    pagination: {
      handler: "getdisplay",
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style scoped>
.sorted {
  cursor: pointer;
}
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

.flip-transition-move {
  transition: all 0.7s;
}

.content-img {
  width: 38%;
  margin-top: -12px;
  display: flex;
}

.list-group-item {
  max-height: 50px;
}

.list-group {
  cursor: move;
}

.text-title {
  margin-left: -62px;
  margin-top: -11px;
}

.card {
  border: 0.0625rem solid #e3e3e3;
}

.card-header {
  background-color: #e3e3e3 !important;
}

table {
  width: 100% !important;
}

.modal-search .modal-dialog {
  margin: 23px auto;
  max-width: 65% !important;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 2px 0px var(--primary) inset;
  margin-right: 3px;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 35%;
  height: 31%;
  margin: 31% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: var(--primary);
}

.pickup > .mb-0 > input {
  border-radius: 25px;
  border: 1px solid #dddddd;
  background-color: #f9f9f9;
}

.status-span {
  cursor: default;
  display: list-item;
  list-style: none;
  margin: auto;
  position: relative;
  /* border: 1px solid black; */
  border-radius: 30px;
  width: 20px;
  height: 20px;
  color: #f7f7f7;
  max-width: 200px;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}

.status-span:hover {
  cursor: pointer;
  color: #292b2c;
}

.status-span:hover::before {
  position: absolute;
  content: attr(data-title);
  background-color: #000;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  top: -25px;
  right: 50%;
  padding: 3px 8px;
  transform: translate(50%, -50%);
  white-space: nowrap;
  overflow-wrap: break-word;
}

.status-span:hover::after {
  position: absolute;
  content: "\25BC";
  height: 1.25rem;
  color: #000;
  top: -12px;
  left: 50%;
  transform: scale(1.8, 1) translate(-33%, -50%);
}

.table > tbody > tr > td {
  padding: 5px;
}
.table-header {
  cursor: pointer;
}
.table-header span {
  display: flex;
  align-items: center;
}
</style>

<style>
.widthtemp-100 {
  height: auto;
  width: 100%;
}
</style>
