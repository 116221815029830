<template>
  <div class="mt-3">
    <h4>{{ $t("DISPLAY DETAIL - SOFTWARE VERSION: 3.0.0.0") }}</h4>
    <card>
      <card>
        <div class="row">
          <div class="col-6">
            <div class="card" style="min-height: 94%">
              <ul class="list-group">
                <li
                  class="list-group-item m-2"
                  style="background-color: var(--primary); color: #ffffff"
                >
                  {{ $t("LAST SCREENSHOT") }}<i class="handle"></i>
                </li>
              </ul>

              <div class="card-body text-center py-5">
                <code><img src="" alt="Image of Last Screenshot" /></code>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card">
              <ul class="list-group">
                <li
                  class="list-group-item m-2"
                  style="background-color: var(--primary); color: #ffffff"
                >
                  {{ $t("PARAMETERS") }}
                </li>
              </ul>

              <div id="app">
                <table class="table">
                  <thead>
                    <tr>
                      <th v-for="(table, key) in tableData.title" :key="key">
                        {{ table }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(table, key) in tableData"
                      :key="table.id"
                      v-if="key !== 'title'"
                    >
                      <td v-for="t in table" :key="t.id" v-if="key != ''">
                        {{ t.displayparamvalue }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </card>

      <card>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <ul class="list-group">
                <li
                  class="list-group-item m-2"
                  style="background-color: var(--primary); color: #ffffff"
                >
                  {{ $t("TEMPLATE SELECTION") }}<i class="handle"></i>
                </li>
              </ul>
              <!-- <div class="card-body text-center py-5">

            </div> -->
            </div>
          </div>
        </div>
      </card>

      <card>
        <div class="row">
          <div class="col-12">
            <div class="card" style="border: none">
              <ul class="list-group">
                <li
                  class="list-group-item m-2"
                  style="background-color: var(--primary); color: #ffffff"
                >
                  {{ $t("SITUATION REPORT") }}<i class="handle"></i>
                </li>
              </ul>
              <card>
                <!-- <table class="table">
                  <tbody>
                    <tr>
                      <td>{{ $t("Assigned group") }} :</td>
                      <td v-if="associatedstore.display_id == id">
                        <i
                          class="tim-icons icon-check-2 m-3"
                          style="color: green"
                        ></i>
                        <base-button
                          type="primary"
                          size="sm"
                          @click="groupdetail(associatedstore.group_id)"
                          >{{ $t("Group details") }}</base-button
                        >
                      </td>
                      <td scope="row" v-else>
                        <i
                          class="tim-icons icon-alert-circle-exc m-3"
                          style="color: red"
                        ></i>
                        <base-button
                          type="primary"
                          size="sm"
                          @click="addgroup()"
                        >
                          {{ $t("Create Group") }}</base-button
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr /> -->
                <table class="table">
                  <tbody>
                    <tr>
                      <td>{{ $t("Assigned templates and designs") }} :</td>
                      <td>
                        <i
                          class="tim-icons icon-alert-circle-exc m-3"
                          style="color: red"
                        ></i
                        ><base-button
                          type="primary"
                          size="sm"
                          @click="addtemplate()"
                          >{{
                            $t("Create a new Template and a new design")
                          }}</base-button
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr />
                <table class="table">
                  <tbody>
                    <tr>
                      <td>{{ $t("Details") }} :</td>
                    </tr>
                  </tbody>
                </table>
                <hr />
              </card>
            </div>
          </div>
        </div>
      </card>

      <card>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <ul class="list-group">
                <li
                  class="list-group-item m-2"
                  style="background-color: var(--primary); color: #ffffff"
                >
                  {{ $t("CONTROL PANEL") }}
                </li>
              </ul>
              <!-- <div class="card-body text-center py-5">

            </div> -->
            </div>
          </div>
        </div>
      </card>

      <card>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <ul class="list-group">
                <li
                  class="list-group-item m-2"
                  style="background-color: var(--primary); color: #ffffff"
                >
                  {{ $t("STATE") }}<i class="handle"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </card>
    </card>
  </div>
</template>

<script>
import { TimeSelect, Table, TableColumn } from "element-ui";
import { ref } from "vue";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";
import { TabPane, Tabs, Collapse, CollapseItem } from "src/components";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  mixins: [formMixin],

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [TimeSelect.name]: TimeSelect,
    TabPane,
    Tabs,
    Collapse,
    CollapseItem,
  },

  created() {
    this.getplaylist();
    this.getshutdown();
    this.getreboot();
  },

  data() {
    return {
      id: this.$route.params.id,
      tableData: [],
      associatedstore: [],
      playlist: {
        seconds: null,
      },
      time: {
        ora: null,
      },

      timereboot: {
        ora: null,
      },
    };
  },
  methods: {
    getdisplay() {
      const id = this.$route.params.id;
      axios.get(`${url}/displaygetiddata/${id}`).then((response) => {
        // console.log(response.data);
        this.tableData = response.data;
      });
    },

    getplaylist() {
      const id = this.$route.params.id;
      axios.get(`${url}/getplaylist/${id}`).then((response) => {
        // console.log(response.data);
        this.playlist = response.data;
      });
    },

    getshutdown() {
      const id = this.$route.params.id;
      axios.get(`${url}/getshutdown/${id}`).then((response) => {
        this.time = response.data[0];
      });
    },
    getreboot() {
      const id = this.$route.params.id;
      axios.get(`${url}/getrestart/${id}`).then((response) => {
        this.timereboot = response.data[0];
      });
    },
    getassociated() {
      const id = this.$route.params.id;
      axios.get(`${url}/getstoreassociateddisplay/${id}`).then((response) => {
        // console.log(response.data)
        this.associatedstore = response.data;
      });
    },
    async addrefresh(id) {
      id = this.$route.params.id;
      await axios
        .patch(`${url}/playlistupdate/${id}`, this.playlist)
        .then((response) => {
          this.$notify({
            type: "success",
            message: this.$t("Successfully."),
            icon: "tim-icons icon-bell-55",
          });
        });
    },
    async addshutdown(id) {
      id = this.$route.params.id;

      axios.patch(`${url}/addshutdown/${id}`, this.time).then((response) => {
        this.$notify({
          type: "success",
          message: this.$t("Successfully."),
          icon: "tim-icons icon-bell-55",
        });
      });
    },
    async addreboot(id) {
      id = this.$route.params.id;

      axios
        .patch(`${url}/addrestart/${id}`, this.timereboot)
        .then((response) => {
          this.$notify({
            type: "success",
            message: this.$t("Successfully."),
            icon: "tim-icons icon-bell-55",
          });
        });
    },

    groupdetail(id) {
      this.$router.push({ name: "Group Detail", params: { id } });
    },
    addgroup() {
      this.$notify({
        type: "danger",
        message: this.$t("You Cannot Do this Action"),
        icon: "tim-icons icon-bell-55",
      });
    },
    addtemplate() {
      this.$notify({
        type: "danger",
        message: this.$t("You Cannot Do this Action"),
        icon: "tim-icons icon-bell-55",
      });
    },
  },

  mounted() {
    this.getdisplay();
    this.getassociated();
  },
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content {
  font-size: inherit !important;
}
#headingOne {
  background-color: var(--primary) !important;
}

#headingOne a,
#headingOne a i {
  color: #ffffff !important;
}
</style>
