<template>
  <div class="row mt-4">
    <div class="col-12 pl-0">
      <div class="card mb-4 p-3">
        <div class="card-header pb-0">
          <h6>{{ $t("Dashboard") }}</h6>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
          <div class="table-responsive p-0">
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th
                    class="text-uppercase text-secondary custom-color text-xxs font-weight-bolder opacity-7"
                    style="padding-left: 2rem"
                  >
                    {{ $t("Display Name") }}
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary custom-color text-xxs font-weight-bolder opacity-7 ps-2"
                  >
                    {{ $t("Online") }}
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary custom-color text-xxs font-weight-bolder opacity-7 ps-2"
                  >
                    {{ $t("Last Seen") }}
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary custom-color text-xxs font-weight-bolder opacity-7 ps-2"
                  >
                    {{ $t("License") }}
                  </th>
                </tr>
              </thead>
              <tbody style="border-bottom: 0.0625rem solid #e3e3e3">
                <template>
                  <tr
                    v-for="(table, key) in adminDisplayChild"
                    :key="key"
                    v-if="key !== ''"
                  >
                    <td style="padding: 0%">
                      <div class="d-flex px-3 py-1 custom-pointer">
                        <div>
                          <template v-for="(image, i) in screenshotUrl">
                            <img
                              v-if="image.dataid === table.display_id"
                              :key="i"
                              class="avatar me-3"
                              :src="image.image"
                              alt="Image of Last Screenshot"
                              @error="handleImageError(i)"
                              @click="goToDetails(table.display_id)"
                            />
                          </template>
                        </div>
                        <div
                          class="d-flex flex-column justify-content-center"
                          @click="goToDetails(table.display_id)"
                        >
                          <h6 class="mb-0 text-sm">
                            {{ table.displayparamvalue }}
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td
                      @click="goToDetails(table.display_id)"
                      class="custom-pointer"
                    >
                      <p class="text-sm text-center font-weight-normal mb-0">
                        <span
                          class="status"
                          :class="table.status === 'offline' ? 'red' : 'green'"
                        ></span>
                      </p>
                    </td>
                    <td>
                      <p class="text-sm text-center font-weight-normal mb-0">
                        {{
                          table.last_seen === null
                            ? "-"
                            : getTime(table.last_seen)
                        }}
                      </p>
                    </td>
                    <td>
                      <p class="text-sm text-center font-weight-normal mb-0">
                        {{
                          table.codice_licenza === null
                            ? "-"
                            : table.codice_licenza
                        }}
                      </p>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <div v-if="adminDisplayChild.length === 0" class="not-found">
              <h4>{{ $t("No Data Found") }}</h4>
            </div>
          </div>
          <base-pagination
            class="pagination-no-border dash-pg pt-2"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="adminDisplayList.total"
          >
          </base-pagination>
        </div>
      </div>
    </div>
    <zends-loader v-if="isLoading" />
  </div>
</template>

<script>
import ZendsLoader from "../Components/ZendsLoader.vue";
import { BasePagination } from "src/components";
import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;
const imageUrl = process.env.VUE_APP_CLEAN_URL;

export default {
  components: {
    BasePagination,
    ZendsLoader,
  },
  data() {
    return {
      isLoading: true,
      adminDisplayList: [],
      adminDisplayChild: [],
      screenshotUrl: [],
      userTypeId: "",
      pagination: {
        perPage: 10,
        currentPage: 1,
      },
      currentSort: 0,
      currentSortDir: "asc",
    };
  },
  mounted() {
    this.userTypeId = localStorage.getItem("role_id");
    this.getdisplayAdminList();
  },
  methods: {
    handleImageError(index) {
      // This method is called when an image loading fails
      const id = this.screenshotUrl[index].dataid; // Get the 'dataid' from the original object
      const imageUrlData = "/img/placeholder.jpg"; // Replace with the path to your static image

      // Create a fallback object with the static image URL and 'dataid'
      const fallbackImageObject = { image: imageUrlData, dataid: id };

      // Set the fallback object in the 'screenshotUrl' array at the specified index
      this.$set(this.screenshotUrl, index, fallbackImageObject);
    },
    getTime(time) {
      const date = new Date(time * 1000);

      // Format the date and time as a string
      const formattedDate = date.toLocaleString();
      return formattedDate;
    },
    async getdisplayAdminList() {
      this.screenshotUrl = [];
      let params = {
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.query ? { searchValue: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };
      if (Number(this.userTypeId) === 1) {
        await this.$store.dispatch("displays/add", params);
      } else if (Number(this.userTypeId) === 2) {
        await this.$store.dispatch("displays/get", params);
      }
      this.adminDisplayList = await this.$store.getters[
        "displays/display_list"
      ];
      const datas = this.adminDisplayList.data[0] === undefined ? [] :  this.adminDisplayList.data.map((dat) => dat[0]);
      datas.sort((a, b) => {
        // Compare the "status" property of objects a and b
        if (a.status === "online" && b.status !== "online") {
          return -1; // 'active' comes before others
        } else {
          return 0;
        }
      });
      const screenshotPromises = datas.map((newdata) =>
        this.getScreenshot(newdata.display_id)
      );

      // Wait for all screenshot requests to finish
      await Promise.all(screenshotPromises);

      this.adminDisplayChild = datas;
      if (this.adminDisplayChild && this.adminDisplayChild.length !== 0) {
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    },
    async getScreenshot(id) {
      try {
        const response = await axios.get(`${url}/getscreenshoot/${id}`);
        const screenname = response.data;
        const imageUrlData = imageUrl + `upload/screen/${id}/${screenname}.jpg`;

        const demo = { image: imageUrlData, dataid: id };
        this.screenshotUrl.push(demo);
      } catch (error) {
        console.error("Error fetching screenshot:", error);
      }
    },
    goToDetails(id) {
      if (Number(this.userTypeId) === 1) {
        this.$router.push({ name: "Detail Display Admin", params: { id } });
      } else if (Number(this.userTypeId) === 2) {
        this.$router.push({ name: "Detail Display", params: { id } });
      }
    },
  },
  watch: {
    "pagination.currentPage": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getdisplayAdminList();
          this.isLoading = true;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.card .card-header {
  padding: 1.5rem;
  background-color: #fff !important;
}

.custom-color {
  color: black !important;
}

h6 {
  font-size: 1rem;
  line-height: 1.625;
  font-weight: 600;
}

.avatar {
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border-radius: 50rem;
  height: 40px;
  width: 40px;
  transition: all 0.2s ease-in-out;
  margin-right: 1.5rem;
  margin-bottom: 0px;
}

.status {
  cursor: default;
  display: list-item;
  list-style: none;
  margin: auto;
  position: relative;
  /* border: 1px solid black; */
  border-radius: 30px;
  width: 20px;
  height: 20px;
  color: #f7f7f7;
  max-width: 200px;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}
.dash-pg {
  float: right;
}
.not-found {
  display: flex;
  justify-content: center;
  margin: 8px;
}
.custom-pointer {
  cursor: pointer;
}
</style>
