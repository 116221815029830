<template>
  <div class="mt-3">
    <h4 slot="header" class="card-title">{{ $t("NEW TEMPLATE CREATION") }}</h4>
    <card class="stacked-form">
      <card class="stacked-form">
        <!-- <span v-if="errors && errors">
        <small class="error" v-text="errors" />
      </span> -->
        <form @submit.prevent>
          <div
            class="list-item"
            v-for="item in myList"
            :id="item.id"
            :order="item.attributes.template_order"
            :key="item.id"
            v-if="
              item.attributes.template_order != 0 &&
              item.attributes.template_order != null
            "
          >
            <base-input
              type="text"
              v-model="group[item.attributes.name + '_' + item.id]"
              :label="capital_letter(item.attributes.name)"
              :placeholder="capital_letter(item.attributes.name)"
              required
            />
          </div>
        </form>
      </card>

      <card class="stacked-form rounded-card" title="Enter Non-Activity Timer">
        <base-input
          :label="$t('Non-activity timer (seconds):')"
          class="col-md-2 offset-md-4"
          v-model="template.timer"
        />
        <span v-if="errors && errors.timer">
          <small class="error col-md-4 offset-md-4" v-text="errors.timer" />
        </span>
      </card>

      <div class="col-md-12 text-center">
        <a
          href="javascript:void(0)"
          id="setscheduler"
          @click="openmodal = true"
          >{{ $t("Set Scheduler") }}</a
        >
      </div>
      <modal
        :show.sync="openmodal"
        class="modal-search"
        id="setscheduler"
        :centered="false"
        :show-close="true"
        :modalContentClasses="widthtemp"
      >
        <div class="row table-full-width">
          <h3>{{ $t("Scheduling Settings") }}</h3>

          <div class="col-md-12">
            <el-table :data="secondTableData">
              <el-table-column width="400" label="Typology" align="center">
                <div class="" style="text-align: justify" slot-scope="{ row }">
                  <div>
                    <!-- <base-radio  v-model="radios.display" name="1" inline>Display</base-radio> -->
                    <input
                      value="1"
                      type="radio"
                      id="sf1"
                      name="scheduler_form"
                      v-model="schdular.Typologychecked"
                      @change="checkboxhide()"
                    />
                    <label for="sf1">{{ $t("Always run") }}</label>
                  </div>
                  <div>
                    <input
                      value="2"
                      id="sf2"
                      type="radio"
                      name="scheduler_form"
                      v-model="schdular.Typologychecked"
                      @change="checkboxselect()"
                    />
                    <label for="sf2">{{ $t("Run if checked") }}</label
                    ><br />
                  </div>
                  <div>
                    <input
                      value="3"
                      id="sf3"
                      type="radio"
                      name="scheduler_form"
                      v-model="schdular.Typologychecked"
                      @change="checkboxselect()"
                    />
                    <label for="sf3">{{
                      $t("Do not execute if selected")
                    }}</label
                    ><br />
                  </div>
                  <div class="row">
                    <div class="col-md-7">
                      <el-date-picker
                        type="date"
                        :placeholder="$t('Date Picker')"
                        v-model="schdular.datePickerStart"
                        :disabled="row.ckdisabled"
                      >
                      </el-date-picker>
                    </div>
                    <div class="col-md-5">
                      <el-time-select
                        :disabled="row.ckdisabled"
                        v-model="schdular.timePickerStart"
                        :picker-options="{
                          start: '00:00',
                          step: '00:15',
                          end: '23:59',
                        }"
                        :placeholder="$t('Select time')"
                      >
                      </el-time-select>
                    </div>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col-md-7">
                      <el-date-picker
                        type="date"
                        :disabled="row.ckdisabled"
                        :placeholder="$t('Date Picker')"
                        v-model="schdular.datePickerEnd"
                      >
                      </el-date-picker>
                    </div>
                    <div class="col-md-5">
                      <el-time-select
                        :disabled="row.ckdisabled"
                        v-model="schdular.timePickerEnd"
                        :picker-options="{
                          start: '00:00',
                          step: '00:15',
                          end: '23:59',
                        }"
                        :placeholder="$t('Select time')"
                      >
                      </el-time-select>
                    </div>
                  </div>
                </div>
              </el-table-column>

              <el-table-column width="100" label="Lun" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Lunchecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>

              <el-table-column width="100" label="Mar" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Marchecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>

              <el-table-column width="100" label="Mer" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Merchecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>

              <el-table-column width="100" label="Gio" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Giochecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>

              <el-table-column width="100" label="Ven" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Venchecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>
              <el-table-column width="100" label="Sab" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Sabchecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>
              <el-table-column width="100" label="Dom" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Domchecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>
            </el-table>
          </div>

          <div class="text-right col-md-12">
            <base-button @click="closem()" class="" type="primary">{{
              $t("Close")
            }}</base-button>
            <base-button
              class=""
              native-type="submit"
              type="primary"
              @click="addschdular(), openmodal"
            >
              {{ $t("Confirmation") }}</base-button
            >
          </div>
        </div>
      </modal>

      <br />
      <hr />
      <hr />
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("Preview") }}</th>
            <th>{{ $t("Name") }}</th>
            <th>{{ $t("Information") }}</th>
            <th>{{ $t("Orientation") }}</th>
            <th>{{ $t("Select") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="d in design" :key="d.id">
            <td scope="row">
              <img
                :src="d.filename"
                alt="preview"
                height="auto"
                width="160px"
                style="border: 1px solid"
              />
            </td>
            <td>{{ d.title }}</td>
            <td>{{ d.info }}</td>
            <td v-if="d.orientation == 0">{{ $t("Horizontal") }}</td>
            <td v-if="d.orientation == 1">{{ $t("Vertical") }}</td>
            <td>
              <input
                type="radio"
                name="list"
                id="list"
                v-model="radio"
                :value="d.id"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <hr />
      <div class="col-md-12 text-center">
        <base-button @click="add()" native-type="submit" type="primary">{{
          $t("Submit")
        }}</base-button>
      </div>
    </card>
  </div>
</template>

<script>
import { Table, TableColumn, DatePicker, TimeSelect } from "element-ui";
import { BaseCheckbox, BaseRadio } from "src/components";
import axios from "axios";

import { Modal } from "@/components";

const url = process.env.VUE_APP_API_BASE_URL;

function sort(valuePath, array) {
  let path = valuePath.split(".");
  return array.sort((a, b) => {
    return getValue(a, path) - getValue(b, path);
  });

  function getValue(obj, path) {
    path.forEach((path) => (obj = obj[path]));
    return obj;
  }
}

export default {
  components: {
    BaseRadio,
    Modal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    BaseCheckbox,
  },
  data: () => ({
    template: {
      type: "template",
      name: null,
      description: null,
      timer: String,
      idTempScheduler: null,
      schedulerform: "1",
    },
    group: {
      type: "template",
    },

    radio: "",

    design: {},

    myList: [],
    errors: null,
    isValid: true,
    searchModalVisible: true,
    openmodal: false,
    widthtemp: "widthtemp-100",
    secondTableData: [
      {
        Typologychecked: "1",
        Lunchecked: false,
        Marchecked: false,
        Merchecked: false,
        Giochecked: false,
        Venchecked: false,
        Sabchecked: false,
        Domchecked: false,
        ckdisabled: true,
      },
    ],
    schdular: {
      Typologychecked: 1,
      datePickerEnd: "",
      datePickerStart: "",
      timePickerStart: "",
      timePickerEnd: "",
      Lunchecked: 0,
      Marchecked: 0,
      Merchecked: 0,
      Giochecked: 0,
      Venchecked: 0,
      Sabchecked: 0,
      Domchecked: 0,
      contid: "",
    },
    errors: {
      default: () => [],
    },
    data: {},
  }),
  beforeMount() {
    this.template.timer = "0";
  },
  methods: {
    capital_letter(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },

    checkboxselect() {
      this.secondTableData[0].ckdisabled = false;
    },
    checkboxhide() {
      this.secondTableData[0].ckdisabled = true;
      this.secondTableData[0].Lunchecked = false;
      this.secondTableData[0].Marchecked = false;
      this.secondTableData[0].Merchecked = false;
      this.secondTableData[0].Giochecked = false;
      this.secondTableData[0].Venchecked = false;
      this.secondTableData[0].Sabchecked = false;
      this.secondTableData[0].Domchecked = false;
    },

    getgroup() {
      axios.get(`${url}/categories`).then((response) => {
        this.myList = sort("attributes.template_order", response.data.data);
      });
    },

    getdesign() {
      axios.get(`${url}/getdesign`).then((response) => {
        this.design = response.data;
      });
    },

    add() {
      var tmp = {};
      if (this.radio == "" || !this.group.name_3 || !this.group.Description_1) {
        this.$notify({
          type: "danger",
          message: this.$t("Please Fill All Required Parameter!"),
          icon: "tim-icons icon-bell-55",
        });
      } else {
        tmp["templatedata"] = this.group;
        tmp["designdata"] = this.radio;
        tmp["timer"] = this.template.timer;

        try {
          axios.post(`${url}/groupadd`, tmp).then((response) => {
            this.$notify({
              type: "success",
              message: this.$t("Template added successfully."),
              icon: "tim-icons icon-bell-55",
            });
            this.goBack();
          });
        } catch (e) {
          this.$notify({
            type: "danger",
            message: this.$t("Oops, something went wrong!"),
            icon: "tim-icons icon-bell-55",
          });
          this.setApiValidation(e.response.data.errors);
        }
      }
    },

    goBack() {
      this.$router.push({ name: "List Template" });
    },

    closem() {
      this.openmodal = false;
      this.schdular = [];
      this.schdular.Typologychecked = 2;
    },
  },

  mounted() {
    this.getgroup();
    this.getdesign();
  },
};
</script>

<style>
.card {
  border: 0.0625rem solid #e3e3e3;
}

.card-header {
  background-color: #e3e3e3 !important;
}

table {
  width: 100% !important;
}

.modal-search .modal-dialog {
  margin: 23px auto;
  max-width: 65% !important;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 2px 0px var(--primary) inset;
  margin-right: 3px;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 35%;
  height: 31%;
  margin: 31% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: var(--primary);
}

.pickup > .mb-0 > input {
  border-radius: 25px;
  border: 1px solid #dddddd;
  background-color: #f9f9f9;
}
</style>
<style>
.widthtemp-100 {
  height: auto;
  width: 100%;
}
</style>
