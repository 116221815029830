<template>
  <div class="content">
    <div class="row mt-3">
      <div class="col-12">
        <h4 slot="header" class="card-title">{{ $t("WEBCAM STATISTICS") }}</h4>
        <card card-body-classes="table-full-width">
          <div class="row">
            <div class="col-md-4">
              <card>
                <h4 slot="header" class="card-title">{{ $t("From") }}</h4>
                <base-input>
                  <el-date-picker
                    type="date"
                    :placeholder="$t('Date Picker')"
                    v-model="datePickerfrom"
                  >
                  </el-date-picker>
                </base-input>
              </card>
            </div>
            <div class="col-md-4">
              <card>
                <h4 slot="header" class="card-title">{{ $t("To") }}</h4>
                <base-input>
                  <el-date-picker
                    type="date"
                    :placeholder="$t('Date Picker')"
                    v-model="datePickerto"
                  >
                  </el-date-picker>
                </base-input>
              </card>
            </div>
          </div>

          <base-button class="mt-3" native-type="refresh" type="primary">{{
            $t("Refresh")
          }}</base-button>

          <hr />
          <hr />

          <form>
            <el-table
              border="2px"
              :data="queriedData"
              v-for="column in tableColumns"
              :key="column.label"
            >
              <template>
                <el-table-column :key="column.label1" :label="column.label1">
                </el-table-column>
              </template>

              <template>
                <el-table-column :key="column.label2" :label="column.label2">
                </el-table-column>
              </template>

              <template>
                <el-table-column :key="column.label3" :label="column.label3">
                </el-table-column>
              </template>

              <template>
                <el-table-column :key="column.label4" :label="column.label4">
                </el-table-column>
              </template>

              <template>
                <el-table-column :key="column.label5" :label="column.label5">
                </el-table-column>
              </template>

              <template>
                <el-table-column :key="column.label6" :label="column.label6">
                </el-table-column>
              </template>

              <template>
                <el-table-column :key="column.label7" :label="column.label7">
                </el-table-column>
              </template>

              <template>
                <el-table-column :key="column.label8" :label="column.label8">
                </el-table-column>
              </template>

              <template>
                <el-table-column :key="column.label9" :label="column.label9">
                </el-table-column>
              </template>

              <template>
                <el-table-column :key="column.label10" :label="column.label10">
                </el-table-column>
              </template>

              <template>
                <el-table-column :key="column.label11" :label="column.label11">
                </el-table-column>
              </template>
            </el-table>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { DatePicker } from "element-ui";
import { Table, TableColumn, Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";

export default {
  mixins: [formMixin],

  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  data() {
    return {
      datePickerfrom: new Date(),
      datePickerto: new Date(),

      tableColumns: [
        {
          //   prop: "attributes.firstname",
          //   propadd: "attributes.address",
          label1: "Preview",
          label2: "Guy",
          label3: "Total Views",
          label4: "Total Clicks",
          label5: "Total Duration",
          label6: "Avarage Duration",
          label7: "Males",
          label8: "Females",
          label9: "Avarage Age of Males",
          label10: "Avarage Age of Females",
          label11: "Details Chart",
        },
      ],
      tableData: [],
    };
  },
};
</script>
