<template>
  <div class="content">
    <div class="row mt-3">
      <div class="col-12">
        <h4 slot="header" class="card-title">{{ $t("USER LIST") }}</h4>
        <card card-body-classes="table-full-width">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('Search records')"
                  v-model="query"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <el-table :data="table" @sort-change="sortChange">
              <el-table-column
                :label="$t('Name')"
                prop="name"
                sortable="custom"
              />
              <el-table-column
                :label="$t('Email')"
                prop="email"
                sortable="custom"
              />
              <el-table-column
                :label="$t('Role')"
                prop="roles.name"
                sortable="custom"
              >
                <template v-slot="{ row }">
                  <span>{{ row.roles[0].name }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('Created At')"
                prop="created_at"
                sortable="custom"
              />
              <el-table-column :min-width="135" align="left" label="Actions">
                <div slot-scope="{ row }">
                  <base-button
                    @click="goToEdit(row.id)"
                    class="edit btn-link"
                    type="warning"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                  <base-button
                    @click="destroy(row.id)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                  <base-button
                    v-if="
                      row.name !== 'Administrator' &&
                      row.active == 1 &&
                      row.name !== 'Manager'
                    "
                    @click="disable(row.id)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="fa fa-ban"></i>
                  </base-button>
                  <base-button
                    v-if="
                      row.name !== 'Administrator' &&
                      row.active == 0 &&
                      row.name !== 'Manager'
                    "
                    @click="enable(row.id)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-check-2"></i>
                  </base-button>
                  <base-button
                    v-if="row.name !== 'Administrator'"
                    @click="sendMailLink(row.email)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-key-25"></i>
                  </base-button>
                  <base-button
                    v-if="row.name !== 'Administrator'"
                    @click="loggedin(row.id)"
                    class="remove btn-link"
                    type="admin"
                    size="sm"
                    icon
                  >
                    <i class="fa fa-sign-in-alt"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
                {{ $t("of") }} {{ total }} {{ $t("entries") }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
import ValidationError from "@/components/ValidationError.vue";

import swal from "sweetalert2";
import axios from "axios";
const url = process.env.VUE_APP_API_BASE_URL;
var obj = {};
var data = {};
export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    ValidationError,
  },

  data: () => ({
    table: [],
    footerTable: ["Name", "Created At", "Actions"],
    loading: true,

    query: null,

    sort: "name",

    pagination: {
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
    },

    total: 0,
  }),

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }

      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      let params = {
        include: "roles",
        ...(this.sort
          ? {
              sort: this.sort,
            }
          : {}),
        filter: {
          ...(this.query
            ? {
                name: this.query,
              }
            : {}),
          ...(this.query
            ? {
                email: this.query,
              }
            : {}),
          ...(this.query
            ? {
                roles: this.query,
              }
            : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };

      try {
        // console.log(params);
        await this.$store.dispatch("users/list", params);
        this.table = this.$store.getters["users/list"];
        this.total = this.$store.getters["users/listTotal"];
        this.loading = false;
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
      }
    },

    async destroy(id) {
      if (this.$isDemo == 1 && ["1", "2", "3"].includes(id)) {
        this.$notify({
          type: "danger",
          message: this.$t(
            "You are not allowed to change data of default users."
          ),
          icon: "tim-icons icon-bell-55",
        });
        return;
      }
      try {
        const confirmation = await swal.fire({
          title: this.$t("Are you sure?"),
          text: this.$t("You won't be able to revert this!"),
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: this.$t("Yes, delete it!"),
          buttonsStyling: false,
        });

        if (confirmation.value === true) {
          await this.$store.dispatch("users/destroy", id);
          this.$notify({
            type: "success",
            message: this.$t("User deleted successfully."),
            icon: "tim-icons icon-bell-55",
          });
          await this.getList();
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: error.response.data.errors[0].title,
            icon: "tim-icons icon-bell-55",
          });
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("Oops, something went wrong!"),
            icon: "tim-icons icon-bell-55",
          });
        }
      }
    },

    async disable(id) {
      id = {
        id: id,
      };
      axios.post(`${url}/disable`, id).then((response) => {});
      await this.getList();
    },
    async sendMailLink(email) {
      obj["type"] = "password-forgot";
      obj["attributes"] = {
        email: email,
        redirect_url: process.env.VUE_APP_BASE_URL + "/password/email",
      };
      data = {
        data: obj,
      };
      axios.post(`${url}/password-forgot`, data).then((response) => {
        this.$notify({
          type: "success",
          message: this.$t(
            "check your email. we've sent forgot password link."
          ),
          icon: "tim-icons icon-bell-55",
        });
      });
      await this.getList();
    },

    async enable(id) {
      id = {
        id: id,
      };
      axios.post(`${url}/enable`, id).then((response) => {});
      await this.getList();
    },
    async loggedin(id) {
      var login_id = {
        id: id,
      };

      try {
        await this.$store.dispatch("newlogin", login_id);
      } catch (e) {
        var str = e.message;
        var msg = "";
        if (str.indexOf("422") == -1) {
          msg = "Invalid credentials!";
        } else {
          msg = "User Not verified!";
        }
        this.$notify({
          message: msg,
          icon: "tim-icons icon-bell-55",
          type: "danger",
        });
        this.setApiValidation(e.response.data.errors);
      }
    },

    goToAdd() {
      this.$router.push({
        name: "Add User",
      });
    },

    goToEdit(id) {
      this.$router.push({
        name: "Edit User",
        params: {
          id,
        },
      });
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>

<style>
.cell,
table > tbody > tr > td {
  font-size: 1rem !important;
}

.el-table table {
  margin-bottom: 0;
}
.el-table__cell {
  padding: 8px !important;
}

.white-content .el-table table > tbody > tr > td {
  border-bottom: 0.0625rem solid #e3e3e3 !important;
  border-color: #fff;
}
</style>
