<template>
  <tr v-if="this.data && data.length === 0">
    <td :colspan="column" class="text-center">
      <h4 style="padding-top: 4rem; width: 100%; color: gray">
        <b>Couldn't find any data.</b>
      </h4>
    </td>
  </tr>
</template>

<script>
export default {
  name: "placeholder-table",
  props: {
    data: Array,
    column: Number,
  },
};
</script>