<template>
  <div class="mt-3">
    <h4>{{ $t("GROUP ASSIGNMENT TO USERS (USER CHOICE)") }}</h4>
    <card class="stacked-form">
      <div class="mt-3 mb-5">
        {{ $t("SELECTED GROUP") }} :
        <h4>
          <span v-for="table in tableData" :key="table.id"
            >{{ table.storeparamvalue }} |
          </span>
        </h4>
        <div class="col-12 text-right m-3">
          <base-button
            @click="goBack"
            type="button"
            class="btn btn-sm btn-primary"
            >{{ $t("Back to list") }}</base-button
          >
        </div>
      </div>

      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <el-select
          class="select-primary mb-3 pagination-select"
          v-model="postsPerPage"
          :placeholder="$t('Per page')"
        >
          <el-option
            class="select-primary"
            v-for="item in perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <base-input>
          <el-input
            type="search"
            class="mb-3 search-input"
            clearable
            prefix-icon="el-icon-search"
            :placeholder="$t('Search records')"
            v-model="search"
            aria-controls="datatables"
          >
          </el-input>
        </base-input>
      </div>
      <form @submit.prevent>
        <table class="table">
          <thead>
            <tr>
              <th @click="sort('username')" class="table-header">
                {{ $t("Name") }}
                <SortIcon
                  :topColor="computedTopColor('username', 'top')"
                  :bottomColor="computedTopColor('username', 'bottom')"
                />
              </th>
              <th @click="sort('useremail')" class="table-header">
                {{ $t("Email") }}
                <SortIcon
                  :topColor="computedTopColor('useremail', 'top')"
                  :bottomColor="computedTopColor('useremail', 'bottom')"
                />
              </th>
              <th>{{ $t("Request Supervision") }}</th>
              <th>{{ $t("action") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in filteredAndSortedBlogs" :key="user.id">
              <td>{{ user.username }}</td>
              <td>{{ user.useremail }}</td>
              <td v-if="user.associate == 1">
                <p v-if="user.role_id == 2">
                  <input type="checkbox" v-model="checkedNames" />
                </p>
                <p v-else>{{ $t("User Not Manager") }}</p>
              </td>
              <td v-else>
                <p>{{ $t("Not Associated") }}</p>
              </td>
              <td>
                <base-button
                  @click="destroy(user.associateid)"
                  native-type="submit"
                  type="primary"
                  v-if="user.associate == 1"
                  >{{ $t("Delete association") }}</base-button
                >
                <base-button
                  @click="add(user.role_id, user.userid)"
                  native-type="submit"
                  type="primary"
                  v-else
                >
                  {{ $t("Associate") }}</base-button
                >
              </td>
            </tr>
          </tbody>
        </table>
      </form>

      <div
        slot="footer"
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div class="">
          <p class="card-category">
            {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
            {{ $t("of") }} {{ total }} {{ $t("entries") }}
          </p>
        </div>
        <base-pagination
          class="pagination-no-border"
          v-model="currentPage"
          :per-page="postsPerPage"
          :total="this.users.length"
        >
        </base-pagination>
      </div>
    </card>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
import Fuse from "fuse.js";
import ValidationError from "src/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import swal from "sweetalert2";
import axios from "axios";
import SortIcon from "@/assets/customImg/SortIcon.vue";
import { getTopColor, getBottomColor } from "../../util/utils";
const url = process.env.VUE_APP_API_BASE_URL;

export default {
  components: {
    SortIcon,
    ValidationError,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  mixins: [formMixin],

  computed: {
    filteredAndSortedBlogs() {
      return this.users
        .filter((user) =>
          user.username.toLowerCase().match(this.search.toLowerCase())
        )
        .sort((a, b) => {
          let modifier = 1;
          if (this.currentSortDir === "desc") modifier = -1;
          if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
        })
        .slice(
          (this.currentPage - 1) * this.postsPerPage,
          this.currentPage * this.postsPerPage
        );
    },
    from() {
      return this.postsPerPage * (this.currentPage - 1);
    },
    to() {
      let highBound = this.from + this.postsPerPage;

      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    total() {
      return this.users.length;
    },
  },

  data() {
    const id = this.$route.params.id;

    return {
      checkedNames: [],

      currentSort: "username",
      currentSortDir: "asc",

      currentPage: 1,
      postsPerPage: 5,

      perPageOptions: [5, 10, 25, 50],

      search: "",

      searchQuery: "",
      propsToSearch: ["attributes.firstname"],
      tableColumns: [
        {
          prop: "attributes.firstname",
          propadd: "attributes.address",
          propstatus: "attributes.associate",
          proptest: 1,
          label1: "First Name",
          label2: "Address",
          label3: "Associate",
          minWidth: 100,
        },
      ],
      tableData: [],
      table: [],
      searchedData: [],
      fuseSearch: null,

      associate: {
        type: "associates",
        group_id: parseInt(id),
        user_id: "",
        associate: "",
      },

      users: [],
      group: [],
      id,
    };
  },

  methods: {
    computedTopColor(table, arrowIcon) {
      const sortparam = {
        field: this.currentSort,
        sort: this.currentSortDir,
      };
      if (arrowIcon === "top") {
        return getTopColor(table, sortparam);
      } else {
        return getBottomColor(table, sortparam);
      }
    },
    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    setCurrentPage(direction) {
      if (direction === -1 && this.currentPage > 1) {
        this.currentPage -= 1;
      } else if (
        direction === 1 &&
        this.currentPage < this.users.length / this.postsPerPage
      ) {
        this.currentPage += 1;
      }
    },

    getuser() {
      const id = this.$route.params.id;
      axios.get(`${url}/getuserassociate/${id}`).then((response) => {
        // console.log(response.data);
        this.users = response.data;
      });
    },

    getgroup() {
      const id = this.$route.params.id;
      axios.get(`${url}/getidvalue/${id}`).then((response) => {
        // console.log(response.data);
        this.tableData = response.data;
      });
    },

    async add(id, userid) {
      try {
        this.associate.role_id = id;
        this.associate.user_id = userid;
        this.associate.associate = 1;
        await this.$store.dispatch("associates/add", this.associate);
        this.$notify({
          type: "success",
          message: this.$t("Assciated  successfully."),
          icon: "tim-icons icon-bell-55",
        });
        this.associate = await this.$store.getters["associates/associate"];
        this.goBack();
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
        this.setApiValidation(e.response.data.errors);
      }
    },

    goBack() {
      this.$router.push({ name: "Assign Group" });
    },

    async destroy(id) {
      try {
        const confirmation = await swal.fire({
          title: this.$t("Are you sure?"),
          text: this.$t("You won't be able to revert this!"),
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: this.$t("Yes, delete it!"),
          buttonsStyling: false,
        });
        if (confirmation.value === true) {
          await this.$store.dispatch("associates/destroy", id);
          this.$notify({
            type: "success",
            message: this.$t("Associate deleted successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.goBack();
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: this.$t("This Group still has associated Users."),
            icon: "tim-icons icon-bell-55",
          });
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("Oops, something went wrong!"),
            icon: "tim-icons icon-bell-55",
          });
        }
      }
    },
  },
  mounted() {
    (this.fuseSearch = new Fuse(this.tableData, {
      keys: ["attributes.firstname"],
      threshold: 0.3,
    })),
      this.getuser();
    this.getgroup();
  },

  watch: {
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.search = result;
    },
  },
};
</script>

<style>
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}
</style>
