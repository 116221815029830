<template>
  <div class="content">
    <div class="row mt-3">
      <div class="col-12">
        <h4 slot="header" class="card-title">{{ $t("DISPLAY LIST") }}</h4>
        <card card-body-classes="table-full-width">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('Search records')"
                  v-model="query"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <div id="app" v-if="!tableData.data['']">
              <table class="table">
                <thead>
                  <tr>
                    <th
                      @click="sort(table, key)"
                      v-for="(table, key) in tableData.title"
                      :key="key"
                      class="table-header"
                    >
                      <span>
                        {{ $t(table)
                        }}<SortIcon
                          :topColor="computedTopColor(table, 'top')"
                          :bottomColor="computedTopColor(table, 'bottom')"
                        />
                      </span>
                    </th>
                    <th @click="sort('status', 0)" class="table-header">
                      <span>
                        {{ $t("Status")
                        }}<SortIcon
                          :topColor="computedTopColor('status', 'top')"
                          :bottomColor="computedTopColor('status', 'bottom')"
                        />
                      </span>
                    </th>
                    <th>{{ $t("action") }}</th>
                    <th>{{ $t("Software Version") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(table, key) in filteredAndSortedBlogs"
                    :key="table.id"
                    v-if="key !== ''"
                  >
                    <td v-for="t in table" :key="t.id">
                      {{ t.displayparamvalue }}
                    </td>
                    <td class="status-td">
                      <span
                        class="status-span"
                        :data-title="table[0].time"
                        :class="table[0].status === 'offline' ? 'red' : 'green'"
                      >
                      </span>
                    </td>
                    <td v-if="table[0].did">
                      <button
                        @click="goToEdit(table[0].display_id)"
                        title="Edit"
                        class="'edit btn-link"
                        type="success"
                        style="color: var(--primary)"
                      >
                        <i class="tim-icons icon-pencil"></i>
                      </button>
                      <button
                        @click="goToDetails(table[0].display_id)"
                        title="Detail"
                        class="'details btn-link"
                        type="success"
                        style="color: var(--primary)"
                      >
                        <i class="tim-icons icon-bag-16"></i>
                      </button>
                    </td>

                    <td v-else>
                      <button
                        @click="goToEdit(table[0].display_id)"
                        title="Edit"
                        class="'edit btn-link"
                        type="success"
                        style="color: var(--primary)"
                      >
                        <i class="tim-icons icon-pencil"></i>
                      </button>
                      <button
                        @click="goToDetails(table[0].display_id)"
                        title="Detail"
                        class="'details btn-link"
                        type="success"
                        style="color: var(--primary)"
                      >
                        <i class="tim-icons icon-bag-16"></i>
                      </button>
                      <button
                        @click="destroy(table[0].display_id)"
                        title="Delete"
                        class="'delete btn-link"
                        type="success"
                        style="color: var(--primary)"
                      >
                        <i class="tim-icons icon-simple-remove"></i>
                      </button>
                    </td>

                    <td>3.0.0.0</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-else>
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ $t("firstname") }}</th>
                    <th>{{ $t("BUSINESS NAME") }}</th>
                    <th
                      @click="sort('firstname')"
                      v-for="(table, key) in tableData.title"
                      :key="key"
                    >
                      {{ $t(table) }}
                    </th>
                    <th>{{ $t("action") }}</th>
                    <th>{{ $t("Software Version") }}</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
                {{ $t("of") }} {{ total }} {{ $t("entries") }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";

import _ from "lodash";
import { BasePagination } from "src/components";
// import users from "./users";
import Fuse from "fuse.js";
import swal from "sweetalert2";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "src/components/ValidationError.vue";
import SortIcon from "@/assets/customImg/SortIcon.vue";
import { getTopColor, getBottomColor } from "../../util/utils";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  mixins: [formMixin],

  components: {
    SortIcon,
    ValidationError,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */

    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.tableData.total < highBound) {
        highBound = this.total;
      }

      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.total;
    },
    filteredAndSortedBlogs() {
      let keyword = "displayparamvalue";
      return [...this.tableData.data].sort((a, b) => {
        if (this.sortparam.field === "status") {
          keyword = "status";
        }
        let modifier = this.currentSortDir === "desc" ? -1 : 1;
        const aValue = a[this.currentSort][keyword].toLowerCase();
        const bValue = b[this.currentSort][keyword].toLowerCase();
        if (aValue < bValue) return -1 * modifier;
        if (aValue > bValue) return 1 * modifier;
        return 0;
      });
    },
  },

  data() {
    return {
      // currentSort:'firstname',
      // currentSortDir:'asc',

      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
      },

      query: "",
      propsToSearch: ["firstname"],
      tableData: [],
      group: [],
      licenses: [],
      searchedData: [],
      fuseSearch: null,
      currentSort: 0,
      currentSortDir: "asc",
      sortparam: {
        field: "name",
        sort: "asc",
      },
    };
  },
  methods: {
    computedTopColor(table, arrowIcon) {
      if (arrowIcon === "top") {
        return getTopColor(table, this.sortparam);
      } else {
        return getBottomColor(table, this.sortparam);
      }
    },
    getListDebounced: _.debounce(function () {
      this.getdisplay();
    }, 300),

    sort: function (data, s) {
      this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      this.currentSort = s;
      this.sortparam.field = data;
      this.sortparam.sort = this.sortparam.sort == "asc" ? "desc" : "asc";
    },

    setCurrentPage(direction) {
      if (direction === -1 && this.pagination.currentPage > 1) {
        this.pagination.currentPage -= 1;
      } else if (
        direction === 1 &&
        this.pagination.currentPage <
          this.tableData.data.length / this.pagination.perPage
      ) {
        this.pagination.currentPage += 1;
      }
    },

    // getdisplay() {
    //   axios
    //     .get(`${url}/displaygetdata`)
    //     .then((response) => {
    //       this.tableData = response.data;

    //     });
    // },

    async getdisplay() {
      let params = {
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.query ? { searchValue: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };
      await this.$store.dispatch("displays/add", params);
      this.tableData = await this.$store.getters["displays/display_list"];
    },

    async destroy(id) {
      try {
        const confirmation = await swal.fire({
          title: this.$t("Are you sure?"),
          text: this.$t("You won't be able to revert this!"),
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: this.$t("Yes, delete it!"),
          buttonsStyling: false,
        });

        if (confirmation.value === true) {
          await this.$store.dispatch("displays/destroy", id);
          this.$notify({
            type: "success",
            message: this.$t("Display deleted successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.getdisplay();
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: this.$t("This Display still has associated Users."),
            icon: "tim-icons icon-bell-55",
          });
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("Oops, something went wrong!"),
            icon: "tim-icons icon-bell-55",
          });
        }
      }
    },

    goToEdit(id) {
      this.$router.push({ name: "Edit Display", params: { id } });
    },

    goToDetails(id) {
      this.$router.push({ name: "Detail Display Admin", params: { id } });
    },

    addLicense(id) {
      this.$router.push({ name: "Add License", params: { id } });
    },

    changeLicense(id) {
      this.$router.push({ name: "Change Group License", params: { id } });
    },
  },

  mounted() {
    // Fuse search initialization.
    (this.fuseSearch = new Fuse(this.tableData, {
      keys: ["displayparamvalue"],
      threshold: 0.3,
    })),
      this.getdisplay();
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    query: {
      handler: "getListDebounced",
      immediate: true,
    },

    pagination: {
      handler: "getdisplay",
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style scoped>
table tbody {
  font-size: 1rem !important;
}
.table > thead > tr > th {
  font-size: 1rem !important;
}
.table > tbody > tr > td {
  padding: 5px;
}
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

.status-span {
  cursor: default;
  display: list-item;
  list-style: none;
  position: relative;
  /* border: 1px solid black; */
  border-radius: 30px;
  width: 20px;
  height: 20px;
  color: #f7f7f7;
  max-width: 200px;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}

.status-span:hover {
  cursor: pointer;
  color: #292b2c;
}

.status-span:hover::before {
  position: absolute;
  content: attr(data-title);
  background-color: #000;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  top: -25px;
  right: 50%;
  padding: 3px 8px;
  transform: translate(50%, -50%);
  white-space: nowrap;
  overflow-wrap: break-word;
}

.status-span:hover::after {
  position: absolute;
  content: "\25BC";
  height: 1.25rem;
  color: #000;
  top: -12px;
  left: 50%;
  transform: scale(1.8, 1) translate(-33%, -50%);
}
.table-header {
  cursor: pointer;
}
.table-header span {
  display: flex;
  align-items: center;
}
</style>
