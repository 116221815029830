<template>
    <card class="stacked-form">
        <div>
            <h2 class="text-center">Domotica vs Videosorveglianza</h2>
            <!-- <div class="col-md-5 center">
                <card class="card-chart card-chart-pie">
                    <h3 slot="header" class="card-category">Click on any segment to drilldown</h3>

                    <div class="row">
                        <div class="col-6">
                            <div class="chart-area">
                                <pie-chart :chart-data="pieChart2.chartData" :extra-options="pieChart2.extraOptions"
                                    :height="120">
                                </pie-chart>
                            </div>
                        </div>

                    </div>
                </card>
            </div> -->
        </div>
    </card>
</template>
<script>
import PieChart from 'src/components/Charts/PieChart';
import config from '@/config';
import * as chartConfigs from '@/components/Charts/config';

export default {
    components: {
        PieChart
    },
    data() {
        return {
            pieChart2: {
                chartData: {
                    labels: ['Home Automation', 'Video surveillance'],
                    datasets: [
                        {
                            label: 'Emails',
                            pointRadius: 0,
                            pointHoverRadius: 0,
                            backgroundColor: ['#ff8779', '#2a84e9'],
                            borderWidth: 0,
                            data: [60, 40]
                        }
                    ]
                },
                extraOptions: chartConfigs.pieChartOptions
            }
        };
    }
};
</script>
<style>
</style>
