<template>
  <div class="mt-3">
    <h4 v-if="this.moderate.length != 0">
      {{ $t("LOADING NEW CONTENT - CONTENT") }}
      <a href="javascript::void()"> {{ $t("MODERATION ACTIVE") }} </a> ({{
        $t("mb used")
      }}: {{ this.mbused }}, {{ $t("mb free") }}: {{ this.mb - this.mbused }})
    </h4>
    <h4 v-else>
      {{ $t("LOADING NEW CONTENT") }} ({{ $t("mb used") }}: {{ this.mbused }},
      {{ $t("mb free") }}: {{ this.mb - this.mbused }})
    </h4>
    <card class="stacked-form">
      <span v-if="errors && errors">
        <small class="error" v-text="errors" />
      </span>
      <card class="table-responsive">
        <div
          class="flex items-center justify-center w-full h-screen text-center"
          id="app"
        >
          <div
            class="bg-gray-100 border border-gray-300"
            style="height: 100%"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
          >
            <input
              type="file"
              multiple
              name="fields[assetsFieldHandle][]"
              id="assetsFieldHandle"
              class="w-px h-px opacity-0 overflow-hidden absolute"
              @change="onChange"
              ref="file"
              accept=".jpeg,.pdf,.gif,.bmp,.tif,.tiff,.jpg,.png,.avi,.mp4,.mov,.mkv,.mpg,.mpeg,.wmv,.mp3,.wav,.aiff,.wma,.webm'"
            />

            <label
              for="assetsFieldHandle"
              class="p-12 w-100 block cursor-pointer"
            >
              <div style="size: 100%">{{ $t("Drag & Drop file here") }}...</div>
            </label>

            <ul class="p-12 mt-1 text-left" v-if="this.filelist.length" v-cloak>
              <div class="row">
                <div
                  class="col-md-4"
                  v-for="(file, index) in filelist"
                  :key="file.src"
                  v-show="!type_length"
                >
                  <li class="text-sm row mt-3 table-responsive">
                    <div class="table-responsive">
                      <p>{{ file.filename }}</p>
                      <img
                        v-if="file.filetype == 'image'"
                        :src="file.src"
                        class="preview"
                        style="width: auto; height: 160px"
                      />
                      <video
                        v-else-if="file.filetype == 'video'"
                        id="video-preview"
                        controls
                        style="width: auto; height: 160px"
                      >
                        <source :src="file.src" type="video/mp4" />
                      </video>
                      <embed
                        v-else
                        :src="file.src"
                        style="width: auto; height: 160px"
                      />
                      <br />

                      <br />
                      <base-progress
                        max="100"
                        v-show="singleprogress[index]"
                        :value="uploadPercentage[index]"
                      ></base-progress>
                      <p
                        style="text-align: right"
                        v-show="singleprogress[index]"
                      >
                        {{ uploadPercentage[index] }} %
                      </p>
                      <base-progress
                        max="100"
                        v-show="showprogress"
                        :value="uploadPercentages"
                      ></base-progress>
                      <p style="text-align: right" v-show="showprogress">
                        {{ uploadPercentages }} %
                      </p>
                      <base-button
                        @click="submitSingleFile(file, filelist.indexOf(file))"
                        class="upload btn-link"
                        size="sm"
                        v-if="!file.hwmsg"
                        title="Upload file"
                        icon
                        v-show="singleupload"
                      >
                        <i class="tim-icons icon-upload"></i>
                      </base-button>
                      <base-button
                        @click="removeFile(filelist, file)"
                        class="upload btn-link"
                        size="sm"
                        title="Remove file"
                        icon
                      >
                        <i class="tim-icons icon-simple-remove"></i>
                      </base-button>
                      <base-button
                        v-if="file.hwmsg"
                        class="upload btn-link btn-danger"
                        title="Failed To Upload"
                        size="sm"
                        icon
                      >
                        <i
                          class="tim-icons icon-alert-circle-exc"
                          style="color: red"
                        ></i>
                      </base-button>
                      <div
                        v-if="file.imageerror === 'both'"
                        style="background-color: #ec4545"
                      >
                        <base-alert type="danger">
                          <span>
                            {{ $t("Width of image file") }}
                            {{ $t("cannot exceed 5760 px") }}.</span
                          >
                          <br />
                          <span>
                            {{ $t("Height of image file") }}
                            {{ $t(" cannot exceed 4096 px") }}.
                          </span>
                        </base-alert>
                        <p v-show="(showupload = false)"></p>
                      </div>
                      <div
                        v-if="file.imageerror === 'width'"
                        style="background-color: #ec4545"
                      >
                        <base-alert type="danger">
                          <span>
                            {{ $t("Width of image file") }}
                            {{ $t("cannot exceed 5760 px") }}.</span
                          >
                        </base-alert>
                        <p v-show="(showupload = false)"></p>
                      </div>
                      <div
                        v-if="file.imageerror === 'height'"
                        style="background-color: #ec4545"
                      >
                        <base-alert type="danger">
                          <span>
                            {{ $t("Height of image file") }}
                            {{ $t(" cannot exceed 4096 px") }}.</span
                          >
                        </base-alert>
                        <p v-show="(showupload = false)"></p>
                      </div>
                      <div
                        v-if="file.imageerror === 'sizeLimit'"
                        style="background-color: #ec4545"
                      >
                        <base-alert type="danger">
                          <span>
                            {{
                              $t(
                                "The selected file exceeds the maximum allowed size of 250MB. Kindly choose a file that is within this limit to proceed."
                              )
                            }}</span
                          >
                        </base-alert>
                        <p v-show="(showupload = false)"></p>
                      </div>
                    </div>
                  </li>
                </div>
              </div>
            </ul>
            <div class="px-12 test">
              <base-progress
                class="test"
                max="100"
                v-show="showprogress"
                :value="uploadPercentages"
              ></base-progress>
              <p v-show="showprogress">{{ uploadPercentages }} %</p>
            </div>
          </div>
        </div>
        <div style="border: 1px solid black">
          <base-button
            @click="chooseFiles()"
            class="mr-5 mt-3 float-right"
            native-type="browse"
            >{{ $t("Browse") }}..</base-button
          >
          <base-button
            v-if="this.filelist.length"
            @click="submitFile()"
            class="mt-3 float-right"
            native-type="submit"
            type="primary"
            v-show="showupload"
            >{{ $t("Submit") }}
          </base-button>
          <base-button
            v-if="this.filelist.length"
            @click="removeall()"
            class="mt-3 float-right"
            native-type="remove"
            type="warning"
            v-show="showremove"
            >{{ $t("Remove") }}
          </base-button>
        </div>
      </card>
    </card>
  </div>
</template>

<script>
import { extend } from "vee-validate";
import { BaseAlert, BaseProgress } from "src/components";
import axios from "axios";
import ValidationError from "src/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import { BaseCheckbox, BaseRadio } from "src/components/index";
import { required, numeric, regex, confirmed } from "vee-validate/dist/rules";
import Editor from "@tinymce/tinymce-vue";
import swal from "sweetalert2";
import { text } from "d3";

var _URL = window.URL || window.webkitURL;
extend("required", required);
extend("numeric", numeric);
extend("regex", regex);
extend("confirmed", confirmed);

const url = process.env.VUE_APP_API_BASE_URL;

function sort(valuePath, array) {
  let path = valuePath.split(".");
  return array.sort((a, b) => {
    return getValue(a, path) - getValue(b, path);
  });

  function getValue(obj, path) {
    path.forEach((path) => (obj = obj[path]));
    return obj;
  }
}

export default {
  components: {
    ValidationError,
    BaseInput,
    BaseCheckbox,
    BaseRadio,
    BaseProgress,
    editor: Editor,
    BaseAlert,
  },

  mixins: [formMixin],

  data: () => ({
    content: "html",
    showprogress: false,
    singleprogress: {},
    filelist: [], // Store our uploaded files
    show: false,
    type_length: false,
    showconfirm: false,
    singleupload: true,

    updatepack: {
      type: "updatepackage",
      name: null,
    },
    errors: null,
    responsesubmit: null,
    responsefile: null,

    weather_url: "http://192.168.1.65/widgets/generalWeather/?",
    url: "",
    progress: 0,

    group: {
      type: "groups",
    },
    radios: {
      radio1: null,
    },
    uploadPercentage: [],
    uploadPercentages: 0,
    showremove: false,
    myList: [],
    errors: null,
    // editor: ClassicEditor,
    // editorConfig: {
    // toolbar: [
    //   "heading",
    //   "|",
    //   "bold",
    //   "italic",
    //   "link",
    //   "bulletedList",
    //   "numberedList",
    //   "blockQuote",
    //   "Outdent",
    //   "Indent",
    //   "TextColor"
    // ],
    // },
    images: [],
    item: {
      type: "items",
      name: null,
      description: null,
      excerpt: null,
      status: "published",
      category: {
        id: null,
        type: "categories",
      },
      tags: [],
      is_on_homepage: false,
      date_at: null,
      relationshipNames: ["category", "tags"],
    },
    showupload: false,
    cols: 2,
    moderate: "",
    mb: 0,
    mbused: 0,
    show: {},
    filelists: [],
    imageerrr: [],
    imageerrrwidth: [],
    imageerrrheight: [],
  }),

  methods: {
    capital_letter(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },

    goBack() {
      this.$router.push({ name: "List Content" });
    },

    chooseFiles() {
      document.getElementById("assetsFieldHandle").click();
    },

    hide() {
      this.show = false;
    },
    getgroup() {
      axios.get(`${url}/categories`).then((response) => {
        this.myList = sort("attributes.content_order", response.data.data);
      });
    },

    getmoderate() {
      axios.get(`${url}/getmoderatecontent`).then((response) => {
        this.moderate = response.data;
      });
    },

    getmaxgb() {
      axios.get(`${url}/mbuse`).then((response) => {
        this.mb = response.data;
      });
    },

    getgb() {
      axios.get(`${url}/mbf`).then((response) => {
        this.mbused = response.data;
      });
    },

    async onChange(e) {
      this.filelists = [...this.$refs.file.files];
      this.showremove = true;
      this.showupload = true;
      var file_list = this.filelists;
      if (this.filelists.length < 51) {
        const maxSize = 250 * 1024 * 1024;
        const processFile = (file, index) => {
          return new Promise((resolve) => {
            let reader = new FileReader();
            let err = "";
            if (file.size > maxSize) {
              err = "sizeLimit";
            }
            reader.onload = (evt) => {
              if (file.type.startsWith("image/")) {
                let img = new Image();
                img.onload = function () {
                  if (img.width > 5760 && img.height > 4096) {
                    err = "both";
                  } else if (img.width > 5760) {
                    err = "width";
                  } else if (img.height > 4096) {
                    err = "height";
                  }
                  resolve({
                    src: URL.createObjectURL(file),
                    file: file,
                    filename: file.name,
                    filetype: file.type.split("/")[0],
                    filesize: file.size,
                    imageerror: err,
                    hwmsg: err !== "",
                  });
                }.bind(this);
                img.src = evt.target.result;
              } else if (file.type.startsWith("video/")) {
                resolve({
                  src: URL.createObjectURL(file),
                  file: file,
                  filename: file.name,
                  filetype: file.type.split("/")[0],
                  filesize: file.size,
                  imageerror: err,
                  hwmsg: err !== "",
                });
              } else {
                resolve({
                  src: URL.createObjectURL(file),
                  file: file,
                  filename: file.name,
                  filetype: file.type.split("/")[0],
                  filesize: file.size,
                  imageerror: "unsupported",
                  hwmsg: false,
                });
              }
            };

            reader.readAsDataURL(file);
          });
        };
        const processFiles = async () => {
          for (let i = 0; i < file_list.length; i++) {
            const img1 = await processFile(file_list[i], i);
            this.filelist.push(img1);
          }
        };
        await processFiles();
      } else {
        swal.fire({
          title: this.$t("Maximum limit reach"),
          showCancelButton: false,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          text: this.$t("Maximum allowed limit of 50 files"),
          buttonsStyling: false,
        });
      }
    },
    remove(i) {
      this.imageerrr.splice(i, 1);
      if (this.filelist[i].hwmsg == true) {
        // this.imageerrr[i] =false;
        // this.imageerrrwidth[i] =false;
        // this.imageerrrheight[i] =false;
        this.showupload = true;
      }
      this.filelist.splice(i, 1);
      this.showconfirm = false;
    },
    removeall(i) {
      this.filelist = [];
      this.showconfirm = false;
      this.showremove = false;
      this.showupload = false;
      this.showprogress = false;
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },

    async submitFile() {
      var test = [];
      this.showprogress = true;
      this.singleupload = false;
      let formData = new FormData();
      if (this.filelist.length > 50) {
        alert("Maximum Limit reached.");
      } else {
        for (var i = 0; i < this.filelist.length; i++) {
          let file = this.filelist[i].file;
          formData.append("files[" + i + "]", file);
          test.push(this.filelist[i].filesize);
        }
        const maxSize = 250 * 1024 * 1024;
        const isMaxSizeContent = this.filelist.filter(
          (file) => file.filesize > maxSize
        );
        const sum = test.reduce((partialSum, a) => partialSum + a, 0);
        if (isMaxSizeContent.length > 0) {
          const fileName = isMaxSizeContent
            .map((item) => item.filename)
            .join(", ");
          this.$notify({
            type: "danger",
            message: this.$t(
              ` The selected file, ${fileName} exceeds the maximum allowed size of 250MB. Kindly choose a file that is within this limit to proceed.`
            ),
            icon: "tim-icons icon-bell-55",
            timeout: 8000,
          });
        } else if (sum / 1000000 > this.mb - this.mbused) {
          this.$notify({
            type: "danger",
            message: this.$t("MB Free is Less than File Size!"),
            icon: "tim-icons icon-bell-55",
            timeout: 8000,
          });
        } else {
          axios
            .post(`${url}/addmultifile`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: function (progressEvent) {
                this.uploadPercentages = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
              }.bind(this),
            })
            .then((response) => {
              this.$notify({
                type: "success",
                message: this.$t("Files upload successfully."),
                icon: "tim-icons icon-bell-55",
              });
              this.goBack();
            })
            .catch(function () {});
          this.showupload = false;
        }
      }
    },

    async submitSingleFile(file, index) {
      this.$set(this.singleprogress, index, true);

      var tmp = [];

      tmp["file"] = file;

      if (file.filesize / 1000000 > this.mb - this.mbused) {
        this.$notify({
          type: "danger",
          message: this.$t("MB Free is Less than File Size!"),
          icon: "tim-icons icon-bell-55",
        });
      } else {
        try {
          const formData = new FormData();
          formData.append("file", tmp["file"]["file"]);

          const options = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };

          // this.uploadPercentage[index] = parseInt(0);

          return axios
            .post(`${url}/addfilesingle`, formData, {
              options,
              onUploadProgress: function (progressEvent) {
                // this.uploadPercentage[index] = parseInt(
                //   Math.round((progressEvent.loaded / progressEvent.total) * 100)
                // );

                this.$set(
                  this.uploadPercentage,
                  index,
                  parseInt(
                    Math.round(
                      (progressEvent.loaded / progressEvent.total) * 100
                    )
                  )
                );
              }.bind(this),
            })
            .then((response) => {
              this.$notify({
                type: "success",
                message: this.$t("File upload successfully."),
                icon: "tim-icons icon-bell-55",
              });
               this.$set(this.singleprogress, index, false);
              this.filelist.splice(index, 1);
            });
        } catch (e) {
          this.$notify({
            type: "danger",
            message: this.$t("Oops, something went wrong!"),
            icon: "tim-icons icon-bell-55",
          });
        }
      }
    },
    removeFile(filelist, file) {
      if (filelist.length === 1) {
        this.showprogress = false;
      }
      this.remove(filelist.indexOf(file));
    },
    async confirm() {
      this.errors = "";
      if (this.updatepack.name == null) {
        this.errors = $t("This field is required.");
      } else {
        try {
          const confirmation = await swal.fire({
            title: this.$t("Confirm update?"),
            showCancelButton: true,
            customClass: {
              confirmButton: "btn btn-success btn-fill",
              cancelButton: "btn btn-danger btn-fill",
            },
            confirmButtonText: "OK",
            buttonsStyling: false,
          });
          if (confirmation.value === true) {
            await this.$store.dispatch("updatepackage/add", this.updatepack);
            this.responsesubmit = await this.$store.getters[
              "updatepackage/updatepack"
            ];

            if (this.responsesubmit) {
              if (this.responsesubmit.message) {
                this.$notify({
                  type: "danger",
                  message: this.responsesubmit.message,
                  icon: "tim-icons icon-bell-55",
                });
              } else {
                this.$notify({
                  type: "success",
                  message: this.$t("Update inserted."),
                  icon: "tim-icons icon-bell-55",
                });
              }
            } else {
              this.$notify({
                type: "danger",
                message: this.$t("Something went wrong."),
                icon: "tim-icons icon-bell-55",
              });
            }
            this.removeall();
          }
        } catch (error) {
          this.$notify({
            type: "danger",
            message: this.$t("Request insertion error"),
            icon: "tim-icons icon-bell-55",
          });
        }
      }
    },
  },
  mounted() {
    this.getgroup();
    this.getmoderate();
    this.getmaxgb();
    this.getgb();
  },
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}

.w-px {
  width: 1px;
}

.absolute {
  position: absolute;
}

.opacity-0 {
  opacity: 0;
}

.p-12 {
  padding: 3rem;
}

.px-12 {
  padding-inline: 3rem;
}

.border {
  border-width: 1px;
}

.border-gray-300 {
  border-color: #e2e8f0;
}

.bg-gray-100 {
  background-color: #f7fafc;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
z .list-item {
  border: none !important;
}
.dangerp {
  color: red;
}
.successp {
  color: green;
}
.progress-value {
  margin: 18px;
}
</style>
