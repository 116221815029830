<template>
  <svg
    :style="{ width: '18px', height: '18px' }"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 20 
       Q 10 18, 12 18 
       L 28 18 
       Q 30 18, 30 20 
       L 22 28 
       Q 20 30, 18 28 
       L 10 20 Z"
      :fill="bottomColor"
      transform="translate(0, 3)"
    />
    <path
      d="M10 10 
       Q 10 14, 12 14 
       L 28 14 
       Q 30 14, 30 12 
       L 22 4 
       Q 20 2, 18 4 
       L 10 12 Z"
      :fill="topColor"
      transform="translate(0, 3)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    topColor: {
      type: String,
      required: true,
      default: 'gray'
    },
    bottomColor: {
      type: String,
      required: true,
      default: 'gray'
    },
  },
};
</script>