<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-12">
        <h4 slot="header" class="card-title">{{ $t("USER ACTIONS LOG") }}</h4>
        <card card-body-classes="table-full-width">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="postsPerPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('Search records')"
                  v-model="search"
                >
                </el-input>
              </base-input>
            </div>

            <table class="table">
              <thead>
                <tr>
                  <th @click="sort('user_level')">{{ $t("User") }}</th>
                  <!-- <th @click="sort('action')">Action</th> -->
                  <th @click="sort('element_involved')">
                    {{ $t("Element Involved") }}
                  </th>
                  <th @click="sort('date')">{{ $t("Date (MM/DD/YYYY)") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(c, k) in filteredAndSortedBlogs" :key="k">
                  <td scope="row">{{ c.user_level }}</td>
                  <!-- <td scope="row"></td> -->
                  <td scope="row">{{ c.element_involved }}</td>
                  <td scope="row">{{ c.date }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
                {{ $t("of") }} {{ total }} {{ $t("entries") }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="currentPage"
              :per-page="postsPerPage"
              :total="this.container.length"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  TimeSelect,
} from "element-ui";

import { BasePagination } from "src/components";
// import users from "./users";
import Fuse from "fuse.js";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "src/components/ValidationError.vue";
import { Modal } from "@/components";
import { required } from "vee-validate/dist/rules";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  mixins: [formMixin],

  components: {
    ValidationError,
    BasePagination,
    Modal,
    required,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [TimeSelect.name]: TimeSelect,
    [DatePicker.name]: DatePicker,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */

    filteredAndSortedBlogs() {
      var scope = this;
      return this.container
        .filter(function (c) {
          if (c.user_level.toLowerCase().match(scope.search.toLowerCase())) {
            return (c) =>
              c.user_level.toLowerCase().match(this.search.toLowerCase());
          } else if (c.action.toLowerCase().match(scope.search.toLowerCase())) {
            return (c) =>
              c.action.toLowerCase().match(this.search.toLowerCase());
          } else if (
            c.element_involved.toLowerCase().match(scope.search.toLowerCase())
          ) {
            return (c) =>
              c.element_involved.toLowerCase().match(this.search.toLowerCase());
          } else if (c.date.toLowerCase().match(scope.search.toLowerCase())) {
            return (c) => c.date.toLowerCase().match(this.search.toLowerCase());
          }
        })
        .sort((a, b) => {
          let modifier = 1;
          if (this.currentSortDir === "desc") modifier = -1;
          if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
        })
        .slice(
          (this.currentPage - 1) * this.postsPerPage,
          this.currentPage * this.postsPerPage
        );
    },
    queriedData() {
      let result = this.container;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.postsPerPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.postsPerPage * (this.currentPage - 1);
    },
    total() {
      return this.container.length;
    },
  },

  data() {
    return {
      currentSort: "user_level",
      currentSortDir: "desc",

      currentPage: 1,
      postsPerPage: 10,
      perPageOptions: [10, 25, 50, 100],
      search: "",

      id: "",

      query: "",
      propsToSearch: ["user_level"],
      container: [],
      group: [],
      licenses: [],
      searchedData: [],
      fuseSearch: null,
      searchModalVisible: true,
    };
  },
  methods: {
    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    setCurrentPage(direction) {
      if (direction === -1 && this.currentPage > 1) {
        this.currentPage -= 1;
      } else if (
        direction === 1 &&
        this.currentPage < this.container.total_length / this.postsPerPage
      ) {
        this.currentPage += 1;
      }
    },

    checkboxselect() {
      this.secondTableData[0].ckdisabled = false;
    },
    checkboxhide() {
      this.secondTableData[0].ckdisabled = true;
      this.secondTableData[0].Lunchecked = false;
      this.secondTableData[0].Marchecked = false;
      this.secondTableData[0].Merchecked = false;
      this.secondTableData[0].Giochecked = false;
      this.secondTableData[0].Venchecked = false;
      this.secondTableData[0].Sabchecked = false;
      this.secondTableData[0].Domchecked = false;
    },

    getcontainer() {
      axios.get(`${url}/get-user-accesslog`).then((response) => {
        this.container = response.data;
      });
    },
  },

  mounted() {
    this.getcontainer();
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    query(value) {
      let result = this.container;
      if (value !== "") {
        result = this.fuseSearch.search(this.query);
      }
      this.searchedData = result;
    },
  },
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

.flip-transition-move {
  transition: all 0.7s;
}

.content-img {
  width: 38%;
  margin-top: -12px;
  display: flex;
}

.list-group-item {
  max-height: 50px;
}

.list-group {
  cursor: move;
}

.text-title {
  margin-left: -62px;
  margin-top: -11px;
}

.card {
  border: 0.0625rem solid #e3e3e3;
}

.card-header {
  background-color: #e3e3e3 !important;
}

table {
  width: 100% !important;
}

.modal-search .modal-dialog {
  margin: 23px auto;
  max-width: 65% !important;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 2px 0px var(--primary) inset;
  margin-right: 3px;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 35%;
  height: 31%;
  margin: 31% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: var(--primary);
}

.pickup > .mb-0 > input {
  border-radius: 25px;
  border: 1px solid #dddddd;
  background-color: #f9f9f9;
}
</style>
