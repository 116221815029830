<template>
  <div class="content">
    <div class="row mt-3">
      <div class="col-12">
        <h4 slot="header" class="card-title">
          {{ $t("Content List") }} ({{ $t("mb used") }}: {{ this.mbused }},
          {{ $t("mb free") }}: {{ this.mb - this.mbused }})
        </h4>
        <card card-body-classes="table-full-width">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('Search records')"
                  v-model="query"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>

              <modal
                :show.sync="openmodalimage"
                class="modal-search"
                id="setscheduler"
                :centered="false"
                :show-close="true"
                :modalContentClasses="widthlist100"
              >
                <div
                  v-if="selectedImage"
                  max-width="85vw"
                  style="text-align: center"
                >
                  <img :src="selectedImage" alt="" width="100%" height="auto" />

                  <br />
                  <br />
                  <hr />
                  <base-button
                    @click="openmodalimage = false"
                    class=""
                    type="primary"
                    >{{ $t("Close") }}</base-button
                  >
                </div>
              </modal>

              <modal
                :show.sync="openvideomodal"
                class="modal-search"
                id="setscheduler"
                :centered="false"
                :show-close="true"
                :modalContentClasses="widthlist100"
              >
                <div
                  v-if="selectedVideo"
                  max-width="85vw"
                  style="text-align: center"
                >
                  <iframe
                    :v-model="iframe"
                    :src="selectedVideo"
                    height="500px"
                    width="100%"
                    title="Iframe Example"
                  ></iframe>

                  <br />
                  <br />
                  <hr />
                  <base-button @click="closemodal()" class="" type="primary"
                    >Close</base-button
                  >
                </div>
              </modal>

              <modal
                :show.sync="openappmodal"
                class="modal-search"
                id="setscheduler"
                :centered="false"
                :show-close="true"
                :modalContentClasses="widthlist100"
              >
                <div
                  v-if="selectedapp"
                  max-width="85vw"
                  style="text-align: center"
                >
                  <iframe
                    :src="selectedapp"
                    height="390px"
                    width="400px"
                    title="Iframe Example"
                  ></iframe>

                  <br />
                  <br />
                  <hr />
                  <base-button
                    @click="openappmodal = false"
                    class=""
                    type="primary"
                    >{{ $t("Close") }}</base-button
                  >
                </div>
              </modal>
            </div>
            <div id="app">
              <table class="table" id="dataTable">
                <thead>
                  <tr>
                    <th
                      @click="sortChange(table)"
                      v-for="(table, key) in tableData.title"
                      :key="key"
                      class="table-header"
                    >
                      <span>
                        {{ $t(table)
                        }}<SortIcon
                          :topColor="computedTopColor(table, 'top')"
                          :bottomColor="computedTopColor(table, 'bottom')"
                        />
                      </span>
                    </th>
                    <th @click="sortChange('type')" class="table-header">
                      <span>
                        {{ $t("type")
                        }}<SortIcon
                          :topColor="computedTopColor('type', 'top')"
                          :bottomColor="computedTopColor('type', 'bottom')"
                        />
                      </span>
                    </th>
                    <th
                      class="text-center table-header"
                      @click="sortChange('preview')"
                    >
                      <span style="justify-content: center">
                        {{ $t("preview")
                        }}<SortIcon
                          :topColor="computedTopColor('preview', 'top')"
                          :bottomColor="computedTopColor('preview', 'bottom')"
                        />
                      </span>
                    </th>
                    <th
                      class="text-center table-header"
                      @click="sortChange('size')"
                    >
                      <span style="justify-content: center">
                        {{ $t("size")
                        }}<SortIcon
                          :topColor="computedTopColor('size', 'top')"
                          :bottomColor="computedTopColor('size', 'bottom')"
                        />
                      </span>
                    </th>
                    <th class="text-center">{{ $t("select") }}</th>
                    <th>{{ $t("action") }}</th>
                  </tr>
                </thead>
                <tbody style="border-bottom: 0.0625rem solid #e3e3e3">
                  <tr v-for="table in tableData.data" :key="table.id">
                    <template v-for="t in table">
                      <td
                        v-if="t.name === 'name'"
                        :key="t.id"
                        class="content-name"
                        style="
                          box-orient: vertical;
                          -webkit-line-clamp: 1;
                          overflow: hidden;
                        "
                      >
                        {{ t.contentparamvalue }}
                      </td>
                      <td
                        v-else
                        :key="t.id + 'zends'"
                        style="max-width: 325px; padding-right: 1.2rem"
                      >
                        {{ t.contentparamvalue }}
                      </td>
                    </template>

                    <td v-if="table[0].mime == 'Url'">
                      <i
                        style="color: var(--primary); font-size: 18px"
                        class="tim-icons icon-credit-card"
                      ></i>
                    </td>
                    <td v-else-if="table[0].mime == 'Html'">
                      <i
                        style="color: var(--primary); font-size: 18px"
                        class="tim-icons icon-credit-card"
                      ></i>
                    </td>
                    <td v-else-if="table[0].mime == 'image'">
                      <i
                        style="color: var(--primary); font-size: 18px"
                        class="tim-icons icon-image-02"
                      ></i>
                    </td>
                    <td v-else-if="table[0].mime == 'video'">
                      <i
                        style="color: var(--primary); font-size: 20px"
                        class="tim-icons icon-video-66"
                      ></i>
                    </td>
                    <td v-else-if="table[0].mime == 'application'">
                      <i
                        style="color: var(--primary); font-size: 18px"
                        class="tim-icons icon-notes"
                      ></i>
                    </td>
                    <td v-else></td>

                    <!-- <td v-if="table[0].mime == 'video'"><a style="z-index: 10000" href="javascript:void()" @click="videopreview(table[0].content_file)">
                        <video style="z-index: 0" id="myVideo" width="30px" height="30px"  controls>
                      <source :src="table[0].content_file" type="video/mp4">
                    </video>

                      </a><p class="ml-2" style="display:inline" v-if="table[0].size">{{ table[0].size }} MB</p></td> -->
                    <td class="text-center" v-if="table[0].mime == 'video'">
                      <a
                        href="javascript:void(0)"
                        @click="videopreview(table[0].content_file)"
                        ><video
                          :src="table[0].content_file"
                          height="30px"
                          width="30px"
                      /></a>
                    </td>
                    <td class="text-center" v-else-if="table[0].mime == 'Html'">
                      <a target="_blank" :href="table[0].url">{{
                        $t("link")
                      }}</a>
                    </td>
                    <td class="text-center" v-else-if="table[0].mime == 'Url'">
                      <a target="_blank" :href="table[0].url">{{
                        $t("link")
                      }}</a>
                    </td>
                    <td
                      class="text-center"
                      v-else-if="table[0].mime == 'image'"
                    >
                      <img
                        style="
                          display: inline;
                          object-fit: contain;
                          border-radius: 0;
                        "
                        :src="table[0].content_file"
                        alt="img"
                        height="50px"
                        width="50px"
                        @click="zoom(table[0].content_file)"
                      />
                    </td>
                    <td
                      class="text-center"
                      v-else-if="table[0].mime == 'application'"
                    >
                      <a
                        href="javascript::void)"
                        @click="apppreview(table[0].content_file)"
                        ><img
                          @click="apppreview(table[0].content_file)"
                          style="display: inline"
                          src="/img/pdf-icon.png"
                          width="25px"
                          height="30px"
                      /></a>
                    </td>
                    <td class="text-center" v-else-if="table[0].mime == ''">
                      <a
                        href="http://zends.eu/content/list-content"
                        target="_blank"
                        >{{ $t("link") }}</a
                      >
                    </td>

                    <td class="text-center">
                      <p
                        class="ml-2"
                        style="display: inline"
                      >
                        {{
                          table[0].mime !== "Html"
                            ? (table[0].size || 0) + " " + $t("MB")
                            : "-"
                        }}
                      </p>
                    </td>
                    <td
                      class="text-center"
                      v-if="matched(table[0].content_id) == false"
                    >
                      <input
                        type="checkbox"
                        class="checkbox_content"
                        :value="table[0].content_id"
                      />
                    </td>
                    <td class="text-center" v-else>
                      <p>{{ $t("Content in use") }}</p>
                    </td>

                    <td v-if="matched(table[0].content_id) == false">
                      <button
                        @click="goToEdit(table[0].content_id)"
                        title="Edit"
                        class="'details btn-link"
                        type="success"
                        style="color: var(--primary)"
                      >
                        <i class="tim-icons icon-pencil"></i>
                      </button>
                      <button
                        @click="destroy(table[0].content_id)"
                        title="Delete "
                        class="'details btn-link"
                        type="success"
                        style="color: var(--primary)"
                      >
                        <i class="tim-icons icon-simple-remove"></i>
                      </button>
                    </td>
                    <td v-else>
                      <button
                        @click="goToEdit(table[0].content_id)"
                        title="Edit"
                        class="'details btn-link"
                        type="success"
                        style="color: var(--primary)"
                      >
                        <i class="tim-icons icon-pencil"></i>
                      </button>
                    </td>
                  </tr>
                  <place-holder-table
                    v-if="!loading"
                    :data="tableData.data"
                    :column="5"
                  />
                </tbody>
              </table>
            </div>
          </div>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
                {{ $t("of") }} {{ total }} {{ $t("entries") }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
        <div style="text-align: center">
          <base-button
            class="mr-2 btn-color"
            v-model="multipleSelect"
            @click="selectAll"
          >
            {{ $t("Select All") }}</base-button
          >
          <base-button
            class="mr-2 btn-color"
            v-model="multipleSelect"
            @click="deselectAll"
            >{{ $t("Uncheck All") }}</base-button
          >
          <base-button @click="deletemulti()" class="btn-color">{{
            $t("Delete Selected")
          }}</base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";

import _ from "lodash";
import { BasePagination } from "src/components";
// import users from "./users";
import Fuse from "fuse.js";
import swal from "sweetalert2";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "src/components/ValidationError.vue";
import { Modal } from "@/components";
import PlaceHolderTable from "../Tables/placeHolderTable.vue";
import SortIcon from "@/assets/customImg/SortIcon.vue";
import { getTopColor, getBottomColor } from "../../util/utils";
const url = process.env.VUE_APP_API_BASE_URL;

export default {
  mixins: [formMixin],

  components: {
    SortIcon,
    Modal,
    ValidationError,
    BasePagination,
    PlaceHolderTable,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */

    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.tableData.total < highBound) {
        highBound = this.total;
      }

      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.total;
    },

    // filteredAndSortedBlogs() {
    //   return this.tableData.data.sort((a, b) => {
    //     let modifier = 1;
    //     if (this.currentSortDir === "desc") modifier = -1;
    //     if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
    //     if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
    //     return 0;
    //   });
    // },
  },

  data() {
    return {
      mbused: 0,
      mb: 0,
      selectedImage: null,
      selectid: [],
      multipleSelect: false,
      query: "",

      tableData: [],
      group: [],
      licenses: [],
      widthlist100: "widthlist-100",
      fuseSearch: null,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
      },
      usecont: "",
      openmodalimage: false,
      openvideomodal: false,
      openappmodal: false,
      selectedVideo: "",
      selectedapp: "",
      iframe: "",
      myVideo: null,
      sortparam: {
        field: "name",
        sort: "asc",
      },
      loading: true,
    };
  },
  methods: {
    computedTopColor(table, arrowIcon) {
      if (arrowIcon === "top") {
        return getTopColor(table, this.sortparam);
      } else {
        return getBottomColor(table, this.sortparam);
      }
    },
    getApiBaseUrl() {
      return url;
    },
    getgb() {
      axios.get(`${url}/mbf`).then((response) => {
        this.mbused = response.data;
      });
    },
    getmaxgb() {
      axios.get(`${url}/mbuse`).then((response) => {
        this.mb = response.data;
      });
    },
    sortChange(data) {
      this.sortparam.field = data;
      this.sortparam.sort = this.sortparam.sort == "asc" ? "desc" : "asc";
      this.getcontent();
    },

    matched(id) {
      if (Object.keys(this.usecont).length > 0) {
        return this.usecont.some(function (el) {
          return el == id;
        });
      } else {
        return false;
      }
    },
    zoom(url) {
      this.selectedImage = url;
      this.openmodalimage = true;
    },

    selectAll() {
      this.multipleSelect = true;

      var inputs = document.querySelectorAll(".checkbox_content");
      for (var i = 0; i < inputs.length; i++) {
        inputs[i].checked = true;
      }
    },

    deselectAll() {
      var inputs = document.querySelectorAll(".checkbox_content");
      for (var i = 0; i < inputs.length; i++) {
        inputs[i].checked = false;
      }
    },

    closemodal() {
      this.openvideomodal = false;
      this.selectedVideo = "";
    },
    videopreview(videourl) {
      this.selectedVideo = videourl;
      this.openvideomodal = true;
    },
    apppreview(appurl) {
      this.selectedapp = appurl;
      this.openappmodal = true;
    },
    deletemulti() {
      var obj = [];
      var inputs = document.querySelectorAll(".checkbox_content");
      for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].checked == true) {
          obj.push(inputs[i].value);
        }
      }
      if (obj != "") {
        axios.post(`${url}/deletecontentall`, obj);
        this.$notify({
          type: "success",
          message: this.$t("Content deleted successfully."),
          icon: "tim-icons icon-bell-55",
        });
        setTimeout(() => {
          this.getcontent();
        }, 500);
        this.deselectAll();
      }
    },
    getListDebounced: _.debounce(function () {
      this.getcontent();
    }, 300),

    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    setCurrentPage(direction) {
      if (direction === -1 && this.pagination.currentPage > 1) {
        this.pagination.currentPage -= 1;
      } else if (
        direction === 1 &&
        this.pagination.currentPage <
          this.tableData.data.length / this.pagination.perPage
      ) {
        this.pagination.currentPage += 1;
      }
    },

    async getcontent() {
      let params = {
        sort: {
          ...this.sortparam,
        },
        filter: {
          ...(this.query ? { searchValue: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };
      await this.$store.dispatch("contentfile/contentgetdata", params);
      this.tableData = await this.$store.getters["contentfile/content_list"];
      if (this.tableData) {
        this.loading = false;
      }
    },

    async destroy(id) {
      try {
        const confirmation = await swal.fire({
          title: this.$t("Are you sure?"),
          text: this.$t("You won't be able to revert this!"),
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: this.$t("Yes, delete it!"),
          buttonsStyling: false,
        });

        if (confirmation.value === true) {
          axios.delete(`${url}/deletecontent/${id}`);
          this.$notify({
            type: "success",
            message: this.$t("Content deleted successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.getcontent();
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: this.$t("This Group still has associated Users."),
            icon: "tim-icons icon-bell-55",
          });
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("Oops, something went wrong!"),
            icon: "tim-icons icon-bell-55",
          });
        }
      }
    },

    goToEdit(id) {
      this.$router.push({ name: "Edit Content", params: { id } });
    },

    usecontant() {
      axios.get(`${url}/contentuse`).then((response) => {
        this.usecont = response.data;
      });
    },

    html(id) {
      axios.get(`${url}/gethtmlcontent/${id}`).then((response) => {
        this.htmlcontent = response.data;

        // console.log(this.htmlcontent);
      });

      // var page = 'http://localhost/zends-backend/resources/views/generated_html.blade.php';
      //    var myWindow = window.open(page, "_blank", "scrollbars=yes,width=400,height=500,top=300");
      //    myWindow.focus();
      //  this.$router.push({ name: "Generate Html", params: { id } });
    },
  },

  mounted() {
    this.getcontent();
    this.usecontant();
    this.html();
    this.getgb();
    this.getmaxgb();
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getcontent",
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped>
.content-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* Change this line if you want. In this case it trimmed the text to 3 lines. */
  overflow: hidden;
}
.table-header {
  cursor: pointer;
}
.table-header span {
  display: flex;
  align-items: center;
}
table tbody {
  font-size: 1rem !important;
}
.table > thead > tr > th {
  font-size: 1rem !important;
}
.table > tbody > tr > td {
  padding: 5px;
}
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

.btn-color {
  background-image: linear-gradient(
    to bottom left,
    #49a3f1,
    #1a73e8,
    #49a3f1
  ) !important;
}

.btn:focus {
  background-image: linear-gradient(to bottom left, #49a3f1, #1a73e8, #49a3f1);
}

.btn-color:hover {
  background-image: linear-gradient(
    to bottom left,
    #49a3f1,
    #1a73e8,
    #49a3f1
  ) !important;
}

.btn-color:not(:disabled):not(.disabled):active,
.btn-color:not(:disabled):not(.disabled).active {
  color: #fff;
  background-image: linear-gradient(
    to bottom left,
    #49a3f1,
    #1a73e8,
    #49a3f1
  ) !important;
}

/* .modal-content{
  height: auto;
  width:  700px;
} */
</style>
<style>
.widthlist-100 {
  height: auto;
  width: 700px;
}
</style>

<style>
.card-footer {
  padding: 0 !important;
}
</style>
