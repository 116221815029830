<template>
  <div class="row mb-4">
    <div class="col-lg-4 col-md-6 mt-4 mb-4">
      <div class="card z-index-2">
        <div
          class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent"
        >
          <div
            class="bg-gradient-primary-chart shadow-primary border-radius-lg py-3 pe-1 chart-shadow"
          >
            <div class="chart">
              <canvas
                id="chart-bars"
                class="chart-canvas"
                height="170"
                width="277"
                style="
                  display: block;
                  box-sizing: border-box;
                  height: 170px;
                  width: 277.7px;
                "
              ></canvas>
            </div>
          </div>
        </div>
        <div class="card-body p-50">
          <h6 class="mb-0">Website Views</h6>
          <p class="text-sm">Last Campaign Performance</p>
          <hr class="dark horizontal" />
          <div class="d-flex">
            <i class="fas fa-clock text-sm my-auto me-1 mr-1"></i>
            <p class="mb-0 text-sm">campaign sent 2 days ago</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 mt-4 mb-4">
      <div class="card z-index-2">
        <div
          class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent"
        >
          <div
            class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1 chart-shadow"
          >
            <div class="chart">
              <canvas
                id="chart-line"
                class="chart-canvas"
                height="170"
                width="277"
                style="
                  display: block;
                  box-sizing: border-box;
                  height: 170px;
                  width: 277.7px;
                "
              ></canvas>
            </div>
          </div>
        </div>
        <div class="card-body p-50">
          <h6 class="mb-0">Daily Sales</h6>
          <p class="text-sm">
            <span class="font-weight-bolder">+15%</span> increase in today
            sales.
          </p>
          <hr class="dark horizontal" />
          <div class="d-flex">
            <i class="fas fa-clock text-sm my-auto me-1 mr-1"></i>
            <p class="mb-0 text-sm">updated 4 min ago</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 mt-4 mb-3">
      <div class="card z-index-2">
        <div
          class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent"
        >
          <div
            class="bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1 chart-shadow"
          >
            <div class="chart">
              <canvas
                id="chart-line-tasks"
                class="chart-canvas"
                height="170"
                width="277"
                style="
                  display: block;
                  box-sizing: border-box;
                  height: 170px;
                  width: 277.7px;
                "
              ></canvas>
            </div>
          </div>
        </div>
        <div class="card-body p-50">
          <h6 class="mb-0">Completed Tasks</h6>
          <p class="text-sm">Last Campaign Performance</p>
          <hr class="dark horizontal" />
          <div class="d-flex">
            <i class="fas fa-clock text-sm my-auto me-1 mr-1"></i>
            <p class="mb-0 text-sm">just updated</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
export default {
  mounted() {
    var ctx = document.getElementById("chart-bars").getContext("2d");

    new Chart(ctx, {
      type: "bar",
      data: {
        labels: ["M", "T", "W", "T", "F", "S", "S"],
        datasets: [
          {
            label: "Sales",
            tension: 0.4,
            borderWidth: 0,
            borderRadius: 4,
            borderSkipped: false,
            backgroundColor: "rgba(255, 255, 255, .8)",
            data: [50, 20, 10, 22, 50, 10, 40],
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
              color: "rgba(255, 255, 255, .2)",
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 500,
              beginAtZero: true,
              padding: 10,
              font: {
                size: 14,
                weight: 300,
                family: "Roboto",
                style: "normal",
                lineHeight: 2,
              },
              color: "#fff",
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
              color: "rgba(255, 255, 255, .2)",
            },
            ticks: {
              display: true,
              color: "#f8f9fa",
              padding: 10,
              font: {
                size: 14,
                weight: 300,
                family: "Roboto",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    });

    var ctx2 = document.getElementById("chart-line").getContext("2d");

    new Chart(ctx2, {
      type: "line",
      data: {
        labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
          {
            label: "Mobile apps",
            tension: 0,
            borderWidth: 0,
            pointRadius: 5,
            pointBackgroundColor: "rgba(255, 255, 255, .8)",
            pointBorderColor: "transparent",
            borderColor: "rgba(255, 255, 255, .8)",
            borderColor: "rgba(255, 255, 255, .8)",
            borderWidth: 4,
            backgroundColor: "transparent",
            fill: true,
            data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
              color: "rgba(255, 255, 255, .2)",
            },
            ticks: {
              display: true,
              color: "#f8f9fa",
              padding: 10,
              font: {
                size: 14,
                weight: 300,
                family: "Roboto",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#f8f9fa",
              padding: 10,
              font: {
                size: 14,
                weight: 300,
                family: "Roboto",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    });

    var ctx3 = document.getElementById("chart-line-tasks").getContext("2d");

    new Chart(ctx3, {
      type: "line",
      data: {
        labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
          {
            label: "Mobile apps",
            tension: 0,
            borderWidth: 0,
            pointRadius: 5,
            pointBackgroundColor: "rgba(255, 255, 255, .8)",
            pointBorderColor: "transparent",
            borderColor: "rgba(255, 255, 255, .8)",
            borderWidth: 4,
            backgroundColor: "transparent",
            fill: true,
            data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
            maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
              color: "rgba(255, 255, 255, .2)",
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#f8f9fa",
              font: {
                size: 14,
                weight: 300,
                family: "Roboto",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#f8f9fa",
              padding: 10,
              font: {
                size: 14,
                weight: 300,
                family: "Roboto",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    });
  },
};
</script>

<style>
.chart-shadow {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(64, 64, 64, 0.4) !important;
  border-radius: 0.5rem;
}

.bg-gradient-primary-chart {
  background-image: linear-gradient(195deg, #ec407a, #d81b60) !important;
}

.bg-gradient-success {
  background-image: linear-gradient(195deg, #66bb6a, #43a047) !important;
}

.bg-gradient-dark {
  background-image: linear-gradient(195deg, #42424a, #191919) !important;
}
</style>
