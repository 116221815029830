<template>
  <div class="mt-3">
    <h4>{{ $t("NEW GROUP CREATION") }}</h4>
    <card class="stacked-form">
      <span v-if="errors && errors">
        <small class="error" v-text="errors" />
      </span>
      <form @submit.prevent>
        <div
          class="list-item"
          v-for="item in myList"
          :id="item.id"
          :order="item.attributes.store_order"
          :key="item.id"
          v-if="
            item.attributes.store_order != 0 &&
            item.attributes.store_order != null
          "
        >
          <base-input
            type="text"
            v-model="group[item.attributes.name + '_' + item.id]"
            :label="capital_letter(item.attributes.name)"
            :placeholder="capital_letter(item.attributes.name)"
            required
          />
        </div>
        <base-button
          @click="add()"
          class="mt-3"
          native-type="submit"
          type="primary"
          >{{ $t("Submit") }}</base-button
        >
      </form>
    </card>
  </div>
</template>

<script>
import axios from "axios";
import ValidationError from "src/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "../../components/Inputs/BaseInput.vue";
const url = process.env.VUE_APP_API_BASE_URL;

function sort(valuePath, array) {
  let path = valuePath.split(".");
  return array.sort((a, b) => {
    return getValue(a, path) - getValue(b, path);
  });

  function getValue(obj, path) {
    path.forEach((path) => (obj = obj[path]));
    return obj;
  }
}

export default {
  components: { ValidationError, BaseInput },

  mixins: [formMixin],

  data: () => ({
    group: {
      type: "groups",
    },
    myList: [],
    errors: null,
  }),

  methods: {
    capital_letter(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },

    async add() {
      this.errors = "";
      var next = false;
      var i = 0;
      Object.keys(this.myList).forEach((key) => {
        if (this.myList[key].attributes.store_order != 0) {
          i++;
        }
      });
      Object.keys(this.group).forEach((key) => {
        if (Object.keys(this.group).length != i + 1) {
          this.errors = "* Field is required";
          next = true;
        } else if (this.group[key] == "" || this.group[key] == null) {
          this.errors = "* Field is required";
          next = true;
        }
      });
      if (next == true) {
        return false;
      }
      try {
        axios.post(`${url}/groupadd`, this.group).then((response) => {
          // if (response.data == "error") {
          //   this.$notify({
          //     type: "danger",
          //     message: this.$t("Please Fill the correct address."),
          //     icon: "tim-icons icon-bell-55",
          //   });
          // } else {
          //   this.$notify({
          //     type: "success",
          //     message: this.$t("Group added successfully."),
          //     icon: "tim-icons icon-bell-55",
          //   });
          this.goBack();
          // }
        });
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
        this.setApiValidation(e.response.data.errors);
      }
    },
    goBack() {
      this.$router.push({ name: "List Group Manager" });
    },
    getgroup() {
      axios.get(`${url}/categories`).then((response) => {
        this.myList = sort("attributes.store_order", response.data.data);
      });
    },
  },
  mounted() {
    this.getgroup();
  },
};
</script>

<style>
.list-item {
  border: none !important;
}
</style>
