<template>
  <div class="mt-3">
    <h4>{{ $t("NEW CONTAINER CREATION") }}</h4>
    <card class="stacked-form">
      <span v-if="errors && errors">
        <small class="error" v-text="errors" />
      </span>
      <form @submit.prevent>
        <base-input
          type="text"
          v-model="container.name"
          :label="capital_letter($t('firstname'))"
          :placeholder="capital_letter($t('firstname'))"
          required
        />
        <validation-error :errors="apiValidationErrors.name" />
        <base-button
          @click="add()"
          class="mt-3"
          native-type="submit"
          type="primary"
          >{{ $t("Submit") }}</base-button
        >
      </form>
    </card>
  </div>
</template>

<script>
import axios from "axios";
import ValidationError from "src/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "../../components/Inputs/BaseInput.vue";
const url = process.env.VUE_APP_API_BASE_URL;

function sort(valuePath, array) {
  let path = valuePath.split(".");
  return array.sort((a, b) => {
    return getValue(a, path) - getValue(b, path);
  });

  function getValue(obj, path) {
    path.forEach((path) => (obj = obj[path]));
    return obj;
  }
}

export default {
  components: { ValidationError, BaseInput },

  mixins: [formMixin],

  data: () => ({
    container: {
      name: "",
    },
    errors: null,
  }),

  methods: {
    capital_letter(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },

    async add() {
      axios
        .post(`${url}/addcontainer`, this.container)
        .then((response) => {
          this.$notify({
            type: "success",
            message: this.$t("Container added successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.goBack();
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: this.$t("Oops, something went wrong!"),
            icon: "tim-icons icon-bell-55",
          });
          this.setApiValidation(e.response.data.errors);
        });
    },
    goBack() {
      this.$router.push({ name: "List Container" });
    },
  },
};
</script>

<style>
.list-item {
  border: none !important;
}
</style>
