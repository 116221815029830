<template>
  <div class="content">
    <div class="row mt-3">
      <div class="col-12">
        <h4 slot="header" class="card-title">{{ $t("DESIGN LIST") }}</h4>
        <card card-body-classes="table-full-width">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="postsPerPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('Search records')"
                  v-model="search"
                >
                </el-input>
              </base-input>
            </div>

            <table class="table">
              <thead>
                <tr v-if="tableHeaderList.length">
                  <th
                    @click="sort(item.value)"
                    v-for="item in tableHeaderList"
                    :key="item.id"
                    class="table-header"
                  >
                    <span>
                      {{ $t(item.title)
                      }}<SortIcon
                        :topColor="computedTopColor(item.value, 'top')"
                        :bottomColor="computedTopColor(item.value, 'bottom')"
                      />
                    </span>
                  </th>
                  <th>{{ $t("action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="c in filteredAndSortedBlogs" :key="c.id">
                  <td scope="row">
                    <img
                      :src="c.filename"
                      alt="img"
                      width="160px"
                      height="auto"
                      style="border: 1px solid"
                    />
                  </td>
                  <td scope="row">{{ c.title }}</td>
                  <td scope="row">{{ c.info }}</td>
                  <td scope="row">{{ c.orientation }}</td>
                  <td scope="row">
                    <span v-if="c.is_delete == true">{{ $t("YUP") }}</span
                    ><span v-else>{{ $t("NO") }}</span>
                  </td>
                  <td>
                    <button
                      @click="goToEdit(c.id)"
                      title="Edit"
                      class="'edit btn-link"
                      type="success"
                      style="color: var(--primary)"
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </button>
                    <button
                      v-if="c.is_delete == false"
                      @click="destroy(c.id)"
                      title="Delete"
                      class="'delete btn-link"
                      type="success"
                      style="color: var(--primary)"
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
                {{ $t("of") }} {{ total }} {{ $t("entries") }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="currentPage"
              :per-page="postsPerPage"
              :total="Object.keys(this.tableData).length"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";

import { BasePagination } from "src/components";
// import users from "./users";
import Fuse from "fuse.js";
import swal from "sweetalert2";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "src/components/ValidationError.vue";
import SortIcon from "@/assets/customImg/SortIcon.vue";
import { getTopColor, getBottomColor } from "../../util/utils";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  mixins: [formMixin],

  components: {
    SortIcon,
    ValidationError,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */

    filteredAndSortedBlogs() {
      const filteredContainers = this.tableData.filter((c) =>
        c.title.toLowerCase().match(this.search.toLowerCase())
      );

      const sortedContainers = filteredContainers
        .sort((a, b) => {
          let modifier = this.currentSortDir === "desc" ? -1 : 1;
          const aValue = a[this.currentSort].toString().toLowerCase();
          const bValue = b[this.currentSort].toString().toLowerCase();
          if (aValue < bValue) return -1 * modifier;
          if (aValue > bValue) return 1 * modifier;
          return 0;
        })
        .slice(
          (this.currentPage - 1) * this.postsPerPage,
          this.currentPage * this.postsPerPage
        );

      return sortedContainers;
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.postsPerPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.postsPerPage * (this.currentPage - 1);
    },
    total() {
      return Object.keys(this.tableData).length;
    },
  },

  data() {
    return {
      searchQuery: "",
      currentSort: "filename",
      currentSortDir: "asc",

      currentPage: 1,
      postsPerPage: 10,
      perPageOptions: [10, 25, 50, 100],
      search: "",

      query: "",
      propsToSearch: ["filename"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      tableHeaderList: [
        {
          title: "Preview",
          value: "filename",
        },
        {
          title: "firstname",
          value: "title",
        },
        {
          title: "Information",
          value: "info",
        },
        {
          title: "Orientation",
          value: "orientation",
        },
        {
          title: "Used",
          value: "is_delete",
        },
      ],
    };
  },
  methods: {
    computedTopColor(table, arrowIcon) {
      const sortparam = {
        field: this.currentSort,
        sort: this.currentSortDir,
      };
      if (arrowIcon === "top") {
        return getTopColor(table, sortparam);
      } else {
        return getBottomColor(table, sortparam);
      }
    },
    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    setCurrentPage(direction) {
      if (direction === -1 && this.currentPage > 1) {
        this.currentPage -= 1;
      } else if (
        direction === 1 &&
        this.currentPage <
          Object.keys(this.tableData).length / this.postsPerPage
      ) {
        this.currentPage += 1;
      }
    },

    async getdesignlist() {
      await this.$store.dispatch("templatesdetail/getdesignlist");
      this.tableData = await this.$store.getters["templatesdetail/design_list"];
    },

    async destroy(id) {
      await axios
        .delete(`${url}/delete-design/${id}`)
        .then((response) => {
          this.$notify({
            type: "success",
            message: this.$t("Successfully Deleted."),
            icon: "tim-icons icon-bell-55",
          });
          this.getdesignlist();
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: this.$t("Oops, something went wrong!"),
            icon: "tim-icons icon-bell-55",
          });
        });
    },

    goToEdit(id) {
      this.$router.push({ name: "Edit Design", params: { id } });
    },
  },

  mounted() {
    // Fuse search initialization.
    (this.fuseSearch = new Fuse(this.tableData, {
      keys: ["firstname"],
      threshold: 0.3,
    })),
      this.getdesignlist();
  },
  watch: {
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.search = result;
    },
  },
};
</script>
<style scoped>
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}
.table-header {
  cursor: pointer;
}
.table-header span {
  display: flex;
  align-items: center;
}
</style>
