<template>
  <div class="content">
    <div class="row mt-3">
      <div class="col-12">
        <h4 slot="header" class="card-title">{{ $t("LICENSE LIST") }}</h4>
        <card card-body-classes="table-full-width">
          <div>
            <div class="row">
              <div class="col-md-4">
                <card>
                  <h4 slot="header" class="card-title">{{ $t("From") }}</h4>
                  <base-input>
                    <el-date-picker
                      type="date"
                      :placeholder="$t('Date Picker')"
                      v-model="datePickerfrom"
                    >
                    </el-date-picker>
                    <hr />
                    *{{ $t("optional") }}
                  </base-input>
                </card>
                <base-button
                  @click="dateWiseSorting()"
                  class="mt-3"
                  native-type="submit"
                  type="primary"
                  >{{ $t("Submit") }}</base-button
                >
              </div>
              <div class="col-md-4">
                <card>
                  <h4 slot="header" class="card-title">{{ $t("To") }}</h4>
                  <base-input>
                    <el-date-picker
                      type="date"
                      placeholder="Date Picker"
                      v-model="datePickerto"
                    >
                    </el-date-picker>
                    <hr />
                    *{{ $t("optional") }}
                  </base-input>
                </card>
              </div>
            </div>
            <hr />

            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('Search records')"
                  v-model="query"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>

            <table class="table">
              <thead>
                <tr>
                  <th @click="sort('license_code')">{{ $t("Code") }}</th>
                  <th @click="sort('expiry_date')">{{ $t("Deadline") }}</th>
                  <th @click="sort('group_name')">{{ $t("Store") }}</th>
                  <th @click="sort('display_number')">
                    {{ $t("Usable Displays") }}
                  </th>
                  <th @click="sort('display_number')">
                    {{ $t("Display in Use") }}
                  </th>
                  <th @click="sort('actions')">{{ $t("action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="tab in tableData" :key="tab.id">
                  <td scope="row">{{ tab[0].license_code }}</td>
                  <td scope="row">{{ tab[0].expiry_date }}</td>
                  <td scope="row">
                    <span v-for="(t, k) in tab" :key="k">{{ t.gdata }} | </span>
                  </td>
                  <td scope="row">{{ tab[0].display_number }}</td>
                  <td scope="row">{{ tab[0].display_use }}</td>
                  <td scope="row">
                    <button
                      title="Edit"
                      @click="goToEdit(tab[0].id)"
                      class="'edit btn-link"
                      type="success"
                      style="color: var(--primary)"
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </button>
                    <button
                      title="PDF"
                      @click="getpdf(tab[0].id)"
                      class="'delete btn-link"
                      type="success"
                      style="color: var(--primary)"
                    >
                      <i class="tim-icons icon-paper"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
                {{ $t("of") }} {{ total }} {{ $t("entries") }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, DatePicker } from "element-ui";
import { BasePagination } from "src/components";
// import users from "./users";
import Fuse from "fuse.js";
import swal from "sweetalert2";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "src/components/ValidationError.vue";
import _ from "lodash";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  mixins: [formMixin],

  components: {
    ValidationError,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
  },

  data() {
    return {
      datePickerfrom: new Date(),
      datePickerto: new Date(),

      search: "",
      searchQuery: "",
      propsToSearch: ["attributes.name"],
      tableColumns: [
        {
          prop: "attributes.license_code",
          propid: "id",
          propdate: "attributes.expiry_date",
          propdisplay: "attributes.display_use",
          propadd: "attributes.display_number",
          label1: "Code",
          label2: "Deadline",
          label3: "Store",
          label4: "Usable Displays",
          label5: "Displays In use",
          label6: "Actions",
          minWidth: 100,
        },
      ],
      currentPage: 1,
      tableData: [],
      table: [],
      query: "",
      pdffile: [],
      searchedData: [],
      fuseSearch: null,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
      },
    };
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      // console.log(highBound);
      if (this.tableData.length < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.length;
    },
  },
  methods: {
    getListDebounced: _.debounce(function () {
      this.getlicenses();
    }, 300),

    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    setCurrentPage(direction) {
      if (direction === -1 && this.pagination.currentPage > 1) {
        this.pagination.currentPage -= 1;
      } else if (
        direction === 1 &&
        this.pagination.currentPage <
          this.tableData.data.length / this.pagination.perPage
      ) {
        this.pagination.currentPage += 1;
      }
    },

    async getlicenses() {
      let params = {
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.query ? { searchValue: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };
      await this.$store.dispatch("licenses/list", params);
      this.tableData = await this.$store.getters["licenses/license_list"].data;
    },

    getpdf(id) {
      axios({
        url: `${url}/generate-pdf/` + id,
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "zends.pdf");
        document.body.appendChild(link);
        link.click();
      });
    },
    dateWiseSorting() {
      var date = { fromDate: this.datePickerfrom, toDate: this.datePickerto };
      axios.post(`${url}/date-wise-sorting`, date).then((response) => {
        this.tableData = response.data;
      });
    },

    goToEdit(id) {
      this.$router.push({ name: "Edit License", params: { id } });
    },
  },

  mounted() {
    // Fuse search initialization.
    (this.fuseSearch = new Fuse(this.tableData, {
      keys: ["attributes.name"],
      threshold: 0.3,
    })),
      this.getlicenses();
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getlicenses",
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}
</style>
