<template>
  <div>
    <div class="content">
      <div class="row mt-3">
        <div class="col-12">
          <h4 slot="header" class="card-title">
            {{ $t("GLOBAL VIEWS DIVIDED BY CONTENT") }}
          </h4>
          <card card-body-classes="table-full-width">
            <div class="row">
              <div class="col-md-4">
                <card>
                  <h4 slot="header" class="card-title">{{ $t("From") }}</h4>
                  <base-input>
                    <el-date-picker
                      type="date"
                      :placeholder="$t('Date Picker')"
                      v-model="datePickerfrom"
                    >
                    </el-date-picker>
                  </base-input>
                </card>
              </div>
              <div class="col-md-4">
                <card>
                  <h4 slot="header" class="card-title">{{ $t("To") }}</h4>
                  <base-input>
                    <el-date-picker
                      type="date"
                      :placeholder="$t('Date Picker')"
                      v-model="datePickerto"
                    >
                    </el-date-picker>
                  </base-input>
                </card>
              </div>
            </div>

            <base-button class="mt-3" native-type="refresh" type="primary">{{
              $t("Update")
            }}</base-button>
          </card>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h4 slot="header" class="card-title">
            {{ $t("GLOBAL VIEWS DIVIDED BY CLICK") }}
          </h4>
          <card card-body-classes="table-full-width">
            <div class="row">
              <div class="col-md-4">
                <card>
                  <h4 slot="header" class="card-title">{{ $t("From") }}</h4>
                  <base-input>
                    <el-date-picker
                      type="date"
                      :placeholder="$t('Date Picker')"
                      v-model="datePickerfrom"
                    >
                    </el-date-picker>
                  </base-input>
                </card>
              </div>
              <div class="col-md-4">
                <card>
                  <h4 slot="header" class="card-title">{{ $t("To") }}</h4>
                  <base-input>
                    <el-date-picker
                      type="date"
                      :placeholder="$t('Date Picker')"
                      v-model="datePickerto"
                    >
                    </el-date-picker>
                  </base-input>
                </card>
              </div>
            </div>

            <base-button class="mt-3" native-type="refresh" type="primary">{{
              $t("Update")
            }}</base-button>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DatePicker } from "element-ui";
import formMixin from "@/mixins/form-mixin";

export default {
  mixins: [formMixin],

  components: {
    [DatePicker.name]: DatePicker,
  },

  data() {
    return {
      datePickerfrom: new Date(),
      datePickerto: new Date(),
    };
  },
};
</script>
