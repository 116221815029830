<template>
  <div class="mt-3">
    <h4>{{ $t("NEW TEMPLATE IMPORT") }}</h4>
    <card class="stacked-form">
      <span v-if="errors && errors">
        <small class="error" v-text="errors" />
      </span>
      <card>
        <div
          class="flex items-center justify-center w-full h-screen text-center"
          id="app"
        >
          <div
            class="p-12 bg-gray-100 border border-gray-300"
            style="height: 100%"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
          >
            <input
              type="file"
              multiple
              name="fields[assetsFieldHandle][]"
              id="assetsFieldHandle"
              class="w-px h-px opacity-0 overflow-hidden absolute"
              @change="onChange"
              ref="file"
              accept=".zip"
            />

            <label for="assetsFieldHandle" class="block cursor-pointer">
              <div style="size: 100%">{{ $t("Drag & Drop file here") }}...</div>
            </label>

            <ul class="mt-1 text-left" v-if="this.filelist.length" v-cloak>
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="(file, index) in filelist"
                  :key="index"
                  v-show="!type_length"
                >
                  <li class="text-sm row mt-3">
                    <div class="">
                      <p>{{ file.filename }}</p>
                      <img
                        :src="file.src"
                        class="preview"
                        height="100px"
                        width="auto"
                      />
                      <br />

                      <base-button
                        @click="remove(filelist.indexOf(file))"
                        size="sm"
                        class="mt-2"
                        native-type="button"
                        type="warning"
                        title="Remove file"
                        >{{ $t("Remove") }}</base-button
                      >
                    </div>
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div style="border: 1px solid black">
          <base-button
            @click="chooseFiles()"
            class="mr-5 mt-3 float-right"
            native-type="submit"
            >{{ $t("Browse") }}..</base-button
          >
          <base-button
            v-if="this.filelist.length"
            @click="submitFile()"
            class="mt-3 float-right"
            native-type="submit"
            type="primary"
            v-show="showupload"
            >{{ $t("Submit") }}
          </base-button>
          <base-button
            v-if="this.filelist.length"
            @click="removeall()"
            class="mt-3 float-right"
            native-type="submit"
            type="warning"
            v-show="showremove"
            >{{ $t("Remove") }}
          </base-button>
        </div>
      </card>
    </card>
  </div>
</template>

<script>
import { extend } from "vee-validate";
import { BaseAlert, BaseProgress } from "src/components";
import axios from "axios";
import ValidationError from "src/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import { BaseCheckbox, BaseRadio } from "src/components/index";
import { required, numeric, regex, confirmed } from "vee-validate/dist/rules";
import Editor from "@tinymce/tinymce-vue";

extend("required", required);
extend("numeric", numeric);
extend("regex", regex);
extend("confirmed", confirmed);

const url = process.env.VUE_APP_API_BASE_URL;

function sort(valuePath, array) {
  let path = valuePath.split(".");
  return array.sort((a, b) => {
    return getValue(a, path) - getValue(b, path);
  });

  function getValue(obj, path) {
    path.forEach((path) => (obj = obj[path]));
    return obj;
  }
}

export default {
  components: {
    ValidationError,
    BaseInput,
    BaseCheckbox,
    BaseRadio,
    BaseProgress,
    editor: Editor,
    BaseAlert,
  },

  mixins: [formMixin],

  data: () => ({
    content: "html",

    filelist: [], // Store our uploaded files
    show: false,
    type_length: false,
    showconfirm: false,

    updatepack: {
      type: "updatepackage",
      name: null,
    },
    errors: null,
    responsesubmit: null,
    responsefile: null,

    weather_url: "http://192.168.1.65/widgets/generalWeather/?",
    url: "",

    group: {
      type: "groups",
    },
    radios: {
      radio1: null,
    },
    myList: [],
    errors: null,
    // editor: ClassicEditor,
    // editorConfig: {
    // toolbar: [
    //   "heading",
    //   "|",
    //   "bold",
    //   "italic",
    //   "link",
    //   "bulletedList",
    //   "numberedList",
    //   "blockQuote",
    //   "Outdent",
    //   "Indent",
    //   "TextColor"
    // ],
    // },
    item: {
      type: "items",
      name: null,
      description: null,
      excerpt: null,
      status: "published",
      category: {
        id: null,
        type: "categories",
      },
      tags: [],
      is_on_homepage: false,
      date_at: null,
      relationshipNames: ["category", "tags"],
    },
    showupload: true,
  }),

  methods: {
    capital_letter(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },

    removeall(i) {
      this.filelist = [];
      this.showconfirm = false;
      this.showremove = false;
      this.showupload = false;
    },

    async add() {
      this.errors = "";
      var next = false;
      var i = 0;
      Object.keys(this.myList).forEach((key) => {
        if (this.myList[key].attributes.content_order != 0) {
          i++;
        }
      });
      Object.keys(this.group).forEach((key) => {
        if (Object.keys(this.group).length != i + 1) {
          this.errors = "* Field is required";
          next = true;
        } else if (this.group[key] == "" || this.group[key] == null) {
          this.errors = "* Field is required";
          next = true;
        }
      });
      if (next == true) {
        return false;
      }
      try {
        axios.post(`${url}/groupadd`, this.group).then((response) => {});
        this.$notify({
          type: "success",
          message: this.$t("Group added successfully."),
          icon: "tim-icons icon-bell-55",
        });
        // this.goBack();
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
        this.setApiValidation(e.response.data.errors);
      }
    },
    goBack() {
      this.$router.push({ name: "List Content" });
    },
    getgroup() {
      axios.get(`${url}/categories`).then((response) => {
        this.myList = sort("attributes.content_order", response.data.data);
      });
    },

    chooseFiles() {
      document.getElementById("assetsFieldHandle").click();
    },

    onChange(e) {
      this.filelists = [...this.$refs.file.files];
      this.showremove = true;
      this.showupload = true;
      if (this.filelists.length < 51) {
        for (let i = 0; i < this.filelists.length; i++) {
          let img = {
            src: URL.createObjectURL(this.filelists[i]),
            file: this.filelists[i],
            filename: this.filelists[i].name,
            filetype: this.filelists[i].type.split("/")[0],
          };

          this.filelist.push(img);
        }
      } else {
        swal.fire({
          title: $t("Maximum limit reach"),
          showCancelButton: false,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          timer: 2000,
          text: $t("Maximum allowed limit of 50 files"),
          buttonsStyling: false,
        });
      }

      // if (this.filelist.length != 0) {
      //   this.show = true;
      //   this.type_length = true;
      //   this.showupload = false;
      //   // } else if (this.filelist[0].type != "image/jpeg" || "application/pdf") {
      //   //     this.show = true;
      //   //      this.showupload = false;
      // }
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.showconfirm = false;
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },

    async submitFile() {
      let formData = new FormData();
      if (this.filelist.length > 50) {
        alert("Maximum Limit reached.");
      }
      for (var i = 0; i < this.$refs.file.files.length; i++) {
        let file = this.$refs.file.files[i];
        formData.append("files[" + i + "]", file);
      }

      axios
        .post(`${url}/importtemplate`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {});
      this.$notify({
        type: "success",
        message: this.$t("Files Uploaded successfully."),
        icon: "tim-icons icon-bell-55",
      });
      setInterval(() => {
        window.location.reload();
      }, 1150).catch(function () {});
    },

    async confirm() {
      this.errors = "";
      if (this.updatepack.name == null) {
        this.errors = "This field is required.";
      } else {
        try {
          const confirmation = await swal.fire({
            title: $t("Confirm update?"),
            showCancelButton: true,
            customClass: {
              confirmButton: "btn btn-success btn-fill",
              cancelButton: "btn btn-danger btn-fill",
            },
            confirmButtonText: "OK",
            buttonsStyling: false,
          });
          if (confirmation.value === true) {
            await this.$store.dispatch("updatepackage/add", this.updatepack);
            this.responsesubmit = await this.$store.getters[
              "updatepackage/updatepack"
            ];

            if (this.responsesubmit) {
              if (this.responsesubmit.message) {
                this.$notify({
                  type: "danger",
                  message: this.responsesubmit.message,
                  icon: "tim-icons icon-bell-55",
                });
              } else {
                this.$notify({
                  type: "success",
                  message: this.$t("Update inserted."),
                  icon: "tim-icons icon-bell-55",
                });
              }
            } else {
              this.$notify({
                type: "danger",
                message: this.$t("Something went wrong."),
                icon: "tim-icons icon-bell-55",
              });
            }
            // window.location.reload();
          }
        } catch (error) {
          this.$notify({
            type: "danger",
            message: this.$t("Request insertion error"),
            icon: "tim-icons icon-bell-55",
          });
        }
      }
    },
  },
  mounted() {
    this.getgroup();
  },
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}

.w-px {
  width: 1px;
}

.absolute {
  position: absolute;
}

.opacity-0 {
  opacity: 0;
}

.p-12 {
  padding: 3rem;
}

.border {
  border-width: 1px;
}

.border-gray-300 {
  border-color: #e2e8f0;
}

.bg-gray-100 {
  background-color: #f7fafc;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-item {
  border: none !important;
}
</style>
