<template>
  <div>
    <h4>{{ $t("CHANGE LICENSE") }}</h4>
    <card class="stacked-form">
      {{ $t("SELECTED GROUP") }} :
      <h4>
        <span v-for="table in tableData" :key="table.id"
          >{{ table.gdata }} |
        </span>
      </h4>

      <div class="col-12 text-right">
        <base-button
          @click="goBack"
          type="button"
          class="btn btn-sm btn-primary"
          >{{ $t("Back to list") }}</base-button
        >
      </div>

      <hr />

      <h5>{{ $t("License Code") }} : {{ tableData[0].license_code }}</h5>
      <h5>
        {{ $t("License Type") }} : <b>{{ tableData[0].license_type }}</b>
      </h5>

      <hr />

      <form @submit.prevent>
        <div>
          <base-input
            v-model="license.display_number"
            :label="$t('Display Number')"
            :placeholder="$t('Display Number')"
          />
          <validation-error :errors="apiValidationErrors.display_number" />

          <base-input
            v-model="license.display_use"
            :label="$t('Display Associated With The License')"
            disabled
          />

          <base-input label="Expiry Date" placeholder="Date">
            <el-date-picker
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
              :placeholder="$t('Expiry Date')"
              v-model="license.expiry_date"
            >
            </el-date-picker>
          </base-input>
          <validation-error :errors="apiValidationErrors.expiry_date" />

          <base-input label="Gb Associated" v-model="license.gb">
            <el-select
              v-model="license.gb"
              class="select-primary"
              size="large"
              placeholder="1"
            >
              <el-option
                v-for="option in selects.gb"
                class="select-primary"
                :value="option.value"
                :label="option.label"
                :key="option.label"
              >
              </el-option>
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.gb" />

          <hr />

          <div class="row">
            <div class="col-sm-10 checkbox-radios">
              <base-checkbox v-model="license.view_statistics">{{
                $t("Enable view statistics")
              }}</base-checkbox>
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-sm-10 checkbox-radios">
              <base-checkbox v-model="license.webcam_statistics">{{
                $t("Enable Webcam Statistics")
              }}</base-checkbox>
            </div>
          </div>

          <hr />

          <div class="row">
            <div class="col-sm-10 checkbox-radios">
              <base-checkbox v-model="license.wifi_statistics">{{
                $t("Enable Wi-Fi statistics")
              }}</base-checkbox>
            </div>
          </div>

          <hr />

          <base-button
            @click="update()"
            class="mt-3"
            native-type="submit"
            type="primary"
            >{{ $t("Submit") }}</base-button
          >
        </div>
      </form>
    </card>
  </div>
</template>

<script>
import ValidationError from "src/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import { DatePicker, Select, Option } from "element-ui";

import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  components: {
    ValidationError,

    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
  },

  mixins: [formMixin],

  data() {
    const id = this.$route.params.id;

    return {
      table: [],
      tableData: [],
      selects: {
        simple: "",
        gbs: "",
        licenses: [
          { value: "Quarrel", label: "Quarrel" },
          { value: "Gold", label: "Gold" },
        ],
        gb: [
          { value: "1", label: "1" },
          { value: "2", label: "2" },
          { value: "3", label: "3" },
          { value: "4", label: "4" },
          { value: "5", label: "5" },
          { value: "6", label: "6" },
          { value: "7", label: "7" },
          { value: "8", label: "8" },
          { value: "9", label: "9" },
          { value: "10", label: "10" },
          { value: "11", label: "11" },
          { value: "12", label: "12" },
          { value: "13", label: "13" },
          { value: "14", label: "14" },
          { value: "15", label: "15" },
          { value: "16", label: "16" },
          { value: "17", label: "17" },
          { value: "18", label: "18" },
          { value: "19", label: "19" },
          { value: "Unlimited", label: "Unlimited" },
        ],
      },

      license: {
        type: "licenses",
        display_number: null,
        display_use: null,
        group_id: id,
        expiry_date: "",
        gb: "",
        view_statistics: false,
        webcam_statistics: false,
        wifi_statistics: false,
      },
    };
  },
  created() {
    this.get();
  },

  methods: {
    getgroup() {
      const id = this.$route.params.id;
      axios.get(`${url}/getalldata/${id}`).then((response) => {
        this.tableData = response.data;
      });
    },

    //    getlicense() {
    //        const id = this.$route.params.id
    // axios
    //   .get(`${url}/licenses/${id}`)
    //   .then((response) => {
    //     console.log(response.data.data);
    //      this.table = response.data.data;
    //   })
    //    },

    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("licenses/get", id);
        this.license = this.$store.getters["licenses/license"];
      } catch (e) {
        this.$notify({
          type: "success",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
      }
    },

    async update() {
      if (
        this.$isDemo == 1 &&
        ["1", "2", "3"].includes(this.$route.params.id)
      ) {
        this.$notify({
          type: "danger",
          message: this.$t(
            "You are not allowed to change data of default roles."
          ),
          icon: "tim-icons icon-bell-55",
        });
        return;
      }
      try {
        // console.log(this.license)
        await this.$store.dispatch("licenses/update", this.license);
        this.$notify({
          type: "success",
          message: this.$t("License updated successfully."),
          icon: "tim-icons icon-bell-55",
        });
        this.goBack();
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
        this.setApiValidation(e.response.data.errors);
      }
    },

    goBack() {
      this.$router.push({ name: "New License" });
    },
  },

  mounted() {
    this.getgroup();
  },
};
</script>
