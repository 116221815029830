<template>
  <div class="content">
    <div class="row mt-3">
      <div class="col-12">
        <h4 slot="header" class="card-title">{{ $t("LICENSE LIST") }}</h4>
        <card card-body-classes="table-full-width">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('Search records')"
                  v-model="query"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th @click="sort('license_code')" class="table-header">
                    <span>
                      {{ $t("Code") }}
                      <SortIcon
                        :topColor="computedTopColor('license_code', 'top')"
                        :bottomColor="
                          computedTopColor('license_code', 'bottom')
                        "
                      />
                    </span>
                  </th>
                  <th @click="sort('expiry_date')" class="table-header">
                    <span>
                      {{ $t("Deadline")
                      }}<SortIcon
                        :topColor="computedTopColor('expiry_date', 'top')"
                        :bottomColor="computedTopColor('expiry_date', 'bottom')"
                      />
                    </span>
                  </th>
                  <th @click="sort('gdata')" class="table-header">
                    <span>
                      {{ $t("Store") }}
                      <SortIcon
                        :topColor="computedTopColor('gdata', 'top')"
                        :bottomColor="computedTopColor('gdata', 'bottom')"
                      />
                    </span>
                  </th>
                  <th @click="sort('display_number')" class="table-header">
                    <span>
                      {{ $t("Usable Displays") }}
                      <SortIcon
                        :topColor="computedTopColor('display_number', 'top')"
                        :bottomColor="
                          computedTopColor('display_number', 'bottom')
                        "
                      />
                    </span>
                  </th>
                  <th @click="sort('display_use')" class="table-header">
                    <span>
                      {{ $t("Display in Use") }}
                      <SortIcon
                        :topColor="computedTopColor('display_use', 'top')"
                        :bottomColor="computedTopColor('display_use', 'bottom')"
                      />
                    </span>
                  </th>
                  <th>{{ $t("action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(tab, key) in filteredAndSortedBlogs" :key="key">
                  <td scope="row">{{ tab[0].license_code }}</td>
                  <td scope="row">{{ tab[0].expiry_date }}</td>
                  <td scope="row">
                    <span v-for="(t, k) in tab" :key="k">{{ t.gdata }} | </span>
                  </td>
                  <td scope="row">{{ tab[0].display_number }}</td>
                  <td scope="row">{{ tab[0].display_use }}</td>
                  <td scope="row">
                    <button
                      @click="destroy(tab[0].license_code)"
                      title="Delete"
                      class="'delete btn-link"
                      type="success"
                      style="color: var(--primary)"
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </button>
                    <button
                      @click="goToEdit(tab[0].license_code)"
                      title="Edit"
                      class="'edit btn-link"
                      type="success"
                      style="color: var(--primary)"
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </button>
                    <button
                      @click="getpdf(tab[0].id)"
                      title="PDF"
                      class="'delete btn-link"
                      type="success"
                      style="color: var(--primary)"
                    >
                      <i class="tim-icons icon-paper"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
                {{ $t("of") }} {{ total }} {{ $t("entries") }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";

import _ from "lodash";
import { BasePagination } from "src/components";
// import users from "./users";
import swal from "sweetalert2";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "src/components/ValidationError.vue";
import SortIcon from "@/assets/customImg/SortIcon.vue";
import { getTopColor, getBottomColor } from "../../util/utils";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  mixins: [formMixin],

  components: {
    SortIcon,
    ValidationError,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      sortparam: {
        field: "license_code",
        sort: "asc",
      },
      currentPage: 1,
      query: "",
      propsToSearch: ["firstname"],
      tableData: [],
      group: [],
      licenses: [],
      searchedData: [],
      fuseSearch: null,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
      },
      currentSort: "group_name",
      currentSortDir: "asc",
    };
  },

  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.tableData.total < highBound) {
        highBound = this.total;
      }

      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.total;
    },
    filteredAndSortedBlogs() {
      return this.tableData.data;
      // .sort((a, b) => {
      //   let modifier = 1;
      //   if (this.currentSortDir === 'desc') modifier = -1;
      //   if (a[this.currentSort] < b[this.currentSort]) ;  -1 * modifier;
      //   if (a[this.currentSort] > b[this.currentSort]) ; return 1 * modifier;
      //   return 0;
      // })
    },
  },

  methods: {
    computedTopColor(table, arrowIcon) {
      if (arrowIcon === "top") {
        return getTopColor(table, this.sortparam);
      } else {
        return getBottomColor(table, this.sortparam);
      }
    },
    getListDebounced: _.debounce(function () {
      this.getlicenses();
    }, 300),

    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
      this.sortparam.field = s;
      this.sortparam.sort = this.sortparam.sort == "asc" ? "desc" : "asc";
      this.getlicenses();
    },

    setCurrentPage(direction) {
      if (direction === -1 && this.currentPage > 1) {
        this.currentPage -= 1;
      } else if (
        direction === 1 &&
        this.currentPage < this.tableData.length / this.tableData.perPage
      ) {
        this.currentPage += 1;
      }
    },

    async getlicenses() {
      let params = {
        sort: {
          ...this.sortparam,
        },
        // ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.query ? { searchValue: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };
      await this.$store.dispatch("licenses/list", params);
      this.tableData = await this.$store.getters["licenses/license_list"];
    },

    getpdf(id) {
      axios({
        url: `${url}/generate-pdf/` + id,
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "zends.pdf");
        document.body.appendChild(link);
        link.click();
      });
    },
    goToEdit(id) {
      this.$router.push({ name: "Edit License", params: { id } });
    },
    async destroy(code) {
      try {
        const confirmation = await swal.fire({
          title: this.$t("Are you sure?"),
          text: this.$t("You want to delete the license!"),
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: this.$t("Yes, delete it!"),
          buttonsStyling: false,
        });

        if (confirmation.value === true) {
          await axios.post(`${url}/removelicense/${code}`);
          this.$notify({
            type: "success",
            message: this.$t("License deleted successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.getlicenses();
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
      }
    },
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getlicenses",
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.getlicenses();
  },
};
</script>
<style>
.table > thead > tr > th,
.el-table table > thead > tr > th {
  font-size: 1rem;
}

.white-content .table > tbody > tr > td {
  padding: 5px;
}
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}
</style>
