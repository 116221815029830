<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12 text-center">
        <h3 class="mt-5 card-title" v-if="activeStep === 1">
          {{ $t("ADD USER") }}
        </h3>
        <h3 class="mt-5 card-title" v-if="activeStep === 2">
          {{ $t("NEW GROUP CREATION") }}
        </h3>
        <h3 class="mt-5 card-title" v-if="activeStep === 3">
          {{ $t("NEW LICENSE CREATION") }}
        </h3>
        <div class="multisteps-form mb-5">
          <div class="row">
            <div class="col-12 col-lg-8 mx-auto my-5">
              <div class="card add-user-container">
                <div
                  class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                >
                  <div
                    class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3"
                  >
                    <el-steps :active="activeStep" :align-center="true">
                      <el-step title="Add user" />
                      <el-step title="Create group" />
                      <el-step title="Add License" />
                    </el-steps>
                  </div>
                </div>
                <div class="card-body" v-if="activeStep === 1">
                  <div class="multisteps-form__form">
                    <div
                      class="multisteps-form__panel js-active"
                      data-animation="FadeIn"
                    >
                      <div class="multisteps-form__content">
                        <div class="row mt-3">
                          <div
                            class="col-12 col-sm-4 d-flex justify-content-center align-self-center"
                          >
                            <div
                              class="user-avatar avatar-xxl position-relative"
                            >
                              <img
                                src="/img/placeholder.jpg"
                                class="border-radius-md"
                                alt="team-2"
                              />
                            </div>
                          </div>
                          <div class="col-12 col-sm-8 mt-4 mt-sm-0 text-start">
                            <form @submit.prevent>
                              <div>
                                <validation-error
                                  :errors="apiValidationErrors.attachment"
                                />
                                <base-input
                                  v-model="user.name"
                                  :label="$t('Name')"
                                  :placeholder="$t('Name')"
                                />
                                <validation-error
                                  :errors="apiValidationErrors.name"
                                />
                                <base-input
                                  v-model="user.email"
                                  :label="$t('Email')"
                                  type="email"
                                  :placeholder="$t('Enter email')"
                                />
                                <validation-error
                                  :errors="apiValidationErrors.email"
                                />
                                <base-input
                                  label="User Type"
                                  v-model="user.role"
                                >
                                  <el-select
                                    class="select-primary"
                                    name="role"
                                    v-model="user.roles[0].id"
                                  >
                                    <el-option
                                      v-for="role in available_roles"
                                      :key="role.id"
                                      :value="role.id"
                                      :label="role.name"
                                    >
                                    </el-option>
                                  </el-select>
                                </base-input>
                                <validation-error
                                  :errors="apiValidationErrors.roles"
                                />
                              </div>
                              <div
                                class="list-item"
                                v-for="item in myList"
                                :id="item.id"
                                :order="item.attributes.user_order"
                                :key="item.id"
                                v-if="
                                  item.attributes.user_order != 0 &&
                                  item.attributes.user_order != null
                                "
                              >
                                <base-input
                                  v-model="
                                    group[item.attributes.name + '_' + item.id]
                                  "
                                  :label="capital_letter(item.attributes.name)"
                                  :placeholder="
                                    capital_letter(item.attributes.name)
                                  "
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                        <div class="button-row d-flex justify-content-end mt-4">
                          <base-button
                            @click="add()"
                            class="btn bg-primary ms-auto mb-0 js-btn-next"
                            native-type="submit"
                            type="primary"
                            >{{ $t("Submit") }}</base-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body" v-if="activeStep === 2">
                  <div class="multisteps-form__form">
                    <div
                      class="multisteps-form__panel js-active"
                      data-animation="FadeIn"
                    >
                      <div class="multisteps-form__content">
                        <div class="row mt-3">
                          <div class="col-12 mt-4 mt-sm-0 text-start">
                            <span v-if="errors && errors">
                              <small class="error" v-text="errors" />
                            </span>
                            <form @submit.prevent>
                              <div
                                class="list-item"
                                v-for="item in myList"
                                :id="item.id"
                                :order="item.attributes.store_order"
                                :key="item.id"
                                v-if="
                                  item.attributes.store_order != 0 &&
                                  item.attributes.store_order != null
                                "
                              >
                                <base-input
                                  type="text"
                                  v-model="
                                    groups[item.attributes.name + '_' + item.id]
                                  "
                                  :label="capital_letter(item.attributes.name)"
                                  :placeholder="
                                    capital_letter(item.attributes.name)
                                  "
                                  required
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                        <div class="button-row d-flex justify-content-end mt-4">
                          <base-button
                            @click="addGroup()"
                            class="mt-3"
                            native-type="submit"
                            type="primary"
                            >{{ $t("Submit") }}</base-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body" v-if="activeStep === 3">
                  <div class="multisteps-form__form">
                    <div
                      class="multisteps-form__panel js-active"
                      data-animation="FadeIn"
                    >
                      <div class="multisteps-form__content">
                        <div class="row mt-3">
                          <div class="col-12 mt-4 mt-sm-0 text-start">
                            <card class="stacked-form">
                              {{ $t("SELECTED GROUP") }} :
                              <h4>
                                <span
                                  v-for="table in tableData"
                                  :key="table.id"
                                  v-if="table.storeparamvalue == null"
                                ></span>
                                <span v-else
                                  >{{ table.storeparamvalue }} |
                                </span>
                              </h4>
                              <hr />
                              <form @submit.prevent>
                                <div>
                                  <base-input-hidden
                                    v-model="license.license_code"
                                  />

                                  <base-input-hidden
                                    v-model="license.group_id"
                                  />

                                  <div class="row">
                                    <div class="col-md-6">
                                      <base-input
                                        v-model="license.display_number"
                                        :label="$t('Display Number')"
                                        :placeholder="$t('Display Number')"
                                      />
                                      <validation-error
                                        :errors="
                                          apiValidationErrors.display_number
                                        "
                                      />
                                    </div>

                                    <div class="col-md-6">
                                      <base-input
                                        :label="$t('Expiry Date')"
                                        placeholder="Date"
                                      >
                                        <el-date-picker
                                          format="yyyy-MM-dd"
                                          value-format="yyyy-MM-dd"
                                          type="date"
                                          :placeholder="$t('Expiry Date')"
                                          v-model="license.expiry_date"
                                        >
                                        </el-date-picker>
                                      </base-input>
                                      <validation-error
                                        :errors="
                                          apiValidationErrors.expiry_date
                                        "
                                      />
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-6">
                                      <base-input :label="$t('License Type')">
                                        <el-select
                                          class="select-primary"
                                          size="large"
                                          :placeholder="$t('Lite')"
                                          v-model="license.license_type"
                                        >
                                          <el-option
                                            v-for="option in selects.licenses"
                                            class="select-primary"
                                            :value="option.value"
                                            :label="option.label"
                                            :key="option.label"
                                          >
                                          </el-option>
                                        </el-select>
                                      </base-input>
                                      <validation-error
                                        :errors="
                                          apiValidationErrors.license_type
                                        "
                                      />
                                    </div>

                                    <div class="col-md-6">
                                      <base-input
                                        label="Gb Associated"
                                        v-model="license.gb"
                                      >
                                        <el-select
                                          v-model="license.gb"
                                          class="select-primary"
                                          size="large"
                                          placeholder="1"
                                        >
                                          <el-option
                                            v-for="option in selects.gb"
                                            class="select-primary"
                                            :value="option.value"
                                            :label="option.label"
                                            :key="option.label"
                                          >
                                          </el-option>
                                        </el-select>
                                      </base-input>
                                    </div>
                                  </div>

                                  <hr />

                                  <div class="row">
                                    <div class="col-md-4 checkbox-radios">
                                      <base-checkbox
                                        v-model="license.webcam_statistics"
                                        >{{
                                          $t("Enable Webcam Statistics")
                                        }}</base-checkbox
                                      >
                                    </div>
                                    <div class="col-md-4 checkbox-radios">
                                      <base-checkbox
                                        v-model="license.wifi_statistics"
                                        >{{
                                          $t("Enable Wi-Fi statistics")
                                        }}</base-checkbox
                                      >
                                    </div>
                                    <div class="col-md-4 checkbox-radios">
                                      <base-checkbox
                                        v-model="license.wifi_statistics"
                                        >{{
                                          $t("Enable Wi-Fi statistics")
                                        }}</base-checkbox
                                      >
                                    </div>
                                  </div>
                                  <el-radio-group
                                    v-model="shapeRadioButton"
                                    class="mt-4"
                                  >
                                    <el-radio
                                      label="Landscape"
                                      size="large"
                                      border
                                      >Landscape</el-radio
                                    >
                                    <el-radio
                                      label="vertical"
                                      size="large"
                                      border
                                      >vertical</el-radio
                                    >
                                  </el-radio-group>
                                </div>
                              </form>
                            </card>
                          </div>
                        </div>
                        <div class="button-row d-flex justify-content-end mt-4">
                          <base-button
                            @click="addmultidefalutcontent()"
                            class="mt-3"
                            native-type="submit"
                            type="primary"
                            >{{ $t("Submit") }}</base-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { ImageUpload } from "src/components/index";
import {
  DatePicker,
  Select,
  Option,
  Steps,
  Step,
  RadioGroup,
  Radio,
} from "element-ui";
import ValidationError from "src/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import axios from "axios";
import qs from "qs";
import Jsona from "jsona";
import callDefaultApiMixin from "../../mixins/call-default-api-mixin";
const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function sort(valuePath, array) {
  let path = valuePath.split(".");
  return array.sort((a, b) => {
    return getValue(a, path) - getValue(b, path);
  });

  function getValue(obj, path) {
    path.forEach((path) => (obj = obj[path]));
    return obj;
  }
}
export default {
  components: {
    ValidationError,
    ImageUpload,
    [Option.name]: Option,
    [Select.name]: Select,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [DatePicker.name]: DatePicker,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },

  mixins: [formMixin, callDefaultApiMixin],

  data: () => ({
    user: {
      type: "users",
      name: null,
      email: null,
      password: null,
      password_confirmation: null,
      role: null,
      roles: [
        {
          id: null,
          type: "roles",
        },
      ],
      relationshipNames: ["roles"],
    },
    myList: [],
    group: [],
    shapeRadioButton: "Landscape",
    available_roles: [],
    activeStep: 1,
    groups: {
      type: "groups",
    },
    errors: null,
    tableData: [],
    selects: {
      simple: "",
      gbs: "",
      licenses: [
        { value: "Lite", label: "Lite" },
        { value: "Gold", label: "Gold" },
        { value: "Gold Touch", label: "Gold Touch" },
      ],
      gb: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" },
        { value: "13", label: "13" },
        { value: "14", label: "14" },
        { value: "15", label: "15" },
        { value: "16", label: "16" },
        { value: "17", label: "17" },
        { value: "18", label: "18" },
        { value: "19", label: "19" },
        { value: "Unlimited", label: "Unlimited" },
      ],
    },

    license: {
      type: "licenses",
      display_number: null,
      group_id: "", // addd group id
      expiry_date: "",
      license_type: "Lite",
      gb: "1",
      view_statistics: false,
      webcam_statistics: false,
      wifi_statistics: false,
      license_code: Math.random().toString(36),
      orientation: 0,
    },
    user_id: "",
  }),

  created() {
    this.getRoles();
    this.license.expiry_date = this.getCurrentDate();
  },

  methods: {
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const monthIndex = today.getMonth();
      const day = String(today.getDate()).padStart(2, "0");
      const month = String(monthIndex + 1).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
    capital_letter(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
    onAvatarChange(file) {
      this.images.avatar = file;
    },
    async add() {
      try {
        this.resetApiValidation();
        delete this.user.id;

        await this.$store.dispatch("users/add", this.user);
        var email = {
          email: this.user.email,
        };
        this.group.email = this.user.email;
        this.group.type = "user";
        this.user = await this.$store.getters["users/user"];
        this.user_id = this.user.id;
        this.sendMail(email);
        this.addGroupValue(this.group);
        this.$notify({
          type: "success",
          message: this.$t("User added successfully."),
          icon: "tim-icons icon-bell-55",
        });
        this.activeStep = 2;
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
        this.setApiValidation(e.response.data.errors);
        if (this.user.id) {
          await this.$store.dispatch("users/destroy", this.user.id);
        }
      }
    },
    addGroup() {
      this.errors = "";
      var next = false;
      var i = 0;
      Object.keys(this.myList).forEach((key) => {
        if (this.myList[key].attributes.store_order != 0) {
          i++;
        }
      });
      Object.keys(this.groups).forEach((key) => {
        if (Object.keys(this.groups).length != i + 1) {
          this.errors = "* Field is required";
          next = true;
        } else if (this.groups[key] == "" || this.groups[key] == null) {
          this.errors = "* Field is required";
          next = true;
        }
      });
      if (next == true) {
        return false;
      }
      try {
        this.groups.user_id = this.user_id;
        axios.post(`${url}/groupadd`, this.groups).then((response) => {
          this.license.group_id = response.data;
          this.addGroupToUser(response.data);
          this.getLicenseRealatedGroup(this.license.group_id);
          if (response.data == "error") {
            this.$notify({
              type: "danger",
              message: this.$t("Please Fill the correct address."),
              icon: "tim-icons icon-bell-55",
            });
          } else {
            this.$notify({
              type: "success",
              message: this.$t("Group added successfully."),
              icon: "tim-icons icon-bell-55",
            });
            this.activeStep = 3;
          }
        });
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
        this.setApiValidation(e.response.data.errors);
      }
    },
    validate() {
      if (
        this.license.display_number === null &&
        this.license.expiry_date === ""
      ) {
        this.$notify({
          type: "danger",
          message: this.$t("All fields are required."),
          icon: "tim-icons icon-bell-55",
        });
        return false;
      } else if (this.license.display_number === null) {
        this.$notify({
          type: "danger",
          message: this.$t("Display number fields are required."),
          icon: "tim-icons icon-bell-55",
        });
        return false;
      } else if (this.license.expiry_date === "") {
        this.$notify({
          type: "danger",
          message: this.$t("Expiry date fields are required."),
          icon: "tim-icons icon-bell-55",
        });
        return false;
      }
      return true;
    },
    async addLicense() {
      this.license.view_statistics = this.license.view_statistics ? 1 : 0;
      this.license.webcam_statistics = this.license.webcam_statistics ? 1 : 0;
      this.license.wifi_statistics = this.license.wifi_statistics ? 1 : 0;
      this.license.orientation = this.shapeRadioButton === "Landscape" ? 0 : 1;

      try {
        if (this.validate()) {
          const payload = jsona.serialize({
            stuff: this.license,
            includeNames: null,
          });

          const options = {
            headers: {
              Accept: "application/vnd.api+json",
              "Content-Type": "application/vnd.api+json",
            },
          };

          await axios
            .post(`${url}/addlicense`, payload, options)
            .then(async (response) => {
              const licenseData = response.data;
              this.$notify({
                type: "success",
                message: this.$t("License added successfully."),
                icon: "tim-icons icon-bell-55",
              });
              await this.associateLicenseWithGroup(
                licenseData.data.license_code
              );
              swal.close();
              this.goBack();
            });
        }
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
        this.setApiValidation(e.response.data.errors);
      }
    },
    sendMail(email) {
      axios.post(`${url}/send-mail`, email).then((response) => {});
    },
    addGroupValue(group_data) {
      axios
        .post(`${url}/groupadd`, qs.stringify(group_data, { encode: true }))
        .then((response) => {});
    },

    getRoles() {
      this.$store.dispatch("roles/list").then(() => {
        this.available_roles = this.$store.getters["roles/dropdown"];
        const data = this.available_roles.find((e) => e.name === "Manager");
        this.user.roles[0].id = data.id;
      });
    },
    getgroup() {
      axios.get(`${url}/categories`).then((response) => {
        this.myList = sort("attributes.user_order", response.data.data);
      });
    },

    goBack() {
      this.$router.push({ name: "List Users" });
    },

    getLicenseRealatedGroup(id) {
      axios.get(`${url}/getidvalue/${id}`).then((response) => {
        this.tableData = response.data;
      });
    },
    showPopup(isVisible) {
      if (isVisible) {
        swal.fire({
          title: "User Default Information",
          html: `
        <div>
          <div id="loader-container">
            <div class="loader"></div>
            <p>Processing...</p>
          </div>
        </div>`,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    },
  },
  mounted() {
    this.getgroup();
  },
};
</script>

<style>
.border-radius-lg {
  border-radius: 0.5rem;
}
.shadow-primary {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(64, 64, 64, 0.4) !important;
}
.el-steps.is-horizontal .el-steps__line {
  background-color: white; /* Change to your desired color */
}

.el-step__title.is-finish {
  color: hsla(0, 0%, 100%, 0.7);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1rem;
}
.el-step__title.is-wait,
.el-step__title.is-process {
  color: hsla(0, 0%, 100%, 0.4);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1rem;
}

.el-step__icon-inner {
  color: white;
}

.el-step__head.is-finish {
  color: white !important;
  border-color: white;
}
.el-step__head.is-finish > .el-step__icon.is-text,
.el-step__head.is-finish > .el-step__icon.is-text > .el-step__icon-inner {
  color: white !important;
  border-color: white;
  background-color: white;
}

.el-step__head.is-process,
.el-step__head.is-wait {
  color: #82bfff !important;
  border-color: #82bfff;
}

.el-step__icon.is-text,
.el-step__icon-inner {
  color: #82bfff !important;
  background-color: #82bfff;
  border-color: #82bfff;
  border: 2px solid #82bfff;
}

.user-avatar img {
  display: flex;
  justify-content: center;
  width: 110px !important;
  height: 110px !important;
}
.border-radius-md {
  border-radius: 0.375rem;
}

.form-group.has-label,
.error,
.el-radio-group,
.checkbox-radios {
  text-align: left;
}

.el-radio-group {
  display: block;
}

.add-user-container {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  border-radius: 0.75rem;
}

#loader-container {
  text-align: center;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 10px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
