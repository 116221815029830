<template>
  <div class="content">
    <div class="row mt-3">
      <div class="col-12">
        <h4>{{ $t("PARAMETER ASSIGNMENT AVAILABLE") }}</h4>
        <card card-body-classes="table-full-width">
          <div>
            <form @submit.prevent>
              <table class="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ $t("Name") }}</th>
                    <th>{{ $t("User") }}</th>
                    <th>{{ $t("Store") }}</th>
                    <th>{{ $t("Display") }}</th>
                    <th>{{ $t("Template") }}</th>
                    <th>{{ $t("Section") }}</th>
                    <th>{{ $t("Content") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(tab, key) in tableData" :key="tab.id">
                    <td>
                      <base-input hidden :value="tab.category_id"></base-input
                      >{{ key + 1 }}
                    </td>
                    <td scope="row">{{ tab.category_name }}</td>
                    <td
                      v-for="(t, k) in tab"
                      v-bind:key="t.category_id"
                      v-if="k != 'category_id' && k != 'category_name'"
                    >
                      <input
                        type="checkbox"
                        :name="k + tab.category_id"
                        class="checklist"
                        :value="k + '_' + tab.category_id"
                        :id="tab.category_id"
                        v-if="
                          tab[k] == 1 &&
                          tab.category_name == 'address' &&
                          k == 'store'
                        "
                        checked
                        disabled
                      />
                      <input
                        type="checkbox"
                        :name="k + tab.category_id"
                        class="checklist"
                        :value="k + '_' + tab.category_id"
                        :id="tab.category_id"
                        v-else-if="tab[k] == 1"
                        checked
                      />
                      <input
                        type="checkbox"
                        :name="k + tab.category_id"
                        class="checklist"
                        :id="tab.category_id"
                        :value="k + '_' + tab.category_id"
                        v-if="tab[k] == 0"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <base-button
                @click="add()"
                class="mt-3"
                native-type="submit"
                type="primary"
                >{{ $t("Submit") }}</base-button
              >
            </form>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
                {{ $t("of") }} {{ total }} {{ $t("entries") }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "src/components";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "src/components/ValidationError.vue";

const url = process.env.VUE_APP_API_BASE_URL;
let userDetails = [];
let merged = [];
let paramDetails = [];

export default {
  mixins: [formMixin],

  components: {
    ValidationError,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },

  data() {
    return {
      paramlist: {},
      submitParamlist: {},

      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["attributes.name"],
      tableColumns: [
        {
          prop: "category_name",
          propuser: "user",
          propstore: "store",
          propdisplay: "display",
          proptemplate: "template",
          propsection: "section",
          propcontent: "content",
          propid: "id",
          label: "Parameter Name",
          minWidth: 100,
        },
      ],
      tableData: [],
      table: [],
      searchedData: [],
      fuseSearch: null,
      param: [],
    };
  },
  methods: {
    async add() {
      var lis = document.querySelectorAll(".checklist");
      var obj = {};
      for (var i = 0; i < lis.length; i++) {
        obj[lis[i].value] = lis[i].checked;
      }
      axios.patch(`${url}/paramlist`, obj).then((response) => {
        this.$notify({
          type: "success",
          message: this.$t("Parameter Updated  successfully."),
          icon: "tim-icons icon-bell-55",
        });
      });
    },
    getparam() {
      axios.get(`${url}/paramlist`).then((response) => {
        this.tableData = response.data;
      });
    },
  },
  mounted() {
    this.getparam();
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}
</style>
