<template>
  <div class="mt-3">
    <h4>{{ $t("EDIT CONTAINER") }}</h4>
    <card class="stacked-form">
      <div class="col-12 text-right">
        <base-button
          @click="goBack"
          type="button"
          class="btn btn-sm btn-primary"
          >{{ $t("Back to list") }}</base-button
        >
      </div>
      <br />
      <span v-if="errors && errors">
        <small class="error" v-text="errors" />
      </span>
      <form @submit.prevent>
        <base-input
          type="text"
          v-model="editcontainer.name"
          :label="capital_letter($t('firstname'))"
          :placeholder="capital_letter($t('firstname'))"
          required
        />

        <base-button
          @click="update()"
          class="mt-3"
          native-type="submit"
          type="primary"
          >{{ $t("Submit") }}</base-button
        >
      </form>
    </card>
  </div>
</template>

<script>
import ValidationError from "src/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import axios from "axios";
import { Vue } from "vue";
const url = process.env.VUE_APP_API_BASE_URL;

function sort(valuePath, array) {
  let path = valuePath.split(".");
  return array.sort((a, b) => {
    return getValue(a, path) - getValue(b, path);
  });

  function getValue(obj, path) {
    path.forEach((path) => (obj = obj[path]));
    return obj;
  }
}

export default {
  components: { ValidationError },

  mixins: [formMixin],

  data: () => ({
    editcontainer: [],
    errors: null,
  }),

  created() {
    this.getedit();
  },

  methods: {
    capital_letter(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },

    getedit(id) {
      id = this.$route.params.id;
      axios.get(`${url}/editcontainer/${id}`).then((response) => {
        // response.data.forEach((value, index) => {
        // this.$set(this.my_data, value.name, value.displayparamvalue);
        this.editcontainer = response.data[0];
      });
    },

    async update(id) {
      id = this.$route.params.id;
      this.errors = "";
      var next = false;
      var i = 0;
      // Object.keys(this.myList).forEach(key => {
      //   if(this.myList[key].attributes.display_order != 0){
      //     i++;
      //   }
      // });
      // Object.keys(this.my_data).forEach(key => {
      //   if (Object.keys(this.my_data).length != i) {
      //     this.errors = "* Field is required";
      //     next = true;
      //   }else if(this.my_data[key] == "" || this.my_data[key] == null){
      //         this.errors = "* Field is required";
      //         next = true;
      //       }
      // });
      // if (Object.keys(this.my_data).length != i) {
      //   this.errors = "* Field is required";
      //   next = true;
      // }
      if (next == true) {
        return false;
      }

      axios
        .patch(`${url}/updatecontainer/${id}`, this.editcontainer)
        .then((response) => {
          this.goBack();
        });
    },

    goBack() {
      this.$router.push({ name: "List Container" });
    },
  },
  mounted() {},
};
</script>
