<template>
  <div class="content">
    <div class="row mt-3">
      <div class="col-12">
        <h4>{{ $t("SORTING OF CONTAINED PARAMETERS 132") }}</h4>
        <card card-body-classes="table-full-width">
          <div>
            <form @submit.prevent>
              <draggable v-model="myList">
                <transition-group name="flip-transition">
                  <ul
                    class="list-group"
                    v-for="item in myList"
                    :id="item.id"
                    :order="item.attributes.content_order"
                    :key="item.id"
                    v-if="item.attributes.content_order != 0"
                  >
                    <li class="list-group-item">
                      {{ capital_letter(item.attributes.name)
                      }}<i class="handle"></i>
                    </li>
                  </ul>
                </transition-group>
              </draggable>
              <base-button
                @click="add()"
                class="mt-3"
                native-type="submit"
                type="primary"
                >{{ $t("Submit") }}</base-button
              >
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
const url = process.env.VUE_APP_API_BASE_URL;

function sort(valuePath, array) {
  let path = valuePath.split(".");
  return array.sort((a, b) => {
    return getValue(a, path) - getValue(b, path);
  });

  function getValue(obj, path) {
    path.forEach((path) => (obj = obj[path]));
    return obj;
  }
}

export default {
  components: {
    draggable,
  },
  data() {
    return {
      myList: [],
    };
  },
  methods: {
    capital_letter(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
    getgroup() {
      axios.get(`${url}/categories`).then((response) => {
        this.myList = sort("attributes.content_order", response.data.data);
      });
    },
    add() {
      var lis = document.querySelectorAll(".list-group");
      var arr = [];
      for (var i = 0; i < lis.length; i++) {
        arr.push(+lis[i].id);
      }
      arr = { content_order: arr };
      axios.patch(`${url}/sortorder`, arr).then((response) => {
        this.$notify({
          type: "success",
          message: this.$t("Sorting Contained successfully."),
          icon: "tim-icons icon-bell-55",
        });
      });
      return;
    },
  },
  mounted() {
    this.getgroup();
  },
};
</script>

<style>
.flip-transition-move {
  transition: all 0.7s;
}
</style>
