<template>
  <div class="mt-3">
    <h4>{{ $t("GROUP DETAIL") }}</h4>
    <card>
      <card>
        <div class="row">
          <div class="col-6">
            <div class="card">
              <ul class="list-group">
                <li
                  class="bg-gradient-info list-group-item m-2"
                  style="color: #ffffff"
                >
                  {{ $t("PARAMETERS") }}<i class="handle"></i>
                </li>
              </ul>

              <div id="app" class="m-2">
                <table class="table">
                  <thead>
                    <tr>
                      <th v-for="(table, key) in tableData.title" :key="key">
                        {{ table }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(table, key) in tableData"
                      :key="table.id"
                      v-if="key !== 'title'"
                    >
                      <td v-for="t in table" :key="t.id" v-if="key != ''">
                        {{ t.storeparamvalue }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card">
              <ul class="list-group">
                <li
                  class="bg-gradient-info list-group-item m-2"
                  style="color: #ffffff"
                >
                  {{ $t("MANAGER") }}<i class="handle"></i>
                </li>
              </ul>
              <div class="m-2">
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ $t("User Name") }}</th>
                      <th>{{ $t("Email") }}</th>
                    </tr>
                  </thead>
                  <tbody v-if="users[2]">
                    <tr
                      v-if="
                        users[2][0].associate == 1 && users[2][0].role_id == 2
                      "
                    >
                      <td>{{ users[2][0].username }}</td>
                      <td>{{ users[2][0].useremail }}</td>
                    </tr>
                    <tr v-else></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </card>

      <card>
        <div class="row">
          <div class="col-6">
            <div class="card">
              <ul class="list-group">
                <li
                  class="bg-gradient-info list-group-item m-2"
                  style="color: #ffffff"
                >
                  {{ $t("DESIGNER") }}<i class="handle"></i>
                </li>
              </ul>
              <div class="m-2">
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ $t("User Name") }}</th>
                      <th>{{ $t("Email") }}</th>
                    </tr>
                  </thead>
                  <tbody v-if="users[3]">
                    <tr
                      v-if="
                        users[3][0].associate == 1 && users[3][0].role_id == 3
                      "
                    >
                      <td>{{ users[3][0].username }}</td>
                      <td>{{ users[3][0].useremail }}</td>
                    </tr>
                    <tr v-else></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="card">
              <ul class="list-group">
                <li
                  class="bg-gradient-info list-group-item m-2"
                  style="color: #ffffff"
                >
                  {{ $t("EDITOR") }}<i class="handle"></i>
                </li>
              </ul>
              <div class="m-2">
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ $t("User Name") }}</th>
                      <th>{{ $t("Email") }}</th>
                    </tr>
                  </thead>

                  <tbody v-if="users[4]">
                    <tr
                      v-if="
                        users[4][0].associate == 1 && users[4][0].role_id == 4
                      "
                    >
                      <td>{{ users[4][0].username }}</td>
                      <td>{{ users[4][0].useremail }}</td>
                    </tr>
                    <tr v-else></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </card>

      <card>
        <div class="row">
          <div class="col-4">
            <div class="card">
              <ul class="list-group">
                <li
                  class="bg-gradient-info list-group-item m-2"
                  style="color: #ffffff"
                >
                  {{ $t("AUTHOR") }}<i class="handle"></i>
                </li>
              </ul>
              <div class="m-2">
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ $t("User Name") }}</th>
                      <th>{{ $t("Email") }}</th>
                    </tr>
                  </thead>
                  <tbody v-if="users[5]">
                    <tr
                      v-if="
                        users[5][0].associate == 1 && users[5][0].role_id == 5
                      "
                    >
                      <td>{{ users[5][0].username }}</td>
                      <td>{{ users[5][0].useremail }}</td>
                    </tr>
                    <tr v-else></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="card">
              <ul class="list-group">
                <li
                  class="bg-gradient-info list-group-item m-2"
                  style="color: #ffffff"
                >
                  {{ $t("CONTROLLER TEMPLATE") }}<i class="handle"></i>
                </li>
              </ul>
              <div class="m-2">
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ $t("User Name") }}</th>
                      <th>{{ $t("Email") }}</th>
                    </tr>
                  </thead>
                  <tbody v-if="users[6]">
                    <tr
                      v-if="
                        users[6][0].associate == 1 && users[6][0].role_id == 6
                      "
                    >
                      <td>{{ users[6][0].username }}</td>
                      <td>{{ users[6][0].useremail }}</td>
                    </tr>
                    <tr v-else></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="card">
              <ul class="list-group">
                <li
                  class="bg-gradient-info list-group-item m-2"
                  style="color: #ffffff"
                >
                  {{ $t("WATCHER") }}<i class="handle"></i>
                </li>
              </ul>
              <div class="m-2">
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ $t("User Name") }}</th>
                      <th>{{ $t("Email") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="users[7]">
                      <td
                        v-if="
                          users[7][0].user_id == 7 &&
                          users[7][0].associate == 1 &&
                          users[7][0].role_id == 7
                        "
                      >
                        {{ users[7][0].username }}
                      </td>
                      <td
                        v-if="
                          users[7][0].user_id == 7 &&
                          users[7][0].associate == 1 &&
                          users[7][0].role_id == 7
                        "
                      >
                        {{ users[7][0].useremail }}
                      </td>
                    </tr>
                    <tr v-else></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </card>

      <card>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <ul class="list-group">
                <li
                  class="bg-gradient-info list-group-item m-2"
                  style="color: #ffffff"
                >
                  {{ $t("ASSIGNED DISPLAY") }}<i class="handle"></i>
                </li>
              </ul>

              <div id="app" class="m-2">
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ $t("Display details") }}</th>
                      <th>{{ $t("Code") }}</th>
                      <th>{{ $t("Expiry Date") }}</th>
                      <th>{{ $t("Display associated with the license") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-for="(table, key) in display"
                      v-if="key !== 'title'"
                    >
                      <tr v-for="t in table" :key="table.id">
                        <td>
                          <span
                            v-for="(a, index) in t"
                            :key="index"
                            v-if="key !== ''"
                          >
                            {{ a.displayparamvalue }} |
                          </span>
                        </td>
                        <td v-if="t[0].license_code">
                          {{ t[0].license_code }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="t[0].expiry_date">{{ t[0].expiry_date }}</td>
                        <td v-else>-</td>
                        <td v-if="t[0].id_display">{{ $t("Yes") }}</td>
                        <td v-else>{{ $t("No") }}</td>

                        <!-- <td v-if="t.id_display">Yes</td>
                    <td v-else>No</td> -->
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </card>

      <card>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <ul class="list-group">
                <li
                  class="bg-gradient-info list-group-item m-2"
                  style="color: #ffffff"
                >
                  {{ $t("LICENSE DETAILS") }} <i class="handle"></i>
                </li>
              </ul>
              <div v-if="this.license != 0" class="m-2">
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ $t("Code") }}</th>
                      <th>{{ $t("Expiry Date") }}</th>
                      <th>{{ $t("Remaining Days") }}</th>
                      <th>{{ $t("View statistics") }}</th>
                      <th>{{ $t("Webcam Statistics") }}</th>
                      <th>{{ $t("Wi-Fi statistics") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(licenseData, index) in license" :key="index">
                      <template v-if="licenseData.is_secondary === 0">
                        <td>
                          <b>{{ licenseData.license_code }}</b>
                        </td>
                        <td>
                          <b>{{ licenseData.expiry_date }}</b>
                        </td>
                        <td>
                          <b>{{ licenseData.expirydays }}</b>
                        </td>
                      </template>
                      <template v-else>
                        <td>{{ licenseData.license_code }}</td>
                        <td>{{ licenseData.expiry_date }}</td>
                        <td>
                          {{ licenseData.expirydays }}
                        </td>
                      </template>
                      <template v-if="licenseData.is_secondary === 0">
                        <td v-if="licenseData.view_statistics == 0">
                          <base-checkbox :disabled="true"></base-checkbox>
                        </td>
                        <td v-else>
                          <base-checkbox
                            :disabled="true"
                            :checked="true"
                          ></base-checkbox>
                        </td>
                        <td v-if="licenseData.webcam_statistics == 0">
                          <base-checkbox :disabled="true"></base-checkbox>
                        </td>
                        <td v-else>
                          <base-checkbox
                            :disabled="true"
                            :checked="true"
                          ></base-checkbox>
                        </td>
                        <td v-if="licenseData.wifi_statistics == 0">
                          <base-checkbox :disabled="true"></base-checkbox>
                        </td>
                        <td v-else>
                          <base-checkbox
                            :disabled="true"
                            :checked="true"
                          ></base-checkbox>
                        </td>
                      </template>
                      <template v-else>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>N/A</td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ $t("License Code") }}</th>
                      <th>{{ $t("Expiry Date") }}</th>
                      <th>{{ $t("Days Remaining") }}</th>
                      <th>{{ $t("View statistics") }}</th>
                      <th>{{ $t("Webcam Statistics") }}</th>
                      <th>{{ $t("Wi-Fi statistics") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <base-checkbox :disabled="true"></base-checkbox>
                      </td>
                      <td>
                        <base-checkbox :disabled="true"></base-checkbox>
                      </td>
                      <td>
                        <base-checkbox :disabled="true"></base-checkbox>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </card>

      <card>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <ul class="list-group">
                <li
                  class="bg-gradient-info list-group-item m-2"
                  style="color: #ffffff"
                >
                  {{ $t("SPACE USED") }}<i class="handle"></i>
                </li>
              </ul>
              <div
                class="col-12 mt-5 mb-3"
                v-if="this.pieChart1.chartData.datasets[0].data !== ''"
              >
                <div class="chart-area">
                  <pie-chart :chart-data="pieChart1.chartData" :height="100">
                  </pie-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </card>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";
import PieChart from "src/components/Charts/PieChart";
import * as chartConfigs from "@/components/Charts/config";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  mixins: [formMixin],

  components: {
    PieChart,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  data() {
    return {
      tableColumns: [
        {
          prop: "attributes.firstname",
          propadd: "attributes.address",
          label1: "First Name",
          label2: "Address",
          label3: "User Name",
          label4: "Email",
          label5: "Display details",
          label6: "Display associated with the license",
          label7: "License code",
          label8: "Expiry date",
          label9: "Days remaining",
          label10: "View statistics",
          label11: "Webcam Statistics",
          label12: "Wi-Fi Statistics",
        },
      ],

      pieChart1: {
        chartData: {
          labels: ["SPACE USED (MB)", "SPACE FREE(MB)"],
          datasets: [
            {
              backgroundColor: ["#f01818", "#0ddee3"],
              borderWidth: 0,
              data: "",
            },
          ],
        },
      },
      tableData: [],
      display: [],
      license: [],
      expiryday: [],
      all: [],
      getlicensedetail: [],
      users: [],
      u_id: "",
      mb: 0,
      mbused: 0,
    };
  },
  methods: {
    getgroup() {
      const id = this.$route.params.id;
      axios.get(`${url}/groupgetiddata/${id}`).then((response) => {
        this.tableData = response.data;
      });
    },
    getdisplay() {
      const id = this.$route.params.id;
      axios.get(`${url}/getgroupdisplay/${id}`).then((response) => {
        this.display = response.data;
      });
    },

    async getmaxgb() {
      await axios.get(`${url}/mbuse`).then((response) => {
        this.mb = response.data;
        this.getgb();
      });
    },

    async getgb() {
      await axios.get(`${url}/mbf`).then((response) => {
        this.mbused = response.data;
        this.pieChart1.chartData.datasets[0].data = [
          this.mbused,
          this.mb - this.mbused,
        ];
      });
    },

    async created() {
      this.$store.watch(
        () => this.$store.getters["profile/me"],
        (me) => {
          this.u_id = me.id;
        }
      );
    },

    getexpiryday() {
      const id = this.$route.params.id;
      axios.get(`${url}/getexpiryday/${id}`).then((response) => {
        this.expiryday = response.data;
      });
    },
    getlicense() {
      const id = this.$route.params.id;
      axios.get(`${url}/getalllicensedata/${id}`).then((response) => {
        // Use Promise.all to wait for all promises to resolve
        Promise.all(
          response.data.map(async (newdata) => {
            const data = await this.getlicenceexpiryday(newdata.license_code);
            newdata.expirydays = data;
          })
        ).then(() => {
          this.license = response.data;
        });
      });
    },
    getlicenceexpiryday(id) {
      const data = axios
        .get(`${url}/getlicenceexpiryday/${id}`)
        .then((response) => {
          return response.data;
        });
      return data;
    },

    getuser() {
      const id = this.$route.params.id;
      axios.get(`${url}/associate/${id}`).then((response) => {
        this.users = response.data;
      });
    },
  },

  mounted() {
    this.getgroup();
    this.getlicense();
    this.getexpiryday();
    this.getuser();
    this.getdisplay();
    this.created();
    this.getmaxgb();
  },
};
</script>
<style>
.bg-gradient-info {
  background-image: linear-gradient(195deg, #49a3f1, #1a73e8) !important;
  border-radius: 8px !important;
}

.card {
  border: 0.0625rem solid #e3e3e3;
}
</style>
