<template>
    <div id="worldMap" style="height: 300px"></div>
</template>

<script>
import "d3";
import * as d3 from "d3";
import "topojson";
import DataMap from "datamaps";
import {
    throttle
} from "src/util/throttle";
import axios from 'axios';

const url = process.env.VUE_APP_API_BASE_URL;

export default {
    props: {
        cname: {
            type: String,
        },
        mapstype: {
            type: String,
        },
    },
    data() {
        return {
            color1: "#AAAAAA",
            color2: "#444444",
            highlightFillColor: "#66615B",
            borderColor: "#3c3c3c",
            highlightBorderColor: "#3c3c3c",
            mapData: {},
            getlatlongdata: [],
        };
    },

    watch: {
        cname: function () {
            // console.log(this.cname);
            if (this.cname) {
                this.getcountrycodename(this.cname);
                document.getElementById("worldMap").innerHTML = "";
                this.initVectorMap();
            }
        },
        mapstype: function () {
            if (this.mapstype) {
                this.getlatlong(this.mapstype);
                // this.getcountrycodename(this.cname);
                document.getElementById("worldMap").innerHTML = "";
                setTimeout(() => {
                    this.initVectorMap();
                }, 400);
            }
        },
    },
    methods: {
        setProjection() {
            // console.log(this.cname); return;
            if (this.cname == "China") {
                var element = document.getElementById("worldMap");
                var projection = d3.geoEquirectangular()
                    .center([108, 37])
                    .rotate([4.4, 0])
                    .scale(1100)
                    .translate([element.offsetWidth / 2, element.offsetHeight / 2])
                    ;
                var path = d3.geoPath()
                    .projection(projection);

                return {path: path, projection: projection};
            } else if (this.cname == "Italia") {
                var element = document.getElementById("worldMap");
                var projection = d3.geoEquirectangular()
                    .center([15, 42])
                    .rotate([4.4, 0])
                    .scale(3500)
                    .translate([element.offsetWidth / 2, element.offsetHeight / 2])
                    ;
                var path = d3.geoPath()
                    .projection(projection);

                return {path: path, projection: projection};
            } else if (this.cname == "North America") {
                var element = document.getElementById("worldMap");
                var projection = d3.geoEquirectangular()
                    .center([-90, 50])
                    .rotate([4.4, 0])
                    .scale(440)
                    .translate([element.offsetWidth / 2, element.offsetHeight / 2])
                    ;
                var path = d3.geoPath()
                    .projection(projection);

                return {path: path, projection: projection};
            } else if (this.cname == "Europe") {
                var element = document.getElementById("worldMap");
                var projection = d3.geoEquirectangular()
                    .center([0, 60])
                    .rotate([0, 0])
                    .scale(650)
                    .translate([element.offsetWidth / 2, element.offsetHeight / 2])
                    ;
                ;
                var path = d3.geoPath()
                    .projection(projection);

                return {path: path, projection: projection};
            }
            else {
                var element = document.getElementById("worldMap");
                var projection = d3.geoEquirectangular()
                    .center([-50, 37])
                    .rotate([4.4, 0])
                    .scale(200)
                    // .translate([element.offsetWidth / 2, element.offsetHeight / 2])
                    ;
                var path = d3.geoPath()
                    .projection(projection);

                return {path: path, projection: projection};
            }
        },
        setChinaMap() {

            var element = document.getElementById("worldMap");
            var projection = d3.geoEquirectangular()
                .center([108, 37])
                .rotate([4.4, 0])
                .scale(1100)
                .translate([element.offsetWidth / 2, element.offsetHeight / 2])
                ;
            // .center([150, -3])
            // .rotate([4.4, 0])
            // .scale(element.offsetWidth * 0.6)
            // .translate([element.offsetWidth / 2, element.offsetHeight / 2]);
            var path = d3.geoPath()
                .projection(projection);

            return {path: path, projection: projection};
        },
        getlatlong(maptype) {
            axios.get(`${url}/getlatlang/${maptype}`).then(response => {
                this.getlatlongdata = response.data;

            });
        },
        generateColors(length) {
            return d3
                .scaleLinear()
                .domain([0, length])
                .range([this.color1, this.color2]);
        },
        generateMapColors() {
            let mapDataValues = Object.values(this.mapData);
            let maxVal = Math.max(...mapDataValues);
            let colors = this.generateColors(maxVal);
            let mapData = {};
            let fills = {
                defaultFill: "#e4e4e4",
                gt500: "#ff0000"
            };
            for (let key in this.mapData) {
                let val = this.mapData[key];
                fills[key] = colors(val);
                mapData[key] = {
                    fillKey: key,
                    value: val,
                };
            }
            return {
                mapData,
                fills,
            };
        },
        initVectorMap() {
            document.getElementById("worldMap").innerHTML = "";

            let {
                fills,
                mapData
            } = this.generateMapColors();
            let worldMap = new DataMap({
                scope: "world",
                element: document.getElementById("worldMap"),
                fills,
                data: mapData,
                responsive: true,
                setProjection: this.setProjection,
                geographyConfig: {
                    borderColor: this.borderColor,
                    borderWidth: 0.5,
                    borderOpacity: 0.8,
                    highlightFillColor: this.highlightFillColor,
                    highlightBorderColor: this.highlightBorderColor,
                    highlightBorderWidth: 0.5,
                    highlightBorderOpacity: 0.8,
                },

            });
            worldMap.bubbles(
                this.getlatlongdata, {
                popupTemplate: function (geo, data) {
                    return (
                        '<div class="hoverinfo">' +
                        data.name
                    );
                },
            }
            );
            let resizeFunc = worldMap.resize.bind(worldMap);
            window.addEventListener(
                "resize",
                () => {
                    throttle(resizeFunc, 40);
                },
                false
            );
        },
        getcountrycodename(country) {
            if (country == "Italia") {
                this.mapData = {
                    ITA: 300,
                }
            } else if (country == "Europe") {
                this.mapData = {
                    ITA: 300,
                    RUS: 300,
                    UKR: 300,
                    FIN: 300,
                    SWE: 300,
                    NOR: 300,
                    ISL: 300,
                    GBR: 300,
                    IRL: 300,
                    FRA: 300,
                    ESP: 300,
                    PRT: 300,
                    GRC: 300,
                    BGR: 300,
                    ROU: 300,
                    SRB: 300,
                    HUN: 300,
                    AUT: 300,
                    SVK: 300,
                    POL: 300,
                    BLR: 300,
                    LTU: 300,
                    DEU: 300,
                    NLD: 300,
                    DNK: 300,
                    LVA: 300,
                    EST: 300,
                    BIH: 300,
                    MKD: 300,
                    MDA: 300,
                    CZE: 300,
                    CHE: 300,
                    MNE: 300,
                    ALB: 300,
                }
            } else if (country == "China") {
                this.mapData = {
                    CHN: 300,
                }
            } else if (country == "North America") {
                this.mapData = {
                    CAN: 300,
                    USA: 300,
                    MEX: 300,
                    GRL: 300,
                    BLZ: 300,
                    GTM: 300,
                    NIC: 300,
                    PAN: 300,
                    DOM: 300,
                    PRI: 300,
                    TTO: 300,
                    BHS: 300,
                    GTM: 300,
                    CUB: 300,
                    HND: 300,
                    SLV: 300,
                    CRI: 300,
                    HTI: 300,
                    JAM: 300,
                }
            } else {
                this.mapData = {}
            }
        },

    },
    async mounted() {
        // this.getcountrycodename(this.cname);
        this.getlatlong(this.mapstype);
        setTimeout(() => {
            this.initVectorMap();
        }, 300);
        // this.setProjection("world")
    },
};
</script>

<style>
</style>
