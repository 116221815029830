<template>
  <div class="content">
    <div class="row mt-3">
      <div class="col-12">
        <h4 slot="header" class="card-title">{{ $t("GROUP LIST") }}</h4>
        <card card-body-classes="table-full-width">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                :placeholder="$t('Per page')"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <base-input>
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  :placeholder="$t('Search records')"
                  v-model="query"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
            </div>
            <div id="app">
              <table class="w-100" v-if="tableData.length !== 0">
                <thead class="border-bottom">
                  <tr>
                    <th
                      @click="sortChange(table)"
                      v-for="(table, key) in tableData.title"
                      :key="key"
                      class="table-header"
                    >
                      <span>
                        {{ $t(table)
                        }}<SortIcon
                          :topColor="computedTopColor(table, 'top')"
                          :bottomColor="computedTopColor(table, 'bottom')"
                        />
                      </span>
                    </th>
                    <th class="text-center">{{ $t("action") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="table in tableData.data"
                    :key="table.id"
                    v-if="!tableData.data['']"
                  >
                    <td v-for="t in table" :key="t.id">
                      {{ t.storeparamvalue }}
                    </td>
                    <template
                      v-for="(t, k) in table"
                      v-if="t.name == 'address' && t.l_gid != ''"
                    >
                      <td
                        class="text-center"
                        :key="k"
                        scope="row"
                        v-if="t.group_id == t.l_gid"
                      >
                        <button
                          @click="pairDisplay(t.group_id)"
                          title="Pair Display"
                          class="'pair btn-link"
                          type="success"
                          style="color: var(--primary)"
                        >
                          <i class="tim-icons icon-attach-87"></i>
                        </button>
                        <button
                          @click="goToEdit(t.group_id)"
                          title="Edit"
                          class="'edit btn-link"
                          type="success"
                          style="color: var(--primary)"
                        >
                          <i class="tim-icons icon-pencil"></i>
                        </button>
                        <button
                          @click="goToDetails(t.group_id)"
                          title="Detail"
                          class="'details btn-link"
                          type="success"
                          style="color: var(--primary)"
                        >
                          <i class="tim-icons icon-bag-16"></i>
                        </button>
                      </td>

                      <td class="text-center" :key="k" scope="row" v-else>
                        <button
                          @click="pairDisplay(t.group_id)"
                          title="Pair Display"
                          class="'pair btn-link"
                          type="success"
                          style="color: var(--primary)"
                        >
                          <i class="tim-icons icon-attach-87"></i>
                        </button>
                        <button
                          @click="goToEdit(t.group_id)"
                          title="Edit"
                          class="'edit btn-link"
                          type="success"
                          style="color: var(--primary)"
                        >
                          <i class="tim-icons icon-pencil"></i>
                        </button>
                        <button
                          @click="destroy(t.group_id)"
                          title="Delete"
                          class="'delete btn-link"
                          type="success"
                          style="color: var(--primary)"
                        >
                          <i class="tim-icons icon-simple-remove"></i>
                        </button>
                        <button
                          @click="goToDetails(t.group_id)"
                          title="Detail"
                          class="'details btn-link"
                          type="success"
                          style="color: var(--primary)"
                        >
                          <i class="tim-icons icon-bag-16"></i>
                        </button>
                      </td>
                    </template>
                  </tr>

                  <tr v-else></tr>
                </tbody>
              </table>
            </div>
          </div>

          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
                {{ $t("of") }} {{ total }} {{ $t("entries") }}
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";

import _ from "lodash";
import { BasePagination } from "src/components";
// import users from "./users";
import Fuse from "fuse.js";
import swal from "sweetalert2";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "src/components/ValidationError.vue";
import SortIcon from "@/assets/customImg/SortIcon.vue";
import { getTopColor, getBottomColor } from "../../util/utils";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  mixins: [formMixin],

  components: {
    SortIcon,
    ValidationError,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.tableData.total < highBound) {
        highBound = this.total;
      }

      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.total;
    },
  },

  data() {
    return {
      currentSort: "firstname",
      currentSortDir: "asc",

      currentPage: 1,
      // postsPerPage: 5,
      // perPageOptions: [5, 10, 25, 50],
      // search: '',

      query: "",
      propsToSearch: ["firstname"],
      tableData: [],
      group: [],
      licenses: [],
      searchedData: [],
      fuseSearch: null,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
      },

      sortparam: {
        field: "name",
        sort: "asc",
      },
    };
  },
  methods: {
    computedTopColor(table, arrowIcon) {
      if (arrowIcon === "top") {
        return getTopColor(table, this.sortparam);
      } else {
        return getBottomColor(table, this.sortparam);
      }
    },
    getListDebounced: _.debounce(function () {
      this.getgroup();
    }, 300),

    sortChange(data) {
      this.sortparam.field = data;
      this.sortparam.sort = this.sortparam.sort == "asc" ? "desc" : "asc";
      this.getgroup();
    },

    setCurrentPage(direction) {
      if (direction === -1 && this.pagination.currentPage > 1) {
        this.pagination.currentPage -= 1;
      } else if (
        direction === 1 &&
        this.pagination.currentPage <
          this.tableData.data.length / this.pagination.perPage
      ) {
        this.pagination.currentPage += 1;
      }
    },

    async getgroup() {
      let params = {
        sort: {
          ...this.sortparam,
        },
        filter: {
          ...(this.query ? { searchValue: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };
      await this.$store.dispatch("groups/associate", params);
      this.tableData = await this.$store.getters["groups/group_list"];
    },

    async destroy(id) {
      try {
        const confirmation = await swal.fire({
          title: this.$t("Are you sure?"),
          text: this.$t("You won't be able to revert this!"),
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: this.$t("Yes, delete it!"),
          buttonsStyling: false,
        });

        if (confirmation.value === true) {
          await this.$store.dispatch("groups/destroy", id);
          this.$notify({
            type: "success",
            message: this.$t("Group deleted successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.getgroup();
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: this.$t("This Group still has associated Users."),
            icon: "tim-icons icon-bell-55",
          });
        } else {
          this.$notify({
            type: "danger",
            message: this.$t("Oops, something went wrong!"),
            icon: "tim-icons icon-bell-55",
          });
        }
      }
    },

    goToEdit(id) {
      this.$router.push({
        name: "Edit Group Manager",
        params: {
          id,
        },
      });
    },

    goToDetails(id) {
      this.$router.push({
        name: "Group Detail",
        params: {
          id,
        },
      });
    },

    addLicense(id) {
      this.$router.push({
        name: "Add License",
        params: {
          id,
        },
      });
    },

    pairDisplay(id) {
      this.$router.push({
        name: "Display Associate",
        params: {
          id,
        },
      });
    },
  },

  mounted() {
    // Fuse search initialization.
    // this.fuseSearch = new Fuse(this.tableData, {
    //     keys: ["firstname"],
    //     threshold: 0.3,
    // }),
    this.getgroup();
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    query: {
      handler: "getListDebounced",
      immediate: true,
    },

    // query(value) {
    //     let result = this.tableData;
    //     if (value !== "") {
    //         result = this.fuseSearch.search(this.query);
    //     }
    //     this.searchedData = result;
    // },
    pagination: {
      handler: "getgroup",
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped>
table thead,
table tbody {
  font-size: 1rem;
  text-transform: uppercase;
}
table tbody tr {
  border-bottom: 1px solid #e9ecef;
}
table thead tr th,
table tbody tr td {
  padding: 5px;
}
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}
.table-header {
  cursor: pointer;
}
.table-header span {
  display: flex;
  align-items: center;
}
</style>
