import { render, staticRenderFns } from "./NewLicense.vue?vue&type=template&id=e47232ec"
import script from "./NewLicense.vue?vue&type=script&lang=js"
export * from "./NewLicense.vue?vue&type=script&lang=js"
import style0 from "./NewLicense.vue?vue&type=style&index=0&id=e47232ec&prod&scope=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports